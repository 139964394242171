import React from 'react';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/styles';
import Auth from '../../util/Auth';
import Typography from '@material-ui/core/Typography';
import { FieldArray, Form, Formik } from 'formik';
import { TextField } from '@material-ui/core';
import Select from 'react-select';
import moment from 'moment';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LEASE);

const TableStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
  },
  tableRow: {
    width: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    placeItems: 'center center',
    gap: '1.5rem',
  },
}));

export const AssigneeTable = ({ Lease, Operators, queries }) => {
  const { createAssignment, deleteAssignment, editAssignment } = queries;

  const classes = TableStyles();

  const { assignments } = Lease;

  //Capture Leased Lands for use in Assignment Mutation
  const lands = Lease.leasedLands.map((land) => land.Tract.id);

  // Add the lands on the table
  const tryLands = Lease.leasedLands.map((Land) => {
    return {
      title: `${Land.Tract.id} Acres`,
      field: `${Land.Tract.id}`,
      type: 'numeric',
      editable: 'onAdd',
    };
  });

  //create empty array to handle form data
  const finalData = [];

  if (Array.isArray(assignments) || assignments.length > 0) {
    // loop through each assignee
    assignments.forEach((assign) => {
      //create the new data object
      const dataObj = {
        id: assign.id,
        book: assign.book,
        volPage: assign.volPage,
        dateFiled: assign.dateFiled,
        effectiveDate: assign.effectiveDate,
        totalAcres: assign.totalAcres,
        grantee: assign.grantee.name,
        grantor: assign.grantor.name,
        comments: assign.comments,
        wellsDrilled: assign.wellsDrilled,
        docType: assign.docType,
      };

      delete dataObj.assignments;

      assign.assignmentInterests.forEach((assn) => {
        //loop through the assignments to capture all of the tract ids, and acres
        dataObj[assn.Tract.id] = assn.acres;
      });
      //push data onto empty array
      finalData.push(dataObj);
    });
  }

  const formikData = {
    finalData,
  };

  return (
    <>
      <Formik initialValues={formikData} onSubmit={() => {}}>
        {(props) => {
          return (
            <Form className={classes.form}>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                Assignment History
              </Typography>
              <FieldArray name='finalData'>
                {({ push, remove, insert }) => {
                  const columns = [
                    {
                      title: 'Vol/Page',
                      field: 'volPage',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'Book',
                      field: 'book',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'Effective Date',
                      field: 'effectiveDate',
                      render: (rowData) => moment(rowData.effectiveDate).format('MM/DD/YYYY'),
                      customSort: (a, b) => (moment(a.effectiveDate).isAfter(moment(b.effectiveDate)) ? 1 : -1),
                      editComponent: (props) => (
                        <TextField type='date' onChange={(e) => props.onChange(e.target.value)} />
                      ),
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'Date Filed',
                      field: 'dateFiled',
                      render: (rowData) => moment(rowData.dateFiled).format('MM/DD/YYYY'),
                      customSort: (a, b) => (moment(a.dateFiled).isAfter(moment(b.dateFiled)) ? 1 : -1),
                      editComponent: (props) => (
                        <TextField type='date' onChange={(e) => props.onChange(e.target.value)} />
                      ),
                      type: 'date',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'Doc Type',
                      field: 'docType',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'Grantor',
                      field: 'grantor',
                      editable: 'onAdd',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                      editComponent: ({ onChange }) => (
                        <Select
                          options={Operators}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={onChange}
                          styles={{
                            menu: (provided, state) => ({ ...provided, width: 150 }),
                            menuList: (provided, state) => ({ ...provided, width: 150 }),
                          }}
                        />
                      ),
                    },
                    {
                      title: 'Grantee',
                      field: 'grantee',
                      editable: 'onAdd',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                      editComponent: ({ onChange }) => (
                        <Select
                          options={Operators}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={onChange}
                          styles={{
                            menu: (provided, state) => ({ ...provided, width: 150 }),
                            menuList: (provided, state) => ({ ...provided, width: 150 }),
                          }}
                        />
                      ),
                    },
                    ...tryLands,
                    {
                      title: 'Total Acres',
                      field: 'totalAcres',
                      render: (rowData) => (!rowData ? '0' : rowData.totalAcres.toFixed(8)),
                      editable: 'never',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Comments',
                      field: 'comments',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Wells Drilled',
                      field: 'wellsDrilled',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                  ];

                  return (
                    <MaterialTable
                      columns={columns}
                      data={props.values.finalData}
                      editable={{
                        onRowAdd: (newData) => {
                          const landsAssigned = lands
                            .filter((land) => newData[land] && newData[land] !== '0')
                            .map((land) => ({
                              TractID: land,
                              acres: !newData[land] ? null : newData[land],
                            }));
                          return createAssignment({
                            leaseID: Lease.id,
                            landIDs: landsAssigned,
                            AssignmentInfo: {
                              volPage: newData.volPage,
                              book: newData.book,
                              effectiveDate: newData.effectiveDate,
                              dateFiled: newData.dateFiled,
                              docType: newData.docType,
                              grantor: newData.grantor.id,
                              grantee: newData.grantee.id,
                              comments: !newData.comments ? 'None' : newData.comments,
                              wellsDrilled: !newData.wellsDrilled ? 'None' : newData.comments,
                            },
                          }).then((data) => {
                            const update = data.data.createAssignment;
                            const pushData = {
                              ...newData,
                              grantor: update.grantor.name,
                              grantee: update.grantee.name,
                              id: update.id,
                              totalAcres: update.totalAcres,
                            };
                            push(pushData);
                          });
                        },
                        onRowUpdate: (newData, oldData) => {
                          const newValues = {
                            id: oldData.id,
                            volPage: newData.volPage,
                            book: newData.book,
                            effectiveDate: newData.effectiveDate,
                            dateFiled: newData.dateFiled,
                            docType: newData.docType,
                            comments: newData.comments,
                            wellsDrilled: newData.wellsDrilled,
                          };

                          const newInfo = {
                            ...oldData,
                            volPage: oldData.volPage === newData.volPage ? oldData.volpage : newData.volPage,
                            book: oldData.book === newData.book ? oldData.book : newData.book,
                            effectiveDate:
                              oldData.effectiveDate === newData.effectiveDate
                                ? oldData.effectiveDate
                                : newData.effectiveDate,
                            dateFiled: oldData.dateFiled === newData.dateFiled ? oldData.dateFiled : newData.dateFiled,
                            docType: oldData.docType === newData.docType ? oldData.docType : newData.docType,
                            comments: oldData.comments === newData.comments ? oldData.comments : newData.comments,
                            wellsDrilled:
                              oldData.wellsDrilled === newData.wellsDrilled
                                ? oldData.wellsDrilled
                                : newData.wellsDrilled,
                          };

                          delete newInfo.tableData;
                          delete newInfo.Operator;

                          return editAssignment(newValues).then(() => {
                            remove(oldData.tableData.id);
                            insert(oldData.tableData.id, newInfo);
                          });
                        },
                        onRowDelete: (oldData) => {
                          const deleteData = {
                            id: oldData.id,
                          };
                          return deleteAssignment(deleteData).then(() => remove(oldData.tableData.id));
                        },
                      }}
                      options={{
                        headerStyle: {
                          backgroundColor: '#1976d2',
                          color: '#FFF',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                        },
                        cellStyle: {
                          maxWidth: 0,
                          overFlowX: 'auto',
                        },
                        exportButton: true,
                        addRowPosition: 'first',
                        searchFieldAlignment: 'left',
                        toolbarButtonAlignment: 'left',
                        pageSize: 20,
                      }}
                      title={''}
                    />
                  );
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AssigneeTable;
