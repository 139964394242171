import gql from 'graphql-tag';
import * as yup from 'yup';
import { v4 } from 'uuid';

export const UnitSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup
    .string()
    .required()
    .trim(),
  status: yup.string().required(),
  unitCreatedBy: yup
    .string()
    .nullable()
    .trim(),
  operatorHistory: yup
    .string()
    .nullable()
    .trim(),
  currentOperator: yup
    .string()
    .nullable()
    .trim(),
  familyId: yup
    .string()
    .nullable()
    .trim(),
  rrcNumber: yup
    .string()
    .nullable()
    .trim(),
  unitGMA: yup
    .string()
    .nullable()
    .trim(),
  familyNMA: yup
    .string()
    .nullable()
    .trim(),
  propertyDesc: yup
    .string()
    .nullable()
    .trim(),
  depths: yup
    .string()
    .nullable()
    .trim(),
  leaseRoyaltyRate: yup
    .string()
    .nullable()
    .trim(),
  mesDOI: yup
    .string()
    .nullable()
    .trim(),
  mpiDOI: yup
    .string()
    .nullable()
    .trim(),
  sroDOI: yup
    .string()
    .nullable()
    .trim(),
  effectiveDate: yup
    .string()
    .nullable()
    .trim(),
  recordingData: yup
    .string()
    .nullable()
    .trim(),
  original: yup.boolean().nullable(),
  ratification: yup
    .string()
    .nullable()
    .trim(),
  ratifyDate: yup
    .string()
    .nullable()
    .trim(),
  ratifyData: yup
    .string()
    .nullable()
    .trim(),
  mapUrl: yup
    .string()
    .nullable()
    .trim(),
  unitType: yup
    .string()
    .nullable()
    .trim(),
  unitNotes: yup
    .string()
    .nullable()
    .trim(),
});

export const DefaultUnit = {
  id: v4(),
  name: 'Default',
  unitCreatedBy: 'Default',
  operatorHistory: 'Default',
  currentOperator: 'Default',
  unitGMA: 'Default',
  familyNMA: 'Default',
  propertyDesc: 'Default',
  depths: 'Default',
  status: 'Default',
  leaseRoyaltyRate: 'Default',
  mesDOI: 'Default',
  mpiDOI: 'Default',
  sroDOI: 'Default',
  familyId: 'Default',
  rrcNumber: 'Default',
  wellCount: 'Default',
  effectiveDate: 'Default',
  recordingData: 'Default',
  original: false,
  ratification: 'Default',
  ratifyDate: 'Default',
  ratifyData: 'Default',
  mapUrl: 'Default',
  unitNotes: 'None',
  gloPoolingAgreement: 'None',
};

const UNIT_FRAGMENT = gql`
  {
    id
    name
    unitCreatedBy
    operatorHistory
    currentOperator
    unitGMA
    familyNMA
    propertyDesc
    depths
    status
    leaseRoyaltyRate
    mesDOI
    mpiDOI
    sroDOI
    familyId
    rrcNumber
    wellCount
    effectiveDate
    recordingData
    original
    ratification
    ratifyDate
    ratifyData
    mapUrl
    unitType
    unitNotes
    gloPoolingAgreement
    platURL
    wells {
      id
      name
      familyID
      OGL
      divisionOrder {
        mpiDOI
        sroDOI
        mesDOI
      }
      operatedBy {
        operatorNumber
      }
      lease {
        id
        leaseName
      }
      drilledOn {
        id
      }
      lease {
        id
        leaseName
      }
    }
  }
`;

export const GET_ALL_UNITS = gql`
    query{
        Unit
        ${UNIT_FRAGMENT}
    }
`;

export const GET_UNITS_FOR_TABLE = gql`
  query {
    Unit {
      id
      name
      unitCreatedBy
      currentOperator
      wellCount
      unitType
      status
      wells {
        id
        name
        drilledOn {
          id
        }
        lease {
          id
          leaseName
        }
      }
    }
  }
`;

export const GET_UNIT = gql`
    query Unit($id: ID!){
        Unit(id: $id)
        ${UNIT_FRAGMENT}
    }

`;

export const DELETE_UNIT = gql`
    mutation DeleteUnit($id: ID!){
        DeleteUnit(id: $id)
        ${UNIT_FRAGMENT}
    }
`;

export const CREATE_UNIT = gql`
    mutation CreateUnit(
    $name: String
    $id: ID!
    $unitCreatedBy: String
    $operatorHistory: String
    $currentOperator: String
    $unitGMA: String
    $familyNMA: String
    $propertyDesc: String
    $depths: String
    $leaseRoyaltyRate: String
    $mesDOI: String
    $mpiDOI: String
    $sroDOI: String
    $familyId: String
    $rrcNumber: String
    $status: String
    $effectiveDate: String
    $recordingData: String
    $original: Boolean
    $ratification: String
    $ratifyDate: String
    $ratifyData: String
    $mapUrl: String
    $unitType: String
    $unitNotes: String
    $gloPoolingAgreement: String
    ){
    CreateUnit(
    name: $name
    id: $id
    unitCreatedBy: $unitCreatedBy
    operatorHistory: $operatorHistory
    currentOperator: $currentOperator
    unitGMA: $unitGMA
    familyNMA: $familyNMA
    propertyDesc: $propertyDesc
    depths: $depths
    leaseRoyaltyRate: $leaseRoyaltyRate
    mesDOI: $mesDOI
    mpiDOI: $mpiDOI
    sroDOI: $sroDOI
    familyId: $familyId
    rrcNumber: $rrcNumber
    status: $status
    effectiveDate: $effectiveDate
    recordingData: $recordingData
    original: $original
    ratification: $ratification
    ratifyDate: $ratifyDate
    ratifyData: $ratifyData
    mapUrl: $mapUrl
    unitType: $unitType
    unitNotes: $unitNotes
    gloPoolingAgreement: $gloPoolingAgreement
    )
        ${UNIT_FRAGMENT}
    }
`;

export const EDIT_UNIT = gql`
    mutation UpdateUnit(
        $name: String
        $id: ID!
        $unitCreatedBy: String
        $operatorHistory: String
        $currentOperator: String
        $unitGMA: String
        $familyNMA: String
        $propertyDesc: String
        $depths: String
        $leaseRoyaltyRate: String
        $mesDOI: String
        $mpiDOI: String
        $sroDOI: String
        $familyId: String
        $rrcNumber: String
        $status: String
        $effectiveDate: String
        $recordingData: String
        $original: Boolean
        $ratification: String
        $ratifyDate: String
        $ratifyData: String
        $mapUrl: String
        $unitType: String
        $unitNotes: String
        $gloPoolingAgreement: String
        $platURL: String
    ){
        UpdateUnit(
            name: $name
            id: $id
            unitCreatedBy: $unitCreatedBy
            operatorHistory: $operatorHistory
            currentOperator: $currentOperator
            unitGMA: $unitGMA
            familyNMA: $familyNMA
            propertyDesc: $propertyDesc
            depths: $depths
            leaseRoyaltyRate: $leaseRoyaltyRate
            mesDOI: $mesDOI
            mpiDOI: $mpiDOI
            sroDOI: $sroDOI
            familyId: $familyId
            rrcNumber: $rrcNumber
            status: $status
            effectiveDate: $effectiveDate
            recordingData: $recordingData
            original: $original
            ratification: $ratification
            ratifyDate: $ratifyDate
            ratifyData: $ratifyData
            mapUrl: $mapUrl
            unitType: $unitType
            unitNotes: $unitNotes
            gloPoolingAgreement: $gloPoolingAgreement
            platURL: $platURL
        )
        ${UNIT_FRAGMENT}
    }
`;

export const ADD_UNIT_WELL = gql`
  mutation AddUnitWells($from: _WellInput!, $to: _UnitInput!) {
    AddUnitWells(from: $from, to: $to) {
      from {
        id
        name
      }
      to {
        id
      }
    }
  }
`;

export const REMOVE_UNIT_WELL = gql`
  mutation RemoveUnitWells($from: _WellInput!, $to: _UnitInput!) {
    RemoveUnitWells(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const ADD_UNIT_TITLE = gql`
  mutation addUnitTitle($UnitID: ID!, $info: UnitTitleInfo) {
    addUnitTitle(UnitID: $UnitID, info: $info) {
      id
      titleRun {
        id
        volPage
        effectiveDate
        dateFiled
        comments
        grantor
        grantee
        docType
      }
    }
  }
`;

export const REMOVE_UNIT_TITLE = gql`
  mutation removeUnitTitle($UnitTitleID: ID!) {
    removeUnitTitle(UnitTitleID: $UnitTitleID) {
      id
      titleRun {
        id
        volPage
        effectiveDate
        dateFiled
        comments
        grantor
        grantee
        docType
      }
    }
  }
`;

export const UPDATE_UNIT_TITLE = gql`
  mutation updateUnitTitle($UnitTitleID: ID!, $info: UnitTitleInfo) {
    updateUnitTitle(UnitTitleID: $UnitTitleID, info: $info) {
      id
      titleRun {
        id
        volPage
        effectiveDate
        dateFiled
        comments
        grantor
        grantee
        docType
        fileName
      }
    }
  }
`;
