import React from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import Auth from '../../util/Auth';
import { Button, MenuItem, TextField as MaterialUITextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import operatorSchema from '../../components/operators/OperatorSchema';
import DeleteOperatorModal from './DeleteOperatorModal';
import { Prompt } from 'react-router';
import moment from 'moment';
import Select from 'react-select';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_OPERATORS);

const OperatorDetailPageStyles = makeStyles((theme) => ({
  Switch: {
    margin: 0,
  },
  BottomBar: {
    display: hasPermissions ? 'flex' : 'none',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  SubmitButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
    float: 'left',
  },
  DeleteButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
    float: 'right',
  },
  FormControlLabel: {
    paddingRight: '5em',
  },
  Select: {
    display: 'block',
  },
  FullWidthTextBox: {
    marginBottom: 20,
    marginTop: 20,
  },
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
  },
}));

const OperatorDetailPage = ({
                              Operator,
                              onSubmit,
                              addContact,
                              removeContact,
                              updateContact,
                              deleteOperator,
                              history,
                            }) => {
  const classes = OperatorDetailPageStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = React.useState(false);

  const current = [{ type: 'Yes' }, { type: 'No' }];

  return (
    <Formik
      initialValues={Operator}
      validationSchema={operatorSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        return onSubmit(values).then(
          (value) => {
            enqueueSnackbar('Form Successfully Edited', { variant: 'success' });
            actions.setSubmitting(false);
            closeSnackbar();
          },
          (error) => {
            enqueueSnackbar('Error Submitting Form', { variant: 'error' });
            actions.setSubmitting(false);
            closeSnackbar();
          }
        );
      }}
    >
      {(props) => {
        return (
          <>
            <Prompt
              when={props.dirty && props.submitCount < 1}
              message='You have not submitted the form, are you sure you want to leave?'
            />
            <Form>
              <div className="flex-row">
                <div className="table-responsive card">
                  <table className="table card-body">
                    <thead hidden={true}>
                    <tr>
                      <th>HIDDEN</th>
                      <th>HIDDEN</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={{ borderBottom: 'none' }}>
                      <th scope="row" style={{ borderBottom: 'none' }}>
                        <Field
                          name="name"
                          placeholder="Contact Name"
                          label="Company Name"
                          style={{ width: '60%' }}
                          component={TextField}
                        />
                      </th>
                      <td colSpan={4} style={{ borderBottom: 'none' }}>
                        <Field
                          name="type"
                          select
                          style={{ paddingRight: 90, width: '60%' }}
                          label="Type of Contact"
                          defaultValue=""
                          component={TextField}
                        >
                          <MenuItem value={'Attorney'}>Attorney</MenuItem>
                          <MenuItem value={'County Gov'}>County Gov</MenuItem>
                          <MenuItem value={'Education'}>Education</MenuItem>
                          <MenuItem value={'Family Contact'}>Family Contact</MenuItem>
                          <MenuItem value={'Family Office'}>Family Office</MenuItem>
                          <MenuItem value={'Lessee'}>Lessee</MenuItem>
                          <MenuItem value={'O&G'}>O&G</MenuItem>
                          <MenuItem value={'Office/Business'}>Office/Business</MenuItem>
                          <MenuItem value={'Regulatory'}>Regulatory</MenuItem>
                          <MenuItem value={'ROW'}>ROW</MenuItem>
                          <MenuItem value={'Surface Contractor'}>Surface Contractor</MenuItem>
                        </Field>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ width: '40%', borderTop: 'none', borderBottom: 'none' }}>
                        <Field
                          name='address1'
                          placeholder='Address 1'
                          style={{ width: '100%' }}
                          label='Address 1'
                          component={TextField}
                        />
                      </td>
                      <td colSpan={2} style={{ width: '40%', borderTop: 'none', borderBottom: 'none' }}>
                        <Field
                          name='operatorNumber'
                          placeholder='Operator #'
                          style={{ width: '100%' }}
                          label='Operator #'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='city'
                          placeholder='City'
                          style={{ width: '100%' }}
                          label='City'
                          component={TextField}
                        />
                      </td>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='mesAccountNumber'
                          placeholder='MES Account #'
                          style={{ width: '100%' }}
                          label='MES Account Number'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='state'
                          placeholder='State'
                          style={{ width: '100%' }}
                          label='State'
                          component={TextField}
                        />
                      </td>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='mpiAccountNumber'
                          placeholder='MPI Account #'
                          style={{ width: '100%' }}
                          label='MPI Account Number'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='zip'
                          style={{ width: '100%' }}
                          placeholder='Zip Code'
                          label='Zip Code'
                          component={TextField}
                        />
                      </td>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='sroAccountNumber'
                          placeholder='SRO Account #'
                          style={{ width: '100%' }}
                          label='SRO Account Number'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='phone'
                          placeholder='Phone #'
                          style={{ width: '100%' }}
                          label='Phone #'
                          component={TextField}
                        />
                      </td>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='taxPayerID'
                          placeholder='Tax Payer ID-CONG'
                          style={{ width: '100%' }}
                          label='Tax Payer ID-CONG'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='companyWebsite'
                          placeholder='Company Website'
                          style={{ width: '100%' }}
                          label='Company Website'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='notes'
                          placeholder='Notes'
                          label='Notes'
                          style={{ width: '100%' }}
                          component={TextField}
                          multiline={true}
                          rows={4}
                          variant='outlined'
                          fullWidth
                        />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div className="flex-row">
                  <FieldArray name="contacts">
                    {({ push, remove, insert }) => {
                      const columns = [
                        {
                          title: 'Current',
                          field: 'current',
                          editComponent: ({ onChange }) => (
                            <Select
                              options={current}
                              getOptionLabel={(option) => option.type}
                              getOptionValue={(option) => option.type}
                              onChange={({ type }) => onChange(type)}
                            />
                          ),
                        },
                        {
                          title: 'Name',
                          field: 'contactName',
                          cellStyle: {
                            whiteSpace: 'break-word',
                            textAlign: 'left',
                          },
                        },
                        {
                          title: 'Position',
                          field: 'position',
                        },
                        {
                          title: 'Direct Line',
                          field: 'directLine',
                          cellStyle: {
                            whiteSpace: 'nowrap',
                            textAlign: 'left',
                          },
                        },
                        {
                          title: 'E-Mail',
                          field: 'email',
                        },
                        {
                          title: 'Cell Number',
                          field: 'cell',
                        },
                        {
                          title: 'Date',
                          field: 'date',
                          render: (rowData) =>
                            !rowData.date || rowData.date === 'N/A' ? 'N/A' : moment(rowData.date).format('MM/DD/YYYY'),
                          editComponent: (props) => (
                            <MaterialUITextField type="date" onChange={(e) => props.onChange(e.target.value)} />
                          ),
                          type: 'date',
                          cellStyle: {
                            whiteSpace: 'nowrap',
                            textAlign: 'left',
                          },
                        },
                      ];
                      return (
                        <MaterialTable
                          columns={columns}
                          data={props.values.contacts.map(o => ({ ...o }))}
                          title={'Contact'}
                          editable={{
                            onRowAdd: (newData) => {
                              const addData = {
                                operatorID: props.values.id,
                                contactName: !newData.contactName ? 'N/A' : newData.contactName,
                                position: !newData.position ? 'N/A' : newData.position,
                                directLine: !newData.directLine ? 'N/A' : newData.directLine,
                                cell: !newData.cell ? 'N/A' : newData.cell,
                                email: !newData.email ? 'N/A' : newData.email,
                                current: !newData.current ? 'No' : newData.current,
                                date: !newData.date ? 'N/A' : newData.date,
                              };
                              return addContact(addData).then(({ data: { createNewContact } }) => {
                                newData.id = createNewContact.id;
                                push(newData);
                              });
                            },
                            onRowUpdate: (newData, oldData) => {
                              return updateContact(newData).then(() => {
                                remove(oldData.tableData.id);
                                insert(oldData.tableData.id, newData);
                              });
                            },
                            onRowDelete: (oldData) => {
                              return removeContact(oldData.id).then(() => remove(oldData.tableData.id));
                            },
                          }}
                          options={{
                            headerStyle: {
                              backgroundColor: '#1976d2',
                              color: '#FFF',
                            },
                            rowStyle: (rowData) => ({
                              backgroundColor: rowData.current === 'Yes' ? '#EEE' : '#FFF',
                            }),
                            cellStyle: {
                              maxWidth: 0,
                              overFlowX: 'auto',
                            },
                            pageSize: 20,
                            exportButton: true,
                            toolbarButtonAlignment: 'left',
                            searchFieldAlignment: 'left',
                          }}
                        />
                      );
                    }}
                  </FieldArray>
                </div>

                <div className={classes.BottomBar}>
                  <Button
                    variant='contained'
                    color='secondary'
                    className={classes.DeleteButton}
                    onClick={() => setModalOpen(true)}
                  >
                    Delete Operator
                  </Button>

                  <DeleteOperatorModal
                    open={modalOpen}
                    handleClose={() => setModalOpen(false)}
                    handleAgree={() => {
                      deleteOperator({
                        variables: {
                          id: props.values.id,
                        },
                        update: (store, { data: { DeleteOperator } }) => {
                          store.data.delete(DeleteOperator.id);
                        },
                      })
                        .then(({ data }) => {
                          enqueueSnackbar('Successfully deleted Contact', { variant: 'success' });
                          history.push('/Operators');
                        })
                        .catch((err) => {
                          console.error(err);
                          enqueueSnackbar('Could not delete Contact', { variant: 'error' });
                        });
                    }}
                  />

                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.SubmitButton}
                    onClick={() => props.handleSubmit()}
                    disabled={!props.isValid}
                  >
                    Update Operator
                  </Button>
                </div>


              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default OperatorDetailPage;
