import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Auth from '../../util/Auth';
import { Button, Typography, Paper, TextField } from '@material-ui/core';

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(''); // For user notifications

  useEffect(() => {
    // Retrieve the success message from localStorage and then clear it
    const loginMessage = localStorage.getItem('loginMessage');
    if (loginMessage) {
      setMessage(loginMessage);
      localStorage.removeItem('loginMessage');
    }
  }, []);

  const handleLogin = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    if (!username || !password) {
      setMessage('Please fill in both username and password.');
    } else {
      // Proceed with the login process
      try {
        Auth.login(username, password);
      }catch (error){// You need to notify the user of something if they cant login
        setMessage(`Error ${error}`);

      }
    }
  };

  const glassEffectStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    padding: '30px',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    backdropFilter: 'blur(0.8px)',
    WebkitBackdropFilter: 'blur(0.8px)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
  };

  const rootStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #FF3CAC 0%, #784BA0 0%, #2B86C5 100%)',
  };

  return (
    <div style={rootStyle}>
      <Paper style={glassEffectStyle}>
        <Typography variant="h4" style={{ marginBottom: '20px' }}>
          <u>Land Machine</u>
        </Typography>
        {message && (
          <Typography color="error" style={{ marginBottom: '20px' }}>
            {message}
          </Typography>
        )}
        <form onSubmit={handleLogin}
              style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TextField
            label="Username or Email"
            variant="outlined"
            style={{ width: '300px', marginBottom: '20px' }} // Added marginBottom for spacing
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete={username}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            style={{ width: '300px', marginBottom: '20px' }} // Added marginBottom for spacing
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete={password}
          />
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <Button
              type="submit" // Specify the button type as 'submit' to submit the form
              variant="contained"
              color="primary"
              style={{ borderRadius: '20px', padding: '10px 30px' , width: '150px' }}
            >
              Login
            </Button>
            <Link to={"/update-password"}>
            <Button
              variant="contained"
              color="secondary"
              style={{ borderRadius: '20px', padding: '10px 10px'}}
              // onClick={handleForgotPassword}
            >
              Reset Password
            </Button>
            </Link>
          </div>
        </form>
      </Paper>
    </div>
);
}

export default LoginForm;
