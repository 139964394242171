import React from 'react';
import { WellFormStyles } from './WellTabPage';
import { Formik, Form, Field } from 'formik';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import { TextField } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
import { EDIT_WELL } from '../../components/wells/WellQueries';
import { Button } from '@material-ui/core';

const TitleQuery = gql`
  query GetWell($id: ID!) {
    Well(id: $id) {
      id
      name
      titleOpinion
    }
  }
`;

const TitleOpinion = ({ history, match, enqueueSnackbar }) => {
  const classes = WellFormStyles();

  const [updateNotes] = useMutation(EDIT_WELL);

  const { data, loading, error } = useQuery(TitleQuery, {
    variables: { id: match.params.wellID },
  });

  if (loading) {
    return <LoadingView />;
  }
  if (error) {
    return error.toString();
  }
  // Break well out of data returned from query
  const Well = data.Well[0] || [];

  return (
    <Formik
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        return updateNotes({ variables: values })
          .then(() => {
            enqueueSnackbar('Successfully updated Title Opinion', { variant: 'success' });
            actions.setSubmitting(false);
          })
          .catch((error) => {
            enqueueSnackbar(`Could not update Title Opinion: ${error.toString()}`, { variant: 'error' });
            actions.setSubmitting(false);
          });
      }}
      initialValues={Well}
    >
      {(props) => {
        return (
          <Form>
            <div>
              <Typography className={classes.title} color='textSecondary' gutterBottom style={{ textAlign: 'center' }}>
                {Well.name}
              </Typography>
            </div>
            <br />
            <hr />
            <br />
            <Field
              name='titleOpinion'
              placeholder='Title Opinion'
              label='Title Opinion'
              component={TextField}
              multiline={true}
              rows={50}
              variant='outlined'
              className={classes.FullWidthTextBox}
              fullWidth
            />
            <div>
              <Button variant='contained' color='primary' type='submit' fullWidth>
                Update Title Opinion
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TitleOpinion;
