import * as yup from 'yup';
import { isFraction } from '../../util/Utils';

export const TractSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .nullable(),
  abstract: yup.string().nullable(),
  blk: yup.string().nullable(),
  bonusDelayAcres: yup.number().nullable(),
  caliche: yup.boolean().nullable(),
  calicheAcres: yup.number().nullable(),
  county: yup
    .string()
    .required()
    .nullable(),
  description: yup.string().nullable(),
  legalDescription: yup.string().nullable(),
  executiveRights: yup.boolean().nullable(),
  familyID: yup.string().nullable(),
  grossMineralAcres: yup.number().required(),
  grossSurfaceAcres: yup.number().required(),
  mineralFraction: yup
    .string()
    .test('is-fraction', '${path} is not a fraction', (value) => isFraction(value))
    .label('Mineral Fraction'),
  surfaceFraction: yup
    .string()
    .test('is-fraction', '${path} is not a fraction', (value) => isFraction(value))
    .label('Surface Fraction')
    .nullable(),
  familyNMA: yup.string().required('Required Field'),
  familyNSA: yup.string().required('Required Field'),
  notes: yup.string().nullable(),
  orriPercentage: yup.number().nullable(),
  state: yup
    .string()
    .oneOf(['Colorado', 'Texas', 'New Mexico', 'Kansas'])
    .required(),
  surfaceOwnership: yup.boolean().nullable(),
  township: yup.string().nullable(),
  tract: yup.string().nullable(),
  bonusDelay: yup.boolean().nullable(),
  mineralClassifiedAcres: yup.number().nullable(),
  mineralClassified: yup.boolean().nullable(),
  mineralOwnershipPercentage: yup.string().nullable(),
  surfaceOwnershipPercentage: yup.string().nullable(),
  otherMineralAcres: yup.number().nullable(),
  originalPapers: yup.string().nullable(),
  otherMinerals: yup.boolean().nullable(),
  overRidingRoyaltyInterest: yup.boolean().nullable(),
  waterAcres: yup.string().nullable(),
  purchaser: yup.string().nullable(),
  recording: yup.string().nullable(),
  purchaseDate: yup.string().nullable(),
  docType: yup.string().nullable(),
  range: yup.string().nullable(),
  survey: yup.string().nullable(),
  geometry: yup
    .string()
    .nullable()
    .trim(),
});
