import React from 'react';
import { useQuery } from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import LeaseTable from './LeaseTable';
import Button from '@material-ui/core/Button';
import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import TemplateLeaseTable from './TemplateLeaseTable';
import gql from 'graphql-tag';

const LEASE_TABLE_QUERY = gql`
  query {
    Lease {
      id
      leaseName
      status
      effectiveDate
      template
      leaseRecordings {
        id
        county
      }
    }
  }
`;

const SortLeasesByAlphabet = (LeaseArr = []) => {
  let sorted = [...LeaseArr];

  return sorted.sort((a, b) => {
    if (a.leaseName.toLocaleLowerCase() > b.leaseName.toLowerCase()) {
      return 1;
    } else if (a.leaseName.toLowerCase() < b.leaseName.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  });
};

function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const LeaseTableView = ({ history }) => {
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const LeaseQuery = useQuery(LEASE_TABLE_QUERY);

  if (LeaseQuery.error) {
    return <h1>{LeaseQuery.error.toString()}</h1>;
  } else if (LeaseQuery.loading) {
    return <LoadingView />;
  }

  const Leases = SortLeasesByAlphabet(LeaseQuery.data.Lease) || [];

  const nonTemplateLeases = Leases.filter((lease) => lease.template === false || !lease.template);

  const templateLeases = Leases.filter((lease) => lease.template === true);

  return (
    <>
      <AppBar position='static'>
        <Tabs value={value} onChange={handleChange}>
          <Tab key={'1'} label='Leases' />
          <Tab key={'2'} label='Lease Templates' />
        </Tabs>
      </AppBar>
      {value === 0 && (
        <TabContainer>
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 20 }}>
            <Button
              color='primary'
              variant='contained'
              style={{ marginBottom: 20 }}
              onClick={() => history.push('/addLease')}
            >
              Add Lease
            </Button>
          </div>
          <LeaseTable data={nonTemplateLeases} history={history} />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 20 }}>
            <Button
              color='primary'
              variant='contained'
              style={{ marginBottom: 20 }}
              onClick={() => history.push('/addLeaseTemplate')}
            >
              Add Lease Template
            </Button>
          </div>
          <TemplateLeaseTable templateData={templateLeases} history={history} />
        </TabContainer>
      )}
    </>
  );
};

export default LeaseTableView;
