import React from 'react';
import { useMutation } from '@apollo/client';
import {MenuItem, Typography} from '@material-ui/core';
import { CREATE_LEASE, GET_ALL_LEASES } from '../../components/leases/LeaseQueries';
import Auth from '../../util/Auth';
import { makeStyles } from '@material-ui/styles';
import { v4 } from 'uuid';
import { Field, Form, Formik } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Switch, TextField } from 'formik-material-ui';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import * as yup from 'yup';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import {oglStatuses} from "../../util/Statuses";

const hasPermission = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LEASE);

const LeaseDetailPageStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  gdone: {
    display: 'grid',
    gridTemplateColumns: '250px auto 200px',
    gridTemplateRows: '90% 10%',
    gap: '.5rem 2rem',
  },
  left: {
    display: 'grid',
    gridTemplateRows: '',
  },
  middle: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  right: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  deleteButton: {
    gridRow: '',
    gridColumn: '3',
  },
  buttons: {
    display: hasPermission ? '' : 'none',
  },
}));

const initialAddLease = {
  mineralClassified: false,
  leaseName: '',
  currentLessee: '',
  grossAcres: '',
  netMineralAcres: '',
  netSurfaceAcres: '',
  heldByProductionAcres: '',
  status: '',
  id: v4(),
};

const addLeaseSchema = yup.object({
  mineralClassified: yup.boolean(),
  leaseName: yup
    .string()
    .required('Required')
    .trim(),
  currentLessee: yup
    .string()
    .required('Required')
    .trim(),
  grossAcres: yup
    .string()
    .required('Required')
    .trim(),
  netMineralAcres: yup
    .string()
    .required('Required')
    .trim(),
  netSurfaceAcres: yup
    .string()
    .required('Required')
    .trim(),
  heldByProductionAcres: yup
    .string()
    .required('Required')
    .trim(),
  status: yup
    .string()
    .required('Required')
    .trim(),
});

const AddLease = ({ match, history }) => {
  const classes = LeaseDetailPageStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Get Lease ID from URL and query lease to populate lease detail page
  const [AddLease] = useMutation(CREATE_LEASE);
  //From here we can pass in all of the various mutations that we'll use for the Lease Object to the parts that need them

  return (
    <>
      <Typography className={classes.title} color='textSecondary' gutterBottom>
        Add Lease
      </Typography>
      <Formik
        onSubmit={(values, actions) => {
          return AddLease({
            variables: { ...values, leaseUseGas: false, flaredGas: false, condensateOil: false, skimOil: false },
            update: (store, { data: { CreateLease } }) => {
              try {
                const AllLeases = store.readQuery({ query: GET_ALL_LEASES });

                const data = { Lease: [...AllLeases.Lease, CreateLease] };

                store.writeQuery({ query: GET_ALL_LEASES, data: data });
              } catch (e) {
                console.log('Could not add lease to all lands query due to it not being run yet');
              }
            },
          })
            .then(() => {
              actions.setSubmitting(false);
              enqueueSnackbar('Lease Successfully Added', { variant: 'success' });
              history.push(`/Leases/${initialAddLease.id}`);
            })
            .catch((err) => {
              actions.setSubmitting(false);
              enqueueSnackbar(`Could not add Lease ${err.toString()}`, { variant: 'error' });
            });
        }}
        initialValues={initialAddLease}
        validationSchema={addLeaseSchema}
      >
        {(props) => {
          return (
            <Form>
              <div style={{ width: 'auto' }}>
                <div style={{ display: 'flex' }}>
                  <FormControlLabel
                    control={<Field name='mineralClassified' component={Switch} color='primary' />}
                    label='Mineral Classified'
                    labelPlacement='top'
                  />
                  <Field
                    name='leaseName'
                    placeholder='Lease ID'
                    label='ID'
                    component={TextField}
                    className={classes.TextField}
                    margin='normal'
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <Field
                    name='currentLessee'
                    placeholder='Current Lessee'
                    label='Current Lessee'
                    component={TextField}
                    className={classes.TextField}
                    style={{ width: 350 }}
                    fullWidth
                  />
                  <Field
                    name='grossAcres'
                    placeholder='Gross Acres'
                    label='Gross Acres'
                    component={TextField}
                    className={classes.TextField}
                  />
                  <Field
                    name='netMineralAcres'
                    placeholder='Net Mineral Acres'
                    label='Net Mineral Acres'
                    component={TextField}
                    className={classes.TextField}
                  />
                  <Field
                    name='netSurfaceAcres'
                    placeholder='Net Surface Acres'
                    label='Net Surface Acres'
                    component={TextField}
                    className={classes.TextField}
                  />
                  <Field
                    name='heldByProductionAcres'
                    placeholder='HBP Acres'
                    label='HBP Acres'
                    component={TextField}
                    className={classes.TextField}
                  />
                  <Field
                    name='status'
                    select
                    placeholder='Status'
                    label='Status'
                    component={TextField}
                    className={classes.TextField}
                  >
                    {
                      Object.keys(oglStatuses).map((key) => {
                        return <MenuItem key={key} value={key}>{oglStatuses[key]}</MenuItem>;
                      })
                    }
                  </Field>
                </div>
                <div>
                  <Field
                    name='depthsLeased'
                    placeholder='Depths Leased'
                    label='Depths Leased'
                    component={TextField}
                    className={classes.TextField}
                    fullWidth
                  />
                </div>
              </div>
              <br />
              <hr />
              <br />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.SubmitButton}
                  style={{ display: hasPermission ? 'inline-block' : 'none', marginBottom: 10 }}
                  disabled={!props.isValid}
                  onClick={() => {
                    props.handleSubmit();
                  }}
                  fullWidth
                >
                  Add Lease
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  style={{ display: hasPermission ? 'inline-block' : 'none' }}
                  className={classes.SubmitButton}
                  onClick={() => history.push('/Leases')}
                  fullWidth
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddLease;
