import React from 'react';
import {
  EDIT_WELL,
  GET_WELL,
  DELETE_WELL,
  ADD_WELL_OPERATED_BY,
  UPDATE_WELL_OPERATED_BY,
  UPDATE_WELL_DRILLED_ON,
} from '../../components/wells/WellQueries';
import { GET_TRACT_NAMES } from '../../components/tracts/TractQueries';
import LoadingView from '../../components/LoadingView';
import { Typography } from '@material-ui/core';
import WellDetailForm from './detailPage/WellDetailForm';
import { makeStyles } from '@material-ui/styles';
import Auth from '../../util/Auth';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { GET_ALL_OPERATORS, CREATE_OPERATOR } from '../../components/operators/OperatorQueries';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from "recompose";
import PERMISSIONS_LIST from '../../util/RolesEnum';

const EditWellStyles = makeStyles((theme) => ({
  isNotAuthorized: {
    display: Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_WELLS) ? 'none' : 'block',
  },
}));

const EditWellFragment = gql`
  fragment EditWell on Well {
    id
    apiNumber
    name
    notes
    type
    rrcLeaseNumber
    permitNumber
    permitDate
    abstract
    status
    spudDate
    rigReleaseDate
    completionDate
    dateProducing
    dateInPay
    OGL
    location
    decProdUnit
    dpu
    producingFormation
    lengthOfLateral
    rrcField
    operator
    familyID
    mpiDOI
    mesDOI
    sroDOI
    inService
    inactive
    drillType
    operatorInterval
    upperPerf
    lowerPerf
    perfLength
    operatorHistory
    rrcLeaseHistory
    latitude
    longitude
    latitudeBottom
    longitudeBottom
    state
    county
    trueVerticalDepth
    totalDepth
    landId
    operatedBy {
      id
      name
      wellCount
      swdCount
    }
    drilledOn {
      id
      name
      state
      county
    }
  }
`;

const OperatorFragment = gql`
  fragment OperatorFragment on Operator {
    id
    name
    wellCount
    swdCount
  }
`;

export const formatWell = (Well) => {
  let formattedWell = { ...Well };

  const defaultDateStr = '1200-01-01';
  const isDateValid = (dateVal) => {
    if (dateVal === null || dateVal === '') {
      return true;
    } else if (
      moment(dateVal, 'YYYY-MM-DD', true).isValid() &&
      moment(dateVal, 'YYYY-MM-DD', true).isAfter('1200-01-01')
    ) {
      return true;
    }

    return false;
  };

  formattedWell.dateProducing = isDateValid(formattedWell.dateProducing) ? formattedWell.dateProducing : defaultDateStr;
  formattedWell.spudDate = isDateValid(formattedWell.spudDate) ? formattedWell.spudDate : defaultDateStr;
  formattedWell.rigReleaseDate = isDateValid(formattedWell.rigReleaseDate)
    ? formattedWell.rigReleaseDate
    : defaultDateStr;
  formattedWell.completionDate = isDateValid(formattedWell.completionDate)
    ? formattedWell.completionDate
    : defaultDateStr;
  formattedWell.dateInPay = isDateValid(formattedWell.dateInPay) ? formattedWell.dateInPay : defaultDateStr;

  formattedWell.inService = formattedWell.inService || false;

  formattedWell.operatedBy = formattedWell.operatedBy || { id: '', name: '' };
  formattedWell.drilledOn =
    Array.isArray(formattedWell.drilledOn) && formattedWell.drilledOn.length > 0 ? formattedWell.drilledOn : [];

  formattedWell.latitude = formattedWell.latitude || 0;
  formattedWell.longitude = formattedWell.longitude || 0;
  formattedWell.latitudeBottom = formattedWell.latitudeBottom || 0;
  formattedWell.longitudeBottom = formattedWell.longitudeBottom || 0;
  formattedWell.trueVerticalDepth = formattedWell.trueVerticalDepth || 0;
  formattedWell.divisionOrder = formattedWell.divisionOrder || [];

  return formattedWell;
};

const EditWell = ({
  history,
  match,
  UpdateWellDrilledOn,
  UpdateWellOperatedBy,
  AddWellOperatedBy,
  DeleteWell,
  CreateOperator,
}) => {
  const classes = EditWellStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, error } = useQuery(GET_WELL, { variables: { id: match.params.wellID } });
  const TractNames = useQuery(GET_TRACT_NAMES);
  const AllOperators = useQuery(GET_ALL_OPERATORS);

  const [EditWell] = useMutation(EDIT_WELL);

  if (error || TractNames.error || AllOperators.error) {
    console.error(error.toString());
    return <p>Something went wrong!</p>;
  }

  if (loading || TractNames.loading || AllOperators.loading) {
    return <LoadingView />;
  }

  let Well;

  if (data) {
    Well = !data.Well ? <Typography variant='h5'>Could not find well with that ID!</Typography> : data.Well[0];
  }

  const tractNames = TractNames.data.Tract || [];
  const Operators = AllOperators.data.Operator || [];

  return (
    <>
      <Typography variant='h5' className={classes.isNotAuthorized}>
        Not Authorized To Make Changes
      </Typography>
      <WellDetailForm
        Well={formatWell(Well)}
        TractNames={tractNames}
        Operators={Operators}
        history={history}
        enqueueSnackbar={enqueueSnackbar}
        CreateOperator={CreateOperator}
        DeleteWell={DeleteWell}
        onSuccess={(newWell) => {
          return EditWell({
            variables: newWell,
            update: (store, { data: { UpdateWell } }) => {
              try {
                let data = { ...UpdateWell, __typename: 'Well' };

                store.writeFragment({
                  id: UpdateWell.id,
                  fragment: EditWellFragment,
                  data,
                });
              } catch (e) {
                console.log('Could not overwrite well!', e);
              }
            },
          })
            .then(() => UpdateDrilledOnTracts(UpdateWellDrilledOn, newWell))
            .then(() =>
              UpdateWellOperatedByRelationship(newWell.operatedBy, formatWell(Well).operatedBy, newWell.id, {
                AddWellOperatedBy,
                UpdateWellOperatedBy,
              })
            )
            .then(() => {
              enqueueSnackbar('Successfully edited Well!', { variant: 'success' });
              if (newWell.inactive !== formatWell(Well).inactive) {
                const route = newWell.inactive ? `/InactiveWells/${newWell.id}` : `/Wells/${newWell.id}`;
                history.push(route);
              }
            })
            .catch((err) => {
              enqueueSnackbar('Something went wrong!', { variant: 'error' });
              console.error(err);
            });
        }}
      />
    </>
  );
};

const UpdateDrilledOnTracts = (UpdateWellDrilledOn, currentWell) => {
  return UpdateWellDrilledOn({
    variables: {
      wellId: currentWell.id,
      newDrills: currentWell.drilledOn.map((tract) => tract.id),
    },
    update: (store, { data: { setNewDrilledOn } }) => {
      try {
        let data = store.readFragment({
          id: currentWell.id,
          fragment: EditWellFragment,
        });

        data.drilledOn = setNewDrilledOn;

        store.writeFragment({
          id: currentWell.id,
          fragment: EditWellFragment,
          data,
        });
      } catch (e) {
        console.error('Could not write to well drilled on relationships!', e);
      }
    },
  }).then(() => currentWell);
};

const UpdateWellOperatedByRelationship = (
  // TODO: Fix potential cache bug here
  currentOperator = {},
  initialOperator = {},
  wellID,
  { AddWellOperatedBy, UpdateWellOperatedBy }
) => {
  if (initialOperator.id === currentOperator.id) {
    return new Promise((resolve) => resolve(currentOperator));
  } else if (!initialOperator.id && currentOperator.id !== '') {
    return AddWellOperatedBy({
      variables: {
        from: { id: currentOperator.id },
        to: { id: wellID },
      },
      update: (store, { data: { AddWellOperatedBy } }) => {
        let well = store.readFragment({
          id: wellID,
          fragment: EditWellFragment,
        });

        let operator = store.readFragment({
          id: AddWellOperatedBy.from.id,
          fragment: OperatorFragment,
        });

        const data = { ...well, operatedBy: operator };

        store.writeFragment({
          id: wellID,
          fragment: EditWellFragment,
          data,
        });
      },
    });
  }

  return UpdateWellOperatedBy({
    variables: {
      wellId: wellID,
      operatorId: currentOperator.id,
    },
    update: (store, { data: { setNewOperator } }) => {
      try {
        const currentWell = store.readFragment({
          id: wellID,
          fragment: EditWellFragment,
        });

        const newOperator = store.readFragment({
          id: setNewOperator.operatedBy.id,
          fragment: OperatorFragment,
        });

        const data = { ...currentWell, operatedBy: newOperator };

        store.writeFragment({
          id: wellID,
          fragment: EditWellFragment,
          data,
        });
      } catch (e) {
        console.log('Could not write update well operated by fragment!', e);
      }
    },
  });
};

export default compose(
  graphql(UPDATE_WELL_DRILLED_ON, { name: 'UpdateWellDrilledOn' }),
  graphql(ADD_WELL_OPERATED_BY, { name: 'AddWellOperatedBy' }),
  graphql(UPDATE_WELL_OPERATED_BY, { name: 'UpdateWellOperatedBy' }),
  graphql(DELETE_WELL, { name: 'DeleteWell' }),
  graphql(CREATE_OPERATOR, {
    name: 'CreateOperator',
    options: {
      update: (store, { data: CreateOperator }) => {
        let data = store.readQuery({ query: GET_ALL_OPERATORS });

        const Operators = data.Operator;

        store.writeQuery({
          query: GET_ALL_OPERATORS,
          data: [...Operators, CreateOperator.CreateOperator],
        });
      },
    },
  })
)(EditWell);
