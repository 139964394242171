import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

const LoadingViewStyles = makeStyles({
    container: {
        textAlign: 'center',
        display: 'flex',
        height: '90vh',
    },
    item: {
        margin: 'auto',
    },
});

function LoadingView(props) {
    const classes = LoadingViewStyles();

    return (
        <div className={classes.container}>
            <div className={classes.item}>
                <CircularProgress />
            </div>
        </div>
    );
}

export default LoadingView;
