import React from 'react';
import { ADD_TRACT, DefaultTract, TRACTS_QUERY } from '../../components/tracts/TractQueries';
import { useSnackbar } from 'notistack';
import { Field, Form, Formik } from 'formik';
import { TractSchema } from '../../components/tracts/TractSchema';
import { TextField } from 'formik-material-ui';
import { Button, MenuItem, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useMutation } from '@apollo/client';
import Auth from '../../util/Auth';
import { makeStyles } from '@material-ui/styles';
import math from 'mathjs';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LANDS);

const EditFormStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  rowItem: {
    '& + &': {
      marginLeft: 20,
    },
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  gdone: {
    display: 'grid',
    gridTemplateColumns: '250px auto 200px',
    gridTemplateRows: '90% 10%',
    gap: '.5rem 2rem',
  },
  left: {
    display: 'grid',
    gridTemplateRows: '',
  },
  middle: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  right: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  deleteButton: {
    gridRow: '',
    gridColumn: '3',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
  },
}));

const propertyValues = (fraction) => (gross) => {
  const decimal = math.number(math.fraction(fraction));
  return {
    net: math.multiply(decimal, gross),
    decimal: math.multiply(decimal, 100),
  };
};

const AddTract = ({ history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = EditFormStyles();

  const [addTract] = useMutation(ADD_TRACT);

  return (
    <>
      <Formik
        initialValues={DefaultTract}
        validationSchema={TractSchema}
        onSubmit={(values, actions) => {
          // Set Tract ID
          values.id = values.name;
          // Set Ownership Values
          values.grossMineralAcres = parseFloat(values.grossMineralAcres);
          values.grossSurfaceAcres = parseFloat(values.grossSurfaceAcres);
          values.mineralOwnershipPercentage = propertyValues(values.mineralFraction)(
            values.grossMineralAcres
          ).decimal.toFixed(8);
          values.surfaceOwnershipPercentage = propertyValues(values.surfaceFraction)(
            values.grossSurfaceAcres
          ).decimal.toFixed(8);
          values.familyNMA = propertyValues(values.mineralFraction)(values.grossMineralAcres).net.toFixed(8);
          values.familyNSA = propertyValues(values.surfaceFraction)(values.grossSurfaceAcres).net.toFixed(8);
          values.mineralOwnership = values.familyNMA !== 0;
          values.surfaceOwnership = values.familyNSA !== 0;

          actions.setSubmitting(true);

          return addTract({
            variables: { ...values },
            update: (store, { data: { CreateTract } }) => {
              try {
                const AllTracts = store.readQuery({ query: TRACTS_QUERY });

                const data = { Tract: [...AllTracts.Tract, CreateTract] };

                store.writeQuery({ query: TRACTS_QUERY, data: data });
              } catch (e) {
                console.log('Could not add lease to all lands query due to it not being run yet');
              }
            },
          })
            .then(() => {
              enqueueSnackbar('Successfully Added Land!', { variant: 'success' });
              actions.setSubmitting(false);
              history.push(`/Lands/${values.id}`);
            })
            .catch((error) => {
              console.error(error);
              enqueueSnackbar(error.toString(), { variant: 'error' });
              actions.setSubmitting(false);
            });
        }}
      >
        {(props) => (
          <Form>
            <div style={{ display: 'flex', direction: 'row' }}>
              <Field
                name='name'
                placeholder='Land ID'
                component={TextField}
                label='Land ID'
                className={classes.TextField}
              />
              <Field
                name='familyID'
                placeholder='Family ID'
                component={TextField}
                label='Family ID'
                className={classes.TextField}
              />
              <Field
                name='abstract'
                placeholder='Abstract'
                component={TextField}
                label='Abstract'
                className={classes.TextField}
              />
              <Field
                name='description'
                placeholder='Description'
                component={TextField}
                label='Description'
                className={classes.TextField}
              />
              <Field
                name='state'
                select
                label='State'
                component={TextField}
                className={classes.TextField}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value={'Colorado'}>Colorado</MenuItem>
                <MenuItem value={'Kansas'}>Kansas</MenuItem>
                <MenuItem value={'New Mexico'}>New Mexico</MenuItem>
                <MenuItem value={'Texas'}>Texas</MenuItem>
              </Field>
              <Field
                name='county'
                placeholder='County'
                component={TextField}
                label='County'
                className={classes.TextField}
              />
            </div>

            <div style={{ display: 'flex' }}>
              <Field
                name='purchaser'
                placeholder='Purchaser'
                component={TextField}
                label='Purchaser'
                className={classes.TextField}
              />
              <Field
                name='docType'
                placeholder='Document Type'
                component={TextField}
                label='Document Type'
                className={classes.TextField}
              />
              <Field
                name='recording'
                placeholder='Recording'
                component={TextField}
                label='Recording'
                className={classes.TextField}
              />
              <Field
                name='purchaseDate'
                label='Purchase Date'
                placeholder='MM-DD-YYYY'
                type='date'
                component={TextField}
                className={classes.TextField}
                margin={'normal'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div>
              <Field
                name='color'
                select
                label='Color Code'
                component={TextField}
                className={classes.TextField}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 200 }}
              >
                <MenuItem value={'M'}>Minerals</MenuItem>
                <MenuItem value={'M&S'}>Minerals & Surface</MenuItem>
                <MenuItem value={'S'}>Surface</MenuItem>
              </Field>
            </div>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Paper className={classes.paper} style={{ display: props.values.state === 'None' ? 'none' : 'block' }}>
                <div
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Typography variant='h5' align='center' className={classes.title}>
                    {props.values.state === 'Texas' ? 'Texas' : 'Cats & Dogs'}
                  </Typography>
                  <Field
                    name={props.values.state === 'Texas' ? 'blk' : 'range'}
                    placeholder={props.values.state === 'Texas' ? 'TX Block' : 'Range'}
                    component={TextField}
                    label={props.values.state === 'Texas' ? 'TX Block' : 'Range'}
                    className={classes.TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    name={props.values.state === 'Texas' ? 'section' : 'township'}
                    placeholder={props.values.state === 'Texas' ? 'TX Section' : 'Township'}
                    component={TextField}
                    label={props.values.state === 'Texas' ? 'TX Section' : 'Township'}
                    className={classes.TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    name={props.values.state === 'Texas' ? 'tract' : 'section'}
                    placeholder={props.values.state === 'Texas' ? 'TX Tract' : 'Section'}
                    component={TextField}
                    label={props.values.state === 'Texas' ? ' TX Tract' : 'Section'}
                    className={classes.TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    name={props.values.state === 'Texas' ? 'lot' : 'tract'}
                    placeholder={props.values.state === 'Texas' ? 'TX Lot' : 'Tract'}
                    component={TextField}
                    label={props.values.state === 'Texas' ? 'TX Lot' : 'Tract'}
                    className={classes.TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    name={props.values.state === 'Texas' ? 'survey' : 'lot'}
                    placeholder={props.values.state === 'Texas' ? 'Survey' : 'Lot'}
                    component={TextField}
                    label={props.values.state === 'Texas' ? 'Survey' : 'Lot'}
                    className={classes.TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </Paper>
              <Paper className={classes.paper}>
                <div>
                  <Typography variant='h5' align='center' className={classes.title}>
                    Ownership
                  </Typography>
                  <div style={{ display: 'Flex', alignContent: 'space-around' }}>
                    <div>
                      <Typography variant='h5' align='center' className={classes.title}>
                        Minerals
                      </Typography>
                      <Field
                        name='grossMineralAcres'
                        placeholder='GMA'
                        component={TextField}
                        label='GMA'
                        className={classes.TextField}
                      />
                      <Field
                        name='mineralFraction'
                        placeholder='Mineral Fraction'
                        component={TextField}
                        label='Mineral Fraction'
                        className={classes.TextField}
                      />
                      <Field
                        name='mineralOwnershipPercentage'
                        placeholder='MIN %'
                        label='MIN %'
                        component={TextField}
                        InputProps={{
                          endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                        }}
                        className={classes.TextField}
                        disabled
                      />
                      <Field
                        name='familyNMA'
                        placeholder='NMA'
                        component={TextField}
                        label='NMA'
                        className={classes.TextField}
                        disabled
                      />
                    </div>
                    <div>
                      <Typography variant='h5' align='center' className={classes.title}>
                        Surface
                      </Typography>
                      <Field
                        name='grossSurfaceAcres'
                        placeholder='GSA'
                        component={TextField}
                        label='GSA'
                        className={classes.TextField}
                      />
                      <Field
                        name='surfaceFraction'
                        placeholder='Surface Fraction'
                        component={TextField}
                        label='Surface Fraction'
                        className={classes.TextField}
                      />
                      <Field
                        name='surfaceOwnershipPercentage'
                        placeholder='SRF %'
                        label='SRF %'
                        component={TextField}
                        InputProps={{
                          endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                        }}
                        className={classes.TextField}
                        disabled
                      />
                      <Field
                        name='familyNSA'
                        placeholder='NSA'
                        component={TextField}
                        label='NSA'
                        className={classes.TextField}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
            <hr />
            <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'space-between', height: 'auto' }}>
              <Field
                name='notes'
                placeholder='Notes'
                component={TextField}
                label='Notes'
                className={classes.TextArea}
                fullWidth
                multiline
                variant='outlined'
                rows={4}
                style={{ marginTop: 50, marginBottom: 50 }}
              />
              <Field
                name='description'
                placeholder='Description of Property'
                component={TextField}
                label='Description of Property'
                className={classes.TextArea}
                fullWidth
                multiline
                variant='outlined'
                rows={4}
                style={{ marginTop: 50, marginBottom: 50 }}
              />
            </div>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant='contained'
                color='secondary'
                className={classes.buttons}
                onClick={() => history.push('/Lands')}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                className={classes.buttons}
                disabled={!props.isValid}
              >
                Add Land
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddTract;
