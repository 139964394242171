import React from 'react';
import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Auth from '../../util/Auth';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import { useSnackbar } from 'notistack/build';
import EditROW from './EditROW';
import ROWChainOfTitle from './ROWChainOfTitle';
import {useMutation, useQuery} from "@apollo/client";
import {EDIT_ROW, GET_ROW} from "../../components/row/ROWQueries";
import LoadingView from "../../components/LoadingView";
import Plat from "./Plat";
import gql from "graphql-tag";

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_WELLS);

export const ROWFormStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 50,
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  Switch: {
    margin: 0,
  },
  BottomBar: {
    display: hasPermissions ? 'flex' : 'none',
    justifyContent: 'space-between',
  },
  positives: {
    display: 'flex',
    flexDirection: 'row',
  },
  SubmitButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
    float: 'left',
  },
  DeleteButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
    float: 'right',
  },
  DIButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
  },
  FormControlLabel: {
    paddingRight: '5em',
  },
  Select: {
    display: 'block',
  },
  FullWidthTextBox: {
    marginBottom: 20,
    marginTop: 20,
  },
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'left',
    textDecoration: 'underline',
  },
}));

const GET_OPERATORS = gql`
  query {
    Operator {
        id
        name
    }
  }
`;

function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const GetROWFromQuery = (queryResult) => {
  if (!Array.isArray(queryResult) || queryResult.length === 0) {
    return false;
  }

  return queryResult[0];
};

const ROWTabPage = ({ match, history }) => {
  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, loading } = useQuery(GET_ROW, {
    variables: {
      id: match.params.rowID,
    },
  });
  const { data: operatorData, loading: operatorLoading, error: operatorError } = useQuery(GET_OPERATORS);



  const [EditROWQuery] = useMutation(EDIT_ROW);

  if(loading || operatorLoading) return <LoadingView/>;
  if(error || operatorError) return <p>ERROR: {error}</p>;
  const operators = operatorData.Operator;

  const ROW = data.ROW[0];


  if(data) {
    return (
        <div>
          <AppBar position='static'>
            <Tabs
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
            >
              <Tab label='Main' />
              <Tab label='Chain Of Title' />
              <Tab label='Plat' />
            </Tabs>
          </AppBar>

          {value === 0 && (
              <TabContainer>
                <EditROW match={match} history={history} ROW={ROW}/>
              </TabContainer>
          )}
          {value === 1 && (
              <TabContainer>
                <ROWChainOfTitle
                    match={match}
                    history={history}
                    enqueueSnackbar={enqueueSnackbar}
                    setValue={setValue}
                />
              </TabContainer>
          )}
          {value === 2 && (
              <TabContainer>
                <Plat ROW={ROW} EditROW={EditROWQuery} setNotification={enqueueSnackbar}/>
              </TabContainer>
          )}
        </div>
    );
  }
};

export default ROWTabPage;
