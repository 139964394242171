import React from 'react';
import { useQuery } from '@apollo/client';
import { OPERATOR_TABLE_QUERY } from '../../components/operators/OperatorQueries';
import LoadingView from '../../components/LoadingView';
import OperatorTable from './OperatorTable';
import Button from '@material-ui/core/Button';

const SortOperatorsByAlphabet = (OperatorArr = []) => {
  let sorted = [...OperatorArr];

  return sorted.sort((a, b) => {
    if (a.name.toLocaleLowerCase() > b.name.toLowerCase()) {
      return 1;
    } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  });
};

const OperatorTableView = ({ history }) => {
  const OperatorsQuery = useQuery(OPERATOR_TABLE_QUERY);

  if (OperatorsQuery.error) {
    return <h1>{OperatorsQuery.error.toString()}</h1>;
  } else if (OperatorsQuery.loading) {
    return <LoadingView />;
  }

  const Operators = SortOperatorsByAlphabet(OperatorsQuery.data.Operator) || [];

  const editableOperators = Operators.map(o => ({...o}));

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          color='primary'
          variant='contained'
          style={{ marginBottom: 20 }}
          onClick={() => history.push('/addOperator')}
        >
          Add Contact
        </Button>
      </div>
      <OperatorTable data={editableOperators} history={history} />
    </div>
  );
};

export default OperatorTableView;
