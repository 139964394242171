import React from 'react';
import MaterialTable from 'material-table';
import {TextField, Typography} from '@material-ui/core';
import moment from 'moment';
import {FieldArray, Form, Formik} from 'formik';
import Uploader from "../../util/Uploader";
import LoadingView from "../../components/LoadingView";
import FileUploadModal from "../../components/files/FileUploadModal";
import {FILE_QUERY} from '../../components/files/fileQueries';
import {useLazyQuery, useMutation} from "@apollo/client";
import {EDIT_TITLE_DOCUMENT} from "../../components/tracts/TractQueries";


const ChainOfTitleTable = ({Tract, queries, history, setValue, match}) => {
    const tractID = match.params.tractID;
    const [fileKey, setFileKey] = React.useState(null);
    const [fileUploadOpen, setFileUploadOpen] = React.useState(false);
    const [rowInfo, setRowInfo] = React.useState({});
    const [loadFile, {data: fileData, loading: fileLoading, error: fileError}] = useLazyQuery(FILE_QUERY,
        {variables: {fileName: fileKey}});
    const {createTitle, deleteTitle, editTitle} = queries;
    const [updateTitle] = useMutation(EDIT_TITLE_DOCUMENT);

    const [loading, setLoading] = React.useState(false);

    if (loading) {
        return <LoadingView/>
    }

    const formikData = {
        finalData: Tract.titleRun
    }

    return (
        <div>
            <Uploader
                itemID={Tract.id}
                updateFunction={createTitle}
                typeOfUploader={"TRACT"}
                history={history}
                setValue={setValue}
                setLoading={setLoading}
            />
            <Formik onSubmit={() => {
            }} initialValues={formikData}>
                {(props) => (
                    <Form>
                        <Typography
                            variant='h5'
                            style={{
                                color: '#1976d2',
                                fontSize: '1.75rem',
                                textAlign: 'center',
                                textDecoration: 'underline',
                            }}
                        >
                            Title
                        </Typography>
                        <FieldArray name='finalData'>
                            {({push, remove, insert}) => {
                                const columns = [
                                    {
                                        title: 'Vol/Page',
                                        field: 'volPage',
                                        cellStyle: {
                                            whiteSpace: 'nowrap',
                                        },
                                    },
                                    {
                                        title: 'Book',
                                        field: 'book',
                                        cellStyle: {
                                            whiteSpace: 'nowrap',
                                        },
                                    },
                                    {
                                        title: 'Effective Date',
                                        field: 'effectiveDate',
                                        render: (rowData) => moment(rowData.effectiveDate).format('MM/DD/YYYY'),
                                        customSort: (a, b) => (moment(a.effectiveDate).isAfter(moment(b.effectiveDate)) ? 1 : -1),
                                        editComponent: (props) => (
                                            <TextField type='date' onChange={(e) => props.onChange(e.target.value)}/>
                                        ),
                                        cellStyle: {
                                            whiteSpace: 'nowrap',
                                        },
                                    },
                                    {
                                        title: 'Date Filed',
                                        field: 'dateFiled',
                                        render: (rowData) => moment(rowData.dateFiled).format('MM/DD/YYYY'),
                                        customSort: (a, b) => (moment(a.dateFiled).isAfter(moment(b.dateFiled)) ? 1 : -1),
                                        editComponent: (props) => (
                                            <TextField type='date' onChange={(e) => props.onChange(e.target.value)}/>
                                        ),
                                        cellStyle: {
                                            whiteSpace: 'nowrap',
                                        },
                                    },
                                    {
                                        title: 'Doc Type',
                                        field: 'docType',
                                        cellStyle: {
                                            whiteSpace: 'nowrap',
                                        },
                                    },
                                    {
                                        title: 'Grantor',
                                        field: 'grantor',
                                        editable: 'onAdd',
                                    },
                                    {
                                        title: 'Grantee',
                                        field: 'grantee',
                                        editable: 'onAdd',
                                        cellStyle: {
                                            whiteSpace: 'nowrap',
                                        },
                                    },
                                    {
                                        title: 'NMA Total',
                                        field: 'nmaTotal',
                                    },
                                    {
                                        title: 'NSA Total',
                                        field: 'nsaTotal',
                                    },
                                    {
                                        title: 'Land Description',
                                        field: 'landDescription',
                                    },
                                    {
                                        title: 'Comments',
                                        field: 'comments',
                                    },
                                ];

                                return (
                                    <MaterialTable
                                        columns={columns}
                                        data={props.values.finalData.map(t => ({...t}))}
                                        options={{
                                            pageSize: 20,
                                            searchFieldAlignment: 'left',
                                            showTitle: false,
                                            toolbarButtonAlignment: 'left',
                                            addRowPosition: 'first',
                                            exportButton: true,
                                            headerStyle: {
                                                backgroundColor: '#1976d2',
                                                color: '#FFF',
                                                textAlign: 'left',
                                                justifyContent: 'center',
                                                whiteSpace: 'pre',
                                            },
                                        }}
                                        editable={{
                                            onRowAdd: (newData) => {
                                                newData.tractID = Tract.id;
                                                newData.book = !newData.book ? 'N/A' : newData.book;
                                                newData.comments = !newData.comments ? 'None' : newData.comments;
                                                newData.nsaTotal = !newData.nsaTotal ? '0' : newData.nsaTotal;
                                                newData.nmaTotal = !newData.nmaTotal ? '0' : newData.nmaTotal;
                                                return createTitle(newData).then(({data: {createTitleDocument}}) => {
                                                    push(createTitleDocument.titleRun[0]);
                                                });
                                            },
                                            onRowUpdate: (newData, oldData) => {
                                                return editTitle(newData).then(({data: {UpdateLandTitle}}) => {
                                                    remove(oldData.tableData.id);
                                                    insert(oldData.tableData.id, UpdateLandTitle);
                                                });
                                            },
                                            onRowDelete: (oldData) => {
                                                return deleteTitle({id: oldData.id}).then((data) => {
                                                    remove(oldData.tableData.id);
                                                });
                                            },
                                        }}
                                        actions={
                                            [
                                                {
                                                    icon: 'addFile',
                                                    tooltip: 'Add File',
                                                    onClick: (event, rowData, tableData) => {
                                                        try {
                                                            const {id} = rowData;
                                                            const fileName = `${tractID}/${id}`;
                                                            setFileKey(fileName);
                                                            setRowInfo(rowData);
                                                            setFileUploadOpen(!fileUploadOpen);

                                                        } catch (e) {
                                                            console.log(e);
                                                        }
                                                    }
                                                },
                                                {
                                                    icon: 'map',
                                                    tooltip: 'View File',
                                                    onClick: async (event, rowData) => {
                                                        const {id} = rowData;
                                                        const fileName = `${tractID}/${id}`;
                                                        setFileKey(rowData.fileName ? rowData.fileName : fileName);
                                                        try {
                                                            const response = await loadFile();
                                                            const {data: {getFile}} = response;
                                                            const url = getFile[0].requestUrl;
                                                            const link = document.createElement('a');
                                                            link.href = url;
                                                            link.click();
                                                        } catch (e) {
                                                            console.log(e);
                                                        }
                                                    }
                                                }
                                            ]
                                        }
                                    />
                                );
                            }}
                        </FieldArray>
                        <FileUploadModal
                            open={fileUploadOpen}
                            setFileUploadOpen={setFileUploadOpen}
                            recordUpdate={updateTitle}
                            fileKey={fileKey}
                            rowInfo={rowInfo}
                            values={props.values}
                            setFieldValue={props.setFieldValue}
                            uploadType={"TITLE"}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ChainOfTitleTable;
