import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import Auth from '../../util/Auth';
import { Button, FormLabel, MenuItem } from '@material-ui/core';
import { v4 } from 'uuid';
import { useMutation } from '@apollo/client';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import { ADD_OTHER_LEASE, defaultOL, GET_ALL_OTHER_LEASES } from '../../components/otherleases/otherleaseQueries';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_OTHER_LEASE);

const DetailPageStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  gdone: {
    display: 'grid',
    gridTemplateColumns: '250px auto 200px',
    gridTemplateRows: '90% 10%',
    gap: '.5rem 2rem',
  },
  left: {
    display: 'grid',
    gridTemplateRows: '',
  },
  middle: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  right: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  deleteButton: {
    gridRow: '',
    gridColumn: '3',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
    marginTop: 20,
  },
}));

const AddOtherLease = ({ history }) => {
  const classes = DetailPageStyles();
  const { enqueueSnackbar } = useSnackbar();

  const CreateOtherLease = useMutation(ADD_OTHER_LEASE);

  //Supply ID for new ROW
  defaultOL.id = v4();

  return (
    <Formik
      initialValues={defaultOL}
      onSubmit={(values, actions) => {
        return CreateOtherLease({
          variables: values,
          update: (store, { data: { CreateROW } }) => {
            try {
              const AllOtherLeases = store.readQuery({ query: GET_ALL_OTHER_LEASES });

              const data = { OtherLease: [...AllOtherLeases.OtherLease, CreateOtherLease] };

              store.writeQuery({ query: GET_ALL_OTHER_LEASES, data: data });
            } catch (e) {
              console.log('Could not add lease to all leases query due to it not being run yet');
            }
          },
        })
          .then((data) => {
            enqueueSnackbar('Lease successfully Added', { variant: 'success' });
            history.push(`/OtherLeases/${values.id}`);
            actions.setSubmitting(false);
          })
          .catch((err) => {
            enqueueSnackbar(err.toString(), { variant: 'error' });
            actions.setSubmitting(false);
          });
      }}
    >
      {(props) => {
        return (
          <Form>
            <div style={{ display: 'grid', gridTemplateColumns: '20% 1fr' }}>
              <div id='1'>
                <Field
                  name='projectName'
                  placeholder='Lease Name'
                  label='Lease Name'
                  component={TextField}
                  className={classes.TextField}
                />
                <Field
                  name='status'
                  margin='normal'
                  select
                  label='Status'
                  component={TextField}
                  className={classes.TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginBottom: 20 }}
                >
                  <MenuItem value={'Pending'}>Pending</MenuItem>
                  <MenuItem value={'Complete'}>Complete</MenuItem>
                </Field>
                <Field
                  name='dateOfLease'
                  placeholder='Lease Date'
                  component={TextField}
                  label='Lease Date'
                  className={classes.TextField}
                />
                <Field
                  name='termOfROW'
                  placeholder='Term of Lease'
                  component={TextField}
                  label='Term of Lease'
                  className={classes.TextField}
                />
                <Field
                  name='dateOfExpiration'
                  label='Date Of Expiration'
                  placeholder='MM-DD-YYYY'
                  type='date'
                  component={TextField}
                  className={classes.TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Field
                  name='type'
                  margin='normal'
                  select
                  label='Type of Lease'
                  component={TextField}
                  className={classes.TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginBottom: 20 }}
                >
                  <MenuItem value={'Electric Line'}>Electric Line</MenuItem>
                  <MenuItem value={'Fiber Optic Line'}>Fiber Optic Line</MenuItem>
                  <MenuItem value={'Gathering Line'}>Gathering Line</MenuItem>
                  <MenuItem value={'Pad Site & Wellbore'}>Pad Site & Wellbore</MenuItem>
                  <MenuItem value={'Pipeline - Gas'}>Pipeline - Gas</MenuItem>
                  <MenuItem value={'Pipeline - Oil'}>Pipeline - Oil</MenuItem>
                  <MenuItem value={'Pipeline - Temp'}>Pipeline - Temp</MenuItem>
                  <MenuItem value={'Road'}>Road</MenuItem>
                  <MenuItem value={'Road - Temp'}>Road - Temp</MenuItem>
                  <MenuItem value={'Seismic'}>Seismic</MenuItem>
                  <MenuItem value={'Storage Tank'}>Storage Tank</MenuItem>
                  <MenuItem value={'SWD'}>SWD</MenuItem>
                  <MenuItem value={'Waterline'}>Waterline</MenuItem>
                  <MenuItem value={'Waterline - Temp'}>Waterline - Temp</MenuItem>
                </Field>
                <Field
                  name='acreage'
                  label='Acreage'
                  placeholder='Acreage'
                  component={TextField}
                  className={classes.TextField}
                />
                <Field
                  name='sizeOfPipeline'
                  label='Size Of Pipeline'
                  placeholder='Size Of Pipeline'
                  component={TextField}
                  className={classes.TextField}
                />
                <Field
                  name='lengthOfPipeline'
                  label='Length Of Pipeline'
                  placeholder='Length Of Pipeline'
                  component={TextField}
                  className={classes.TextField}
                />
                <Field
                  name='costPerUnit'
                  label='Cost Per Unit'
                  placeholder='Cost Per Unit'
                  component={TextField}
                  className={classes.TextField}
                />
                <Field
                  name='datePaid'
                  label='Date Paid'
                  placeholder='MM-DD-YYYY'
                  type='date'
                  component={TextField}
                  className={classes.TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <div>
                  <FormLabel style={{ display: 'block' }}>Original In File</FormLabel>
                  <Field color='primary' name='original' component={Switch} margin='normal' />
                </div>
                <Field
                  name='termOfLease'
                  placeholder='Term of Lease'
                  label='Term of Lease'
                  component={TextField}
                  className={classes.TextField}
                />
                <Field
                  name='volAndPage'
                  placeholder='Vol & Page'
                  label='Vol & Page or Recording Info'
                  component={TextField}
                  className={classes.TextField}
                />
              </div>
              <div id='3'>
                <br />
                <br />
                <Field
                  name='comments'
                  placeholder='Notes'
                  label='Notes'
                  component={TextField}
                  multiline={true}
                  rows={4}
                  variant='outlined'
                  className={classes.notes}
                  fullWidth
                />
              </div>
            </div>
            <div>
              <hr />
              <Button variant='contained' color='primary' type='submit' style={{ float: 'right' }} fullWidth>
                Add Lease
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddOtherLease;
