import gql from 'graphql-tag';

export const USERS_LIST = gql`
  query {
    Users
  }
`;

export const ROLE_LIST = gql`
  query {
    __type(name: "UserRoleEnum") {
      enumValues {
        name
      }
    }
  }
`;

// RESOLVER MUTATION
export const SET_ROLE = gql`
  mutation SetRole($userID: ID!, $roles: [UserRoleEnum]) {
    setUserRole(userID: $userID, roles: $roles)
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($email: String!, $password: String!) {
    CreateUser(email: $email, password: $password)
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userID: ID!) {
    DeleteUser(userID: $userID)
  }
`;
