import React from 'react';
import Link from 'react-router-dom/Link';
import MaterialTable from 'material-table';
import moment from 'moment';

const OtherLeaseTable = ({ data }) => {
  //Ensure that updates to lands are displayed correctly and are searchable
  const editableData = data.forEach((record) => {
    const copy = {...record}
    copy.lands =
      copy.locatedOn.length === 0 ? (
        <span style={{ color: 'red' }}>Error No Connection To Tract</span>
      ) : (
        copy.locatedOn.map((r) => r.name).join(', ')
      );
  });

  const columns = [
    {
      title: 'Lease Name',
      field: 'projectName',
      defaultSort: 'asc',
      render: (rowData) => (
        <Link to={`/OtherLeases/${rowData.id}`} target='_blank'>
          {rowData.projectName === ' ' ? 'None' : rowData.projectName}
        </Link>
      ),
    },
    {
      title: 'Lessee',
      field: 'lessee',
    },
    {
      title: 'Type of Lease',
      field: 'type',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Expiration Date',
      field: 'dateOfExpiration',
      render: (rowData) =>
        moment(rowData.dateOfExpiration).isValid()
          ? moment(rowData.dateOfExpiration).format('MM/DD/YYYY')
          : rowData.dateOfExpiration,
    },
    {
      title: 'Lease Date',
      field: 'dateOfLease',
      render: (rowData) =>
        moment(rowData.dateOfLease).isValid() ? moment(rowData.dateOfLease).format('MM/DD/YYYY') : rowData.dateOfLease,
    },
    {
      title: 'Land ID',
      field: 'lands',
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={editableData}
      options={{
        searchFieldAlignment: 'left',
        exportButton: true,
        pageSize: data.length,
        showTitle: false,
        toolbarButtonAlignment: 'left',
        headerStyle: {
          backgroundColor: '#1976d2',
          color: '#FFF',
          textAlign: 'left',
          justifyContent: 'center',
          whiteSpace: 'pre',
        },
      }}
    />
  );
};

export default OtherLeaseTable;
