import React from 'react';
import MaterialTable from 'material-table';
import { useQuery } from '@apollo/client';
import { TRACTS_TABLE_QUERY } from '../../components/tracts/TractQueries';
import LoadingView from '../../components/LoadingView';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

function LandsTable({ history }) {
  const TractsQueryState = useQuery(TRACTS_TABLE_QUERY);

  if (TractsQueryState.error) {
    return <h1>Cant Load Tracts!</h1>;
  } else if (TractsQueryState.loading) {
    return <LoadingView />;
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'flex-start', marginBottom: 20 }}>
        <Button variant='contained' color='primary' onClick={() => history.push('/addLand')}>
          Add Land
        </Button>
      </div>
      <MaterialTable
        title='Lands'
        data={TractsQueryState.data.Tract.map(t => ({...t}))}
        columns={LandTableColumns}
        options={{
          pageSize: TractsQueryState.data.Tract.length,
          searchFieldAlignment: 'left',
          exportButton: true,
          toolbarButtonAlignment: 'left',
          headerStyle: {
            backgroundColor: '#1976d2',
            color: '#FFF',
            textAlign: 'left',
            justifyContent: 'center',
            whiteSpace: 'pre',
          },
        }}
      />
    </div>
  );
}

const LandTableColumns = [
  {
    title: 'ID',
    field: 'id',
    render: (land) => {
      const backGround = (land) => {
        if (land.color === 'M&S') {
          return { backgroundColor: 'rgba(255,255,0,0.3)', color: 'black' };
        } else if (land.color === 'M') {
          return { backgroundColor: 'rgba(255,0,0,0.2)', color: 'black' };
        } else if (land.color === 'S') {
          return { backgroundColor: 'rgba(0,0,255,0.2)', color: 'black' };
        }
      };
      const color = backGround(land);
      return (
        <Link to={`/Lands/${land.id}`} style={color} target='_blank'>
          {land.id}
        </Link>
      );
    },
  },
  {
    title: 'State',
    field: 'state',
  },
  {
    title: 'County',
    field: 'county',
  },
  {
    title: 'NMA',
    field: 'familyNMA',
    render: (rowData) => (rowData.familyNMA === '0' ? '0' : parseFloat(rowData.familyNMA).toFixed(8)),
  },
  {
    title: 'NSA',
    field: 'familyNSA',
    render: (rowData) => (rowData.familyNSA === '0' ? '0' : parseFloat(rowData.familyNSA).toFixed(8)),
  },
  {
    title: 'Ownership',
    field: 'color',
  },
];

export default LandsTable;
