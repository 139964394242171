import React, { useState } from 'react';
import { Button, Typography, Paper, TextField } from '@material-ui/core';

function UpdatePassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(''); // For user notifications

  const handleForgotPassword = async () => {
    if (!email) {
      setMessage('Please enter your email to reset your password.');
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_FORGOT_PASS_URL, {//ENV VARIABLE ME PLEASE
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('A reset link has been sent to your email.');
      } else {
        setMessage('No such user email, Please check your spelling or verify this email was used.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('No such user email, Please check your spelling or verify this email was used.');
    }
  };

  const glassEffectStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    padding: '30px',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    backdropFilter: 'blur(0.8px)',
    WebkitBackdropFilter: 'blur(0.8px)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
  };

  const rootStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #FF3CAC 0%, #784BA0 0%, #2B86C5 100%)',
  };

  return (
    <div style={rootStyle}>
      <Paper style={glassEffectStyle}>
        <Typography variant="h4" style={{ marginBottom: '20px' }}>
          <u>Password Reset</u>
        </Typography>
        {message && (
          <Typography color="error" style={{ marginBottom: '20px' }}>
            {message}
          </Typography>
        )}
        <form onSubmit={handleForgotPassword}
              style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TextField
            label="Enter your email..."
            variant="outlined"
            style={{ width: '300px', marginBottom: '20px' }} // Added marginBottom for spacing
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete={email}
          />
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <Button
              variant="contained"
              color="secondary"
              style={{ borderRadius: '20px', padding: '10px 30px' }}
              onClick={handleForgotPassword}
            >
              Send Email
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
}

export default UpdatePassword;
