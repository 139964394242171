import _ from 'underscore';
import { fraction } from 'mathjs';

const getShallowObjectValues = (obj) => Object.keys(obj).map((key) => obj[key]);

function castObjectNullsToUndefined(obj = {}) {
  return Object.keys(obj).reduce((newObject, currentKey) => {
    if (_.isObject(obj[currentKey])) {
      newObject[currentKey] = castObjectNullsToUndefined(obj[currentKey]);
    } else if (obj[currentKey] === null) {
      newObject[currentKey] = undefined;
    } else {
      newObject[currentKey] = obj[currentKey];
    }
    return newObject;
  }, {});
}

function isFraction(value) {
  try {
    fraction(value);
    return true;
  } catch (e) {
    return false;
  }
}

export { getShallowObjectValues, castObjectNullsToUndefined, isFraction };
