import React, { useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import CreatableSelect from 'react-select/creatable';

const DIWellDiffSelect = ({
  property,
  CurrentWellValue,
  diWellValue,
  setProperty,
  selectOptions,
  defaultOption,
  history,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  return (
    <TableRow>
      <TableCell>{property}</TableCell>
      <TableCell>
        {CurrentWellValue !== null && CurrentWellValue !== undefined ? CurrentWellValue.toString() : null}
      </TableCell>
      <TableCell>{diWellValue !== null ? diWellValue.toString() : null}</TableCell>
      <TableCell />
      <TableCell>
        <CreatableSelect
          options={selectOptions}
          value={selectedOption}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isValidNewOption={(inputValue, selectValue, selectOptions) => {
            if (inputValue.trim().length === 0 || selectOptions.find((option) => option.name === inputValue)) {
              return false;
            }
            return true;
          }}
          getNewOptionData={(inputValue, optionLabel) => ({
            id: inputValue,
            name: optionLabel,
          })}
          onChange={(option) => {
            if (property === 'operator') {
              setProperty('operatedBy', option);
              setSelectedOption(option);
            } else {
              setProperty(property, option);
              setSelectedOption(option);
            }
          }}
          onCreateOption={(operatorName) => {
            history.push(`/addOperator/?name=${operatorName}`);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default DIWellDiffSelect;
