import React, { useState } from 'react';
import Link from 'react-router-dom/Link';
import MaterialTable, { MTableToolbar } from 'material-table';
import queryString from 'query-string';

function intersperse(arr, sep) {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(
    function(xs, x, i) {
      return xs.concat([sep, x]);
    },
    [arr[0]]
  );
}

function getPageNumberFromQueryParams({ page }) {
  if (!page) {
    return 0;
  }

  if (isNaN(Number(page))) {
    return 0;
  }

  return Number(page);
}

function replaceHistoryWithPage(pageNum, { history }) {
  history.replace(`/Wells?page=${pageNum}`);
}

const WellsTable = ({ data, history }) => {
  const [pageNumber, setPageNumber] = useState(getPageNumberFromQueryParams(queryString.parse(window.location.search)));

  const columns = [
    {
      title: 'Well Name',
      render: (rowData) => (
        <Link to={`/Wells/${rowData.id}`} target='_blank'>
          {rowData.name}
        </Link>
      ),
      field: 'name',
      defaultSort: 'asc',
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },

    {
      title: 'Operator',
      render: (rowData) => {
        if (!rowData.operatedBy) {
          return <h1>No Operator</h1>;
        } else {
          return (
            <Link
              to={`/Operators/${rowData.operatedBy.id}`}
              target='_blank'
              onClick={() => replaceHistoryWithPage(pageNumber, { history })}
            >
              {rowData.operatedBy.name}
            </Link>
          );
        }
      },
      field: 'operatedBy.name',
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    {
      title: 'API #',
      field: 'apiNumber',
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    {
      title: 'Status',
      field: 'status',
      defaultSort: 'asc',
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      title: 'OGL',
      render: (rowData) => {
        if (!rowData.lease) {
          return <div>{rowData.OGL}</div>;
        } else {
          return <div>{rowData.lease.leaseName}</div>;
        }
      },
      field: 'OGL',
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      title: 'RRC Field',
      field: 'rrcField',
    },
    {
      title: 'RRC Lease Number',
      field: 'rrcLeaseNumber',
    },
    {
      title: 'State',
      field: 'state',
    },
    {
      title: 'County',
      field: 'county',
    },
    {
      title: 'Unit',
      render: (rowData) => {
        if (!rowData.unit) {
          return <h5>N/A</h5>;
        } else {
          return (
            <Link
              to={`/Units/${rowData.unit.id}`}
              target='_blank'
              onClick={() => replaceHistoryWithPage(pageNumber, { history })}
            >
              {rowData.unit.name}
            </Link>
          );
        }
      },
      field: 'unit',
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'left',
      },
    },
    {
      title: 'Land Id',
      field: 'landId',
      render: (rowData) => {
        if (!rowData.landId) {
          return 'N/A';
        }
        const splits = rowData.landId.split(',');
        const cleaned = splits.map((s) => s.trim().replace('\n', ''));
        const Links = cleaned.map((e) => (
          <Link to={`/Lands/${e}`} key={e} target='_blank'>
            {e}
          </Link>
        ));
        return intersperse(Links, ',');
      },
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
  ];

  return (
    <div>
      <MaterialTable
        data={data.map(d => ({...d}))}
        columns={columns}
        title={''}
        options={{
          initialPage: pageNumber,
          headerStyle: {
            backgroundColor: '#1976d2',
            color: '#FFF',
            textAlign: 'left',
            justifyContent: 'center',
            whiteSpace: 'pre',
          },
          selection: false,
          pageSize: data.length,
          searchFieldAlignment: 'left',
          toolbarButtonAlignment: 'left',
        }}
        onChangePage={(page) => setPageNumber(page)}
        components={{
          Toolbar: (props) => {
            const [newSearchText, setSearchText] = useState('');
            const [newRenderData, setRenderData] = useState([]);
            const { renderData, searchText, onSearchChanged, ...filteredProps } = props;
            return (
              <MTableToolbar
                {...filteredProps}
                searchText={newSearchText}
                renderData={newRenderData}
                onSearchChanged={(val) => {
                  setSearchText(val);
                  props.onSearchChanged(val);
                  setRenderData([]);
                }}
              />
            );
          },
        }}
      />
    </div>
  );
};

export default WellsTable;
