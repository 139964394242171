import React from 'react';
import MaterialTable from 'material-table';
import Link from 'react-router-dom/Link';
import {Typography} from '@material-ui/core';
import moment from 'moment';

const LandROWTable = ({Land}) => {
    const columns = [
        {
            title: 'Project ID',
            field: 'id',
            render: (rowData) => <Link to={`/ROWS/${rowData.id}`}>{rowData.projectName}</Link>,
        },
        {
            title: 'Status',
            field: 'status',
        },
        {
            title: 'Lessee',
            field: 'lessee',
        },
        {
            title: 'Type',
            field: 'type',
        },
        {
            title: 'Expiration Date',
            field: 'dateOfExpiration',
            render: (rowData) => moment(rowData.dateOfExpiration).format('MM/DD/YYYY'),
        },
    ];

    return (
        <div>
            <Typography
                variant='h5'
                style={{
                    color: '#1976d2',
                    fontSize: '1.75rem',
                    textAlign: 'center',
                    textDecoration: 'underline',
                }}
            >
                Right Of Ways
            </Typography>
            <MaterialTable
                columns={columns}
                data={Land.rowsOn.map(r => ({...r}))}
                options={{
                    pageSize: Land.rowsOn.length,
                    search: false,
                    paging: false,
                    showTitle: false,
                    exportButton: true,
                    toolbarButtonAlignment: 'left',
                    headerStyle: {
                        backgroundColor: '#1976d2',
                        color: '#FFF',
                        textAlign: 'left',
                        justifyContent: 'center',
                        whiteSpace: 'pre',
                    },
                }}
            />
        </div>
    );
};

export default LandROWTable;
