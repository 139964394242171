import * as yup from 'yup';

const AddOperatorSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .nullable(),
  alias: yup.string().nullable(),
  type: yup.string().nullable(),
  address1: yup
    .string()
    .required()
    .nullable(),
  address2: yup
    .string()
    .required()
    .nullable(),
  city: yup
    .string()
    .required()
    .nullable(),
  state: yup
    .string()
    .required()
    .nullable(),
  zip: yup
    .string()
    .required()
    .nullable(),
  phone: yup
    .string()
    .required()
    .nullable(),
  operatorNumber: yup
    .string()
    .required()
    .nullable(),
  notes: yup.string().nullable(),
  companyWebsite: yup.string().nullable(),
  mesAccountNumber: yup.string().nullable(),
  mpiAccountNumber: yup.string().nullable(),
  sroAccountNumber: yup.string().nullable(),
});

export default AddOperatorSchema;
