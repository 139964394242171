import React, { useState } from 'react';
import LoadingView from '../../components/LoadingView';
import { GET_ALL_ROWS } from '../../components/row/ROWQueries';
import { Typography } from '@material-ui/core';
import { Query } from '@apollo/client/react/components';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import { CSVLink } from 'react-csv';

const RightOfWay = () => {
  const [rowData, setRowData] = useState([]);
  return (
    <Query query={GET_ALL_ROWS}>
      {({ data, loading, error }) => {
        if (error) {
          console.error(error);
          return <Typography variant='h5'>{error.toString()}</Typography>;
        } else if (loading) {
          return <LoadingView />;
        }

        const rows = data.ROW;
        setRowData(rows);

        const headers = [
          { label: 'ROW Name', key: 'projectName' },
          { label: 'Status', key: 'status' },
          { label: 'Lessee', key: 'lessee' },
          { label: 'Lease Date', key: 'dateOfLease' },
          { label: 'Term of ROW', key: 'termOfLease' },
          { label: 'Date of Expiration', key: 'dateOfExpiration' },
          { label: 'Type of Lease', key: 'type' },
          { label: 'Vol & Page', key: 'volAndPage' },
          { label: 'Land ID', key: 'landId' },
        ];

        const columns = [
          {
            title: 'ROW Name',
            field: 'projectName',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'Status',
            field: 'status',
          },
          {
            title: 'Lessee',
            field: 'lessee',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'Lease Date',
            field: 'dateOfLease',
          },
          {
            title: 'Term of ROW',
            field: 'termOfLease',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'Date Of Expiration',
            field: 'dateOfExpiration',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'Type of Lease',
            field: 'type',
          },
          {
            title: 'Vol & Page',
            field: 'volAndPage',
          },
          {
            title: 'Land ID',
            render: (rowData) => rowData.locatedOn.map((e) => e.id).join(', '),
            field: 'landId',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
        ];

        const csvRowData = rowData.map((r) => {
          return {
            projectName: r.projectName,
            status: r.status,
            lessee: r.lessee,
            dateOfLease: r.dateOfLease,
            termOfLease: r.termOfLease,
            dateOfExpiration: r.dateOfExpiration,
            type: r.type,
            volAndPage: r.volAndPage,
            landId: r.locatedOn.map((e) => e.id).join(', '),
          };
        });

        return (
          <div>
            <div style={{ display: 'flex', width: 800, justifyContent: 'space-between', marginBottom: 20 }}>
              <CSVLink data={csvRowData} headers={headers}>
                <Button variant={'contained'} color={'primary'} style={{ marginRight: 20 }}>
                  DOWNLOAD CSV
                </Button>
              </CSVLink>
            </div>

            <MaterialTable
              data={rowData.length === 0 ? rows.map(w => ({...w})) : rowData.map(w => ({...w}))}
              columns={columns}
              headers={headers}
              title={'Right Of Ways'}
              options={{
                headerStyle: {
                  backgroundColor: '#1976d2',
                  color: '#FFF',
                  textAlign: 'center',
                  justifyContent: 'center',
                  whiteSpace: 'pre',
                },
                selection: false,
                pageSize: rowData.length === 0 ? rows.length : rowData.length,
                searchFieldAlignment: 'left',
                toolbarButtonAlignment: 'left',
              }}
            />
          </div>
        );
      }}
    </Query>
  );
};

export default RightOfWay;
