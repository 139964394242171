import React, { useState } from 'react';
import Link from 'react-router-dom/Link';
import MaterialTable from 'material-table';
import queryString from 'query-string';

function getPageNumberFromQueryParams({ page }) {
  if (!page) {
    return 0;
  }

  if (isNaN(page)) {
    return 0;
  }

  return Number(page);
}

function replaceHistoryWithPage(pageNum, { history }) {
  history.replace(`/Operators?page=${pageNum}`);
}

const OperatorTable = ({ data, history }) => {
  const [pageNumber, setPageNumber] = useState(getPageNumberFromQueryParams(queryString.parse(window.location.search)));

  const columns = [
    {
      title: 'Contact Name',
      render: (rowData) => (
        <Link
          to={`/Operators/${rowData.id}`}
          target='_blank'
          onClick={() => replaceHistoryWithPage(pageNumber, { history })}
        >
          {rowData.name}
        </Link>
      ),
      field: 'name',
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'left',
      },
    },
    {
      title: 'Type',
      field: 'type',
    },
    {
      title: 'Company Website',
      field: 'companyWebsite',
      render: (rowData) => (
        <a href={`${rowData.companyWebsite}`} target='_blank' rel='noopener noreferrer'>
          {rowData.companyWebsite}
        </a>
      ),
    },
    {
      title: 'Operator Alias',
      field: 'alias',
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'left',
      },
    },
    {
      title: 'Operator Number',
      field: 'operatorNumber',
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'left',
      },
    },
    {
      title: 'Well Count',
      field: 'wellCount',
      render: (rowData) => (rowData.wellCount ? rowData.wellCount : 'N/A'),
    },
  ];

  return (
    <div>
      <MaterialTable
        data={data}
        columns={columns}
        title={''}
        onChangePage={(page) => setPageNumber(page)}
        options={{
          initialPage: pageNumber,
          headerStyle: {
            backgroundColor: '#1976d2',
            color: '#FFF',
            textAlign: 'left',
            justifyContent: 'left',
            whiteSpace: 'pre',
          },
          selection: false,
          exportButton: true,
          pageSize: data.length,
          searchFieldAlignment: 'left',
          toolbarButtonAlignment: 'left',
        }}
      />
    </div>
  );
};

export default OperatorTable;
