import React from 'react';
import Iframe from 'react-iframe';
import LandMapandLegal from './LandMapandLegal';

const LandsLegal = ({ Tract, EditTract, setNotification, mapUrl, mapType }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <LandMapandLegal Tract={Tract} EditTract={EditTract} setNotification={setNotification} mapType={mapType} />
      </div>
      <div style={{ marginTop: 30 }}>
        <Iframe url={mapUrl} width='100%' height='100%' style={{ display: !mapUrl ? 'none' : 'block' }} />
      </div>
    </div>
  );
};

export default LandsLegal;
