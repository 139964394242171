import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Auth from '../../util/Auth';
import { Button } from '@material-ui/core';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import Iframe from "react-iframe";

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LANDS);

const TableStyles = makeStyles((theme) => ({
    TextField: {
        display: 'block',
        marginTop: 10,
        marginBottom: 10,
        paddingRight: '1em',
    },
    form: {
        marginTop: '10px',
    },
    buttons: {
        display: hasPermissions ? '' : 'none',
    },
}));

const Plat = ({ROW, EditROW, setNotification}) => {
    const classes = TableStyles();

    return (
        <>
            <Formik
                initialValues={ROW}
                onSubmit={(values, actions) => {
                    const mapUpdate = {
                        ...ROW,
                    };
                    mapUpdate['mapUrl'] = values['mapUrl'];
                    actions.setSubmitting(true);
                    return EditROW({
                        variables: mapUpdate,
                    })
                        .then(() => {
                            setNotification('Successfully edited ROW!', { variant: 'success' });
                            actions.setSubmitting(false);
                        })
                        .catch((err) => {
                            console.error(err);
                            setNotification({ err }, { variant: 'error' });
                            actions.setSubmitting(false);
                        });
                }}
            >
                {(props) => {
                    return (
                        <Form className={classes.form}>
                            <Field
                                name={'mapUrl'}
                                placeholder='Image URL'
                                label='Image URL'
                                component={TextField}
                                className={classes.TextField}
                                fullWidth
                            />
                            <div>
                                <Button variant='contained' color='primary' type='submit' style={{ margin: 20 }}>
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            <Iframe url={ROW.mapUrl} width='100%' height='100%' style={{ display: !ROW.mapUrl ? 'none' : 'block' }} />
        </>
    );
};

export default Plat;