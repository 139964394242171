const ADMIN = 'ADMIN';
const ALL_USER_PERMISSIONS = 'ALL_USER_PERMISSIONS';
const ALL_READ_PERMISSIONS = 'ALL_READ_PERMISSIONS';
const ALL_WRITE_PERMISSIONS = 'ALL_WRITE_PERMISSIONS';
const READ_LANDS = 'READ_LANDS';
const WRITE_LANDS = 'WRITE_LANDS';
const READ_WELLS = 'READ_WELLS';
const WRITE_WELLS = 'WRITE_WELLS';
const READ_UNITS = 'READ_UNITS';
const WRITE_UNITS = 'WRITE_UNITS';
const READ_ROWS = 'READ_ROWS';
const WRITE_ROWS = 'WRITE_ROWS';
const READ_PRODUCTIONS = 'READ_PRODUCTIONS';
const WRITE_PRODUCTIONS = 'WRITE_PRODUCTIONS';
const READ_OPERATORS = 'READ_OPERATORS';
const WRITE_OPERATORS = 'WRITE_OPERATORS';
const READ_LEASE = 'READ_LEASE';
const WRITE_LEASE = 'WRITE_LEASE';
const READ_OTHER_LEASE = 'READ_OTHER_LEASE';
const WRITE_OTHER_LEASE = 'WRITE_OTHER_LEASE';

const PERMISSIONS_LIST = {
  ADMIN,
  PERMISSION_GROUPS: {
    ALL_USER_PERMISSIONS,
    ALL_READ_PERMISSIONS,
    ALL_WRITE_PERMISSIONS,
  },
  READ: {
    READ_LANDS,
    READ_WELLS,
    READ_UNITS,
    READ_ROWS,
    READ_PRODUCTIONS,
    READ_OPERATORS,
    READ_LEASE,
    READ_OTHER_LEASE,
  },
  WRITE: {
    WRITE_LANDS,
    WRITE_WELLS,
    WRITE_UNITS,
    WRITE_ROWS,
    WRITE_PRODUCTIONS,
    WRITE_OPERATORS,
    WRITE_LEASE,
    WRITE_OTHER_LEASE,
  },
};

export default PERMISSIONS_LIST;
