import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { Query } from '@apollo/client/react/components';
import LoadingView from '../../components/LoadingView';
import WellsTable from './WellsTable';
import gql from 'graphql-tag';
import { CSVLink } from 'react-csv';

const WELL_TABLE_QUERY = gql`
  query {
    Well {
      id
      _id
      apiNumber
      name
      OGL
      status
      rrcField
      rrcLeaseNumber
      county
      state
      landId
      drilledOn {
        id
      }
      operatedBy {
        id
        name
      }
      unit {
        id
        name
      }
      lease {
        _id
        id
        leaseName
      }
    }
  }
`;

const SortWellsByAlphabet = (WellArr = []) => {
  let sorted = [...WellArr];

  return sorted.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  });
};

const Wells = ({ history }) => {
  return (
    <Query query={WELL_TABLE_QUERY}>
      {({ data, loading, error }) => {
        if (error) {
          console.error(error);

          return <Typography variant='h5'>{error.toString()}</Typography>;
        } else if (loading) {
          return <LoadingView />;
        }

        const Wells = SortWellsByAlphabet(data.Well || []);


        const headers = [
          { label: 'Well Name', key: 'name' },
          { label: 'Operator', key: 'operator' },
          { label: 'API #', key: 'apiNumber' },
          { label: 'Status', key: 'status' },
          { label: 'OGL', key: 'OGL' },
          { label: 'RRC Field', key: 'rrcField' },
          { label: 'RRC Lease #', key: 'rrcLeaseNumber' },
          { label: 'State', key: 'state' },
          { label: 'County', key: 'county' },
          { label: 'Unit', key: 'unit' },
          { label: 'Land ID', key: 'landID' },
        ];

        const wellData = Wells.map((w) => ({
          apiNumber: w.apiNumber,
          name: w.name,
          operator: w.operatedBy ? w.operatedBy.name : 'No Operator',
          id: w.id,
          status: w.status,
          OGL: w.lease ? w.lease.leaseName : w.OGL,
          rrcField: w.rrcField,
          rrcLeaseNumber: w.rrcLeaseNumber,
          state: w.state,
          county: w.county,
          unit: !w.unit ? 'N/A' : w.unit.name,
          landID: w.landId,
        }));


        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 20 }}>
              <Button variant='contained' color='primary' onClick={() => history.push('/addWell')}>
                Add Well
              </Button>
              <CSVLink data={wellData} headers={headers}>
                <Button variant='contained' color='primary' style={{ marginLeft: 10 }}>
                  Download CSV
                </Button>
              </CSVLink>
            </div>
            <WellsTable data={Wells} history={history} />
          </>
        );
      }}
    </Query>
  );
};

export default Wells;
