import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import { ADD_UNIT_WELL, DELETE_UNIT, EDIT_UNIT, GET_UNIT, REMOVE_UNIT_WELL } from '../../components/units/UnitQueries';
import UnitDetailPage from './UnitDetailPage';
import gql from 'graphql-tag';

const LEASE_IDS = gql`
  query {
    Lease {
      id
    }
  }
`;

//Capture info from single item queries
const GetUnitFromQuery = (queryResult) => {
  if (!Array.isArray(queryResult) || queryResult.length === 0) {
    return false;
  }
  return queryResult[0];
};
//Capture info from multi item queries
const GetInfoFromQuery = (queryResult) => {
  if (!Array.isArray(queryResult) || queryResult.length === 0) {
    return false;
  }
  return queryResult;
};

const EditUnit = ({ match, history }) => {
  const { data, error, loading } = useQuery(GET_UNIT, {
    variables: {
      id: match.params.unitID,
    },
  });

  const {data: leaseData} = useQuery(LEASE_IDS);
  const [update] = useMutation(EDIT_UNIT);
  const [deleteUnit] = useMutation(DELETE_UNIT);
  const [addWell] = useMutation(ADD_UNIT_WELL);
  const [removeWell] = useMutation(REMOVE_UNIT_WELL);

  const updateUnit = (values) => {
    return update({
      variables: values,
    });
  };

  if (error) {
    return <h1>{error.toString()}</h1>;
  } else if (loading) {
    return <LoadingView />;
  }

  const Unit = GetUnitFromQuery(data.Unit) || [];
  const leaseIDs = GetInfoFromQuery(leaseData) || [];

  return (
    <UnitDetailPage
      Unit={Unit}
      leaseIDs={leaseIDs}
      updateUnit={updateUnit}
      deleteUnit={deleteUnit}
      history={history}
      addWell={addWell}
      removeWell={removeWell}
    />
  );
};

export default EditUnit;
