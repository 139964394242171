import gql from 'graphql-tag';

export const defaultROW = {
  dateOfLease: '0001-01-01',
  lessee: 'Default',
  termOfLease: 'Default',
  dateOfExpiration: '0001-01-01',
  type: 'Default',
  original: false,
  volAndPage: 'Default',
  datePaid: '0001-01-01',
  familyId: 'Default',
  projectName: 'Default',
  comments: 'None',
  status: 'None',
  sizeOfPipeline: 'None',
  lengthOfPipeline: 'None',
  costPerUnit: 'None',
  acreage: 'None',
  contactName: 'None',
  contactAddress: 'None',
  contactPhone: 'None',
  contactEmail: 'None',
};

export const ROW_FRAGMENT = `
{
    id
    landId
    dateOfLease
    lessee
    termOfLease
    dateOfExpiration
    type
    original
    volAndPage
    datePaid
    familyId
    projectName
    comments
    status
    sizeOfPipeline
    lengthOfPipeline
    costPerUnit
    acreage
    contactName
    contactAddress
    contactPhone
    contactEmail
    mapUrl
    payments {
        id
        date
        amount
        paymentType
        entity
        paymentFrom
    }
    locatedOn {
        name
        id
        familyID
        state
        county
    }
    operator{
      name
      id
      address1
      address2
      city
      state
      zip
      contacts{
        id
        contactName
        position
        directLine
        cell
        email
        date
        current
      }
    }
    
  }
`;

export const GET_ALL_ROWS = gql`
  query {
    ROW 
      ${ROW_FRAGMENT}
  }
`;

export const GET_ROW = gql`
    query ROW($id: ID!) {
        ROW(id: $id)
            ${ROW_FRAGMENT}
    }
`;

export const ADD_ROW = gql`
    mutation CreateROW(
        $id: ID!
        $dateOfLease: String
        $lessee: String
        $termOfLease: String
        $dateOfExpiration: String
        $type: String
        $original: Boolean
        $volAndPage: String
        $datePaid: String
        $familyId: String
        $projectName: String
        $comments: String
        $status: String
        $sizeOfPipeline: String
        $lengthOfPipeline: String
        $costPerUnit: String
        $acreage: String
        $contactName: String
        $contactAddress: String
        $contactPhone: String
        $contactEmail: String
    ) {
        CreateROW(
            id: $id
            dateOfLease: $dateOfLease
            lessee: $lessee
            termOfLease: $termOfLease
            dateOfExpiration: $dateOfExpiration
            type: $type
            original: $original
            volAndPage: $volAndPage
            datePaid: $datePaid
            familyId: $familyId
            projectName: $projectName
            comments: $comments
            status: $status
            sizeOfPipeline: $sizeOfPipeline
            lengthOfPipeline: $lengthOfPipeline
            costPerUnit: $costPerUnit
            acreage: $acreage
            contactName: $contactName
            contactAddress: $contactAddress
            contactPhone: $contactPhone
            contactEmail: $contactEmail
        )
        ${ROW_FRAGMENT}
    }
`;

export const EDIT_ROW = gql`
  mutation UpdateROW(
      $id: ID!
      $dateOfLease: String
      $lessee: String
      $termOfLease: String
      $dateOfExpiration: String
      $type: String
      $original: Boolean
      $volAndPage: String
      $datePaid: String
      $familyId: String
      $projectName: String
      $comments: String
      $status: String
      $sizeOfPipeline: String
      $lengthOfPipeline: String
      $costPerUnit: String
      $acreage: String
      $contactName: String
      $contactAddress: String
      $contactPhone: String
      $contactEmail: String
      $mapUrl: String
  ) {
    UpdateROW(
        id: $id
        dateOfLease: $dateOfLease
        lessee: $lessee
        termOfLease: $termOfLease
        dateOfExpiration: $dateOfExpiration
        type: $type
        original: $original
        volAndPage: $volAndPage
        datePaid: $datePaid
        familyId: $familyId
        projectName: $projectName
        comments: $comments
        status: $status
        sizeOfPipeline: $sizeOfPipeline
        lengthOfPipeline: $lengthOfPipeline
        costPerUnit: $costPerUnit
        acreage: $acreage
        contactName: $contactName
        contactAddress: $contactAddress
        contactPhone: $contactPhone
        contactEmail: $contactEmail
        mapUrl: $mapUrl
    ) 
      ${ROW_FRAGMENT}
  }
`;

export const DELETE_ROW = gql`
    mutation DeleteROW($id: ID!){
        DeleteROW(id: $id)
        ${ROW_FRAGMENT}
    }
`;

export const ADD_ROW_ON = gql`
  mutation AddROWLocatedOn($from: _ROWInput!, $to: _TractInput!) {
    AddROWLocatedOn(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
        state
        county
        familyID
      }
    }
  }
`;

export const REMOVE_ROW_ON = gql`
  mutation RemoveROWLocatedOn($from: _ROWInput!, $to: _TractInput!) {
    RemoveROWLocatedOn(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const ADD_ROW_OPERATOR = gql`
  mutation AddOperatorRow($from: _OperatorInput!, $to: _ROWInput!) {
    AddOperatorRow(from: $from, to: $to) {
      from {
        name
        id
        address1
        address2
        city
        state
        zip
        contacts {
          id
          contactName
          position
          directLine
          cell
          email
          date
          current
        }
      }
      to {
        id
      }
    }
  }
`;

export const REMOVE_ROW_OPERATOR = gql`
  mutation RemoveOperatorRow($from: _OperatorInput!, $to: _ROWInput!) {
    RemoveOperatorRow(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const ADD_ROW_PAYMENT = gql`
  mutation addROWPayment($ROWID: ID!, $info: ROWPaymentInput) {
    addROWPayment(ROWID: $ROWID, info: $info) {
      id
      payments {
        id
        date
        amount
        paymentType
        entity
        paymentFrom
      }
    }
  }
`;

export const DELETE_ROW_PAYMENT = gql`
  mutation deleteROWPayment($paymentID: ID!) {
    deleteROWPayment(paymentID: $paymentID) {
      id
      payments {
        id
        date
        amount
        paymentType
        entity
        paymentFrom
      }
    }
  }
`;

export const UPDATE_ROW_PAYMENT = gql`
  mutation updateROWPayment($paymentID: ID!, $paymentInfo: ROWPaymentInput) {
    updateROWPayment(paymentID: $paymentID, paymentInfo: $paymentInfo) {
      id
      date
      amount
      paymentType
      entity
      paymentFrom
    }
  }
`;

export const ADD_ROW_TITLE = gql`
  mutation addROWTitle($ROWID: ID!, $rowinfo: ROWTitleInfo) {
    addROWTitle(ROWID: $ROWID, rowinfo: $rowinfo) {
      id
      titleRun {
        id
        volPage
        effectiveDate
        dateFiled
        comments
        grantor
        grantee
      }
    }
  }
`;

export const REMOVE_ROW_TITLE = gql`
  mutation removeROWTitle($ROWTitleID: ID!) {
    removeROWTitle(ROWTitleID: $ROWTitleID) {
      id
      titleRun {
        id
        volPage
        effectiveDate
        dateFiled
        comments
        grantor
        grantee
      }
    }
  }
`;

export const UPDATE_ROW_TITLE = gql`
    mutation updateROWTitle($ROWTitleID: ID!, $rowinfo: ROWTitleInfo) {
        updateROWTitle(ROWTitleID: $ROWTitleID, rowinfo: $rowinfo) {
            id
            titleRun {
                id
                volPage
                effectiveDate
                dateFiled
                comments
                grantor
                grantee
                fileName
            }
        }
    }
`;
