import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import gql from 'graphql-tag';
import OtherLeaseDetailPage from './OtherLeaseDetailPage';
import {
  ADD_OTHER_LEASE_ON,
  ADD_OTHER_LEASE_OPERATOR,
  DELETE_OTHER_LEASE,
  EDIT_OTHER_LEASE,
  GET_OTHER_LEASE,
  REMOVE_OTHER_LEASE_ON,
  REMOVE_OTHER_LEASE_OPERATOR,
} from '../../components/otherleases/otherleaseQueries';

const OPERATOR_QUERY = gql`
  query {
    Operator {
      id
      name
    }
  }
`;

const OL_TRACTS_QUERY = gql`
  query {
    Tract(orderBy: id_asc) {
      id
      name
    }
  }
`;

const GetOLFromQuery = (queryResult) => {
  if (!Array.isArray(queryResult) || queryResult.length === 0) {
    return false;
  }

  return queryResult[0];
};

const GetInfoFromQuery = (queryResult) => {
  if (!Array.isArray(queryResult) || queryResult.length === 0) {
    return false;
  }

  return queryResult;
};

const EditOtherLease = ({ match, history }) => {
  const { data, error, loading } = useQuery(GET_OTHER_LEASE, {
    variables: {
      id: match.params.OtherLeaseID,
    },
  });

  const [EditOL] = useMutation(EDIT_OTHER_LEASE);
  const tractsQuery = useQuery(OL_TRACTS_QUERY);
  const operatorInfo = useQuery(OPERATOR_QUERY);
  const [deleteOL] = useMutation(DELETE_OTHER_LEASE);
  const [placeLeaseOn] = useMutation(ADD_OTHER_LEASE_ON);
  const [removeLease] = useMutation(REMOVE_OTHER_LEASE_ON);
  const [removeOperator] = useMutation(REMOVE_OTHER_LEASE_OPERATOR);
  const [addOperator] = useMutation(ADD_OTHER_LEASE_OPERATOR);

  if (error || EditOtherLease.error) {
    return <h1>{error.toString()}</h1>;
  } else if (loading || EditOtherLease.loading) {
    return <LoadingView />;
  }

  const OtherLease = GetOLFromQuery(data.OtherLease) || [];

  const tracts = GetInfoFromQuery(tractsQuery.data.Tract) || [];
  const operators = GetInfoFromQuery(operatorInfo.data.Operator) || [];

  return (
    <div>
      <OtherLeaseDetailPage
        OtherLease={OtherLease}
        EditOL={EditOL}
        history={history}
        tracts={tracts}
        operators={operators}
        deleteOL={deleteOL}
        placeLeaseOn={placeLeaseOn}
        removeLease={removeLease}
        removeOperator={removeOperator}
        addOperator={addOperator}
      />
    </div>
  );
};

export default EditOtherLease;
