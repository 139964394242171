export const wellStatuses = {
  'Active': 'Active',
  'DUC': 'DUC',
  'Inactive': 'Inactive',
  'In Process': 'In Process',
  'P&A': 'P&A',
  'Shut-In': 'Shut-In',
};

export const rowStatuses = {
  'Active': 'Active',
  'Inactive': 'Inactive',
  'In Process': 'In Process',
  'Cancelled': 'Cancelled',
};

export const unitStatuses = {
  'Active': 'Active',
  'Inactive': 'Inactive',
  'In Process': 'In Process',
};

// CDC, EXP, EXT, HBP, In Process, PT, PTL REL, REL
export const oglStatuses = {
  'CDC': 'CDC',
  'EXP': 'EXP',
  'EXT': 'EXT',
  'HBP': 'HBP',
  'In Process': 'In Process',
  'PT': 'PT',
  'PTL REL': 'PTL REL',
  'REL': 'REL',
};

export const rowTerms = {
  '5 Yrs': '5 Yrs',
  '10 Yrs': '10 Yrs',
  '15 Yrs': '15 Yrs',
  '40 Yrs': '40 Yrs',
  'Temp': 'Temp',
  'Abdmt': 'Abdmt',

};
