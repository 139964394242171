import React from 'react';
import { useMutation } from '@apollo/client';
import {
  CREATE_CLAUSE,
  CREATE_LEASE,
  defaultLease,
  DELETE_CLAUSE,
  GET_ALL_LEASES,
  UPDATE_CLAUSE,
} from '../../components/leases/LeaseQueries';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Auth from '../../util/Auth';
import { makeStyles } from '@material-ui/styles';
import { v4 } from 'uuid';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';
import ClauseTable from './ClauseTable';
import Button from '@material-ui/core/Button';
import { leaseTemplateSchema } from '../../util/schema/LeaseSchema';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LEASE);

const MaterialTableStyles = makeStyles((theme) => ({
  Switch: {
    margin: 0,
  },
  BottomBar: {
    display: hasPermissions ? 'flex' : 'none',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  SubmitButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    margin: 10,
  },
  DeleteButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
    float: 'right',
  },
  FormControlLabel: {
    paddingRight: '5em',
  },
  Select: {
    display: 'block',
  },
  FullWidthTextBox: {
    marginBottom: 20,
    marginTop: 20,
  },
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
  },
  landsTable: {
    display: 'none',
  },
}));

const LeaseTemplate = ({ history }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [templateCreated, setTemplateCreated] = React.useState(false);
  const [clauseID, setClauseID] = React.useState('');

  const classes = MaterialTableStyles();

  const LeaseClausesFragment = gql`
    fragment LeaseClausesFragment on Lease {
      id
      leaseClauses {
        id
        type
        name
        paragraph
        text
        oil
        gas
      }
    }
  `;

  const [createClause] = useMutation(CREATE_CLAUSE);
  const [deleteClause] = useMutation(DELETE_CLAUSE);
  const [updateClause] = useMutation(UPDATE_CLAUSE);
  const [addTemplate] = useMutation(CREATE_LEASE);

  const editClause = (values) => {
    return updateClause({
      variables: values,
    });
  };

  const removeClause = (clauseId, leaseId) => {
    return deleteClause({
      variables: { id: clauseId },
      update: (store, { data: { DeleteClause } }) => {
        try {
          const oldClauses = store.readFragment({
            id: leaseId,
            fragment: LeaseClausesFragment,
          });

          const updatedClauses = oldClauses.leaseClauses.filter((clause) => clause.id !== clauseId);

          store.writeFragment({
            id: leaseId,
            fragment: LeaseClausesFragment,
            data: {
              ...oldClauses,
              leaseClauses: updatedClauses,
            },
          });
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  const addClause = (values) => {
    return createClause({
      variables: values,
      update: (store, { data: { addClause } }) => {
        try {
          const oldClauses = store.readFragment({
            id: values.leaseID,
            fragment: LeaseClausesFragment,
          });

          const updatedClauses = [...oldClauses.leaseClauses, addClause];

          store.writeFragment({
            id: values.leaseID,
            fragment: LeaseClausesFragment,
            data: {
              ...oldClauses,
              leaseRecordings: updatedClauses,
            },
          });
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  defaultLease.template = true;

  return (
    <div>
      <Formik
        initialValues={defaultLease}
        validationSchema={leaseTemplateSchema}
        onSubmit={(values, actions) => {
          const newID = v4();
          setClauseID(newID);
          values.id = newID;
          actions.setSubmitting(true);
          return addTemplate({
            variables: { ...values },
            update: (store, { data: { CreateLease } }) => {
              try {
                const AllLeasesQuery = store.readQuery({ query: GET_ALL_LEASES });

                const data = { Lease: [...AllLeasesQuery.Lease, CreateLease] };

                store.writeQuery({ query: GET_ALL_LEASES, data: data });
              } catch (e) {
                console.log('Could not add lease to all leases query due to it not being run yet');
              }
            },
          }).then(
            (data) => {
              enqueueSnackbar('Lease Template Created', { variant: 'success' });
              actions.setSubmitting(false);
              setTemplateCreated(true);
              closeSnackbar();
            },
            (error) => {
              enqueueSnackbar(`${error}`, { variant: 'error' });
              actions.setSubmitting(false);
              closeSnackbar();
            }
          );
        }}
      >
        {(props) => {
          return (
            <div>
              <Form>
                <Typography className={classes.title} color='textSecondary' gutterBottom>
                  Create Lease Template
                </Typography>
                <Field
                  name='leaseName'
                  placeholder='Lease ID'
                  label='ID'
                  component={TextField}
                  className={classes.TextField}
                  margin='normal'
                />
                <div>
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.SubmitButton}
                    disabled={!props.isValid || templateCreated}
                    style={{ display: hasPermissions ? 'inline-block' : 'none' }}
                    onClick={() => {
                      props.handleSubmit();
                    }}
                    fullWidth
                  >
                    Set Template ID
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    disabled={templateCreated}
                    style={{ display: hasPermissions ? 'inline-block' : 'none' }}
                    className={classes.SubmitButton}
                    onClick={() => history.push('/Leases')}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
              <ClauseTable
                queries={{ editClause, removeClause, addClause }}
                display={templateCreated}
                leaseID={clauseID}
              />
              <Button
                variant='contained'
                color='primary'
                className={classes.SubmitButton}
                style={{ display: templateCreated ? 'inline-block' : 'none' }}
                onClick={() => {
                  history.push('/Leases');
                }}
                fullWidth
              >
                Finish
              </Button>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default LeaseTemplate;
