import PERMISSIONS_LIST from './RolesEnum';
// Custom backend-based authentication setup
const Auth = (() => {
  let accessToken = localStorage.getItem('accessToken');
  let idToken = localStorage.getItem('idToken');
  let expiresAt = localStorage.getItem('expiresAt');
  let userRoles = JSON.parse(localStorage.getItem('userRoles')) || []; // Load roles from localStorage

  const login = (username, password) => {
    fetch(process.env.REACT_APP_LOGIN_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': idToken,
      },
      body: JSON.stringify({ username, password }),
    })
      .then((res) => res.json())
      .then((json) => {
        accessToken = json.access_token;
        idToken = json.access_token;
        expiresAt = (new Date().getTime() + json.expires_in * 1000).toString();
        userRoles = json.roles; // Directly use the roles from the response
        const processedRoles = userRoles.map(role => {
          // Substitute 'ROLE_ADMIN' with 'ADMIN'
          if (role === 'ROLE_ADMIN') {
            return 'ADMIN';
          }
          return role;
        }).filter(role => {
          // Exclude 'USER_ROLE' from the array
          return role !== 'USER_ROLE';
        });

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('expiresAt', expiresAt);
        localStorage.setItem('userRoles', JSON.stringify(processedRoles)); // Store roles in localStorage

        window.location.hash = ''; // Clear the URL hash
        window.location = process.env.REACT_APP_REDIRECT_URI;
      })
      .catch((error) => {
        console.error('Login failed:', error);
      });
  };

  const logout = () => {
    accessToken = null;
    idToken = null;
    expiresAt = null;
    userRoles = [];
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('userRoles');
    window.location = window.location.origin; // Redirect to home on logout
  };

  const isAuthenticated = () => new Date().getTime() < Number(expiresAt);
  const getRoles = () => userRoles;

  // HasRole: Takes the permissions a user has and distributes them to different parts of the application, starting with Drawer.js line: 118
  //          which populates the navigation panel with tabs based on the users permissions
  const hasRole = (role) => {
    return userRoles.includes(role) || userRoles.includes(PERMISSIONS_LIST.ADMIN);
  };

  const hasRoles = (roles = []) => {
    if (userRoles.includes(PERMISSIONS_LIST.ADMIN)) {
      return true;
    }
    return roles.every((role) => userRoles.includes(role)); // NOTE: .every returns true on empty arrays
  };

  return { login, logout, isAuthenticated, getRoles, hasRole, hasRoles };
})();
export default Auth;