import React from 'react';
import { Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DIWellDiffRadio from './DIWellDiffRadio';
import DIWellDiffSelect from './DIWellDiffSelect';

const DIWellDiffStyles = makeStyles((theme) => ({
  codeBlock: {
    backgroundColor: '#f3f9ff',
  },
}));

const DIWellDiff = ({ CurrentWell, diWell, diffedWell, setDiffedWell, differencePropertyNames, deps, history }) => {
  const classes = DIWellDiffStyles();
  const { Operators } = deps;
  return (
    <Table className={classes.codeBlock}>
      <TableHead>
        <TableRow>
          <TableCell>Property</TableCell>
          <TableCell>Current Well Value</TableCell>
          <TableCell>Drilling Info Well Value</TableCell>
          <TableCell>Choose Current Well Value</TableCell>
          <TableCell>Choose Drilling Info Well Value</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {differencePropertyNames
          .sort((a, b) => (a === 'operator' ? -1 : 0)) // Put operator at the top of the table
          .map((property) =>
            property === 'operator' ? (
              <DIWellDiffSelect
                key={property}
                property={property}
                selectOptions={Operators}
                defaultOption={Operators.find((operator) => operator.name === diWell[property]) || null}
                CurrentWellValue={CurrentWell[property]}
                diWellValue={diWell[property]}
                setProperty={(property, value) => {
                  const obj = { ...diffedWell };

                  obj[property] = value;

                  setDiffedWell(obj);
                }}
                history={history}
              />
            ) : (
              <DIWellDiffRadio
                key={property}
                property={property}
                CurrentWellValue={CurrentWell[property]}
                diWellValue={diWell[property]}
                setProperty={(property, value) => {
                  const obj = { ...diffedWell };

                  obj[property] = value;

                  setDiffedWell(obj);
                }}
              />
            )
          )}
      </TableBody>
    </Table>
  );
};

export default DIWellDiff;
