import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { AppBar, Tabs, Tab, Typography } from '@material-ui/core';
import {
  GET_LEASE,
  EDIT_LEASE,
  DELETE_LEASE,
  ADD_LEASE_INTEREST,
  DELETE_LEASE_INTEREST,
  UPDATE_LEASE_INTEREST,
  ADD_CDC_WELL,
  DELETE_CDC_WELL,
  UPDATE_CDC_WELL,
  CREATE_ASSIGNEE_INTEREST,
  REMOVE_ASSIGNMENT_INTEREST,
  UPDATE_ASSIGNMENT_INTEREST,
  CREATE_CLAUSE,
  DELETE_CLAUSE,
  UPDATE_CLAUSE,
} from '../../components/leases/LeaseQueries';
import LoadingView from '../../components/LoadingView';
import LeaseDetailPage from './LeaseDetailPage';
import Auth from '../../util/Auth';
import { makeStyles } from '@material-ui/styles';
import CDCWells from './CDCWells';
import { useSnackbar } from 'notistack';
import { WELL_AUTOCOMPLETE } from '../../components/wells/WellQueries';
import gql from 'graphql-tag';
import { GET_ALL_TRACT_IDS } from '../../components/tracts/TractQueries';
import { GET_ALL_OPERATORS } from '../../components/operators/OperatorQueries';
import AssigneeTable from './AssigneeTable';
import ClauseTable from './ClauseTable';
import MapAndLegal from './MapAndLegal';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import RecordingsTable from './RecordingsTable';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LEASE);

export const LeaseTableStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
  },
  tableRow: {
    width: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    placeItems: 'center center',
    gap: '1.5rem',
  },
}));

const LeaseInterestFragment = gql`
  fragment LeaseInterestFragment on Lease {
    id
    leasedLands {
      id
      netMineralAcres
      netSurfaceAcres
      depths
      shortDescription
      Tract {
        id
      }
    }
  }
`;

const LeaseClausesFragment = gql`
  fragment LeaseClausesFragment on Lease {
    id
    leaseClauses {
      id
      type
      name
      paragraph
      text
      oil
      gas
    }
  }
`;

const LeaseAssignmentFragment = gql`
  fragment LeaseAsssignmentFragment on Lease {
    id
    assignments {
      id
      volPage
      book
      docType
      effectiveDate
      dateFiled
      totalAcres
      comments
      wellsDrilled
      grantor {
        id
        name
      }
      grantee {
        id
        name
      }
      assignmentInterests {
        id
        acres
        Tract {
          id
        }
      }
    }
  }
`;

const EditLeaseStyles = makeStyles((theme) => ({
  isNotAuthorized: {
    display: Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LEASE) ? 'none' : 'block',
    paddingTop: '2rem',
  },
}));

function getLandIDsFromQuery({ data: { Tract } }) {
  if (Array.isArray(Tract) && Tract.length !== 0) {
    return Tract;
  }
  return [];
}

const CleanLeaseFromQuery = (rawLease) => {
  let cleanedLease = { ...rawLease };

  cleanedLease.leasedLands = cleanedLease.leasedLands.map((land) => ({ ...land, tractID: land.Tract.id }));

  return cleanedLease;
};

const GetLeaseFromQuery = (queryResult) => {
  if (!Array.isArray(queryResult) || queryResult.length === 0) {
    return false;
  }

  return CleanLeaseFromQuery(queryResult[0]);
};

//Tab component - borrowed this directly from material ui
function TabContainer(props) {
  return (
      <Typography component='div' style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
  );
}

const EditLease = ({ match, history }) => {
  const classes = EditLeaseStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(0);
  const {data, loading, error} = useQuery(GET_LEASE, {
    variables: {
      id: match.params.leaseID,
    },
  });
  const landIDs = useQuery(GET_ALL_TRACT_IDS);
  const wellAutoComplete = useQuery(WELL_AUTOCOMPLETE);
  const [EditLeaseQuery] = useMutation(EDIT_LEASE);
  const [deleteLease] = useMutation(DELETE_LEASE);
  const [addLand] = useMutation(ADD_LEASE_INTEREST, { awaitRefetchQueries: true });
  const [deleteLand] = useMutation(DELETE_LEASE_INTEREST, { awaitRefetchQueries: true });
  const [editLeasedLand] = useMutation(UPDATE_LEASE_INTEREST, { awaitRefetchQueries: true });
  const [addLeaseInterest] = useMutation(ADD_CDC_WELL);
  const [removeLeaseInterest] = useMutation(DELETE_CDC_WELL);
  const [editLeaseInterest] = useMutation(UPDATE_CDC_WELL);
  const [addAssignee] = useMutation(CREATE_ASSIGNEE_INTEREST);
  const [removeAssignee] = useMutation(REMOVE_ASSIGNMENT_INTEREST);
  const [updateAssignment] = useMutation(UPDATE_ASSIGNMENT_INTEREST);
  const [createClause] = useMutation(CREATE_CLAUSE);
  const [deleteClause] = useMutation(DELETE_CLAUSE);
  const [updateClause] = useMutation(UPDATE_CLAUSE);


  //Updated Chain of Title

  const editClause = (values) => {
    return updateClause({
      variables: values,
    });
  };

  const removeClause = (clauseId, leaseId) => {
    return deleteClause({
      variables: { id: clauseId },
      update: (store, { data: { DeleteClause } }) => {
        try {
          const oldClauses = store.readFragment({
            id: leaseId,
            fragment: LeaseClausesFragment,
          });

          const updatedClauses = oldClauses.leaseClauses.filter((clause) => clause.id !== clauseId);

          store.writeFragment({
            id: leaseId,
            fragment: LeaseClausesFragment,
            data: {
              ...oldClauses,
              leaseClauses: updatedClauses,
            },
          });
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  const addClause = (values) => {
    return createClause({
      variables: values,
      update: (store, { data: { addClause } }) => {
        try {
          const oldClauses = store.readFragment({
            id: values.leaseID,
            fragment: LeaseClausesFragment,
          });

          const updatedClauses = [...oldClauses.leaseClauses, addClause];

          store.writeFragment({
            id: values.leaseID,
            fragment: LeaseClausesFragment,
            data: {
              ...oldClauses,
              leaseRecordings: updatedClauses,
            },
          });
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  const editAssignment = (values) => {
    return updateAssignment({
      variables: values,
    });
  };

  const deleteAssignment = (values) => {
    return removeAssignee({
      variables: values,
      update: (store, { data: { DeleteAssignment } }) => {
        try {
          const oldAssignments = store.readFragment({
            id: match.params.leaseID,
            fragment: LeaseAssignmentFragment,
          });

          const updatedAssignments = [...oldAssignments.assignments.filter((a) => a.id !== values.id)];

          store.writeFragment({
            id: match.params.leaseID,
            fragment: LeaseAssignmentFragment,
            data: {
              ...oldAssignments,
              assignments: updatedAssignments,
            },
          });
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  const createAssignment = ({ leaseID, AssignmentInfo, landIDs }) => {
    return addAssignee({
      variables: { leaseID, AssignmentInfo, landIDs },
      update: (store, { data: { createAssignment } }) => {
        try {
          const oldAssignments = store.readFragment({
            id: match.params.leaseID,
            fragment: LeaseAssignmentFragment,
          });

          const updatedAssignments = [...oldAssignments.assignments, createAssignment];

          store.writeFragment({
            id: match.params.leaseID,
            fragment: LeaseAssignmentFragment,
            data: {
              ...oldAssignments,
              assignments: updatedAssignments,
            },
          });
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  const editCDCWell = (values) => {
    return editLeaseInterest({
      variables: values,
    });
  };

  const removeCDCWell = (values) => {
    return removeLeaseInterest({
      variables: values,
    });
  };

  const addCDCWell = (values) => {
    return addLeaseInterest({
      variables: values,
    });
  };

  const addLeasedLand = (leaseValues) => {
    return addLand({
      variables: leaseValues,
    });
  };

  const updateLeasedLand = (values) => {
    return editLeasedLand({
      variables: values,
    });
  };

  const removeLeasedLand = (ids, recordId) => {
    return deleteLand({
      variables: ids,
      update: (store, { data: { deleteLeaseInterest } }) => {
        try {
          const oldLeases = store.readFragment({
            id: ids.leaseID,
            fragment: LeaseInterestFragment,
          });

          const updatedLeases = oldLeases.leasedLands.filter((lease) => lease.id !== recordId);

          store.writeFragment({
            id: ids.leaseID,
            fragment: LeaseInterestFragment,
            data: {
              ...oldLeases,
              leasedLands: updatedLeases,
            },
          });
        } catch (e) {
          console.error(e);
        }
      },
    });
  };



  // Handle lease query
  if (error || landIDs.error) {
    return (
        <h1>
          {data.error.toString()} | {landIDs.error.toString()}
        </h1>
    );
  } else if (loading || landIDs.loading) {
    return <LoadingView />;
  }

  const LandIDs = getLandIDsFromQuery(landIDs);

  if(data){
    const { Lease } = data
    const editableLease = GetLeaseFromQuery(Lease)
    return (
        <>
          <Typography
              style={{ color: '#1976d2', fontSize: '1.75rem', textAlign: 'center', textDecoration: 'underline' }}
              className={classes.title}
              color='textSecondary'
              gutterBottom
          >
            {Lease.leaseName}
          </Typography>
          <AppBar position='static'>
            <Tabs
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
            >
              <Tab label='Main' />
              <Tab label='OGL Chain OF Title' />
              <Tab label='CDC Wells' />
              <Tab label='Clauses' />
              <Tab label='Legal Description & Map' />
            </Tabs>
          </AppBar>
          <Typography variant='h5' className={classes.isNotAuthorized}>
            Not Authorized To Make Changes
          </Typography>
          {value === 0 && (
              <TabContainer>
                <LeaseDetailPage
                    Lease={editableLease}
                    isEditing={true}
                    mutationFn={EditLeaseQuery}
                    queries={{
                      deleteLease,
                      addLeasedLand,
                      removeLeasedLand,
                      updateLeasedLand,
                      EditLeaseQuery,
                    }}
                    setNotification={enqueueSnackbar}
                    landIDs={LandIDs}
                    history={history}
                    onSubmit={(newLease) => {
                      return EditLeaseQuery({
                        variables: { ...newLease },
                        update: (store, { data: { UpdateLease } }) => {
                          try {
                          } catch (e) {
                            console.log('Could not update lease fragment');
                          }
                        },
                      });
                    }}
                />
              </TabContainer>
          )}
          {value === 1 && (
              <TabContainer>
                <RecordingsTable
                    match={match}
                />
              </TabContainer>
          )}
          {value === 2 && (
              <TabContainer>
                <CDCWells
                    queries={{
                      wellAutoComplete,
                      addCDCWell,
                      removeCDCWell,
                      editCDCWell,
                    }}
                    Lease={editableLease}
                />
              </TabContainer>
          )}
          {value === 3 && (
              <TabContainer>
                <ClauseTable Lease={editableLease} queries={{ addClause, removeClause, editClause }} />
              </TabContainer>
          )}
          {value === 4 && (
              <TabContainer>
                <MapAndLegal
                    Lease={editableLease}
                    setNotification={enqueueSnackbar}
                    onSubmit={(newLease) => {
                      return EditLeaseQuery({
                        variables: { ...newLease },
                        update: (store, { data: { UpdateLease } }) => {
                          try {
                          } catch (e) {
                            console.log('Could not update lease fragment');
                          }
                        },
                      });
                    }}
                />
              </TabContainer>
          )}
        </>
    );
  }
};

export default EditLease;

