import React from 'react';
import { makeStyles } from '@material-ui/styles';
import LoginForm from './LoginForm';

const LoginComponentStyle = makeStyles({
  root: {},
});

export default function Login() {
  const classes = LoginComponentStyle();

  return (
    <div id='login_root' className={classes.root}>
      <LoginForm />
    </div>
  );
}
