import React from 'react';
import MaterialTable from 'material-table';
import {Typography} from '@material-ui/core';
import Link from 'react-router-dom/Link';

const LandWellsTable = ({Land}) => {
    const columns = [
        {
            title: 'ID',
            field: 'id',
            render: (rowData) => <Link to={`/Wells/${rowData.id}`}>{rowData.name}</Link>,
        },
        {
            title: 'Land ID',
            field: 'drilledOn',
            render: (rowData) => rowData.drilledOn.map((w) => w.id).join(', '),
        },
        {
            title: 'Status',
            field: 'status',
        }
    ];

    return (
        <div>
            <Typography
                variant='h5'
                style={{
                    color: '#1976d2',
                    fontSize: '1.75rem',
                    textAlign: 'center',
                    textDecoration: 'underline',
                }}
            >
                Wells
            </Typography>
            <MaterialTable
                columns={columns}
                data={Land.drilledOn.map(d => ({...d}))}
                options={{
                    search: false,
                    showTitle: false,
                    paging: false,
                    exportButton: true,
                    toolbarButtonAlignment: 'left',
                    headerStyle: {
                        backgroundColor: '#1976d2',
                        color: '#FFF',
                        textAlign: 'left',
                        justifyContent: 'center',
                        whiteSpace: 'pre',
                    },
                }}
            />
        </div>
    );
};

export default LandWellsTable;
