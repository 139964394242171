import React, { useState } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { FormLabel, Button, MenuItem, TextField as MaterialUITextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Auth from '../../util/Auth';
import { Prompt } from 'react-router';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteLeaseModal from './DeleteLeaseModal';
import Select from 'react-select';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { LeaseSchema } from '../../util/schema/LeaseSchema';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import { oglStatuses } from "../../util/Statuses";

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LEASE);

const LeaseDetailPageStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  gdone: {
    display: 'grid',
    gridTemplateColumns: '250px auto 200px',
    gridTemplateRows: '90% 10%',
    gap: '.5rem 2rem',
  },
  left: {
    display: 'grid',
    gridTemplateRows: '',
  },
  middle: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  right: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  deleteButton: {
    gridRow: '',
    gridColumn: '3',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
  },
}));

const mineralClassifiedText = (
  <Typography
    style={{
      color: '#1976d2',
      fontSize: '1.75rem',
      textAlign: 'center',
      textDecoration: 'underline',
    }}
    color='textSecondary'
    gutterBottom
  >
    Mineral Classified - Family owns surface, State owns minerals. Family is agent for State, Family & State share 50/50
    all lease/well/surface payments.
  </Typography>
);

const calculateExpiryDate = ({ effectiveDate, termMonth = '0' }) => {
  if (!effectiveDate) {
    return null;
  }

  const startDate = moment(effectiveDate, 'YYYY-MM-DD', true);

  return startDate.add('months', termMonth).format('MM-DD-YYYY');
};

const calculateExtensionExpiryDate = ({ extensionDueDate, extensionOptionsYears, extensionOptionsMonths }) => {
  if (!extensionDueDate) {
    return null;
  }

  const startDate = moment(extensionDueDate, 'YYYY-MM-DD', true);

  return startDate
    .add('years', extensionOptionsYears)
    .add('months', extensionOptionsMonths)
    .format('MM-DD-YYYY');
};

const calculateBonusAmount = ({ netMineralAcres, bonusPerAcre }) => {
  if (!netMineralAcres || !bonusPerAcre || isNaN(netMineralAcres) || isNaN(bonusPerAcre)) {
    return 0;
  }
  const result = Number(netMineralAcres) * Number(bonusPerAcre);

  return result.toFixed(2);
};

const LeaseDetailPage = ({ Lease, setNotification, queries, landIDs, history }) => {
  const { deleteLease, addLeasedLand, removeLeasedLand, updateLeasedLand, EditLeaseQuery } = queries;
  const classes = LeaseDetailPageStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const totalNMA = Lease.leasedLands ? Lease.leasedLands.reduce((a, b) => a + Number(b.netMineralAcres), 0) : 0;
  const totalNSA = Lease.leasedLands ? Lease.leasedLands.reduce((a, b) => a + Number(b.netSurfaceAcres), 0) : 0;
  Lease.skimOil = !Lease.skimOil ? false : Lease.skimOil;
  Lease.condensateOil = !Lease.condensateOil ? false : Lease.condensateOil;
  Lease.flaredGas = !Lease.flaredGas ? false : Lease.flaredGas;
  Lease.leaseUseGas = !Lease.leaseUseGas ? false : Lease.leaseUseGas;
  Lease.separateLeaseLanguage = !Lease.separateLeaseLanguage ? false : Lease.separateLeaseLanguage;
  Lease.paidUpOGL = !Lease.paidUpOGL ? false : Lease.paidUpOGL;
  Lease.termYears = !Lease.termMonth ? '0' : `${(Lease.termMonth / 12).toFixed(2)}`;
  Lease.dueOil = !Lease.dueOil ? '0' : Lease.dueOil;
  Lease.dueGas = !Lease.dueGas ? '0' : Lease.dueGas;
  Lease.extensionOptionsYears = !Lease.extensionOptionsYears ? '0' : Lease.extensionOptionsYears;
  Lease.extensionOptionsMonths = !Lease.extensionOptionsMonths ? '0' : Lease.extensionOptionsMonths;
  Lease.extensionDueDate = !Lease.extensionDueDate ? '' : Lease.extensionDueDate;
  Lease.amountPerAcre = !Lease.amountPerAcre ? '0' : Lease.amountPerAcre;
  Lease.totalAmount = !Lease.totalAmount ? '0' : Lease.totalAmount;
  Lease.rentalPerAcre = !Lease.rentalPerAcre ? '0' : Lease.rentalPerAcre;
  Lease.rentalAmount = !Lease.rentalAmount ? '0' : Lease.rentalAmount;
  Lease.cdcDeadline = !Lease.cdcDeadline ? '' : Lease.cdcDeadline;
  Lease.cdcPeriod = !Lease.cdcPeriod ? '' : Lease.cdcPeriod;
  Lease.shutinMaxConsecutive = !Lease.shutinMaxConsecutive ? '' : Lease.shutinMaxConsecutive;
  Lease.horizontalPughDeepDate = !Lease.horizontalPughDeepDate ? '' : Lease.horizontalPughDeepDate;
  Lease.horizontalPughDeepDescription = !Lease.horizontalPughDeepDescription ? '' : Lease.horizontalPughDeepDescription;
  Lease.horizontalPughShallowDate = !Lease.horizontalPughShallowDate ? '' : Lease.horizontalPughShallowDate;
  Lease.horizontalPughShallowDescription = !Lease.horizontalPughShallowDescription ? '' : Lease.horizontalPughShallowDescription;

  return (
    <Formik
      initialValues={Lease}
      enableReinitialize='true'
      validationSchema={LeaseSchema}
      onSubmit={(values, actions) => {
        values.termMonth = values.termMonth.toString();
        return EditLeaseQuery({ variables: values })
          .then(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            setNotification('Lease Successfully Edited', { variant: 'success' });
          })
          .catch((err) => {
            actions.setSubmitting(false);
            setNotification('Could not edit lease!', { variant: 'error' });
          });
      }}
    >
      {(props) => {
        const leaseExpirationDate = calculateExpiryDate(props.values);
        const leaseExtensionExpiryDate = calculateExtensionExpiryDate(props.values) || '';
        const bonusAmount = calculateBonusAmount(props.values);
        const nmaColor = totalNMA === Number(props.values.netMineralAcres) ? 'blue' : 'red';
        const nsaColor = totalNSA === Number(props.values.netSurfaceAcres) ? 'blue' : 'red';

        return (
          <>
            <Prompt
              when={props.dirty && props.submitCount < 1}
              message='Are you sure you want to leave? Your unsaved changes will be deleted'
            />
            <Form className={classes.form}>
              <div style={{ width: 'auto' }}>
                <div style={{ display: 'flex' }}>
                  <FormControlLabel
                    control={<Field name='mineralClassified' component={Switch} color='primary' />}
                    label='Mineral Classified'
                    labelPlacement='top'
                  />
                  <Field
                    name='leaseName'
                    placeholder='Lease ID'
                    label='ID'
                    component={TextField}
                    className={classes.TextField}
                    margin='normal'
                  />
                    <Field
                        name='mfNumber'
                        placeholder='MF #'
                        label='MF #'
                        component={TextField}
                        className={classes.TextField}
                        margin='normal'
                    />
                </div>
                <div style={{ display: 'flex' }}>
                  <Field
                    name='currentLessee'
                    placeholder='Current Lessee'
                    label='Current Lessee'
                    component={TextField}
                    className={classes.TextField}
                    style={{ width: 350 }}
                    fullWidth
                  />
                  <Field
                    name='grossAcres'
                    placeholder='Gross Acres'
                    label='Gross Acres'
                    component={TextField}
                    className={classes.TextField}
                  />
                  <Field
                    name='netMineralAcres'
                    placeholder='Net Mineral Acres'
                    label='Net Mineral Acres'
                    component={TextField}
                    className={classes.TextField}
                  />
                  <Field
                    name='netSurfaceAcres'
                    placeholder='Net Surface Acres'
                    label='Net Surface Acres'
                    component={TextField}
                    className={classes.TextField}
                  />
                  <Field
                    name='heldByProductionAcres'
                    placeholder='HBP Acres'
                    label='HBP Acres'
                    component={TextField}
                    className={classes.TextField}
                  />
                  <Field
                    name='status'
                    select
                    placeholder='Status'
                    label='Status'
                    component={TextField}
                    className={classes.TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {
                      Object.keys(oglStatuses).map((key) => {
                        return <MenuItem key={key} value={key}>{oglStatuses[key]}</MenuItem>;
                      })
                    }
                  </Field>
                </div>
                <div>
                  <Field
                    name='depthsLeased'
                    placeholder='Depths Leased'
                    label='Depths Leased'
                    component={TextField}
                    className={classes.TextField}
                    fullWidth
                  />
                </div>
                {props.values.mineralClassified ? mineralClassifiedText : ''}
                <br />
                <hr />
                <br />
                <div>
                  <Paper className={classes.paper}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ width: 200 }}>
                        <Typography className={classes.title} color='textSecondary' gutterBottom>
                          Contract Terms
                        </Typography>
                        <Field
                          name='termYears'
                          placeholder='Term Years'
                          label='Term Years'
                          component={TextField}
                          className={classes.TextField}
                          disabled
                        />
                        <Field
                          name='termMonth'
                          type='number'
                          placeholder='Term Months'
                          label='Term Months'
                          component={TextField}
                          className={classes.TextField}
                        />
                        <Field
                          name='effectiveDate'
                          placeholder='Effective Date'
                          component={TextField}
                          label='Effective Date'
                          type='date'
                          className={classes.TextField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <MaterialUITextField value={leaseExpirationDate} label='LeaseExpirationDate' disabled />
                        <Field
                          name='bonusPerAcre'
                          placeholder='Bonus Per Acre'
                          label='Bonus Per Acre'
                          component={TextField}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          className={classes.TextField}
                        />
                        <MaterialUITextField
                          value={bonusAmount}
                          label='Bonus Amount'
                          className={classes.TextField}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          disabled
                        />
                      </div>
                      <div style={{ width: 200 }}>
                        <Typography className={classes.title} color='textSecondary' gutterBottom>
                          Royalty
                        </Typography>
                        <Field
                          name='RoyaltyRate'
                          placeholder='Royalty Rate'
                          label='Royalty Rate'
                          component={TextField}
                          className={classes.TextField}
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                          }}
                          fullWidth
                        />
                        <Typography className={classes.title} color='textSecondary' gutterBottom>
                          Due
                        </Typography>
                        <Field
                          name='dueOil'
                          placeholder='Oil'
                          label='Oil'
                          component={TextField}
                          className={classes.TextField}
                          fullWidth
                        />
                        <Field
                          name='dueGas'
                          placeholder='Gas'
                          label='Gas'
                          component={TextField}
                          className={classes.TextField}
                          fullWidth
                        />
                        <Field
                          name='minRoyalty'
                          placeholder='Minimum Royalty'
                          label='Minimum Royalty'
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                            endAdornment: <InputAdornment position='end'>/Acre/Year</InputAdornment>,
                          }}
                          component={TextField}
                          className={classes.TextField}
                          fullWidth
                        />
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <FormLabel style={{ display: 'block' }}>Skim Oil</FormLabel>
                            <Field label='Skim Oil' name='skimOil' component={Switch} color='primary' />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <FormLabel style={{ display: 'block' }}>Condensate Oil</FormLabel>
                            <Field label='Skim Oil' name='condensateOil' component={Switch} color='primary' />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <FormLabel style={{ display: 'block' }}>Lease Use Gas</FormLabel>
                            <Field label='Skim Oil' name='leaseUseGas' component={Switch} color='primary' />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <FormLabel style={{ display: 'block' }}>Flared Gas</FormLabel>
                            <Field label='Skim Oil' name='flaredGas' component={Switch} color='primary' />
                          </div>
                        </div>
                      </div>
                      <div style={{ width: 200 }}>
                        <Typography className={classes.title} color='textSecondary' gutterBottom>
                          Extensions & Rentals
                        </Typography>
                        <div>
                          <FormLabel style={{ display: 'block' }}>Paid Up OGL</FormLabel>
                          <Field name='paidUpOGL' component={Switch} color='primary' />
                        </div>
                        <Field
                          name='extensionOptionsYears'
                          placeholder='Ext. Options Years'
                          label='Ext. Options Years'
                          component={TextField}
                          className={classes.TextField}
                        />
                        <Field
                          name='extensionOptionsMonths'
                          placeholder='Ext. Options Months'
                          label='Ext. Options Months'
                          component={TextField}
                          className={classes.TextField}
                        />
                        <Field
                          name='extensionDueDate'
                          placeholder='Ext. Due Date'
                          component={TextField}
                          label='Ext. Due Date'
                          type='date'
                          className={classes.TextField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <MaterialUITextField value={leaseExtensionExpiryDate} label='Ext Expiry Date' disabled />
                        <Field
                          name='amountPerAcre'
                          placeholder='Amount Per Acre'
                          label='Amount Per Acre'
                          component={TextField}
                          className={classes.TextField}
                        />
                        <Field
                          name='totalAmount'
                          placeholder='Total Amount'
                          label='Total Amount'
                          component={TextField}
                          className={classes.TextField}
                        />
                        <Field
                          name='rentalPerAcre'
                          placeholder='Rental Per Acre'
                          label='Rental Per Acre'
                          component={TextField}
                          className={classes.TextField}
                        />
                        <Field
                          name='rentalAmount'
                          placeholder='Rental Amount'
                          label='Rental Amount'
                          component={TextField}
                          className={classes.TextField}
                        />
                      </div>
                      <div style={{ width: 200, display: 'flex', flexDirection: 'column' }}>
                        <Typography className={classes.title} color='textSecondary' gutterBottom>
                          CDC
                        </Typography>
                        <div>
                          <Field
                            name='cdcCalc'
                            margin='normal'
                            select
                            label='CDC By'
                            component={TextField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ marginBottom: 20 }}
                          >
                            <MenuItem value={'Completion'}>Completion</MenuItem>
                            <MenuItem value={'RigRelease'}>Rig Release</MenuItem>
                          </Field>
                          <Field
                            name='cdcDeadline'
                            label='CDC Deadline'
                            placeholder='MM-DD-YYYY'
                            type='date'
                            component={TextField}
                            className={classes.TextField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <Field
                            name='cdcPeriod'
                            label='CDC Period'
                            placeholder='CDC Period'
                            component={TextField}
                            className={classes.TextField}
                          />
                          <Field
                            name='cdcDescription'
                            placeholder='CDC'
                            label='CDC Description'
                            component={TextField}
                            style={{ float: 'right' }}
                            multiline={true}
                            rows={2}
                            variant='outlined'
                            className={classes.FullWidthTextBox}
                            fullWidth
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <FormLabel style={{ display: 'block' }}>Separate Lease Language</FormLabel>
                          <Field
                            label='Separate Lease Language'
                            name='separateLeaseLanguage'
                            component={Switch}
                            color='primary'
                          />
                        </div>
                      </div>
                      <div style={{ width: 200 }}>
                        <Typography className={classes.title} color='textSecondary' gutterBottom>
                          Shut In
                        </Typography>
                        <Field
                          name='shutinTriggerDate'
                          label='Trigger Date'
                          placeholder='Trigger Date'
                          component={TextField}
                          className={classes.TextField}
                        />
                        <Field
                          name='shutinMaxConsecutive'
                          label='Max Consecutive'
                          placeholder='Max Consecutive'
                          component={TextField}
                          className={classes.TextField}
                        />
                        <Field
                          name='shutinMaxPost'
                          label='Max Post-PT'
                          placeholder='Max Post-PT'
                          component={TextField}
                          className={classes.TextField}
                        />
                        <Field
                          name='shutinDescription'
                          placeholder='Shut In'
                          label='Shut In'
                          component={TextField}
                          style={{ float: 'right' }}
                          multiline={true}
                          rows={4}
                          variant='outlined'
                          className={classes.FullWidthTextBox}
                          fullWidth
                        />
                      </div>
                      <div style={{ width: 200 }}>
                        <Typography className={classes.title} color='textSecondary' gutterBottom>
                          Vertical Pugh
                        </Typography>
                        <Field
                          name='verticalPughDate'
                          label='Vertical Expiration'
                          component={TextField}
                          className={classes.TextField}
                        />
                        <Field
                          name='verticalPughDescription'
                          placeholder='Vertical Pugh Description'
                          label='Vertical Pugh'
                          component={TextField}
                          style={{ float: 'right' }}
                          multiline={true}
                          rows={2}
                          variant='outlined'
                          className={classes.FullWidthTextBox}
                          fullWidth
                        />
                        <Typography className={classes.title} color='textSecondary' gutterBottom>
                          Horizontal Pugh
                        </Typography>
                        <Field
                          name='horizontalPughDeepDate'
                          label='Deep Expiration'
                          component={TextField}
                          className={classes.TextField}
                        />
                        <Field
                          name='horizontalPughDeepDescription'
                          placeholder='Deep Description'
                          label='Deep Description'
                          component={TextField}
                          multiline={true}
                          rows={2}
                          variant='outlined'
                          className={classes.FullWidthTextBox}
                        />
                        <Field
                          name='horizontalPughShallowDate'
                          label='Shallow Expiration'
                          component={TextField}
                          className={classes.TextField}
                        />
                        <Field
                          name='horizontalPughShallowDescription'
                          placeholder='Shallow Description'
                          label='Shallow Description'
                          component={TextField}
                          style={{ float: 'right', marginBottom: 10 }}
                          multiline={true}
                          rows={2}
                          variant='outlined'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                        />
                        <div>
                          <FormLabel style={{ display: 'block' }}>Release Required</FormLabel>
                          <Field name='pughRelease' component={Switch} color='primary' />
                        </div>
                      </div>
                    </div>
                  </Paper>
                  <br />
                  <hr />
                  <br />
                  <div style={{ display: 'flex', flexDirection: 'column', width: 1450 }}>
                    <div>
                      <FieldArray name='leasedLands'>
                        {({ push, remove, insert }) => {
                          const columns = [
                            {
                              title: 'Land ID',
                              field: 'Tract.id',
                              editable: 'onAdd',
                              render: (rowData) => <Link to={`/lands/${rowData.Tract.id}`}>{rowData.Tract.id}</Link>,
                              editComponent: ({ onChange }) => (
                                <Select
                                  options={landIDs}
                                  getOptionLabel={(option) => option.id}
                                  getOptionValue={(option) => option.id}
                                  onChange={({ id }) => onChange(id)}
                                />
                              ),
                              cellStyle: {
                                whiteSpace: 'nowrap',
                              },
                            },
                            {
                              title: 'NMA',
                              field: 'netMineralAcres',
                            },
                            {
                              title: 'NSA',
                              field: 'netSurfaceAcres',
                            },
                            {
                              title: 'Depths',
                              field: 'depths',
                            },
                            {
                              title: 'Description',
                              field: 'shortDescription',
                            },
                          ];
                          return (
                            <MaterialTable
                              columns={columns}
                              data={props.values.leasedLands.map(l => ({...l}))}
                              title={'Leased Lands'}
                              options={{
                                headerStyle: {
                                  backgroundColor: '#1976d2',
                                  color: '#FFF',
                                  textAlign: 'left',
                                },
                                cellStyle: {
                                  maxWidth: 0,
                                  overFlowX: 'auto',
                                },
                                exportButton: true,
                                addRowPosition: 'first',
                                searchFieldAlignment: 'left',
                                toolbarButtonAlignment: 'left',
                                paging: false,
                              }}
                              editable={{
                                onRowAdd: (newData) => {
                                  const dataFormattedForMutation = {
                                    netMineralAcres: !newData.netMineralAcres ? '0' : newData.netMineralAcres,
                                    netSurfaceAcres: !newData.netSurfaceAcres ? '0' : newData.netSurfaceAcres,
                                    depths: !newData.depths ? 'None' : newData.depths,
                                    shortDescription: !newData.shortDescription ? 'None' : newData.shortDescription,
                                    landID: newData['Tract.id'],
                                    leaseID: props.values.id,
                                  };

                                  return addLeasedLand(dataFormattedForMutation).then((data) => {
                                    push(data.data.addLeaseInterest.leasedLands[0]);
                                  });
                                },
                                onRowUpdate: (newData, oldData) => {
                                  const update = {
                                    relationID: newData.id,
                                    netSurfaceAcres: newData.netSurfaceAcres,
                                    netMineralAcres: newData.netMineralAcres,
                                    depths: newData.depths,
                                    shortDescription: newData.shortDescription,
                                  };
                                  return updateLeasedLand(update).then((data) => {
                                    remove(oldData.tableData.id);
                                    insert(oldData.tableData.id, newData);
                                  });
                                },
                                onRowDelete: (oldData) => {
                                  const ids = { leaseID: props.values.id, landID: oldData.Tract.id };
                                  return removeLeasedLand(ids, oldData.id).then(() => {
                                    remove(oldData.tableData.id);
                                  });
                                },
                              }}
                            />
                          );
                        }}
                      </FieldArray>
                    </div>
                    <Paper className={classes.paper}>
                      <Typography className={classes.title} color='textSecondary' gutterBottom>
                        Totals
                      </Typography>
                      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div>
                          <h4 style={{ color: nmaColor }}> NMA: {totalNMA.toFixed(4)} </h4>
                        </div>
                        <div>
                          <h4 style={{ color: nsaColor }}> NSA: {totalNSA.toFixed(4)} </h4>
                        </div>
                      </div>
                    </Paper>
                    <Paper className={classes.paper}>
                      <div>
                        <Typography className={classes.title} color='textSecondary' gutterBottom>
                          Notes
                        </Typography>
                        <Field
                          name='notes'
                          placeholder='Notes'
                          label='Notes'
                          component={TextField}
                          style={{ float: 'right' }}
                          multiline={true}
                          rows={4}
                          variant='outlined'
                          className={classes.FullWidthTextBox}
                          fullWidth
                        />
                      </div>
                    </Paper>
                  </div>
                </div>
                <div className={classes.buttons}>
                  <Button
                    variant='contained'
                    color='secondary'
                    className={classes.deleteButton}
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  >
                    Delete Lease
                  </Button>

                  <DeleteLeaseModal
                    open={modalOpen}
                    handleClose={() => setModalOpen(false)}
                    handleAgree={() => {
                      deleteLease({
                        variables: {
                          id: props.values.id,
                        },
                        update: (store, { data: { DeleteLease } }) => {
                          store.data.delete(DeleteLease.id);
                        },
                      })
                        .then(({ data }) => {
                          setNotification('Successfully deleted Lease', { variant: 'success' });
                          history.push('/Leases');
                        })
                        .catch((err) => {
                          console.error(err);
                          setNotification(err.toString, { variant: 'error' });
                        });
                    }}
                  />
                  <Button variant='contained' color='primary' type='submit' style={{ float: 'right' }}>
                    Edit Lease
                  </Button>
                </div>
              </div>
              {/*<Debug />*/}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default LeaseDetailPage;
