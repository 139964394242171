import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from "recompose";
import {
  DefaultWell,
  ADD_WELL,
  ADD_WELL_OPERATED_BY,
  GET_INACTIVE_WELLS,
  GET_ACTIVE_WELLS,
  UPDATE_WELL_DRILLED_ON,
} from '../../components/wells/WellQueries';
import { GET_TRACT_NAMES } from '../../components/tracts/TractQueries';
import LoadingView from '../../components/LoadingView';
import { Typography } from '@material-ui/core';
import WellDetailForm from './detailPage/WellDetailForm';
import { makeStyles } from '@material-ui/styles';
import Auth from '../../util/Auth';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_OPERATORS, CREATE_OPERATOR } from '../../components/operators/OperatorQueries';
import { useSnackbar } from 'notistack';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import { v4 } from 'uuid';

const AddWellStyles = makeStyles((theme) => ({
  isNotAuthorized: {
    display: Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_WELLS) ? 'none' : 'block',
  },
}));

export const UpdateWellOperatedByRelationship = (currentOperator, wellID, AddWellOperatedBy) =>
  AddWellOperatedBy({
    variables: {
      from: { id: currentOperator.id },
      to: { id: wellID },
    },
  });

const UpdateDrilledOnTracts = (UpdateWellDrilledOn, currentWell) => {
  return UpdateWellDrilledOn({
    variables: {
      wellId: currentWell.id,
      newDrills: currentWell.drilledOn.map((tract) => tract.id),
    },
  }).then(() => currentWell);
};

const AddWell = ({ history, AddWellOperatedBy, UpdateWellDrilledOn }) => {
  const classes = AddWellStyles();
  const { enqueueSnackbar } = useSnackbar();

  const TractNames = useQuery(GET_TRACT_NAMES);
  const AllOperators = useQuery(GET_ALL_OPERATORS);
  const [AddWell] = useMutation(ADD_WELL);

  if (TractNames.error || AllOperators.error) {
    console.error(TractNames.error);
    return <p>Something went wrong!</p>;
  } else if (TractNames.loading || TractNames.loading || AllOperators.loading) {
    return <LoadingView />;
  }

  const tractNames = TractNames.data.Tract || [];
  const Operators = AllOperators.data.Operator || [];

  return (
    <>
      <Typography variant='h5' className={classes.isNotAuthorized}>
        Not Authorized To Make Changes
      </Typography>
      <WellDetailForm
        Well={{ ...DefaultWell, id: v4() }}
        TractNames={tractNames}
        Operators={Operators}
        history={history}
        enqueueSnackbar={enqueueSnackbar}
        mutationFn={AddWell}
        onSuccess={(Well) => {
          return AddWell({
            variables: Well,
            update: (store, { data: { CreateWell } }) => {
              let WellsQuery = { Well: null };
              let query = Well.inactive ? GET_INACTIVE_WELLS : GET_ACTIVE_WELLS;

              try {
                WellsQuery = store.readQuery({ query });

                const data = {
                  Well: [...WellsQuery.Well, CreateWell],
                };

                store.writeQuery({ query, data });
              } catch (e) {
                console.log(`${Well.inactive ? 'Inactive' : 'Active'} Wells Query has not been run yet`);
              }
            },
          })
            .then(() => {
              if (Well.drilledOn.length > 0) {
                return UpdateDrilledOnTracts(UpdateWellDrilledOn, Well);
              } else {
                return Well;
              }
            })
            .then(() => UpdateWellOperatedByRelationship(Well.operatedBy, Well.id, AddWellOperatedBy))
            .then(() => {
              enqueueSnackbar('Successfully added Well!', { variant: 'success' });
              if (Well.inactive) {
                return history.push(`/InactiveWells/${Well.id}`);
              } else {
                return history.push(`/Wells/${Well.id}`);
              }
            });
        }}
      />
    </>
  );
};

export default compose(
  graphql(CREATE_OPERATOR, {
    name: 'CreateOperator',
    options: {
      update: (store, { data: CreateOperator }) => {
        let OperatorsQuery = { Operator: null };
        const query = CREATE_OPERATOR;

        try {
          OperatorsQuery = store.readQuery({ query: GET_ALL_OPERATORS });

          const data = [...OperatorsQuery.Operator, CreateOperator];

          store.writeQuery({ query, data });
        } catch (e) {
          console.log('Could not CREATE Operator, as the ALL_OPERATORS query has not been run yet');
          return CreateOperator;
        }
      },
    },
  }),
  graphql(ADD_WELL_OPERATED_BY, { name: 'AddWellOperatedBy' }),
  graphql(UPDATE_WELL_DRILLED_ON, { name: 'UpdateWellDrilledOn' })
)(AddWell);
