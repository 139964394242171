import React from 'react';
import Auth from '../../util/Auth';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack/build';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, MenuItem } from '@material-ui/core';
import { CREATE_OPERATOR, DefaultOperator, GET_ALL_OPERATORS } from '../../components/operators/OperatorQueries';
import AddOperatorSchema from '../../components/operators/AddOperatorSchema';
import { Prompt } from 'react-router';
import { useMutation } from '@apollo/client';
import { v4 } from 'uuid';
import queryString from 'query-string';
import { withApollo } from '@apollo/client/react/hoc';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_OPERATORS);

const OperatorDetailPageStyles = makeStyles((theme) => ({
  Switch: {
    margin: 0,
  },
  BottomBar: {
    display: hasPermissions ? 'flex' : 'none',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  SubmitButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
    float: 'left',
  },
  DeleteButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
    float: 'right',
  },
  FormControlLabel: {
    paddingRight: '5em',
  },
  Select: {
    display: 'block',
  },
  FullWidthTextBox: {
    marginBottom: 20,
    marginTop: 20,
  },
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
  },
}));

function mergeDefaultsWithQueryParams(defaultOperator, queryParameters = {}) {
  const { name } = queryParameters;
  let newOperator = { ...defaultOperator };

  if (name) {
    newOperator.name = name;
  }

  return newOperator;
}

const AddOperator = ({ history }) => {
  const classes = OperatorDetailPageStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [addOperator] = useMutation(CREATE_OPERATOR, {
    update: (store, { data: { CreateOperator } }) => {
      try {
        const operatorQuery = store.readQuery({ query: GET_ALL_OPERATORS });

        const data = { Operator: [...operatorQuery.Operator, CreateOperator] };

        store.writeQuery({ query: GET_ALL_OPERATORS, data });
      } catch (e) {
        console.error(e);
      }
    },
  });

  const onSubmit = (newOperatorFormValues) => {
    return addOperator({
      variables: newOperatorFormValues,
    });
  };

  return (
    <Formik
      initialValues={mergeDefaultsWithQueryParams(DefaultOperator, queryString.parse(window.location.search))}
      validationSchema={AddOperatorSchema}
      onSubmit={(values, actions) => {
        values.id = v4();
        actions.setSubmitting(true);
        return onSubmit(values).then(
          (value) => {
            enqueueSnackbar('Operator Successfully Created', { variant: 'success' });
            actions.setSubmitting(false);
            history.push(`/Operators/${values.id}`);
          },
          (error) => {
            enqueueSnackbar({ error }, { variant: 'error' });
            actions.setSubmitting(false);
            closeSnackbar();
            console.log(error);
          }
        );
      }}
    >
      {(props) => {
        return (
          <>
            <Prompt
              when={props.dirty && props.submitCount < 1}
              message='You have not submitted the form, are you sure you want to leave?'
            />
            <Form>
              <div className="row">
                <div className="table-responsive">
                  <table className="table">
                    <thead hidden={true}>
                    <tr>
                      <th>HIDDEN</th>
                      <th>HIDDEN</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={{ borderBottom: 'none' }}>
                      <th scope="row" style={{ borderBottom: 'none' }}>
                        <Field
                          name="name"
                          placeholder="Contact Name"
                          label="Company Name"
                          style={{ width: '60%' }}
                          component={TextField}
                        />
                      </th>
                      <td colSpan={4} style={{ borderBottom: 'none' }}>
                        <Field
                          name="type"
                          style={{ paddingRight: 90, width: '60%' }}
                          label="Type of Contact"
                          component={TextField}
                          defaultValue=""
                          select
                        >
                          <MenuItem value="">Select Type</MenuItem>
                          <MenuItem value={'Attorney'}>Attorney</MenuItem>
                          <MenuItem value={'County Gov'}>County Gov</MenuItem>
                          <MenuItem value={'Education'}>Education</MenuItem>
                          <MenuItem value={'Family Contact'}>Family Contact</MenuItem>
                          <MenuItem value={'Family Office'}>Family Office</MenuItem>
                          <MenuItem value={'Lessee'}>Lessee</MenuItem>
                          <MenuItem value={'O&G'}>O&G</MenuItem>
                          <MenuItem value={'Office/Business'}>Office/Business</MenuItem>
                          <MenuItem value={'Regulatory'}>Regulatory</MenuItem>
                          <MenuItem value={'ROW'}>ROW</MenuItem>
                          <MenuItem value={'Surface Contractor'}>Surface Contractor</MenuItem>
                        </Field>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ width: '40%', borderTop: 'none', borderBottom: 'none' }}>
                        <Field
                          name='address1'
                          placeholder='Address 1'
                          style={{ width: '100%' }}
                          label='Address 1'
                          component={TextField}
                        />
                      </td>
                      <td colSpan={2} style={{ width: '40%', borderTop: 'none', borderBottom: 'none' }}>
                        <Field
                          name='operatorNumber'
                          placeholder='Operator #'
                          style={{ width: '100%' }}
                          label='Operator #'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='city'
                          placeholder='City'
                          style={{ width: '100%' }}
                          label='City'
                          component={TextField}
                        />
                      </td>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='mesAccountNumber'
                          placeholder='MES Account #'
                          style={{ width: '100%' }}
                          label='MES Account Number'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='state'
                          placeholder='State'
                          style={{ width: '100%' }}
                          label='State'
                          component={TextField}
                        />
                      </td>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='mpiAccountNumber'
                          placeholder='MPI Account #'
                          style={{ width: '100%' }}
                          label='MPI Account Number'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='zip'
                          style={{ width: '100%' }}
                          placeholder='Zip Code'
                          label='Zip Code'
                          component={TextField}
                        />
                      </td>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='sroAccountNumber'
                          placeholder='SRO Account #'
                          style={{ width: '100%' }}
                          label='SRO Account Number'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='phone'
                          placeholder='Phone #'
                          style={{ width: '100%' }}
                          label='Phone #'
                          component={TextField}
                        />
                      </td>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='taxPayerID'
                          placeholder='Tax Payer ID-CONG'
                          style={{ width: '100%' }}
                          label='Tax Payer ID-CONG'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='companyWebsite'
                          placeholder='Company Website'
                          style={{ width: '100%' }}
                          label='Company Website'
                          component={TextField}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} style={{ borderTop: 'none', borderBottom: 'none'}}>
                        <Field
                          name='notes'
                          placeholder='Notes'
                          label='Notes'
                          style={{ width: '100%' }}
                          component={TextField}
                          multiline={true}
                          rows={4}
                          variant='outlined'
                          fullWidth
                        />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={classes.BottomBar}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.DeleteButton}
                  onClick={() => history.push('/Operators')}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.SubmitButton}
                  onClick={() => props.handleSubmit()}
                  disabled={!props.isValid}
                >
                  Submit Operator
                </Button>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default withApollo(AddOperator);
