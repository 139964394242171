import React from 'react';
import { Map, Marker, Popup, TileLayer, Polyline, Polygon, Tooltip } from 'react-leaflet';
import { makeStyles } from '@material-ui/styles';

const createMapStyles = makeStyles((theme) => ({
    Map: {
        marginTop: 20,
        marginBottom: 20,
        height: 600,
        width: '100%',
    },
    Display: {
        display: 'none',
    },
}));

const WellMapCard = (props) => {
    const {
        latitude,
        longitude,
        latitudeBottom,
        longitudeBottom,
        name,
        land,
        totalDepth,
        upperPerf,
        lowerPerf,
        perfLength,
    } = props;

    const MapStyles = createMapStyles();
    const bottomHole = [latitudeBottom, longitudeBottom];
    const surfaceHole = [latitude, longitude];
    const polyLine = [surfaceHole, bottomHole];

    if (!latitude || !longitude) {
        return null;
    }

    const display = latitude === latitudeBottom && longitude === longitudeBottom;

    const bottomHoleMarker = (
        <Marker position={bottomHole}>
            <Popup>
                <u>Bottom Hole Location</u> <br />
                True Vertical Depth: {!props.trueVerticalDepth ? '-' : props.trueVerticalDepth} <br />
                Total Depth: {!props.totalDepth ? '-' : props.totalDepth} <br />
                Upper Perf: {!props.upperPerf ? '-' : props.upperPerf} <br />
                Lower Perf: {!props.lowerPerf ? '-' : props.lowerPerf} <br />
                Gross Perf: {!props.perfLength ? '-' : props.perfLength} <br />
                Bottom Latitude: {!props.latitudeBottom ? '-' : props.latitudeBottom} <br />
                Bottom Longitude: {!props.longitudeBottom ? '-' : props.longitudeBottom} <br />
            </Popup>
        </Marker>
    );

    const polygon = [
        [
            [31.563910048839922, -103.39091777801515],
            [31.554256042991895, -103.37825775146486],
            [31.54880693954955, -103.3837938308716],
            [31.558461509266184, -103.39645385742189],
            [31.563910048839922, -103.39091777801515],
        ],
    ];

    const tractLocation = (
        <Polygon color='blue' positions={polygon}>
            <Tooltip>{land}</Tooltip>
        </Polygon>
    );

    const markerLine = <Polyline color='red' positions={polyLine} />;
    return (
        <Map center={surfaceHole} zoom={12} className={MapStyles.Map}>
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <Marker position={surfaceHole}>
                <Popup>
                    <u>Surface Hole Location</u> <br />
                    Well Name: {name} <br />
                    Land ID(s): {land} <br />
                    Latitude: {latitude} <br />
                    Longitude: {longitude}
                </Popup>
            </Marker>
            {display ? '' : bottomHoleMarker}
            {display ? '' : markerLine}
        </Map>
    );
};

export default WellMapCard;
