import React from 'react';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/styles';
import Auth from '../../util/Auth';
import { Formik, Form, FieldArray } from 'formik';
import Typography from '@material-ui/core/Typography';
import { withApollo } from '@apollo/client/react/hoc';
import Select from 'react-select';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LEASE);

const MaterialTableStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
    display: 'grid',
    gridTemplateColumns: '80% 20%',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
  },

  notes: {
    width: '100%',
    height: '100%',
  },
  fab: {
    margin: '3rem',
  },
}));

const ClauseTable = ({ queries, Lease, display = true, leaseID }) => {
  const { addClause, removeClause, editClause } = queries;

  const tableDisplay = display ? 'block' : 'none';

  const classes = MaterialTableStyles();

  const typeOptions = [
    { type: 'Consents' },
    { type: 'Drilling Obligations' },
    { type: 'Information Owed' },
    { type: 'Operations' },
    { type: 'Payment Obligation' },
    { type: 'Retained Acreage' },
    { type: 'Royalty' },
  ];

  const nameOptions = [
    { name: 'Additional Provisions of Note' },
    { name: 'CDC' },
    { name: 'Cessation of Production' },
    { name: 'Compensatory Royalty' },
    { name: 'Consent to Assign' },
    { name: 'Definition of Commencement' },
    { name: 'Definition of Completion' },
    { name: 'Division Orders' },
    { name: 'Force Majeure' },
    { name: 'Formation Pugh' },
    { name: 'Horizontal Pugh' },
    { name: 'Late Royalty' },
    { name: 'Minimum Royalty' },
    { name: 'No Deductions' },
    { name: 'Offset Well' },
    { name: 'Offsite Well Site' },
    { name: 'Paid-up or Delay Rental' },
    { name: 'Pooling' },
    { name: 'Release-OGL/Pugh' },
    { name: 'Reports' },
    { name: 'Retained Acreage' },
    { name: 'Royalty Clause' },
    { name: 'Royalty Free Gas Use' },
    { name: 'Separate Lease Language' },
    { name: 'Shut-in' },
    { name: 'Surface Damage' },
    { name: 'Vertical Pugh' },
  ];

  return (
    <>
      <Formik initialValues={Lease} onSubmit={() => {}}>
        {(props) => {
          return (
            <Form className={classes.form} style={{ display: tableDisplay }}>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                Clauses
              </Typography>
              <FieldArray name='leaseClauses'>
                {({ push, remove, insert }) => {
                  const columns = [
                    {
                      title: 'Clause Type',
                      field: 'type',
                      editComponent: ({ onChange }) => (
                        <Select
                          options={typeOptions}
                          getOptionLabel={(option) => option.type}
                          getOptionValue={(option) => option.type}
                          onChange={({ type }) => onChange(type)}
                        />
                      ),
                      cellStyle: {
                        whiteSpace: 'normal',
                        textAlign: 'center',
                        overFlow: 'auto',
                        minWidth: 100,
                      },
                    },
                    {
                      title: 'Clause Name',
                      field: 'name',
                      editComponent: ({ onChange }) => (
                        <Select
                          options={nameOptions}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          onChange={({ name }) => onChange(name)}
                        />
                      ),
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        minWidth: 100,
                      },
                    },
                    {
                      title: 'Paragraph(s)',
                      field: 'paragraph',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Text',
                      field: 'text',
                      cellStyle: {
                        textAlign: 'center',
                      },
                    },
                  ];
                  return (
                    <MaterialTable
                      columns={columns}
                      data={props?.values?.leaseClauses}
                      title={''}
                      editable={{
                        onRowAdd: (newData) => {
                          return addClause({
                            type: newData.type,
                            name: newData.name,
                            text: !newData.text ? 'N/A' : newData.text,
                            paragraph: !newData.paragraph ? 'N/A' : newData.paragraph,
                            leaseID: !Lease ? leaseID : Lease.id,
                          }).then((data) => {
                            push(data.data.addClause.leaseClauses[0]);
                          });
                        },
                        onRowUpdate: (newData, oldData) => {
                          newData.leaseID = !Lease ? leaseID : Lease.id;
                          return editClause(newData).then(() => {
                            remove(oldData.tableData.id);
                            insert(oldData.tableData.id, newData);
                          });
                        },
                        onRowDelete: (oldData) => {
                          return removeClause(oldData.id, !Lease ? leaseID : Lease.id).then(() => {
                            remove(oldData.tableData.id);
                          });
                        },
                      }}
                      options={{
                        headerStyle: {
                          backgroundColor: '#1976d2',
                          color: '#FFF',
                          textAlign: 'center',
                        },
                        cellStyle: {
                          maxWidth: 0,
                          overFlowX: 'auto',
                        },
                        exportButton: true,
                        addRowPosition: 'first',
                        searchFieldAlignment: 'left',
                        toolbarButtonAlignment: 'left',
                        paging: false,
                      }}
                    />
                  );
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default withApollo(ClauseTable);
