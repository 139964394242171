import React from 'react';
import LoadingView from '../../components/LoadingView';
import { GET_INACTIVE_WELLS } from '../../components/wells/WellQueries';
import { Typography } from '@material-ui/core';
import { Query } from '@apollo/client/react/components';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import { CSVLink } from 'react-csv';

const SortWellsByAlphabet = (WellArr = []) => {
  let sorted = [...WellArr];

  return sorted.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  });
};

const headers = [
  { label: 'Well Name', key: 'name' },
  { label: 'API #', key: 'apiNumber' },
  { label: 'Operator', key: 'operator' },
  { label: 'RRC Lease #', key: 'rrcLeaseNumber' },
  { label: 'Permit', key: 'permitNumber' },
  { label: 'land ID', key: 'landId' },
  { label: 'Abstract', key: 'abstract' },
  { label: 'Status', key: 'status' },
  { label: 'Spud Date', key: 'spudDate' },
  { label: 'Rig Release', key: 'rigReleaseDate' },
  { label: 'Date Producing', key: 'dateProducing' },
  { label: 'Date In Pay', key: 'dateInPay' },
  { label: 'OGL', key: 'OGL' },
  { label: 'Location', key: 'location' },
  { label: 'DecProdUnit', key: 'decProdUnit' },
  { label: 'DPU', key: 'dpu' },
  { label: 'Producing Formation', key: 'producingFormation' },
  { label: 'Length of Lateral', key: 'lengthOfLateral' },
  { label: 'RRC Field', key: 'rrcField' },
  { label: 'Payor', key: 'oilPayor' },
  { label: 'Operator', key: 'operator' },
  { label: 'MES DOI', key: 'mesDOI' },
  { label: 'MPI DOI', key: 'mpiDOI' },
  { label: 'SRO DOI', key: 'sroDOI' },
  { label: 'Comments', key: 'notes' },
  { label: 'RRC Lease Notes', key: 'notes' },
  { label: 'Operator History', key: 'operatorHistory' },
  { label: 'Unit', key: 'unit' },
];

const InactiveWells = () => {
  const [wellData, setWellData] = React.useState([]);

  return (
    <Query query={GET_INACTIVE_WELLS}>
      {({ data, loading, error }) => {
        if (error) {
          console.error(error);
          return <Typography variant='h5'>{error.toString()}</Typography>;
        } else if (loading) {
          return <LoadingView />;
        }

        const Wells = SortWellsByAlphabet(data.Well);

        const columns = [
          {
            title: 'Well Name',
            field: 'name',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'API #',
            field: 'apiNumber',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'Rcc Lease',
            field: 'rrcLeaseNumber',
          },
          {
            title: 'Permit',
            field: 'permitNumber',
          },
          {
            title: 'Land Id',
            render: (rowData) => rowData.drilledOn.map((e) => e.id).join(', '),
            field: 'landId',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'Abstract',
            field: 'abstract',
          },
          {
            title: 'Status',
            field: 'status',
          },
          {
            title: 'Spud Date',
            field: 'spudDate',
          },
          {
            title: 'Rig Release',
            field: 'rigReleaseDate',
          },
          {
            title: 'Date Producing',
            field: 'dateProducing',
          },
          {
            title: 'Date In Pay',
            field: 'dateInPay',
          },
          {
            title: 'OGL',
            field: 'OGL',
          },
          {
            title: 'Location',
            field: 'location',
          },
          {
            title: 'DecProdUnit',
            field: 'decProdUnit',
          },
          {
            title: 'DPU',
            field: 'dpu',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'Producing Formation',
            field: 'producingFormation',
          },
          {
            title: 'Length of Lateral',
            field: 'lengthOfLateral',
          },
          {
            title: 'rrcField',
            field: 'rrcField',
          },
          {
            title: 'Payor',
            field: 'payor',
          },
          {
            title: 'Operator',
            field: 'operator',
            cellStyle: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
          },
          {
            title: 'Family ID',
            field: 'familyID',
          },
          {
            title: 'lmslt DOI',
            field: 'mpiDOI',
          },
          {
            title: 'mpi DOI',
            field: 'mesDOI',
          },
          {
            title: 'sro DOI',
            field: 'sroDOI',
          },
          {
            title: 'Comments',
            field: 'notes',
          },
          {
            title: 'RCC Lease Notes',
            field: 'rrcLeaseHistory',
          },
          {
            title: 'Operator History',
            field: 'operatorHistory',
          },
          {
            title: 'Unit',
            field: 'unit.name',
          },
        ];

        const csvWellData = Wells.map((w) => ({
          name: w.name,
          apiNumber: w.apiNumber,
          rrcLeaseNumber: w.rrcLeaseNumber,
          permit: w.permitNumber,
          landId: !w.landId ? '' : w.landId.replace(/(?:\r\n|\r|\n)/g, ''),
          abstract: w.abstract,
          status: w.status,
          spudDate: w.spudDate,
          rigRelease: w.rigRelease,
          dateProducing: w.dateProducing,
          dateInPay: w.dateInPay,
          OGL: w.OGL,
          location: !w.location ? '' : w.location,
          decProdUnit: w.decProdUnit,
          dpu: w.dpu,
          producingFormation: w.producingFormation,
          lengthOfLateral: w.lengthOfLateral,
          rrcField: w.rrcField,
          payor: !w.payor ? '' : w.payor,
          operator: w.operator,
          familyID: w.familyID,
          mesDOI: w.mesDOI,
          mpiDOI: w.mpiDOI,
          sroDOI: w.sroDOI,
          notes: !w.notes ? '' : w.notes.replace(/(?:\r\n|\r|\n)/g, ''),
          rrcLeaseNotes: !w.rrcLeaseNotes ? '' : w.rrcLeaseNotes.replace(/(?:\r\n|\r|\n)/g, ''),
          operatorHistory: !w.operatorHistory ? '' : w.operatorHistory.replace(/(?:\r\n|\r|\n)/g, ''),
          unit: !w.unit ? '' : w.unit.name,
        }));

        return (
          <div>
            <div style={{ display: 'flex', width: 800, justifyContent: 'space-between', marginBottom: 20 }}>
              <CSVLink data={csvWellData} headers={headers}>
                <Button variant={'contained'} color={'primary'} style={{ marginRight: 20 }}>
                  DOWNLOAD CSV
                </Button>
              </CSVLink>
            </div>
            <MaterialTable
              data={wellData.length === 0 ? Wells.map(w => ({...w})) : wellData.map(w => ({...w}))}
              columns={columns}
              title={'Inactive Wells'}
              options={{
                headerStyle: {
                  backgroundColor: '#1976d2',
                  color: '#FFF',
                  textAlign: 'center',
                  justifyContent: 'center',
                  whiteSpace: 'pre',
                },
                selection: false,
                exportButton: true,
                pageSize: wellData.length === 0 ? Wells.length : wellData.length,
                searchFieldAlignment: 'left',
                toolbarButtonAlignment: 'left',
              }}
            />
          </div>
        );
      }}
    </Query>
  );
};

export default InactiveWells;
