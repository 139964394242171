import React from 'react';
import Link from 'react-router-dom/Link';
import MaterialTable from 'material-table';

export function intersperse(arr, sep) {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(
    function(xs, x, i) {
      return xs.concat([sep, x]);
    },
    [arr[0]]
  );
}

const UnitTable = ({ data }) => {
  //Capture Land IDs
  const editableUnits = data.map(u => ({...u}))
  editableUnits.forEach((unit) => {
    const larr = new Set([]);
    unit.wells.map((well) => {
      well.drilledOn.map((tract) => larr.add(tract.id));
    });
    unit.lands = [...larr].map((i) => i).join(',');
  });

  const columns = [
    {
      title: 'Unit Name',
      field: 'name',
      render: (rowData) => (
        <Link to={`/Units/${rowData.id}`} target='_blank'>
          {rowData.name}
        </Link>
      ),
    },
    {
      title: 'Type',
      field: 'unitType',
    },
    {
      title: 'Operator',
      field: 'currentOperator',
    },
    {
      title: 'Land ID',
      field: 'lands',
      render: (rowData) => {
        const splits = rowData.lands.split(',');
        const Links = splits.map((e) => (
          <Link to={`/Lands/${e}`} key={e} target='_blank'>
            {e.trim()}
          </Link>
        ));
        return intersperse(Links, ', ');
      },
    },
    {
      title: 'Status',
      field: 'status',
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={editableUnits}
      options={{
        searchFieldAlignment: 'left',
        pageSize: data.length,
        showTitle: false,
        exportButton: true,
        toolbarButtonAlignment: 'left',
        headerStyle: {
          backgroundColor: '#1976d2',
          color: '#FFF',
          textAlign: 'left',
          justifyContent: 'center',
          whiteSpace: 'pre',
        },
      }}
    />
  );
};

export default UnitTable;
