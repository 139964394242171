import React from 'react';
import Link from 'react-router-dom/Link';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import moment from 'moment';

const LeaseTable = ({ data, history }) => {

  const editableLeases = data.map(o => ({...o}))

  editableLeases.forEach((lease) => {
    const countyList = lease.leaseRecordings.map((rec) => rec.county);
    lease.counties = countyList.filter((item, index) => countyList.indexOf(item) === index).join(', ');
  });

  const columns = [
    {
      title: 'Lease',
      render: (rowData) => (
        <Link to={`/Leases/${rowData.id}`} target='_blank'>
          {rowData.leaseName}
        </Link>
      ),
      field: 'leaseName',
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'left',
      },
    },
    {
      title: 'Status',
      field: 'status',
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'left',
      },
    },
    {
      title: 'County',
      field: 'counties',
      render: (rowData) => (!rowData.counties ? 'N/A - No Recordings' : rowData.counties),
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'left',
      },
    },
    {
      title: 'Effective Date',
      field: 'effectiveDate',
      render: (rowData) => moment(rowData.effectiveDate).format('MM/DD/YYYY'),
    },
  ];

  return (
    <div>
      <MaterialTable
        data={editableLeases}
        columns={columns}
        title={''}
        options={{
          headerStyle: {
            backgroundColor: '#1976d2',
            color: '#FFF',
            textAlign: 'left',
          },
          selection: false,
          exportButton: true,
          pageSize: data.length,
          searchFieldAlignment: 'left',
          toolbarButtonAlignment: 'left',
          showTitle: false,
          actionsColumnIndex: 1,
        }}
        actions={[
          () => ({
            onClick: (event, rowData) => history.push(`/CloneLease/${rowData.id}`),
          }),
        ]}
        components={{
          Action: (props) => (
            <Button
              onClick={() => history.push(`/CloneLease/${props.data.id}`)}
              color='secondary'
              variant='outlined'
              style={{ textTransform: 'none', whiteSpace: 'nowrap' }}
              size='small'
            >
              Clone Lease
            </Button>
          ),
        }}
      />
    </div>
  );
};

export default LeaseTable;
