import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue['700'],
      light: blue['300'],
      dark: blue['900'],
    },
    secondary: {
      main: red['700'],
      light: red['300'],
      dark: red['900'],
    },
  },
  typography: {
    useNextVariants: true,
  },
});

function ThemeProvider(props) {
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
}

export default ThemeProvider;
