import React from 'react';
import MaterialTable from 'material-table';
import { TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import { FieldArray, Form, Formik } from 'formik';
import gql from 'graphql-tag';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import { ADD_UNIT_TITLE, REMOVE_UNIT_TITLE, UPDATE_UNIT_TITLE } from '../../components/units/UnitQueries';
import {FILE_QUERY} from '../../components/files/fileQueries';
import FileUploadModal from "../../components/files/FileUploadModal";
import Uploader from "../../util/Uploader";


const titleQuery = gql`
  query Unit($id: ID!) {
    Unit(id: $id) {
      id
      name
      titleRun {
        id
        volPage
        effectiveDate
        dateFiled
        comments
        grantor
        grantee
        docType
        fileName
      }
    }
  }
`;

const UnitChainOfTitle = ({ history, match, setValue}) => {
  const [loading, setLoading] = React.useState(false);
  const [addTitle] = useMutation(ADD_UNIT_TITLE);
  const [deleteTitle] = useMutation(REMOVE_UNIT_TITLE);
  const [editTitle] = useMutation(UPDATE_UNIT_TITLE);
  const [fileKey, setFileKey] = React.useState({});
  const [rowInfo, setRowInfo] = React.useState({});
  const [fileUploadOpen, setFileUploadOpen] = React.useState(false);
  const [loadFile, {data: fileData, loading: fileLoading, error: fileError}] = useLazyQuery(FILE_QUERY,
      {variables: {fileName: fileKey}});


  const { data, loading: titleLoading, error } = useQuery(titleQuery, {
    variables: { id: match.params.unitID },
  });

  if (loading || titleLoading) {
    return <LoadingView />;
  }
  if (error) {
    return error.toString();
  }

  const Unit = data.Unit[0] || [];

  const formikData= {
    finalData: Unit.titleRun.map(t => ({...t}))
  }

  return (
    <div>
      <Uploader
          itemID={Unit.id}
          updateFunction={addTitle}
          typeOfUploader={'UNIT'}
          history={history}
          setLoading={setLoading}
          setValue={setValue}
      />
      <Formik
          onSubmit={() => {}}
          initialValues={formikData}
          enableReinitialize={true}
      >
        {(props) => (
          <Form>
            <Typography
              variant='h5'
              style={{
                color: '#1976d2',
                fontSize: '1.75rem',
                textAlign: 'center',
                textDecoration: 'underline',
              }}
            >
              Unit Chain Of Title
            </Typography>
            <br />
            <hr />
            <br />
            <Typography
              variant='h5'
              style={{
                color: '#1976d2',
                fontSize: '1.75rem',
                textAlign: 'center',
                textDecoration: 'underline',
              }}
            >
              {Unit.name}
            </Typography>
            <FieldArray name='titleRun'>
              {({ push, remove, insert }) => {
                const columns = [
                  {
                    title: 'Vol/Page',
                    field: 'volPage',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Doc Type',
                    field: 'docType',
                  },
                  {
                    title: 'Date Filed',
                    field: 'dateFiled',
                    render: (rowData) => moment(rowData.dateFiled).format('MM/DD/YYYY'),
                    // Keep in case we need
                    // customSort: (a, b) => (moment(a.dateFiled).isAfter(moment(b.dateFiled)) ? 1 : -1),
                    defaultSort: 'asc',
                    editComponent: (props) => (
                      <TextField type='date' onChange={(e) => props.onChange(e.target.value)} />
                    ),
                  },
                  {
                    title: 'Effective Date',
                    field: 'effectiveDate',
                    render: (rowData) => moment(rowData.effectiveDate).format('MM/DD/YYYY'),
                    editComponent: (props) => (
                      <TextField type='date' onChange={(e) => props.onChange(e.target.value)} />
                    ),
                  },
                  {
                    title: 'Grantor',
                    field: 'grantor',
                  },
                  {
                    title: 'Grantee',
                    field: 'grantee',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Comments',
                    field: 'comments',
                  },
                ];

                return (
                  <MaterialTable
                    columns={columns}
                    data={props.values.finalData}
                    options={{
                      searchFieldAlignment: 'left',
                      showTitle: false,
                      toolbarButtonAlignment: 'left',
                      addRowPosition: 'first',
                      exportButton: true,
                      paging: false,
                      headerStyle: {
                        backgroundColor: '#1976d2',
                        color: '#FFF',
                        textAlign: 'left',
                        justifyContent: 'center',
                        whiteSpace: 'pre',
                      },
                    }}
                    editable={{
                      onRowAdd: (newData) => {
                        return addTitle({
                          variables: {
                            UnitID: Unit?.id,
                            info: { ...newData },
                          },
                        })
                          .then(({ data: { addUnitTitle } }) => {
                            push(addUnitTitle.titleRun[0]);
                          })
                          .catch((error) => console.log(error));
                      },
                      onRowUpdate: (newData, oldData) => {
                        return editTitle({
                          variables: {
                            UnitTitleID: oldData.id,
                            paymentInfo: { ...newData },
                          },
                        }).then(({ data: { updateUnitTitle } }) => {
                          remove(oldData.tableData.id);
                          insert(oldData.tableData.id, updateUnitTitle.titleRun[0]);
                        });
                      },
                      onRowDelete: (oldData) => {
                        return deleteTitle({
                          variables: {
                            UnitTitleID: oldData.id,
                          },
                        })
                          .then((data) => {
                            remove(oldData.tableData.id);
                          })
                          .catch((error) => console.log(error));
                      },
                    }}
                    actions={
                      [
                        {
                          icon: 'addFile',
                          tooltip: 'Add File',
                          onClick: (event, rowData, tableData) => {
                            const {id} = rowData;
                            const unitID = match.params.unitID;
                            const fileName = `${unitID}/${id}`;
                            setFileKey(fileName);
                            setRowInfo(rowData);
                            setFileUploadOpen(!fileUploadOpen);
                          }
                        },
                        {
                          icon: 'map',
                          tooltip: 'View File',
                          onClick: async (event, rowData) => {
                            const {id} = rowData;
                            const leaseID = match.params.leaseID;
                            const fileName = `${leaseID}/${id}`;
                            setFileKey(rowData.fileName ? rowData.fileName : fileName);
                            try {
                              const response = await loadFile();
                              const {data: {getFile}} = response;
                              const url = getFile[0].requestUrl;
                              const link = document.createElement('a');
                              link.href = url;
                              link.click();
                            } catch (e) {
                              console.log(e);
                            }
                          }
                        }
                      ]
                    }
                  />
                );
              }}
            </FieldArray>
            <FileUploadModal
                open={fileUploadOpen}
                setFileUploadOpen={setFileUploadOpen}
                fileKey={fileKey}
                rowInfo={rowInfo}
                values={props.values}
                setFieldValue={props.setValues}
                uploadType={'UNIT'}
                recordUpdate={editTitle}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UnitChainOfTitle;
