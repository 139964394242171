import gql from 'graphql-tag';

export const contactFragment = `
    id
    contactName
    position
    directLine
    cell
    email
    date
    current
`;

export const ADD_CONTACT = gql`
    mutation createNewContact(
        $operatorID: ID!
        $contactName: String
        $position: String
        $directLine: String
        $cell: String
        $email: String
        $date: String
        $current: String
    ) {
        createNewContact(
            operatorID: $operatorID
            contactName: $contactName
            position: $position
            directLine: $directLine
            cell: $cell
            email: $email
            current: $current
            date: $date
        ) {

            ${contactFragment}
        }
    }
`;

export const EDIT_CONTACT = gql`
    mutation UpdateContact ($id: ID!, $contactName: String, $position: String, $directLine: String, $cell: String, $email: String, $current: String, $date: String)
    {
        UpdateContact(id: $id, contactName: $contactName, position: $position, directLine: $directLine, cell: $cell, email: $email current: $current, date: $date)
        {
            ${contactFragment}
        }
    }
`;

export const DELETE_CONTACT = gql`
    mutation DeleteContact($id: ID!){
        DeleteContact(id: $id){
            ${contactFragment}
        }
    }
`;
