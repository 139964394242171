import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import Auth from '../../util/Auth';
import { Button, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Iframe from 'react-iframe';
import { CREATE_UNIT, DefaultUnit, GET_ALL_UNITS, UnitSchema } from '../../components/units/UnitQueries';

import { useMutation } from '@apollo/client';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import {unitStatuses} from "../../util/Statuses";

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_UNITS);

const UnitDetailPageStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  FullWidthTextBox: {
    marginBottom: 20,
    marginTop: 20,
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  gdone: {
    display: 'grid',
    gridTemplateColumns: '250px auto 200px',
    gridTemplateRows: '90% 10%',
    gap: '.5rem 2rem',
  },
  left: {
    display: 'grid',
    gridTemplateRows: '',
  },
  middle: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  right: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  deleteButton: {
    gridRow: '',
    gridColumn: '3',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
    marginTop: 20,
  },
}));

const AddUnit = ({ history }) => {
  const classes = UnitDetailPageStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [CreateUnit] = useMutation(CREATE_UNIT);

  return (
    <Formik
      initialValues={DefaultUnit}
      validationSchema={UnitSchema}
      onSubmit={(values, actions) => {
        // Add unique id to unit
        return CreateUnit({
          variables: values,
          update: (store, { data: { CreateUnit } }) => {
            try {
              const AllUnits = store.readQuery({ query: GET_ALL_UNITS });

              const data = { Unit: [...AllUnits.Unit, CreateUnit] };

              store.writeQuery({ query: GET_ALL_UNITS, data: data });
            } catch (e) {
              console.log('Could not add lease to all Units query due to it not being run yet');
            }
          },
        })
          .then((data) => {
            enqueueSnackbar('Unit successfully Added', { variant: 'success' });
            history.push(`/Units/${values.id}`);
            actions.setSubmitting(false);
          })
          .catch((err) => {
            enqueueSnackbar(err.toString(), { variant: 'error' });
            actions.setSubmitting(false);
          });
      }}
    >
      {(props) => {
        return (
          <Form>
            <Typography className={classes.title} color='textSecondary' gutterBottom>
              Add Unit
            </Typography>
            <br />
            <hr style={{ width: '80%' }} />
            <br />
            <div style={{ display: 'flex' }}>
              <Field
                name='name'
                placeholder='Unit Name'
                label='Unit Name'
                component={TextField}
                className={classes.TextField}
              />
              <Field
                name='status'
                margin='normal'
                select
                label='Status'
                component={TextField}
                className={classes.TextField}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginBottom: 20 }}
              >
                {
                  Object.keys(unitStatuses).map((key) => {
                    return <MenuItem key={key} value={key}>{unitStatuses[key]}</MenuItem>
                  })
                }
              </Field>
              <Field
                name='currentOperator'
                placeholder='Operator'
                label='Operator'
                component={TextField}
                className={classes.TextField}
              />
              <Field
                name='unitCreatedBy'
                placeholder='Unit Created By'
                label='Unit Created By'
                component={TextField}
                className={classes.TextField}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <Field
                name='rrcNumber'
                placeholder='RRC Number'
                label='RRC Number'
                component={TextField}
                className={classes.TextField}
              />
              <Field
                name='unitGMA'
                placeholder='Unit GMA'
                label='Unit GMA'
                component={TextField}
                className={classes.TextField}
              />
              <Field
                name='familyNMA'
                placeholder='Family NMA'
                label='Family NMA'
                component={TextField}
                className={classes.TextField}
              />
              <Field
                name='leaseRoyaltyRate'
                placeholder='Royalty Rate'
                label='Royalty Rate'
                component={TextField}
                className={classes.TextField}
              />
              <Field
                name='depths'
                placeholder='Depths Held'
                label='Depths Held'
                component={TextField}
                className={classes.TextField}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <Field
                name='effectiveDate'
                placeholder='Effective Date'
                type='date'
                label='Effective Date'
                component={TextField}
                className={classes.TextField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Field
                name='recordingData'
                placeholder='Recording Data'
                label='Recording Data'
                component={TextField}
                className={classes.TextField}
              />
              <Field
                name='ratifyDate'
                placeholder='Ratify Date'
                type='date'
                label='Ratify Date'
                component={TextField}
                className={classes.TextField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Field
                name='ratifyData'
                placeholder='Ratify Data'
                label='Ratify Data'
                component={TextField}
                className={classes.TextField}
              />
              <FormControlLabel
                control={<Field name='original' component={Switch} color='primary' />}
                label='Signed Original'
                labelPlacement='top'
              />
            </div>
            <Paper className={classes.paper}>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                DOI
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Field
                  name='mesDOI'
                  placeholder='MES DOI'
                  label='MES DOI'
                  component={TextField}
                  className={classes.TextField}
                />
                <Field
                  name='mpiDOI'
                  placeholder='MPI DOI'
                  label='MPI DOI'
                  component={TextField}
                  className={classes.TextField}
                />
                <Field
                  name='sroDOI'
                  placeholder='SRO DOI'
                  label='SRO DOI'
                  component={TextField}
                  className={classes.TextField}
                />
              </div>
            </Paper>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Field
                name='propertyDesc'
                placeholder='Unit Land Description'
                label='Unit Description'
                component={TextField}
                multiline={true}
                rows={4}
                variant='outlined'
                className={classes.TextBox}
                fullWidth
              />
              <Field
                name='operatorHistory'
                placeholder='Notes'
                label='Operator History Notes'
                component={TextField}
                style={{ float: 'right' }}
                multiline={true}
                rows={4}
                variant='outlined'
                className={classes.TextBox}
                fullWidth
              />
            </div>
            <br />
            <Field
              name='mapUrl'
              placeholder='Map Url'
              label='Map Url'
              component={TextField}
              className={classes.TextField}
              fullWidth
            />
            <div style={{ display: hasPermissions ? 'block' : 'none' }}>
              <Button
                variant='contained'
                color='secondary'
                className={classes.deleteButton}
                onClick={() => {
                  history.push('/Units');
                }}
              >
                Cancel
              </Button>

              <Button variant='contained' color='primary' type='submit' style={{ float: 'right' }}>
                Add Unit
              </Button>
            </div>
            <div style={{ marginTop: 30 }}>
              <Iframe
                url={props.values.mapUrl}
                width='100%'
                height='100%'
                style={{ display: !props.values.mapUrl ? 'none' : 'block' }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddUnit;
