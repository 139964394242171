import React from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/styles';
import Auth from '../../util/Auth';
import { TextField, Switch } from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';
import Fade from '@material-ui/core/Fade';
import math from 'mathjs';
import Button from '@material-ui/core/Button';
import { TextField as MaterialUITextField } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { v4 } from 'uuid';
import LoadingView from '../../components/LoadingView';
import Select from 'react-select';
import {
  ADD_TRACT_MINERAL_OWNERSHIP,
  ADD_TRACT_SURFACE_OWNERSHIP,
  REMOVE_TRACT_MINERAL_OWNERSHIP,
  REMOVE_TRACT_SURFACE_OWNERSHIP,
} from '../../components/tracts/TractQueries';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const OWNERS_QUERY = gql`
  query {
    Owner {
      id
      name
    }
  }
`;

const TRACT_OWNERSHIP_FRAGMENT = gql`
  fragment TractOwnershipFragment on Tract {
    id
    mineralsOwned {
      id
      netMineralAcres
      mineralFraction
      mineralPercentage
      volPage
      familyMember
      propOwnership
      Owner {
        id
        name
      }
    }
    surfaceOwned {
      id
      netSurfaceAcres
      surfaceFraction
      surfacePercentage
      Owner {
        id
        name
      }
    }
    npri {
      id
      mineralFraction
      mineralPercentage
      Owner {
        id
        name
      }
    }
  }
`;

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LANDS);

const EditFormStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginTop: '1rem',
    marginBottom: '1rem',
    width: 'auto',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  gdone: {
    display: 'grid',
    gridTemplateColumns: '250px auto 200px',
    gridTemplateRows: '90% 10%',
    gap: '.5rem 2rem',
  },
  left: {
    display: 'grid',
    gridTemplateRows: '',
  },
  middle: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  right: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  deleteButton: {
    gridRow: '',
    gridColumn: '3',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
  },
}));

const getInfoFromQuery = (queryResult) => {
  if (!Array.isArray(queryResult) || queryResult.length === 0) {
    return false;
  }
  return queryResult;
};

const LandOwnershipInterest = ({
  Tract,
  EditTract,
  setNotification,
  submitValues,
  updateMineralOwnership,
  updateSurfaceOwnership,
}) => {
  const classes = EditFormStyles();
  const [minFracPercent, setMinFracPercent] = React.useState(math.number(math.fraction(Tract.surfaceFraction)));
  const [surfaceFracPercent, setSurfaceFracPercent] = React.useState(math.number(math.fraction(Tract.surfaceFraction)));
  const [nma, setNMA] = React.useState(Tract.familyNMA);
  const [nsa, setNSA] = React.useState(Tract.familyNSA);

  const { data, loading, error } = useQuery(OWNERS_QUERY);
  const [addMinerals] = useMutation(ADD_TRACT_MINERAL_OWNERSHIP);
  const [removeMinerals] = useMutation(REMOVE_TRACT_MINERAL_OWNERSHIP);
  const [addSurface] = useMutation(ADD_TRACT_SURFACE_OWNERSHIP);
  const [removeSurface] = useMutation(REMOVE_TRACT_SURFACE_OWNERSHIP);

  if (loading) {
    return <LoadingView />;
  }

  if (error) {
    return error.toString();
  }

  const owners = getInfoFromQuery(data.Owner) || [];

  return (
    <Formik
      initialValues={Tract}
      onSubmit={(values, actions) => {
        //Setting ownership fields
        //check values on update

        const parsedValues = submitValues(values);

        actions.setSubmitting(true);

        if (
          math.number(Tract.grossMineralAcres) !== math.number(values.grossMineralAcres) ||
          Tract.mineralFraction !== values.mineralFraction
        ) {
          const overGross = math.number(values.grossMineralAcres);
          const overDecimal = math.number(math.fraction(values.mineralFraction));
          const overNet = math.multiply(overGross, overDecimal);
          Promise.all(
            values.mineralsOwned.map((m, i) => {
              const mineralFraction = math.fraction(m.mineralFraction);
              const mineralPercentage = math.number(mineralFraction);
              const netMineralAcres = math.multiply(mineralPercentage, overNet);

              const update = {
                relationshipID: m.id,
                mineralFraction: m.mineralFraction,
                mineralPercentage,
                netMineralAcres,
              };

              actions.setFieldValue(`mineralsOwned[${i}]`, { ...update, id: m.id, Owner: { name: m.Owner.name } });

              return updateMineralOwnership(update)
                .then(({ data: { updateMineralOwnership } }) => {})
                .catch((error) => console.log(error));
            })
          ).catch((error) => console.log(error));
        }

        if (
          math.number(Tract.grossSurfaceAcres) !== math.number(values.grossSurfaceAcres) ||
          Tract.surfaceFraction !== values.surfaceFraction
        ) {
          const overGross = math.number(values.grossSurfaceAcres);
          const overDecimal = math.number(math.fraction(values.surfaceFraction));
          const overNet = math.multiply(overGross, overDecimal);
          Promise.all(
            values.surfaceOwned.map((m, i) => {
              const surfaceFraction = math.fraction(m.surfaceFraction);
              const surfacePercentage = math.number(surfaceFraction);
              const netSurfaceAcres = math.multiply(surfacePercentage, overNet);

              const update = {
                relationshipID: m.id,
                surfaceFraction: m.surfaceFraction,
                surfacePercentage,
                netSurfaceAcres,
              };

              actions.setFieldValue(`surfaceOwned[${i}]`, { ...update, id: m.id, Owner: { name: m.Owner.name } });

              return updateSurfaceOwnership(update)
                .then(({ data: { updateSurfaceOwnership } }) => {})
                .catch((error) => console.log(error));
            })
          ).catch((error) => console.log(error));
        }

        if (values !== Tract) {
          return EditTract({
            variables: parsedValues,
          })
            .then((data) => {
              setNotification('Successfully updated ownership!', { variant: 'success' });
              actions.setSubmitting(false);
            })
            .catch((error) => {
              console.error(error);
              setNotification(error.toString(), { variant: 'error' });
              actions.setSubmitting(false);
            });
        }
      }}
    >
      {(props) => {
        setMinFracPercent(math.number(math.fraction(Tract.mineralFraction)));
        setSurfaceFracPercent(math.number(math.fraction(Tract.surfaceFraction)));
        setNMA(math.multiply(minFracPercent, math.number(Tract.grossMineralAcres)).toFixed(8));
        setNSA(math.multiply(surfaceFracPercent, math.number(Tract.grossSurfaceAcres)).toFixed(8));
        const finalMinPercent = (minFracPercent * 100).toFixed(8);
        const finalSurfacePercent = (surfaceFracPercent * 100).toFixed(8);

        return (
          <Form>
            <div>
              {props.values.mineralClassified ? (
                <Typography
                  style={{
                    color: '#1976d2',
                    fontSize: '1rem',
                    textAlign: 'center',
                    textDecoration: 'underline',
                  }}
                  color='textSecondary'
                  gutterBottom
                >
                  Mineral Classified - Family owns surface, State owns minerals. Family is agent for State, Family &
                  State share 50/50 all lease/well/surface payments.
                </Typography>
              ) : (
                ''
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  control={<Field name='mineralOwnership' component={Switch} color='primary' />}
                  label='Minerals'
                  labelPlacement='top'
                />
                <Field
                  name='grossMineralAcres'
                  placeholder='GMA'
                  component={TextField}
                  label='GMA'
                  className={classes.TextField}
                  style={{ display: props.values.mineralOwnership ? 'block' : 'none' }}
                />
                <Field
                  name='mineralFraction'
                  placeholder='Mineral Fraction'
                  label='Mineral Fraction'
                  component={TextField}
                  className={classes.TextField}
                  style={{ display: props.values.mineralOwnership ? 'block' : 'none' }}
                />
                <MaterialUITextField
                  value={finalMinPercent}
                  label='MIN%'
                  disabled
                  style={{ display: props.values.mineralOwnership ? 'block' : 'none' }}
                />
                <MaterialUITextField
                  value={nma}
                  label='NMA'
                  disabled
                  style={{ display: props.values.mineralOwnership ? 'block' : 'none' }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  control={<Field name='surfaceOwnership' component={Switch} color='primary' />}
                  label='Surface'
                  labelPlacement='top'
                />
                <Field
                  name='grossSurfaceAcres'
                  placeholder='GSA'
                  component={TextField}
                  label='GSA'
                  className={classes.TextField}
                  style={{ display: props.values.surfaceOwnership ? 'block' : 'none' }}
                />
                <Field
                  name='surfaceFraction'
                  placeholder='Surface Fraction'
                  label='Surface Fraction'
                  component={TextField}
                  className={classes.TextField}
                  style={{ display: props.values.surfaceOwnership ? 'block' : 'none' }}
                />
                <MaterialUITextField
                  value={finalSurfacePercent}
                  label='SRF%'
                  disabled
                  style={{ display: props.values.surfaceOwnership ? 'block' : 'none' }}
                />
                <MaterialUITextField
                  value={nsa}
                  label='NSA'
                  disabled
                  style={{ display: props.values.surfaceOwnership ? 'block' : 'none' }}
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Field name='overRidingRoyaltyInterest' component={Switch} color='primary' />}
                  label='ORRI'
                  labelPlacement='top'
                />
                <Field
                  name='orriPercentage'
                  placeholder='ORRI decimal'
                  component={TextField}
                  label='ORRI decimal'
                  className={classes.TextField}
                  style={{ display: props.values.overRidingRoyaltyInterest ? 'block' : 'none' }}
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Field name='npriInterest' component={Switch} color='primary' />}
                  label='NPRI'
                  labelPlacement='top'
                />
                <Field
                  name='npriPercentage'
                  placeholder='NRPI decimal'
                  component={TextField}
                  label='NPRI decimal'
                  className={classes.TextField}
                  style={{ display: props.values.npriInterest ? 'block' : 'none' }}
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Field name='waterOwnership' component={Switch} color='primary' />}
                  label='Water'
                  labelPlacement='top'
                />
                <Field
                  name='waterAcres'
                  placeholder='Water Acres'
                  component={TextField}
                  label='Water Acres'
                  className={classes.TextField}
                  style={{ display: props.values.waterOwnership ? 'block' : 'none' }}
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Field name='caliche' component={Switch} color='primary' />}
                  label='Caliche'
                  labelPlacement='top'
                />
                <Field
                  name='calicheAcres'
                  placeholder='Caliche Acres'
                  component={TextField}
                  label='Caliche Acres'
                  type='number'
                  className={classes.TextField}
                  style={{ display: props.values.caliche ? 'block' : 'none' }}
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Field name='otherMinerals' component={Switch} color='primary' />}
                  label='Other Minerals'
                  labelPlacement='top'
                />
                <Field
                  name='otherMineralAcres'
                  placeholder='Other Mineral Acres'
                  component={TextField}
                  label='Other Minerals Acres'
                  className={classes.TextField}
                  style={{ display: props.values.otherMinerals ? 'block' : 'none' }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <Fade in={props.values.mineralOwnership}>
                  <Paper
                    className={classes.paper}
                    style={{ display: props.values.mineralOwnership ? 'block' : 'none' }}
                  >
                    <Typography className={classes.title} color='textSecondary' gutterBottom>
                      Mineral Ownership
                    </Typography>
                    <div>
                      <FieldArray name='mineralsOwned'>
                        {({ remove, insert, push }) => {
                          const mineralMath = (minFraction = 0) => {
                            minFraction = math.fraction(minFraction);
                            const percentage = math.number(minFraction);

                            return {
                              mineralPercentage: percentage,
                              netMineralAcres: math.multiply(percentage, Tract.familyNMA),
                            };
                          };

                          const columns = [
                            {
                              title: 'Owner',
                              field: 'Owner.name',
                              defaultSort: 'asc',
                              editComponent: ({ onChange }) => (
                                <Select
                                  options={owners}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  onChange={({ id, name }) => onChange({ id, name })}
                                />
                              ),
                              editable: 'onAdd',
                            },
                            {
                              title: 'Mineral Percentage',
                              field: 'mineralPercentage',
                              render: (rowData) => (!rowData ? '0' : rowData.mineralPercentage.toFixed(8)),
                              editable: 'never',
                            },
                            {
                              title: 'NMA',
                              field: 'netMineralAcres',
                              render: (rowData) => (!rowData ? '0' : rowData.netMineralAcres.toFixed(8)),
                              editable: 'never',
                            },
                            {
                              title: 'Mineral Fraction',
                              field: 'mineralFraction',
                            },
                          ];

                          return (
                            <MaterialTable
                              columns={columns}
                              data={props.values.mineralsOwned.map(o => ({...o}))}
                              options={{
                                headerStyle: {
                                  backgroundColor: '#1976d2',
                                  color: '#FFF',
                                  textAlign: 'left',
                                  width: 'auto',
                                },
                                cellStyle: {
                                  maxWidth: 0,
                                  overFlowX: 'auto',
                                },
                                exportButton: false,
                                addRowPosition: 'first',
                                searchFieldAlignment: 'left',
                                toolbarButtonAlignment: 'left',
                                pageSize: 3,
                                sorting: true,
                                showTitle: false,
                                search: false,
                                paging: false,
                              }}
                              editable={{
                                onRowAdd: (newData) => {
                                  const { netMineralAcres, mineralPercentage } = mineralMath(newData.mineralFraction);
                                  return addMinerals({
                                    variables: {
                                      from: { id: newData['Owner.name'].id },
                                      to: { id: Tract.id },
                                      data: {
                                        id: v4(),
                                        mineralPercentage,
                                        netMineralAcres,
                                        mineralFraction: newData.mineralFraction,
                                      },
                                    },
                                  })
                                    .then(({ data: { AddOwnerMinerals } }) => {
                                      push({
                                        Owner: AddOwnerMinerals.from,
                                        mineralPercentage: AddOwnerMinerals.mineralPercentage,
                                        netMineralAcres: AddOwnerMinerals.netMineralAcres,
                                        mineralFraction: AddOwnerMinerals.mineralFraction,
                                        id: AddOwnerMinerals.id,
                                      });
                                    })
                                    .catch((error) => console.log(error));
                                },
                                onRowUpdate: (newData, oldData) => {
                                  //Break down input with Mathjs
                                  const mineralFraction = math.fraction(newData.mineralFraction);
                                  const mineralPercentage = math.number(mineralFraction);
                                  const netMineralAcres = math.multiply(mineralPercentage, Tract.familyNMA);
                                  console.log(mineralFraction, mineralPercentage, netMineralAcres);
                                  //create update object
                                  const ownerUpdate = {
                                    relationshipID: oldData.id,
                                    mineralFraction: newData.mineralFraction.trim(),
                                    netMineralAcres: netMineralAcres,
                                    mineralPercentage: mineralPercentage,
                                    volPage: newData.volPage,
                                    familyMember: newData.familyMember,
                                    propOwnership: newData.propOwnership,
                                  };
                                  return updateMineralOwnership(ownerUpdate).then(
                                    ({ data: { updateMineralOwnership } }) => {
                                      remove(oldData.tableData.id);
                                      insert(oldData.tableData.id, {
                                        ...updateMineralOwnership,
                                        Owner: { name: oldData.Owner.name },
                                      });
                                    }
                                  );
                                },
                                onRowDelete: (oldData) => {
                                  return removeMinerals({
                                    variables: {
                                      from: { id: oldData.Owner.id },
                                      to: { id: Tract.id },
                                    },
                                    update: (store, { data: { RemoveOwnerMinerals } }) => {
                                      try {
                                        const oldMinerals = store.readFragment({
                                          id: Tract.id,
                                          fragment: TRACT_OWNERSHIP_FRAGMENT,
                                        });

                                        const updatedMinerals = oldMinerals.mineralsOwned.filter(
                                          (minerals) => minerals.id !== oldData.id
                                        );

                                        store.writeFragment({
                                          id: Tract.id,
                                          fragment: TRACT_OWNERSHIP_FRAGMENT,
                                          data: {
                                            ...oldMinerals,
                                            mineralsOwned: updatedMinerals,
                                          },
                                        });
                                      } catch (e) {
                                        console.error(e);
                                      }
                                    },
                                  })
                                    .then((data) => remove(oldData.tableData.id))
                                    .catch((error) => console.log(error));
                                },
                              }}
                            />
                          );
                        }}
                      </FieldArray>
                    </div>
                  </Paper>
                </Fade>
              </div>
              <div>
                <Fade in={props.values.surfaceOwnership}>
                  <Paper
                    className={classes.paper}
                    style={{ display: props.values.surfaceOwnership ? 'block' : 'none' }}
                  >
                    <Typography className={classes.title} color='textSecondary' gutterBottom>
                      Surface Ownership
                    </Typography>
                    <div>
                      <FieldArray name='surfaceOwned'>
                        {({ remove, insert, push }) => {
                          const surfaceMath = (surfaceFraction = 0) => {
                            surfaceFraction = math.fraction(surfaceFraction);
                            const percentage = math.number(surfaceFraction);

                            return {
                              surfacePercentage: percentage,
                              netSurfaceAcres: math.multiply(percentage, Tract.familyNSA),
                            };
                          };

                          const columns = [
                            {
                              title: 'Owner',
                              field: 'Owner.name',
                              defaultSort: 'asc',
                              editComponent: ({ onChange }) => (
                                <Select
                                  options={owners}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  onChange={({ id, name }) => onChange({ id, name })}
                                />
                              ),
                              editable: 'onAdd',
                            },
                            {
                              title: 'Surface Percentage',
                              field: 'surfacePercentage',
                              render: (rowData) => (!rowData ? '0' : rowData.surfacePercentage.toFixed(8)),
                              editable: 'never',
                            },
                            {
                              title: 'NSA',
                              field: 'netSurfaceAcres',
                              render: (rowData) => (!rowData ? '0' : rowData.netSurfaceAcres.toFixed(8)),
                              editable: 'never',
                            },
                            {
                              title: 'Surface Fraction',
                              field: 'surfaceFraction',
                            },
                          ];

                          return (
                            <MaterialTable
                              columns={columns}
                              data={props.values.surfaceOwned.map(o => ({...o}))}
                              options={{
                                headerStyle: {
                                  backgroundColor: '#1976d2',
                                  color: '#FFF',
                                  textAlign: 'left',
                                  width: 'auto',
                                },
                                cellStyle: {
                                  maxWidth: 0,
                                  overFlowX: 'auto',
                                },
                                exportButton: false,
                                addRowPosition: 'first',
                                searchFieldAlignment: 'left',
                                toolbarButtonAlignment: 'left',
                                pageSize: 3,
                                sorting: true,
                                showTitle: false,
                                search: false,
                                paging: false,
                              }}
                              editable={{
                                onRowAdd: (newData) => {
                                  const { netSurfaceAcres, surfacePercentage } = surfaceMath(newData.surfaceFraction);
                                  return addSurface({
                                    variables: {
                                      from: { id: newData['Owner.name'].id },
                                      to: { id: Tract.id },
                                      data: {
                                        id: v4(),
                                        surfacePercentage,
                                        netSurfaceAcres,
                                        surfaceFraction: newData.surfaceFraction,
                                      },
                                    },
                                  })
                                    .then(({ data: { AddOwnerSurface } }) => {
                                      push({
                                        Owner: AddOwnerSurface.from,
                                        surfacePercentage: AddOwnerSurface.surfacePercentage,
                                        netSurfaceAcres: AddOwnerSurface.netSurfaceAcres,
                                        surfaceFraction: AddOwnerSurface.surfaceFraction,
                                        id: AddOwnerSurface.id,
                                      });
                                    })
                                    .catch((error) => console.log(error));
                                },
                                onRowUpdate: (newData, oldData) => {
                                  //Break down input with Mathjs
                                  const surfaceFraction = math.fraction(newData.surfaceFraction.trim());
                                  const surfacePercentage = math.number(surfaceFraction);
                                  const netSurfaceAcres = math.multiply(surfacePercentage, Tract.familyNSA);
                                  //create update object
                                  const ownerUpdate = {
                                    relationshipID: oldData.id,
                                    surfaceFraction: newData.surfaceFraction.trim(),
                                    netSurfaceAcres: netSurfaceAcres,
                                    surfacePercentage: surfacePercentage,
                                  };
                                  return updateSurfaceOwnership(ownerUpdate).then(
                                    ({ data: { updateSurfaceOwnership } }) => {
                                      remove(oldData.tableData.id);
                                      insert(oldData.tableData.id, {
                                        ...updateSurfaceOwnership,
                                        Owner: { name: oldData.Owner.name },
                                      });
                                    }
                                  );
                                },
                                onRowDelete: (oldData) => {
                                  return removeSurface({
                                    variables: {
                                      from: { id: oldData.Owner.id },
                                      to: { id: Tract.id },
                                    },
                                    update: (store, { data: { RemoveOwnerSurface } }) => {
                                      try {
                                        const oldSurface = store.readFragment({
                                          id: Tract.id,
                                          fragment: TRACT_OWNERSHIP_FRAGMENT,
                                        });

                                        const updatedSurface = oldSurface.surfaceOwned.filter(
                                          (surface) => surface.id !== oldData.id
                                        );

                                        store.writeFragment({
                                          id: Tract.id,
                                          fragment: TRACT_OWNERSHIP_FRAGMENT,
                                          data: {
                                            ...oldSurface,
                                            surfaceOwned: updatedSurface,
                                          },
                                        });
                                      } catch (e) {
                                        console.error(e);
                                      }
                                    },
                                  })
                                    .then((data) => remove(oldData.tableData.id))
                                    .catch((error) => console.log(error));
                                },
                              }}
                            />
                          );
                        }}
                      </FieldArray>
                    </div>
                  </Paper>
                </Fade>
              </div>
            </div>
            <div>
              <Field
                name='ownershipNotes'
                placeholder='Ownership Notes'
                component={TextField}
                label='Ownership Notes'
                className={classes.TextArea}
                fullWidth
                multiline
                variant='outlined'
                rows={4}
                style={{ marginBottom: 50 }}
              />
            </div>
            <div>
              <Button
                color='primary'
                variant='contained'
                disabled={!hasPermissions || !props.dirty}
                type='submit'
                fullWidth
              >
                Update Ownership
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LandOwnershipInterest;
