import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Paper,
  Divider,
  InputBase,
} from '@material-ui/core';
import { ApolloConsumer } from '@apollo/client';
import { GET_DI_WELL } from '../../components/wells/WellQueries';
import { makeStyles } from '@material-ui/styles';
import DIWellDiff from './DIWellDiff';

const DIWellModalStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 4px',
    flexDirection: 'row',
    marginBottom: 15,
    overflowY: 'none',
  },
  input: {
    marginLeft: 8,
    marginBottom: 0,
    flex: 4,
  },
  searchIcon: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    marginRight: 10,
    marginLeft: 10,
  },
}));

/**
 * Use handleAgree and handleClose to submit/close the Modal
 */
function DIWellModal({ CurrentWell, open, handleClose, handleAgree, deps, history }) {
  const [api, setAPINumber] = useState(CurrentWell.apiNumber || '');
  const [diWell, setDIWell] = useState({});
  const [diffedWell, setDiffedWell] = useState({});

  const { Operators } = deps;

  const classes = DIWellModalStyles();

  const differentProperties = getDiffProperties(CurrentWell, diWell);
  const differencePropertyNames = Object.getOwnPropertyNames(differentProperties).filter((key) => key !== 'operatedBy');

  return (
    <ApolloConsumer>
      {(client) => (
        <Dialog open={open} maxWidth='lg' fullWidth>
          <DialogTitle>Pull Info From Drilling Info?</DialogTitle>
          <DialogContent>
            <Typography variant='body1'>Warning: This will over write the data currently on the form.</Typography>

            <Paper className={classes.root}>
              <InputBase
                value={api}
                onChange={(e) => setAPINumber(e.target.value)}
                label='API #'
                name='API_Number'
                placeholder='Search Drilling Info By API #'
                className={classes.input}
              />

              <Divider className={classes.divider} />

              <Button
                variant='contained'
                onClick={async () => {
                  const {
                    data: { getWell },
                  } = await client.query({
                    query: GET_DI_WELL,
                    variables: { api: api.replace(/[\-]/g, '') },
                  });

                  setDIWell(getWell);
                  setDiffedWell(getWell);
                }}
              >
                Search Drilling Info...
              </Button>
            </Paper>

            {JSON.stringify(diWell) === JSON.stringify({}) ? null : (
              <DIWellDiff
                CurrentWell={CurrentWell}
                diWell={diWell}
                diffedWell={diffedWell}
                setDIWell={setDIWell}
                setDiffedWell={setDiffedWell}
                differencePropertyNames={differencePropertyNames}
                deps={{ Operators }}
                history={history}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setAPINumber('');
                setDIWell({});
                setDiffedWell({});
                handleClose();
              }}
              color='secondary'
            >
              Close
            </Button>
            <Button
              name='di_api_pull'
              color='primary'
              onClick={() => {
                handleAgree(diffedWell);
                setDIWell({});
                setDiffedWell({});
                setAPINumber('');
                handleClose();
              }}
              disabled={JSON.stringify(diWell) === JSON.stringify({})}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ApolloConsumer>
  );
}

export const getDiffProperties = (ofObj, fromObj) => {
  if (!ofObj || !fromObj) return {};

  const fromPropertyNames = Object.getOwnPropertyNames(fromObj);

  const objWithDifferences = fromPropertyNames.reduce((diffObj, currProperty) => {
    if (
      ofObj[currProperty] !== fromObj[currProperty] &&
      fromObj[currProperty] !== null &&
      currProperty !== '__typename'
    ) {
      diffObj[currProperty] = fromObj[currProperty];
    }

    return diffObj;
  }, {});

  return objWithDifferences;
};

export default DIWellModal;
