import gql from 'graphql-tag';

export const DefaultOperator = {
  id: '',
  name: '',
  alias: 'N/A',
  address1: 'N/A',
  address2: 'N/A',
  city: 'N/A',
  state: 'N/A',
  zip: 'N/A',
  phone: 'N/A',
  notes: 'N/A',
  operatorNumber: 'N/A',
  companyWebsite: 'N/A',
  contacts: {
    id: 'Contact ID',
    name: 'Default Contact',
    position: 'Default Position',
    directLine: 'Default Direct Line',
    cell: 'Default Cell',
    email: 'Default Email',
  },
  mesAccountNumber: '',
  mpiAccountNumber: '',
  sroAccountNumber: '',
};

export const GET_ALL_OPERATORS = gql`
  query {
    Operator {
      id
      name
      alias
      operatorNumber
      companyWebsite
      type
      wellCount
    }
  }
`;

export const OPERATOR_TABLE_QUERY = gql`
  query {
    Operator {
      id
      name
      alias
      type
      operatorNumber
      wellCount
      companyWebsite
    }
  }
`;

export const GET_OPERATOR = gql`
  query Operator($id: ID!) {
    Operator(id: $id) {
      id
      name
      alias
      address1
      address2
      city
      state
      zip
      phone
      notes
      operatorNumber
      companyWebsite
      type
      contacts {
        id
        contactName
        position
        directLine
        cell
        email
        current
        date
      }
      mesAccountNumber
      mpiAccountNumber
      sroAccountNumber
      taxPayerID
      swdCount
      wellCount
    }
  }
`;

export const DELETE_OPERATOR = gql`
  mutation DeleteOperator($id: ID!) {
    DeleteOperator(id: $id) {
      id
    }
  }
`;

export const EDIT_OPERATOR = gql`
  mutation UpdateOperator(
    $id: ID!
    $name: String
    $alias: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $phone: String
    $operatorNumber: String
    $notes: String
    $companyWebsite: String
    $type: String
    $mesAccountNumber: String
    $mpiAccountNumber: String
    $sroAccountNumber: String
    $taxPayerID: String
  ) {
    UpdateOperator(
      id: $id
      name: $name
      alias: $alias
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      operatorNumber: $operatorNumber
      notes: $notes
      companyWebsite: $companyWebsite
      type: $type
      mesAccountNumber: $mesAccountNumber
      mpiAccountNumber: $mpiAccountNumber
      sroAccountNumber: $sroAccountNumber
      taxPayerID: $taxPayerID
    ) {
      id
      name
      alias
      address1
      address2
      city
      state
      zip
      phone
      operatorNumber
      notes
      companyWebsite
      contacts {
        contactName
        position
        directLine
        cell
        email
      }
      mesAccountNumber
      mpiAccountNumber
      sroAccountNumber
      swdCount
      wellCount
    }
  }
`;

export const CREATE_OPERATOR = gql`
  mutation CreateOperator(
    $id: ID!
    $name: String
    $alias: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $phone: String
    $operatorNumber: String
    $notes: String
    $companyWebsite: String
    $type: String
    $mesAccountNumber: String
    $mpiAccountNumber: String
    $sroAccountNumber: String
    $taxPayerID: String
  ) {
    CreateOperator(
      id: $id
      name: $name
      alias: $alias
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      operatorNumber: $operatorNumber
      notes: $notes
      companyWebsite: $companyWebsite
      type: $type
      mesAccountNumber: $mesAccountNumber
      mpiAccountNumber: $mpiAccountNumber
      sroAccountNumber: $sroAccountNumber
      taxPayerID: $taxPayerID
    ) {
      id
      name
      alias
      address1
      address2
      city
      state
      zip
      phone
      operatorNumber
      notes
      companyWebsite
      type
      contacts {
        contactName
        position
        directLine
        cell
        email
      }
      mesAccountNumber
      mpiAccountNumber
      sroAccountNumber
      taxPayerID
      swdCount
      wellCount
    }
  }
`;
