import React, { useEffect } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Checkbox,
} from '@material-ui/core';
import LoadingView from '../../components/LoadingView';
import { CSVLink } from 'react-csv';

// GraphQL query for fetching field information based on a given name.
const GET_FIELDS = gql`
    query GetFields($name: String!) {
        __type(name: $name) {
            name
            fields {
                name
                description
                type {
                    name
                    kind
                }
            }
        }
    }
`;
// GraphQL query for fetching ROW lands data.
const GET_ROW_LANDS = gql`
    query {
        ROW{
            id
            locatedOn {
                id
            }
        }
    }
`;
// Function to alphabetize an array of objects based on their 'name' property.
const alphabetize = (arrayToSort) => {
    return arrayToSort.sort((a, b) => a.name.localeCompare(b.name));
}

// Function to generate a GraphQL query for a report based on the report name and selected fields.
// generate the gql query for the report with the report name as the query name and the fields as the query fields
const generateReportGQL = (reportName, fields) => {
    if (fields.length !== 0) {
        const queryFields = fields.map((field) => `${field.name}`).join("\n");
        const queryString = `
    query {
        ${reportName} {
            ${queryFields}
            }
        }`;
        return gql(queryString);
    } else {
        return gql`
            query {
                Well {
                    id
                }
            }
        `
    }

}
// Main component for custom reports.
const CustomReports = () => {
    // State and lazy query hooks for fetching data and managing component state.
    const [getFields, {called, loading, error}] = useLazyQuery(GET_FIELDS);
    const [reportFields, setReportFields] = React.useState([]);
    const [reportName, setReportName] = React.useState("");
    const [selectedFields, setSelectedFields] = React.useState([]);
    const [allSelected, setAllSelected] = React.useState(false);
    const [allRemoved, setAllRemoved] = React.useState(false);
    const [queryString, setQueryString] = React.useState(generateReportGQL(reportName, selectedFields));
    const [reportConfig, setReportConfig] = React.useState({data: [{}], headers: [], filename: ""});
    const [generateReport, setGenerateReport] = React.useState(false);
    const [getReport, {data, called: reportCalled}] = useLazyQuery(queryString, {fetchPolicy: 'network-only'});
    const [getLands, {data: landData, called: landCalled}] = useLazyQuery(GET_ROW_LANDS, {fetchPolicy: 'network-only'});
    const [lands, setLands] = React.useState([]);

// Function to add a field to the selected fields list and remove it from the available fields list.
    const addField = (field) => {
        setSelectedFields([...selectedFields, {...field, key: field.description}]);
        // remove selected field from reportFields
        const updatedFields = alphabetize(reportFields.filter((f) => f.name !== field.name));
        setReportFields(updatedFields);
        setGenerateReport(false)
    }
// Function to remove a field from the selected fields list and add it back to the available fields list.
    const RemoveField = (field) => {
        setSelectedFields(selectedFields.filter((f) => f.description !== field.description));
        // add selected field back to reportFields
        const updatedFields = alphabetize([{...field, key: field.description}, ...reportFields])
        setReportFields(updatedFields);
        setGenerateReport(false)
    }
// Function to toggle the selection of all fields.
    const setAllFields = () => {
        if (allSelected) {
            setSelectedFields([]);
            setReportFields(alphabetize([...reportFields, ...selectedFields]));
        } else {
            setSelectedFields([...reportFields, ...selectedFields]);
            setReportFields([]);
        }
        setAllSelected(!allSelected);
        setAllRemoved(false)
    }
// Function to toggle the removal of all selected fields.
    const setAllRemovedFields = () => {
        if (allRemoved) {
            setSelectedFields([...selectedFields, ...reportFields]);
            setReportFields([]);
        } else {
            setSelectedFields([]);
            setReportFields(alphabetize([...reportFields, ...selectedFields]));
        }
        setAllRemoved(!allRemoved);
        setAllSelected(false)
        setGenerateReport(false);
    }

// Effect hook to fetch fields when the report name changes.
    useEffect(() => {
        const search = async () => {
            setReportFields([])
            setSelectedFields([])
            setAllSelected(false);
            setAllRemoved(false);
            if (reportName !== "") {
                const data = await getFields({variables: {name: reportName}});
                const {data: {__type: {fields}}} = data;
                //sort fields alphabetically
                const sortableFields = fields.map((field) => ({...field})).filter((field) => field.description)
                setReportFields(alphabetize(sortableFields));
            } else {
                console.log("No report name")
            }
        }

        try {
            search();
        } catch (e) {
            console.log(e);
        }

    }, [reportName])
// Effect hook to generate the report when the flag is set.
    useEffect(() => {
        if (generateReport) {
            getReport();
        }
    }, [generateReport, getReport])

    useEffect(() => {
        let reportData = [];
        const csvConfig = async () => {


            if (reportName === 'ROW') {
                // Secondary lazy query to get row lands
                const tracts = await getLands();
                const {data: {ROW}} = tracts;
                const tractIds = await ROW.map((row) => row.locatedOn.map((tract) => tract.id));
                if(data){
                    data[reportName].forEach((row, index) => {
                        let upDatedRow = {...row};
                        if(row.landId){
                            upDatedRow.landId = tractIds[index].join(",");
                            reportData.push(upDatedRow);
                        } else {
                            reportData.push(upDatedRow);
                        }
                    })
                }
            }

            if (data && generateReport) {
                reportData = data[reportName].map((item) => ({ ...item }));
                const csvConfig = {
                    data: reportData,
                    headers: selectedFields.map((field) => ({ label: field.description, key: field.name })),
                    filename: `${reportName}-custom.csv`,
                };

                setReportConfig(csvConfig)
            }
        }
            csvConfig();
    }, [data]
    )
    // Handler for changing report type.
    const handleChange = (event) => {
        setReportName(event.target.value);
        setGenerateReport(false);
    }

// Rendering loading view or error message based on the query state.
    if (called && loading) return <LoadingView/>;
    if (error) return <p>Error :(</p>;
// Main rendering logic, including form controls for report configuration and buttons for adding/removing fields and generating the report.
    return (
        <div style={{display: 'flex', gap: 15}}>
            <div>
                <FormControl style={{width: 150}}>
                    <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
                    <Select
                        id="demo-simple-select"
                        value={reportName}
                        onChange={handleChange}
                    >
                        <MenuItem value={"Well"}>Wells</MenuItem>
                        <MenuItem value={"Unit"}>Units</MenuItem>
                        <MenuItem value={"ROW"}>ROWs</MenuItem>
                        <MenuItem value={"Tract"}>Lands</MenuItem>
                        <MenuItem value={"Lease"}>Leases</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div style={{display: 'flex', flexDirection: "column", gap: 5, minWidth: '25%'}}>
                <h3>Report Fields</h3>
                <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox
                            checked={allSelected || reportFields.length === 0}
                            name="checkedA"
                            color={'primary'}
                            onClick={() => setAllFields()}
                            disabled={reportFields.length === 0}
                        />}
                        label="Select All"
                    />
                </FormGroup>
                {/*// Mapping over reportFields to render buttons for each field, allowing them to be added to the report.*/}
                {
                    loading ? <LoadingView/> : reportFields.map((field, index) => {
                        if (field.description) {
                            return <Button
                                variant={'outlined'}
                                color={'primary'}
                                key={field.description}
                                onClick={() => addField(field)}
                            >{field.description}</Button>
                        }
                    })
                }
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: 5, minWidth: '25%'}}>
                <h3>Selected Fields</h3>
                <FormControlLabel
                    control={<Checkbox
                        checked={allRemoved || selectedFields.length === 0}
                        name="checkedA"
                        color={'secondary'}
                        onClick={() => setAllRemovedFields()}
                        disabled={selectedFields.length === 0}
                    />}
                    label="Remove All"
                />
                {/*// Mapping over selectedFields to render buttons for each field, allowing them to be removed from the selection.*/}
                {
                    selectedFields.map((field, index) => {
                        if (field.description) {
                            return <Button
                                variant={'outlined'}
                                color={'secondary'}
                                key={field.description}
                                onClick={() => RemoveField(field)}
                            >{field.description}</Button>
                        }
                    })
                }
            </div>
            <div>
                <Button
                    color={"primary"}
                    variant={"contained"}
                    disabled={selectedFields.length === 0}
                    onClick={async () => {
                        setQueryString(generateReportGQL(reportName, selectedFields));
                        setGenerateReport(true);

                    }}
                >
                    {
                        // Button text changes based on whether the report is being generated.
                        generateReport && !data ? "Loading...." : "Generate Report"
                    }

                </Button>
            </div>
            <div>
                {
                    // Conditional rendering of CSVLink for downloading the report if it's been generated and data is available.
                    generateReport && data && <CSVLink
                        {...reportConfig}
                    >
                        <Button
                            color={"primary"}
                            variant={"contained"}
                            onClick={() => setGenerateReport(false)}
                        >
                            Download Report
                        </Button>
                    </CSVLink>
                }
            </div>
        </div>
    );
};

export default CustomReports;