import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Auth from './util/Auth';
import Drawer from './components/drawer/Drawer';
import Login from './views/login/Login';
import Home from './views/home/Home';
import EditTract from './views/tracts/editTract';
import AddTract from './views/tracts/addTract';
import WellCardView from './views/wells/WellTableView';
import AddWell from './views/wells/AddWell';
import OperatorTableView from './views/operator/OperatorTableView';
import EditOperator from './views/operator/EditOperator';
import ROWTableView from './views/ROW/ROWTableView';
import ROWTabs from './views/ROW/ROWTabs';
import EditLease from './views/leases/EditLease';
import AddLease from './views/leases/AddLease';
import AddOperator from './views/operator/AddOperator';
import Reports from './views/reports/Reports';
import LeaseTableView from './views/leases/LeaseTableView';
import CloneLease from './views/leases/CloneLease';
import LeaseTemplate from './views/leases/LeaseTemplate';
import EditLeaseTemplate from './views/leases/EditLeaseTemplate';
import LandsTable from './views/tracts/LandsTable';
import AddROW from './views/ROW/AddROW';
import UnitTableView from './views/units/UnitTableView';
import AddUnit from './views/units/AddUnit';
import WellTabPage from './views/wells/WellTabPage';
import AdminPage from './views/admin/AdminView';
import PERMISSIONS_LIST from './util/RolesEnum';
import RoleProtectedRoute from './components/router/middleware/RoleProtectedRoute';
import OtherLeaseTableView from './views/otherLeases/OtherLeaseTableView';
import AddOtherLease from './views/otherLeases/AddOtherLease';
import OtherLeaseTabs from './views/otherLeases/OtherLeaseTabs';
import UnitTabs from './views/units/UnitTabs';
import UpdatePassword from './UpdatePassword';
import PasswordReset from './verify';

const initialState = {
  // TODO: Make auth happen in the App.js file
  User: {
    isLoggedIn: Auth.isAuthenticated(),
    roles: Auth.getRoles(),
  },
};
const Routes = (props) => {
  const [State, setState] = useState(initialState);

  const withDrawer = (Component) => ({ children }) => {
    return (
      <Drawer>
        <Component>{children}</Component>
      </Drawer>
    );
  };

  if (!State.User.isLoggedIn) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Login} loginFn={setState} />
          <Route path="/update-password" component={UpdatePassword} />
          <Route path="/verify" component={PasswordReset} />
          {/* Additional routes can be added here */}
        </Switch>
      </BrowserRouter>
    );  }

  return (
    <BrowserRouter>
      <>
        <Route exact path='/(Home|Dashboard|)' component={withDrawer(Home)} />
        <RoleProtectedRoute
          exact
          path='/Lands'
          component={withDrawer(LandsTable)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_LANDS]}
        />
        <RoleProtectedRoute
          path='/Lands/:tractID'
          component={withDrawer(EditTract)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_LANDS]}
        />
        <RoleProtectedRoute
          exact
          path='/addLand'
          component={withDrawer(AddTract)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_LANDS]}
        />
        <RoleProtectedRoute
          exact
          path='/Wells'
          component={withDrawer(WellCardView)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_WELLS]}
        />
        <RoleProtectedRoute
          path='/Wells/:wellID'
          component={withDrawer(WellTabPage)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_WELLS]}
        />
        <RoleProtectedRoute
          exact
          path='/addWell'
          component={withDrawer(AddWell)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_WELLS]}
        />
        <RoleProtectedRoute
          exact
          path='/Operators'
          component={withDrawer(OperatorTableView)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_OPERATORS]}
        />
        <RoleProtectedRoute
          exact
          path='/RightOfWays'
          component={withDrawer(ROWTableView)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_ROWS]}
        />
        <RoleProtectedRoute
          exact
          path='/addRightOfWay'
          component={withDrawer(AddROW)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_ROWS]}
        />
        <RoleProtectedRoute
          exact
          path='/RightOfWays/:rowID'
          component={withDrawer(ROWTabs)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_ROWS]}
        />
        <RoleProtectedRoute
          exact
          path='/addOperator'
          component={withDrawer(AddOperator)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_OPERATORS]}
        />
        <RoleProtectedRoute
          exact
          path='/Operators/:operatorID'
          component={withDrawer(EditOperator)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_OPERATORS]}
        />
        <RoleProtectedRoute
          exact
          path='/Leases'
          component={withDrawer(LeaseTableView)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_LEASE]}
        />
        <RoleProtectedRoute
          exact
          path='/Leases/:leaseID'
          component={withDrawer(EditLease)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_LEASE]}
        />
        <RoleProtectedRoute
          exact
          path='/addLease'
          component={withDrawer(AddLease)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_LEASE]}
        />
        <Route exact path='/Reports' component={withDrawer(Reports)} />
        <RoleProtectedRoute
          exact
          path='/CloneLease/:leaseID'
          component={withDrawer(CloneLease)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_LEASE]}
        />
        <RoleProtectedRoute
          exact
          path='/AddLeaseTemplate'
          component={withDrawer(LeaseTemplate)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_LEASE]}
        />
        <RoleProtectedRoute
          exact
          path='/EditLeaseTemplate/:leaseID'
          component={withDrawer(EditLeaseTemplate)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_LEASE]}
        />
        <RoleProtectedRoute
          exact
          path='/Units/'
          component={withDrawer(UnitTableView)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_UNITS]}
        />
        <RoleProtectedRoute
          exact
          path='/addUnit'
          component={withDrawer(AddUnit)}
          requiredRoles={[PERMISSIONS_LIST.READ.READ_UNITS]}
        />
        <RoleProtectedRoute
          exact
          path='/Admin'
          requiredRoles={[PERMISSIONS_LIST.ADMIN]}
          component={withDrawer(AdminPage)}
        />
        <RoleProtectedRoute
          exact
          path='/OtherLeases'
          requiredRoles={[PERMISSIONS_LIST.READ.READ_OTHER_LEASE]}
          component={withDrawer(OtherLeaseTableView)}
        />
        <RoleProtectedRoute
          exact
          path='/AddOtherLease'
          requiredRoles={[PERMISSIONS_LIST.READ.READ_OTHER_LEASE]}
          component={withDrawer(AddOtherLease)}
        />
        <RoleProtectedRoute
          exact
          path='/OtherLeases/:OtherLeaseID'
          requiredRoles={[PERMISSIONS_LIST.READ.READ_OTHER_LEASE]}
          component={withDrawer(OtherLeaseTabs)}
        />
        <RoleProtectedRoute
          exact
          path='/Units/:unitID'
          requiredRoles={[PERMISSIONS_LIST.READ.READ_UNITS]}
          component={withDrawer(UnitTabs)}
        />
      </>
    </BrowserRouter>
  );
};

export default Routes;
