import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_ROW,
  EDIT_ROW,
  DELETE_ROW,
  ADD_ROW_ON,
  REMOVE_ROW_ON,
  ADD_ROW_OPERATOR,
  REMOVE_ROW_OPERATOR,
  ADD_ROW_PAYMENT,
  DELETE_ROW_PAYMENT,
  UPDATE_ROW_PAYMENT,
} from '../../components/row/ROWQueries';
import LoadingView from '../../components/LoadingView';
import ROWDetailPage from './ROWDetailPage';
import gql from 'graphql-tag';

const OPERATOR_QUERY = gql`
  query {
    Operator {
      id
      name
    }
  }
`;

const ROW_TRACTS_QUERY = gql`
  query {
    Tract(orderBy: id_asc) {
      id
      name
    }
  }
`;


const getInfoFromQuery = (queryResult) => {
  // if query result is an object, get the first key and extract it from the object
    if (typeof queryResult === 'object' && queryResult !== null) {
    const key = Object.keys(queryResult)[0];
    // check if the key is an array, if so return the array
    if (Array.isArray(queryResult[key])) {
    return queryResult[key];
    } else {
    // if the key is not an array, return the object
      return false;
    }
  }

  return queryResult;
};

const EditROW = ({ history, ROW }) => {

  const [EditROWQuery] = useMutation(EDIT_ROW);
  const [DeleteRow] = useMutation(DELETE_ROW);
  const [placeROW] = useMutation(ADD_ROW_ON);
  const [removeROW] = useMutation(REMOVE_ROW_ON);
  const {data: tractData} = useQuery(ROW_TRACTS_QUERY);
  const {data: operatorData} = useQuery(OPERATOR_QUERY);
  const [addRowOperator] = useMutation(ADD_ROW_OPERATOR);
  const [removeRowOperator] = useMutation(REMOVE_ROW_OPERATOR);
  const [addROWPayment] = useMutation(ADD_ROW_PAYMENT);
  const [deleteROWPayment] = useMutation(DELETE_ROW_PAYMENT);
  const [updateROWPayment] = useMutation(UPDATE_ROW_PAYMENT);

  const tracts = getInfoFromQuery(tractData) || [];
  const operators = getInfoFromQuery(operatorData) || [];

  return (
    <div>
      <ROWDetailPage
        ROW={ROW}
        EditRow={EditROWQuery}
        DeleteRow={DeleteRow}
        history={history}
        placeROW={placeROW}
        tracts={tracts}
        removeROW={removeROW}
        operators={operators}
        addRowOperator={addRowOperator}
        removeRowOperator={removeRowOperator}
        addROWPayment={addROWPayment}
        deleteROWPayment={deleteROWPayment}
        updateROWPayment={updateROWPayment}
      />
    </div>
  );
};

export default EditROW;
