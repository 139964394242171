import React from 'react';
import MaterialTable from 'material-table';
import { TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import { FieldArray, Form, Formik } from 'formik';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import {
  ADD_OTHER_LEASE_TITLE,
  REMOVE_OTHER_LEASE_TITLE,
  UPDATE_OTHER_LEASE_TITLE,
} from '../../components/otherleases/otherleaseQueries';

const titleQuery = gql`
  query OtherLease($id: ID!) {
    OtherLease(id: $id) {
      id
      projectName
      titleRun {
        id
        volPage
        effectiveDate
        dateFiled
        comments
        grantor
        grantee
      }
    }
  }
`;

const OtherLeaseTitle = ({ history, match, enqueueSnackbar }) => {
  const [addTitle] = useMutation(ADD_OTHER_LEASE_TITLE);
  const [deleteTitle] = useMutation(REMOVE_OTHER_LEASE_TITLE);
  const [editTitle] = useMutation(UPDATE_OTHER_LEASE_TITLE);

  const { data, loading, error } = useQuery(titleQuery, {
    variables: { id: match.params.OtherLeaseID },
  });

  if (loading) {
    return <LoadingView />;
  }
  if (error) {
    return error.toString();
  }

  const OtherLease = data.OtherLease[0] || [];

  return (
    <div>
      <Formik onSubmit={() => {}} initialValues={OtherLease}>
        {(props) => (
          <Form>
            <Typography
              variant='h5'
              style={{
                color: '#1976d2',
                fontSize: '1.75rem',
                textAlign: 'center',
                textDecoration: 'underline',
              }}
            >
              Other Lease Chain Of Title
            </Typography>
            <br />
            <hr />
            <br />
            <Typography
              variant='h5'
              style={{
                color: '#1976d2',
                fontSize: '1.75rem',
                textAlign: 'center',
                textDecoration: 'underline',
              }}
            >
              {OtherLease.projectName}
            </Typography>
            <FieldArray name='titleRun'>
              {({ push, remove, insert }) => {
                const columns = [
                  {
                    title: 'Vol/Page',
                    field: 'volPage',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Date Filed',
                    field: 'dateFiled',
                    render: (rowData) => moment(rowData.dateFiled).format('MM/DD/YYYY'),
                    // Keep in case we need
                    // customSort: (a, b) => (moment(a.dateFiled).isAfter(moment(b.dateFiled)) ? 1 : -1),
                    defaultSort: 'asc',
                    editComponent: (props) => (
                      <TextField type='date' onChange={(e) => props.onChange(e.target.value)} />
                    ),
                  },
                  {
                    title: 'Effective Date',
                    field: 'effectiveDate',
                    render: (rowData) => moment(rowData.effectiveDate).format('MM/DD/YYYY'),
                    editComponent: (props) => (
                      <TextField type='date' onChange={(e) => props.onChange(e.target.value)} />
                    ),
                  },
                  {
                    title: 'Grantor',
                    field: 'grantor',
                  },
                  {
                    title: 'Grantee',
                    field: 'grantee',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Comments',
                    field: 'comments',
                  },
                ];

                return (
                  <MaterialTable
                    columns={columns}
                    data={props.values.titleRun}
                    options={{
                      searchFieldAlignment: 'left',
                      showTitle: false,
                      toolbarButtonAlignment: 'left',
                      addRowPosition: 'first',
                      exportButton: true,
                      paging: false,
                      headerStyle: {
                        backgroundColor: '#1976d2',
                        color: '#FFF',
                        textAlign: 'left',
                        justifyContent: 'center',
                        whiteSpace: 'pre',
                      },
                    }}
                    editable={{
                      onRowAdd: (newData) => {
                        return addTitle({
                          variables: {
                            LeaseID: props.values.id,
                            info: { ...newData },
                          },
                        })
                          .then(({ data: { addOtherLeaseTitle } }) => {
                            push(addOtherLeaseTitle.titleRun[0]);
                          })
                          .catch((error) => console.log(error));
                      },
                      onRowUpdate: (newData, oldData) => {
                        const { __typename, id, ...updateData } = newData;
                        return editTitle({
                          variables: {
                            OtherLeaseTitleID: oldData.id,
                            info: { ...updateData },
                          },
                        }).then(({ data: { updateOtherLeaseTitle } }) => {
                          remove(oldData.tableData.id);
                          insert(oldData.tableData.id, updateOtherLeaseTitle.titleRun[0]);
                        });
                      },
                      onRowDelete: (oldData) => {
                        return deleteTitle({
                          variables: {
                            OtherLeaseTitleID: oldData.id,
                          },
                        })
                          .then((data) => {
                            remove(oldData.tableData.id);
                          })
                          .catch((error) => console.log(error));
                      },
                    }}
                  />
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OtherLeaseTitle;
