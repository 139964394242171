import gql from 'graphql-tag';

export const countyOptions = [
  { type: 'Ward' },
  { type: 'Reeves' },
  { type: 'Lea' },
  { type: 'Culberson' },
  { type: 'Cherokee' },
  { type: 'Brewster' },
  { type: 'Trego' },
  { type: 'Kearny' },
  { type: 'Arapahoe' },
  { type: 'Greeley' },
  { type: 'Cheyenne' },
  { type: 'Lincoln' },
  { type: 'Chautauqua' },
  { type: 'Pecos' },
  { type: 'SantaFe' },
  { type: 'Saguache' },
];

export const defaultLease = {
  id: '',
  leaseName: 'Default',
  mineralClassified: false,
  currentLessee: 'None',
  grossAcres: '0',
  netMineralAcres: '0',
  netSurfaceAcres: '0',
  heldByProductionAcres: '0',
  status: 'None',
  termYears: '0',
  termMonths: '0',
  effectiveDate: '2000-01-01',
  primaryExpirationDate: '2000-01-01',
  bonusPerAcre: '0',
  bonusAmount: '0',
  paidUpOGL: false,
  rentalPerAcre: '0',
  rentalAmount: '0',
  extensionOptionsYears: '0',
  extensionOptionsMonths: '0',
  extensionDueDate: '2000-01-01',
  extensionExpirationDate: '2000-01-01',
  amountPerAcre: '0',
  totalAmount: '0',
  RoyaltyRate: '0',
  dueOil: 'None',
  dueGas: 'None',
  minRoyalty: '0',
  compensatory: '0',
  notes: 'Default Notes',
  wells: [],
  assignments: [],
  leasedLands: [],
  leaseRecordings: [],
  leaseClauses: [],
  skimOil: false,
  condensateOil: false,
  leaseUseGas: false,
  flaredGas: false,
  depthsLeased: '',
  mfNumber: '',
};

export const LeaseFragment = gql`
  {
    id
    county
    leaseName
    mineralClassified
    currentLessee
    grossAcres
    netMineralAcres
    netSurfaceAcres
    heldByProductionAcres
    status
    termYears
    termMonth
    effectiveDate
    primaryExpirationDate
    bonusPerAcre
    bonusAmount
    paidUpOGL
    rentalPerAcre
    rentalAmount
    extensionOptionsYears
    extensionOptionsMonths
    extensionDueDate
    extensionExpirationDate
    amountPerAcre
    totalAmount
    RoyaltyRate
    dueOil
    dueGas
    minRoyalty
    compensatory
    notes
    cdcCalc
    cdcDescription
    shutinDescription
    legalDescription
    cdcPeriod
    cdcDeadline
    verticalPughDescription
    verticalPughDate
    horizontalPughDeepDescription
    horizontalPughDeepDate
    horizontalPughShallowDescription
    horizontalPughShallowDate
    pughRelease
    shutinTriggerDate
    shutinMaxConsecutive
    shutinMaxPost
    mapURL
    template
    skimOil
    condensateOil
    leaseUseGas
    flaredGas
    separateLeaseLanguage
    depthsLeased
    mfNumber
    leasedLands {
      id
      netMineralAcres
      netSurfaceAcres
      depths
      shortDescription
      Tract {
        id
      }
    }
    leaseClauses {
      id
      type
      name
      text
      paragraph
    }
    leaseRecordings {
      id
      volPage
      book
      county
      effectiveDate
      dateFiled
      documentName
      notes
      totalAcres
      grantor {
        id
        name
      }
      grantee {
        id
        name
      }
      recordingInterest {
        id
        acres
        Tract {
          id
        }
      }
    }
    wells {
      id
      name
      completionDate
      rigReleaseDate
      cdcTerm
      cdcDate
      spudDate
      status
      landId
    }
    assignments {
      id
      volPage
      book
      docType
      effectiveDate
      dateFiled
      totalAcres
      comments
      wellsDrilled
      grantor {
        id
        name
      }
      grantee {
        id
        name
      }
      assignmentInterests {
        id
        acres
        Tract {
          id
        }
      }
    }
  }
`;

export const GET_ALL_LEASES = gql`
    query {
        Lease
        ${LeaseFragment}
    }
`;

export const GET_LEASE = gql`
    query Lease($id: ID) {
        Lease(id: $id) ${LeaseFragment}
    }
`;

export const CREATE_LEASE = gql`
    mutation CreateLease(
        $id: ID
        $county: String
        $leaseName: String
        $mineralClassified: Boolean
        $currentLessee: String
        $grossAcres: String
        $netMineralAcres: String
        $netSurfaceAcres: String
        $heldByProductionAcres: String
        $status: String
        $termYears: String
        $termMonth: String
        $effectiveDate: String
        $primaryExpirationDate: String
        $bonusPerAcre: String
        $bonusAmount: String
        $paidUpOGL: Boolean
        $rentalPerAcre: String
        $rentalAmount: String
        $extensionOptionsYears: String
        $extensionOptionsMonths: String
        $extensionDueDate: String
        $extensionExpirationDate: String
        $amountPerAcre: String
        $totalAmount: String
        $RoyaltyRate: String
        $dueOil: String
        $dueGas: String
        $minRoyalty: String
        $compensatory: String
        $notes: String
        $cdcCalc: String
        $cdcDescription: String
        $shutinDescription: String
        $legalDescription: String
        $mapURL: String
        $cdcPeriod: String
        $verticalPughDescription: String
        $verticalPughDate: String
        $horizontalPughDeepDescription: String
        $horizontalPughDeepDate: String
        $horizontalPughShallowDescription: String
        $horizontalPughShallowDate: String
        $pughRelease: Boolean
        $shutinTriggerDate: String
        $shutinMaxConsecutive: String
        $shutinMaxPost: String
        $template: Boolean
        $skimOil: Boolean
        $condensateOil: Boolean
        $leaseUseGas: Boolean
        $flaredGas: Boolean
        $depthsLeased: String
        $separateLeaseLanguage: Boolean
        $mfNumber: String
    ) {
        CreateLease(
            id: $id
            county: $county
            leaseName: $leaseName
            mineralClassified: $mineralClassified
            currentLessee: $currentLessee
            grossAcres: $grossAcres
            netMineralAcres: $netMineralAcres
            netSurfaceAcres: $netSurfaceAcres
            heldByProductionAcres: $heldByProductionAcres
            status: $status
            termYears: $termYears
            termMonth: $termMonth
            effectiveDate: $effectiveDate
            primaryExpirationDate: $primaryExpirationDate
            bonusPerAcre: $bonusPerAcre
            bonusAmount: $bonusAmount
            paidUpOGL: $paidUpOGL
            rentalPerAcre: $rentalPerAcre
            rentalAmount: $rentalAmount
            extensionOptionsYears: $extensionOptionsYears
            extensionOptionsMonths: $extensionOptionsMonths
            extensionDueDate: $extensionDueDate
            extensionExpirationDate: $extensionExpirationDate
            amountPerAcre: $amountPerAcre
            totalAmount: $totalAmount
            RoyaltyRate: $RoyaltyRate
            dueOil: $dueOil
            dueGas: $dueGas
            minRoyalty: $minRoyalty
            compensatory: $compensatory
            notes: $notes
            cdcCalc: $cdcCalc
            cdcDescription: $cdcDescription
            shutinDescription: $shutinDescription
            legalDescription: $legalDescription
            mapURL: $mapURL
            cdcPeriod: $cdcPeriod
            verticalPughDescription: $verticalPughDescription
            verticalPughDate: $verticalPughDate
            horizontalPughDeepDescription: $horizontalPughDeepDescription
            horizontalPughDeepDate: $horizontalPughDeepDate
            horizontalPughShallowDescription: $horizontalPughShallowDescription
            horizontalPughShallowDate: $horizontalPughShallowDate
            pughRelease: $pughRelease
            shutinTriggerDate: $shutinTriggerDate
            shutinMaxConsecutive: $shutinMaxConsecutive
            shutinMaxPost: $shutinMaxPost
            template: $template
            skimOil: $skimOil
            condensateOil: $condensateOil
            leaseUseGas: $leaseUseGas
            flaredGas: $flaredGas
            depthsLeased: $depthsLeased
            separateLeaseLanguage: $separateLeaseLanguage
            mfNumber: $mfNumber
        ) ${LeaseFragment}
    }
`;
export const EDIT_LEASE = gql`
    mutation UpdateLease(
        $id: ID!
        $county: String
        $leaseName: String
        $mineralClassified: Boolean
        $currentLessee: String
        $grossAcres: String
        $netMineralAcres: String
        $netSurfaceAcres: String
        $heldByProductionAcres: String
        $status: String
        $termYears: String
        $termMonth: String
        $effectiveDate: String
        $primaryExpirationDate: String
        $bonusPerAcre: String
        $bonusAmount: String
        $paidUpOGL: Boolean
        $rentalPerAcre: String
        $rentalAmount: String
        $extensionOptionsYears: String
        $extensionOptionsMonths: String
        $extensionDueDate: String
        $extensionExpirationDate: String
        $amountPerAcre: String
        $totalAmount: String
        $RoyaltyRate: String
        $dueOil: String
        $dueGas: String
        $minRoyalty: String
        $compensatory: String
        $notes: String
        $cdcCalc: String
        $cdcDescription: String
        $cdcDeadline: String
        $shutinDescription: String
        $legalDescription: String
        $mapURL: String
        $cdcPeriod: String
        $verticalPughDescription: String
        $verticalPughDate: String
        $horizontalPughDeepDescription: String
        $horizontalPughDeepDate: String
        $horizontalPughShallowDescription: String
        $horizontalPughShallowDate: String
        $pughRelease: Boolean
        $shutinTriggerDate: String
        $shutinMaxConsecutive: String
        $shutinMaxPost: String
        $template: Boolean
        $skimOil: Boolean
        $condensateOil: Boolean
        $leaseUseGas: Boolean
        $flaredGas: Boolean
        $separateLeaseLanguage: Boolean
        $depthsLeased: String
        $mfNumber: String
    ) {
        UpdateLease(
            id: $id
            county: $county
            leaseName: $leaseName
            mineralClassified: $mineralClassified
            currentLessee: $currentLessee
            grossAcres: $grossAcres
            netMineralAcres: $netMineralAcres
            netSurfaceAcres: $netSurfaceAcres
            heldByProductionAcres: $heldByProductionAcres
            status: $status
            termYears: $termYears
            termMonth: $termMonth
            effectiveDate: $effectiveDate
            primaryExpirationDate: $primaryExpirationDate
            bonusPerAcre: $bonusPerAcre
            bonusAmount: $bonusAmount
            paidUpOGL: $paidUpOGL
            rentalPerAcre: $rentalPerAcre
            rentalAmount: $rentalAmount
            extensionOptionsYears: $extensionOptionsYears
            extensionOptionsMonths: $extensionOptionsMonths
            extensionDueDate: $extensionDueDate
            extensionExpirationDate: $extensionExpirationDate
            amountPerAcre: $amountPerAcre
            totalAmount: $totalAmount
            RoyaltyRate: $RoyaltyRate
            dueOil: $dueOil
            dueGas: $dueGas
            minRoyalty: $minRoyalty
            compensatory: $compensatory
            notes: $notes
            cdcCalc: $cdcCalc
            cdcDeadline: $cdcDeadline
            cdcDescription: $cdcDescription
            shutinDescription: $shutinDescription
            legalDescription: $legalDescription
            mapURL: $mapURL
            cdcPeriod: $cdcPeriod
            verticalPughDescription: $verticalPughDescription
            verticalPughDate: $verticalPughDate
            horizontalPughDeepDescription: $horizontalPughDeepDescription
            horizontalPughDeepDate: $horizontalPughDeepDate
            horizontalPughShallowDescription: $horizontalPughShallowDescription
            horizontalPughShallowDate: $horizontalPughShallowDate
            pughRelease: $pughRelease
            shutinTriggerDate: $shutinTriggerDate
            shutinMaxConsecutive: $shutinMaxConsecutive
            shutinMaxPost: $shutinMaxPost
            template: $template
            skimOil: $skimOil
            condensateOil: $condensateOil
            leaseUseGas: $leaseUseGas
            flaredGas: $flaredGas
            separateLeaseLanguage: $separateLeaseLanguage
            depthsLeased: $depthsLeased
            mfNumber: $mfNumber
        )
        ${LeaseFragment}
    }
`;

export const DELETE_LEASE = gql`
  mutation DeleteLease($id: ID!) {
    DeleteLease(id: $id) {
      id
    }
  }
`;

export const ADD_LEASE_INTEREST = gql`
    mutation addLeaseInterest(
        $leaseID: ID!
        $landID: ID!
        $netMineralAcres: String
        $netSurfaceAcres: String
        $depths: String
        $shortDescription: String
    ) {
        addLeaseInterest(
            leaseID: $leaseID
            landID: $landID
            netMineralAcres: $netMineralAcres
            netSurfaceAcres: $netSurfaceAcres
            depths: $depths
            shortDescription: $shortDescription
        )
        ${LeaseFragment}
    }
`;

export const DELETE_LEASE_INTEREST = gql`
    mutation deleteLeaseInterest($leaseID: ID!, $landID: ID!) {
        deleteLeaseInterest(leaseID: $leaseID, landID: $landID)
        ${LeaseFragment}
    }
`;

export const UPDATE_LEASE_INTEREST = gql`
  mutation updateLeaseInterest(
    $relationID: ID!
    $netMineralAcres: String
    $netSurfaceAcres: String
    $depths: String
    $shortDescription: String
  ) {
    updateLeaseInterest(
      relationID: $relationID
      netMineralAcres: $netMineralAcres
      netSurfaceAcres: $netSurfaceAcres
      depths: $depths
      shortDescription: $shortDescription
    ) {
      id
      netMineralAcres
      netSurfaceAcres
      depths
    }
  }
`;

export const UPDATE_LEASE_RECORDING = gql`
    mutation UpdateRecording(
        $id: ID!
        $volPage: String!
        $book: String!
        $county: String!
        $effectiveDate: String!
        $dateFiled: String!
        $documentName: String!
        $notes: String!
        $fileName: String
    ) {
        UpdateRecording(
            id: $id
            volPage: $volPage
            book: $book
            county: $county
            effectiveDate: $effectiveDate
            dateFiled: $dateFiled
            documentName: $documentName
            notes: $notes
            fileName: $fileName
        ) {
            id
            volPage
            book
            county
            effectiveDate
            dateFiled
            documentName
            notes
            totalAcres
            fileName
        }
    }
`;

export const DELETE_LEASE_RECORDING = gql`
  mutation deleteLeaseRecording($leaseID: ID!, $recordingID: ID!) {
    deleteLeaseRecording(leaseID: $leaseID, recordingID: $recordingID) {
      id
      leaseRecordings {
        id
        volPage
        book
        county
        documentName
        effectiveDate
        dateFiled
        totalAcres
        notes
        grantor {
          id
          name
        }
        grantee {
          id
          name
        }
        recordingInterest {
          id
          acres
          Tract {
            id
          }
        }
      }
    }
  }
`;

export const ADD_CDC_WELL = gql`
    mutation createWellLeaseInterest($leaseID: ID!, $wellName: String!, $cdcDate: String!, $cdcTerm: String!) {
        createWellLeaseInterest(leaseID: $leaseID, wellName: $wellName, cdcDate: $cdcDate, cdcTerm: $cdcTerm)
        ${LeaseFragment}
    }
`;

export const DELETE_CDC_WELL = gql`
    mutation deleteWellLeaseInterest($leaseID: ID!, $wellName: String!) {
        deleteWellLeaseInterest(leaseID: $leaseID, wellName: $wellName)
        ${LeaseFragment}
    }
`;

export const UPDATE_CDC_WELL = gql`
  mutation updateWellLeaseInterest($wellName: String!, $cdcTerm: String!, $cdcDate: String!) {
    updateWellLeaseInterest(wellName: $wellName, cdcTerm: $cdcTerm, cdcDate: $cdcDate) {
      id
      name
      completionDate
      rigReleaseDate
      cdcTerm
      cdcDate
      spudDate
      status
      landId
    }
  }
`;

export const CREATE_ASSIGNEE_INTEREST = gql`
  mutation createAssignment($leaseID: ID!, $AssignmentInfo: AssignmentInfo, $landIDs: [AssignmentInputs]) {
    createAssignment(leaseID: $leaseID, AssignmentInfo: $AssignmentInfo, landIDs: $landIDs) {
      id
      volPage
      book
      docType
      effectiveDate
      dateFiled
      totalAcres
      comments
      wellsDrilled
      grantor {
        id
        name
      }
      grantee {
        id
        name
      }
      assignmentInterests {
        id
        acres
        Tract {
          id
        }
      }
    }
  }
`;

export const CREATE_LEASE_RECORDING = gql`
  mutation createLeaseRecording($leaseID: ID!, $RecordingInfo: RecordingInput, $landIDs: [AssignmentInputs]) {
    createLeaseRecording(leaseID: $leaseID, RecordingInfo: $RecordingInfo, landIDs: $landIDs) {
      id
      leaseRecordings {
        id
        volPage
        book
        county
        documentName
        effectiveDate
        dateFiled
        totalAcres
        notes
        grantor {
          id
          name
        }
        grantee {
          id
          name
        }
        recordingInterest {
          id
          acres
          Tract {
            id
          }
        }
      }
    }
  }
`;

export const REMOVE_ASSIGNMENT_INTEREST = gql`
  mutation DeleteAssignment($id: ID!) {
    DeleteAssignment(id: $id) {
      id
    }
  }
`;

export const UPDATE_ASSIGNMENT_INTEREST = gql`
  mutation UpdateAssignment(
    $id: ID!
    $volPage: String
    $book: String
    $effectiveDate: String
    $dateFiled: String
    $docType: String
    $comments: String
    $wellsDrilled: String
  ) {
    UpdateAssignment(
      id: $id
      volPage: $volPage
      book: $book
      effectiveDate: $effectiveDate
      dateFiled: $dateFiled
      docType: $docType
      comments: $comments
      wellsDrilled: $wellsDrilled
    ) {
      id
      volPage
      book
      effectiveDate
      dateFiled
      docType
      comments
      wellsDrilled
    }
  }
`;

export const CREATE_CLAUSE = gql`
    mutation addClause($leaseID: ID!, $name: String, $type: String, $text: String, $paragraph: String) {
        addClause(leaseID: $leaseID, name: $name, type: $type, text: $text, paragraph: $paragraph)
        ${LeaseFragment}
    }
`;

export const DELETE_CLAUSE = gql`
  mutation DeleteClause($id: ID!) {
    DeleteClause(id: $id) {
      id
    }
  }
`;

export const CLONE_LEASE_INTEREST = gql`
  mutation cloneLeaseInterest($leaseID: ID!, $relationships: [NewInterestInput]!) {
    cloneLeaseInterest(leaseID: $leaseID, relationships: $relationships) {
      id
    }
  }
`;

export const CLONE_LEASE_RECORDINGS = gql`
    mutation cloneLeaseRecordings($leaseID: ID!, $recordingID: ID!, $grantorID: ID!, $granteeID: ID!, $recordingInterest: [AssignmentInputs]!) {
        cloneLeaseRecordings(leaseID: $leaseID, recordingID: $recordingID, grantorID: $grantorID, granteeID: $granteeID, recordingInterest: $recordingInterest)
        ${LeaseFragment}
    }
`;

export const CLONE_LEASE_CLAUSES = gql`
  mutation cloneLeaseClauses($leaseID: ID!, $clauses: [ID]!) {
    cloneLeaseClauses(leaseID: $leaseID, clauses: $clauses) {
      id
    }
  }
`;

export const CLONE_LEASE_ASSIGNMENTS = gql`
    mutation cloneAssignmentInterest($leaseID: ID!, $assignmentID: ID!, $grantorID: ID!, $granteeID: ID!, $assignmentInterest: [AssignmentInputs]!) {
        cloneAssignmentInterest(leaseID: $leaseID, assignmentID: $assignmentID, grantorID: $grantorID, granteeID: $granteeID, assignmentInterest: $assignmentInterest)
        ${LeaseFragment}
    }
`;

export const UPDATE_CLAUSE = gql`
  mutation UpdateClause($id: ID!, $name: String, $type: String, $text: String, $paragraph: String) {
    UpdateClause(id: $id, name: $name, type: $type, text: $text, paragraph: $paragraph) {
      id
      name
      type
      paragraph
      text
    }
  }
`;
