import * as yup from 'yup';
import moment from 'moment';

const dateSchema = yup
  .string()
  .nullable()
  .test('Valid Date', 'Not a valid date!', (dateValue) => {
    if (dateValue === null || dateValue === '' || dateValue === undefined) {
      return true;
    } else if (/[a-zA-Z]/g.test(dateValue)) {
      return false;
    } else if (moment(dateValue, 'YYYY-MM-DD', true).isValid() === false) {
      return false;
    } else if (moment(dateValue, 'YYYY-MM-DD', true).isAfter(moment('1200-01-01', 'YYYY-MM-DD')) === false) {
      return false;
    }

    return true;
  });

const operatedBySchema = yup.object().shape({
  _id: yup.string().default(''),
  id: yup.string().default(''),
  name: yup.string().default(''),
});

const drilledOnSchema = yup.object().shape({
  id: yup
    .string()
    .required()
    .default(''),
  name: yup
    .string()
    .required()
    .default(''),
  county: yup
    .string()
    .required()
    .default(''),
  state: yup
    .string()
    .required()
    .default(''),
});

export const WellSchema = yup.object().shape({
  id: yup
    .string()
    .default('')
    .required(),
  apiNumber: yup
    .string()
    .default('')
    .required(),
  name: yup
    .string()
    .default('')
    .required(),
  notes: yup
    .string()
    .default('')
    .nullable(),
  type: yup
    .string()
    .trim()
    .nullable()
    .default(''),
  rrcLeaseNumber: yup
    .string()
    .trim()
    .nullable()
    .default(''),
  permitNumber: yup
    .string()
    .trim()
    .nullable()
    .default(''),
  abstract: yup
    .string()
    .trim()
    .nullable()
    .default(''),
  status: yup
    .string()
    .trim()
    .nullable()
    .default(''),
  spudDate: dateSchema.default('').nullable(),
  rigReleaseDate: dateSchema.default('').nullable(),
  completionDate: dateSchema.default('').nullable(),
  dateProducing: dateSchema.default('').nullable(),
  dateInPay: dateSchema.default('').nullable(),
  landId: yup
    .string()
    .default('')
    .nullable(),
  OGL: yup
    .string()
    .default('')
    .nullable(),
  location: yup
    .string()
    .default('')
    .nullable(),
  decProdUnit: yup
    .string()
    .default('')
    .nullable(),
  dpu: yup
    .string()
    .default('')
    .nullable(),
  producingFormation: yup
    .string()
    .default('')
    .nullable(),
  lengthOfLateral: yup
    .string()
    .default('')
    .nullable(),
  rrcField: yup
    .string()
    .default('')
    .nullable(),
  oilPayor: yup
    .string()
    .default('')
    .nullable(),
  gasPayor: yup
    .string()
    .default('')
    .nullable(),
  operator: yup
    .string()
    .default('')
    .nullable(),
  familyID: yup
    .string()
    .default('')
    .nullable(),
  mpiDOI: yup
    .string()
    .default('')
    .nullable(),
  mesDOI: yup
    .string()
    .default('')
    .nullable(),
  sroDOI: yup
    .string()
    .default('')
    .nullable(),
  inService: yup.boolean().default(false),
  inactive: yup.boolean().default(false),
  drilledOn: yup.array().of(drilledOnSchema),
  drillType: yup
    .string()
    .default('')
    .nullable(),
  operatorInterval: yup
    .string()
    .default('')
    .nullable(),
  upperPerf: yup
    .string()
    .default('')
    .nullable(),
  lowerPerf: yup
    .string()
    .default('')
    .nullable(),
  perfLength: yup
    .string()
    .default('')
    .nullable(),
  permitDate: yup
    .string()
    .default('')
    .nullable(),
  rrcLeaseHistory: yup
    .string()
    .default('')
    .nullable(),
  operatorHistory: yup
    .string()
    .default('')
    .nullable(),
  latitude: yup
    .string()
    .default('')
    .nullable(),
  longitude: yup
    .string()
    .default('')
    .nullable(),
  longitudeBottom: yup
    .string()
    .default('')
    .nullable(),
  latitudeBottom: yup
    .string()
    .default('')
    .nullable(),
  state: yup
    .string()
    .default('')
    .nullable(),
  county: yup
    .string()
    .default('')
    .nullable(),
  totalDepth: yup
    .string()
    .default('')
    .nullable(),
  trueVerticalDepth: yup
    .string()
    .default('')
    .nullable(),
  plat: yup
    .string()
    .default('')
    .nullable(),
  g1: yup
    .string()
    .default('')
    .nullable(),
  p4: yup
    .string()
    .default('')
    .nullable(),
  p12: yup
    .string()
    .default('')
    .nullable(),
  p16: yup
    .string()
    .default('')
    .nullable(),
  p17: yup
    .string()
    .default('')
    .nullable(),
  fracFocus: yup
    .string()
    .default('')
    .nullable(),
  doto: yup
    .string()
    .default('')
    .nullable(),
  completionApp: yup
    .string()
    .default('')
    .nullable(),
  mesDivOrder: yup
    .string()
    .default('')
    .nullable(),
  mpiDivOrder: yup
    .string()
    .default('')
    .nullable(),
  operatedBy: operatedBySchema,
});
