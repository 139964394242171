import React from 'react';
import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Auth from '../../util/Auth';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import { useSnackbar } from 'notistack/build';
import EditOtherLease from './EditOtherLease';
import OtherLeaseTitle from './OtherLeaseTitle';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_OTHER_LEASE);

export const FormStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 50,
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  Switch: {
    margin: 0,
  },
  BottomBar: {
    display: hasPermissions ? 'flex' : 'none',
    justifyContent: 'space-between',
  },
  positives: {
    display: 'flex',
    flexDirection: 'row',
  },
  SubmitButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
    float: 'left',
  },
  DeleteButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
    float: 'right',
  },
  DIButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
  },
  FormControlLabel: {
    paddingRight: '5em',
  },
  Select: {
    display: 'block',
  },
  FullWidthTextBox: {
    marginBottom: 20,
    marginTop: 20,
  },
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'left',
    textDecoration: 'underline',
  },
}));

function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const OtherLeaseTabs = ({ match, history }) => {
  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <Tab label='Main' />
          <Tab label='Chain Of Title' />
        </Tabs>
      </AppBar>

      {value === 0 && (
        <TabContainer>
          <EditOtherLease match={match} history={history} />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <OtherLeaseTitle match={match} history={history} enqueueSnackbar={enqueueSnackbar} />
        </TabContainer>
      )}
    </div>
  );
};

export default OtherLeaseTabs;
