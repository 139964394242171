import React from 'react';
import { WellFormStyles } from './WellTabPage';
import { Formik, Form, FieldArray, Field } from 'formik';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import MaterialTable from 'material-table';
import { TextField } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
import { ADD_DIVISION_ORDER, EDIT_WELL, REMOVE_DIVISION_ORDER } from '../../components/wells/WellQueries';
import { v4 } from 'uuid';
import math from 'mathjs';
import { Button, Table, TableBody, TableRow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';

const DOIQuery = gql`
  query GetWell($id: ID!) {
    Well(id: $id) {
      id
      apiNumber
      name
      divisionOrderNotes
      drilledOn {
        id
        orriPercentage
        npriPercentage
        mineralsOwned {
          mineralPercentage
          Owner {
            name
          }
        }
      }
      divisionOrder {
        id
        platTract
        platTractAcres
        wellUnitAcres
        mineralOwnership
        royaltyRate
        totalDOI
        familyAcres
        mesDOI
        mpiDOI
        sroDOI
        Tract {
          id
          mineralsOwned {
            mineralPercentage
            Owner {
              name
            }
          }
        }
      }
    }
  }
`;

const DivisionOrder = ({ history, match, enqueueSnackbar }) => {
  const classes = WellFormStyles();

  const [AddDOI] = useMutation(ADD_DIVISION_ORDER);
  const [RemoveDOI] = useMutation(REMOVE_DIVISION_ORDER);
  const [updateNotes] = useMutation(EDIT_WELL);

  const { data, loading, error } = useQuery(DOIQuery, {
    variables: { id: match.params.wellID },
  });

  if (loading) {
    return <LoadingView />;
  }
  if (error) {
    return error.toString();
  }
  // Break well out of data returned from query
  const Well = data.Well[0] || [];

  const editableWell = Object.assign({}, Well);

  editableWell.divisionOrderNotes = !editableWell.divisionOrderNotes ? '' : editableWell.divisionOrderNotes;

  // Check to see if all division orders are present to ensure calculations are correct
  const divisionOrderComplete = !editableWell.divisionOrder ? false : editableWell.divisionOrder.length === editableWell.drilledOn.length;

  const totalDOI = (ownerName = '') => {
    return editableWell.divisionOrder.reduce(function(a, v) {
      return a + v[ownerName];
    }, 0);
  };

  const stringTotal = (propName = '') => {
    return editableWell.divisionOrder.reduce(function(a, v) {
      return a + parseFloat(v[propName]);
    }, 0);
  };

  const mesDOI = divisionOrderComplete ? totalDOI('mesDOI').toFixed(8) : 'Incomplete';
  const sroDOI = divisionOrderComplete ? totalDOI('sroDOI').toFixed(8) : 'Incomplete';
  const mpiDOI = divisionOrderComplete ? totalDOI('mpiDOI').toFixed(8) : 'Incomplete';
  const doiTotal = divisionOrderComplete ? stringTotal('totalDOI').toFixed(8) : 'Incomplete';
  const familyTotal = divisionOrderComplete ? stringTotal('familyAcres').toFixed(8) : 'Incomplete';

  // Check to see if any division orders exist and provide them or place holders if they don't
  if (editableWell.divisionOrder.length === 0) {
    editableWell.divisionOrder = editableWell.drilledOn.map((w) => ({ Tract: { id: w.id } }));
  } else {
    const doIDs = editableWell.divisionOrder.map((d) => d.Tract.id);
    const tractIDs = editableWell.drilledOn.map((w) => w.id);
    const restLands = [];

    // Get lands that don't have a division order and add them to the table
    tractIDs.forEach((i) => {
      if (!doIDs.includes(i)) {
        restLands.push({ Tract: { id: i } });
      }
    });

    editableWell.divisionOrder = [...editableWell.divisionOrder, ...restLands];
  }

  // Break out tract ownerships
  editableWell.ownerships = editableWell.drilledOn.map((l) => {
    const newObj = {};
    newObj.tractID = l.id;
    newObj.ORRI = l.orriPercentage;
    newObj.NPRI = l.npriPercentage;
    l.mineralsOwned.forEach((m) => (newObj[m.Owner.name] = m.mineralPercentage).toFixed(8));
    return newObj;
  });


  return (
    <Formik
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        return updateNotes({ variables: values })
          .then(() => {
            enqueueSnackbar('Successfully updated Division Order', { variant: 'success' });
            actions.setSubmitting(false);
          })
          .catch((error) => {
            enqueueSnackbar(`Could not update Division Order: ${error.toString()}`, { variant: 'error' });
            actions.setSubmitting(false);
          });
      }}
      initialValues={{ ...editableWell, sroDOI, mesDOI, mpiDOI }}
    >
      {(props) => {
        return (
          <Form>
            <div>
              <Typography className={classes.title} color='textSecondary' gutterBottom style={{ textAlign: 'center' }}>
                {Well.name}
              </Typography>
              <hr style={{ width: '80%' }} />
              <br />
              <div>
                <Typography
                  className={classes.title}
                  color='textSecondary'
                  variant={'h4'}
                  gutterBottom
                  style={{ textAlign: 'center' }}
                >
                  Ownership Interest from Lands
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              {editableWell.ownerships.map((v) => {
                return (
                  <div key={v.tractID}>
                    <Typography
                      className={classes.title}
                      color='textSecondary'
                      variant={'h4'}
                      gutterBottom
                      style={{ textAlign: 'center' }}
                    >
                      {v.tractID}
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Field
                        name='MES'
                        placeholder='MES'
                        component={TextField}
                        label='MES'
                        className={classes.TextField}
                        defaultValue={!v.MES ? 'No Mineral Ownership' : v.MES.toFixed(8)}
                      />
                      <Field
                        name='MPI'
                        placeholder='MPI'
                        component={TextField}
                        label='MPI'
                        className={classes.TextField}
                        defaultValue={!v.MPI ? 'No Mineral Ownership' : v.MPI.toFixed(8)}
                      />
                      <Field
                        name='SRO'
                        placeholder='SRO'
                        component={TextField}
                        label='SRO'
                        className={classes.TextField}
                        defaultValue={!v.SRO ? 'No Mineral Ownership' : v.SRO.toFixed(8)}
                      />
                      <Field
                        name='NPRI'
                        placeholder='NPRI'
                        component={TextField}
                        label='NPRI'
                        className={classes.TextField}
                        defaultValue={v.NPRI}
                        style={{ display: !v.NPRI || v.NPRI === '0' ? 'none' : 'block' }}
                      />
                      <Field
                        name='ORRI'
                        placeholder='ORRI'
                        component={TextField}
                        label='ORRI'
                        className={classes.TextField}
                        defaultValue={v.ORRI}
                        style={{ display: !v.ORRI || v.ORRI === '0' ? 'none' : 'block' }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <br />
            <hr />
            <br />
            <FieldArray name='divisionOrder'>
              {({ insert, remove }) => {
                const columns = [
                  {
                    title: 'Land IDs',
                    field: 'Tract.id',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: "Plat Tract #'s",
                    field: 'platTract',
                  },
                  {
                    title: 'Plat Tract Acres/Wellbore Footage',
                    field: 'platTractAcres',
                  },
                  {
                    title: 'Well Unit Acres/Wellbore Length',
                    field: 'wellUnitAcres',
                  },
                  {
                    title: 'Mineral Ownership',
                    field: 'mineralOwnership',
                  },
                  {
                    title: 'Royalty Rate',
                    field: 'royaltyRate',
                  },
                  {
                    title: 'Total DOI',
                    field: 'totalDOI',
                    editable: 'never',
                  },
                  {
                    title: 'Family Acres',
                    field: 'familyAcres',
                    editable: 'never',
                  },
                  {
                    title: 'MES DOI',
                    field: 'mesDOI',
                    render: (rowData) => (rowData.mesDOI ? rowData.mesDOI.toFixed(8) : ''),
                    editable: 'never',
                  },
                  {
                    title: 'MPI DOI',
                    field: 'mpiDOI',
                    render: (rowData) => (rowData.mpiDOI ? rowData.mpiDOI.toFixed(8) : ''),
                    editable: 'never',
                  },
                  {
                    title: 'SRO DOI',
                    field: 'sroDOI',
                    render: (rowData) => (rowData.sroDOI ? rowData.sroDOI.toFixed(8) : ''),
                    editable: 'never',
                  },
                ];

                return (
                  <MaterialTable
                    columns={columns}
                    data={props.values.divisionOrder.map(d => ({...d}))}
                    title={'DOI'}
                    options={{
                      paging: false,
                      search: false,
                      toolbarButtonAlignment: 'left',
                      searchFieldAlignment: 'left',
                      exportButton: true,
                      headerStyle: {
                        backgroundColor: '#1976d2',
                        color: '#FFF',
                        textAlign: 'left',
                        justifyContent: 'center',
                      },
                    }}
                    editable={{
                      onRowUpdate: (newData, oldData) => {
                        const first = math.divide(newData.platTractAcres, newData.wellUnitAcres);
                        const DOI =
                          newData !== oldData
                            ? math.multiply(
                                first,
                                math.number(math.fraction(newData.mineralOwnership)),
                                newData.royaltyRate
                              )
                            : oldData.totalDOI;
                        const familyAcres = math.multiply(
                          newData.platTractAcres,
                          math.number(math.fraction(newData.mineralOwnership))
                        );
                        const mes = editableWell.ownerships.find((o) => o.tractID === newData.Tract.id);
                        const mpi = editableWell.ownerships.find((o) => o.tractID === newData.Tract.id);
                        const sro = editableWell.ownerships.find((o) => o.tractID === newData.Tract.id);
                        const mpiDOI = math.multiply(DOI, mpi.MPI);
                        const mesDOI = math.multiply(DOI, mes.MES);
                        const sroDOI = math.multiply(DOI, sro.SRO);

                        const pushData = {
                          id: v4(),
                          platTract: newData.platTract,
                          platTractAcres: newData.platTractAcres,
                          wellUnitAcres: newData.wellUnitAcres,
                          mineralOwnership: newData.mineralOwnership,
                          royaltyRate: newData.royaltyRate,
                          familyAcres: familyAcres.toFixed(2),
                          totalDOI: DOI.toFixed(8),
                        };

                        if (!newData.id) {
                          return AddDOI({
                            variables: {
                              from: { id: Well.id },
                              to: { id: newData.Tract.id },
                              data: pushData,
                            },
                          })
                            .then(({ data: { AddWellDivisionOrder } }) => {
                              remove(oldData.tableData.id);
                              insert(oldData.tableData.id, {
                                ...pushData,
                                Tract: { id: newData.Tract.id },
                                mesDOI,
                                mpiDOI,
                                sroDOI,
                              });
                            })
                            .catch((error) => console.log(error));
                        } else {
                          return RemoveDOI({
                            variables: {
                              from: { id: Well.id },
                              to: { id: oldData.Tract.id },
                            },
                          }).catch((error) => console.log(error));
                          return AddDOI({
                            variables: {
                              from: { id: Well.id },
                              to: { id: newData.Tract.id },
                              data: pushData,
                            },
                          })
                            .then(({ data: { AddWellDivisionOrder } }) => {
                              remove(oldData.tableData.id);
                              insert(oldData.tableData.id, {
                                ...pushData,
                                Tract: { id: newData.Tract.id },
                                mesDOI,
                                mpiDOI,
                                sroDOI,
                              });
                            })
                            .catch((error) => console.log(error));
                        }
                      },
                      onRowDelete: (oldData) => {
                        return RemoveDOI({
                          variables: { from: { id: Well.id }, to: { id: oldData.Tract.id } },
                        })
                          .then(({ data: { RemoveWellDivisionOrder } }) => {
                            remove(oldData.tableData.id);
                            insert(oldData.tableData.id, {
                              Tract: { id: oldData.Tract.id },
                              id: oldData.id,
                              platTract: '',
                              platTractAcres: '',
                              wellUnitAcres: '',
                              mineralOwnership: '',
                              royaltyRate: '',
                              familyAcres: '',
                              totalDOI: '',
                            });
                          })
                          .catch((error) => console.log(error));
                      },
                    }}
                  />
                );
              }}
            </FieldArray>
            <div style={{ paddingTop: 20 }}>
              <Paper>
                <Table>
                  <TableHead style={{ background: '#1976d2' }}>
                    <TableRow>
                      <TableCell style={{ color: 'white' }}>Total DOI</TableCell>
                      <TableCell style={{ color: 'white' }}>Total Family Acres</TableCell>
                      <TableCell style={{ color: 'white' }}>Total MES DOI</TableCell>
                      <TableCell style={{ color: 'white' }}>Total MPI DOI</TableCell>
                      <TableCell style={{ color: 'white' }}>Total SRO DOI</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{doiTotal}</TableCell>
                      <TableCell>{familyTotal}</TableCell>
                      <TableCell>{mesDOI}</TableCell>
                      <TableCell>{mpiDOI}</TableCell>
                      <TableCell>{sroDOI}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
            <Field
              name='divisionOrderNotes'
              placeholder='Notes'
              label='Notes'
              component={TextField}
              multiline={true}
              rows={4}
              variant='outlined'
              className={classes.FullWidthTextBox}
              fullWidth
            />
            <div>
              <Button variant='contained' color='primary' type='submit' fullWidth>
                Update Notes
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DivisionOrder;
