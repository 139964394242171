import React from 'react';
import { Map, Marker, Popup, TileLayer, Polyline, Polygon, Tooltip } from 'react-leaflet';
import { makeStyles } from '@material-ui/styles';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@material-ui/core';
import EditTract from '../tracts/editTract';

const createMapStyles = makeStyles((theme) => ({
  Map: {
    marginTop: 20,
    marginBottom: 20,
    height: 600,
    width: '100%',
  },
  Display: {
    display: 'none',
  },
}));

const TractMapCard = ({ Tract, geometry, name }) => {
  const MapStyles = createMapStyles();

  const swap = (piece) => {
    const [a, b] = piece;
    return [b, a];
  };

  let jsonGeo = null;

  try {
    jsonGeo = JSON.parse(geometry);
  } catch (e) {
    console.log('Could not parse GeoJSON!');
    return null;
  }

  const newGeo = jsonGeo.map((i) => swap(i));

  const polygon = [newGeo];

  const tractLocation = (
    <Polygon color='blue' positions={polygon}>
      <Tooltip>{name}</Tooltip>
    </Polygon>
  );

  return (
    <Map center={newGeo[1]} zoom={12} className={MapStyles.Map}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {tractLocation}
    </Map>
  );

  // return (
  //   <>
  //     <Formik
  //       initialValues={Tract}
  //       onSubmit={(values, actions) => {
  //         return EditTract({ variables: {} });
  //       }}
  //     >
  //       <Form>
  //         <Field
  //           name='geometry'
  //           component={TextField}
  //           label='Geometry'
  //           fullWidth
  //           multiline
  //           variant='outlined'
  //           rows={4}
  //         />
  //         <Button variant='contained' color='primary'>
  //           Submit
  //         </Button>
  //       </Form>
  //     </Formik>
  //     <Map center={newGeo[0]} zoom={14} className={MapStyles.Map}>
  //       <TileLayer
  //         attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  //         url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  //       />
  //       {tractLocation}
  //     </Map>
  //   </>
  // );
};

export default TractMapCard;
