import gql from 'graphql-tag';

const defaultDateStr = '';

export const DefaultWell = {
  apiNumber: '',
  name: '',
  notes: '',
  type: '',
  drillType: '',
  rrcLeaseNumber: '',
  permitNumber: '',
  permitDate: '',
  landId: '',
  abstract: '',
  status: '',
  spudDate: defaultDateStr,
  rigReleaseDate: defaultDateStr,
  completionDate: defaultDateStr,
  dateProducing: defaultDateStr,
  dateInPay: defaultDateStr,
  OGL: '',
  location: '',
  decProdUnit: '',
  producingFormation: '',
  lengthOfLateral: '',
  rrcField: '',
  oilPayor: '',
  gasPayor: '',
  operator: '',
  familyID: '',
  mpiDOI: 0,
  mesDOI: 0,
  sroDOI: 0,
  inService: false,
  inactive: false,
  operatorInterval: '',
  upperPerf: '0',
  lowerPerf: '0',
  perfLength: '0',
  drilledOn: [],
  operatedBy: { id: '' },
};

export const WellFragment = `
  id
  apiNumber
  name
  notes
  landId
  type
  rrcLeaseNumber
  permitNumber
  permitDate
  abstract
  status
  spudDate
  rigReleaseDate
  completionDate
  dateProducing
  dateInPay
  OGL
  location
  dpu
  decProdUnit
  producingFormation
  lengthOfLateral
  rrcField
  oilPayor
  gasPayor
  operator
  familyID
  mpiDOI
  mesDOI
  sroDOI
  inService
  inactive
  lowerPerf
  perfLength
  upperPerf
  location
  latitude
  longitude
  latitudeBottom
  longitudeBottom
  drillType
  operatorInterval
  rrcLeaseHistory
  operatorHistory
  state
  county
  trueVerticalDepth
  totalDepth
  plat
  g1
  p4
  p12
  p16
  p17
  fracFocus
  doto
  completionApp
  mesDivOrder
  mpiDivOrder
  divisionOrderNotes 
  titleOpinion
  platURL
  totalDOI
  drilledOn {
    name
    state
    county
    id
    _id
  }
  operatedBy {
    id
    name
    _id
    wellCount
    swdCount
  }
  unit {
    id
    name
  }
  divisionOrder {
    id
  }
`;

export const WELLS_QUERY = gql`
  query {
    Well {
      _id
      id
      apiNumber
      name
      notes
      landId
      type
      rrcLeaseNumber
      permitNumber
      permitDate
      abstract
      status
      landId
      spudDate
      rigReleaseDate
      completionDate
      dateProducing
      dateInPay
      OGL
      location
      dpu
      decProdUnit
      producingFormation
      lengthOfLateral
      rrcField
      oilPayor
      gasPayor
      operator
      familyID
      mpiDOI
      mesDOI
      sroDOI
      inService
      inactive
      lowerPerf
      perfLength
      upperPerf
      location
      latitude
      latitudeBottom
      longitude
      longitudeBottom
      totalDepth
      trueVerticalDepth
      drillType
      operatorInterval
      operatorHistory
      rrcLeaseHistory
      state
      county
      divisionOrderNotes
      drilledOn {
        name
        state
        county
        id
        _id
      }
      operatedBy {
        id
        name
        _id
      }
      unit {
        id
        name
      }
    }
  }
`;

export const GET_WELL = gql`
  query GetWell($id: ID!) {
    Well(id: $id) {
      _id
      id
      apiNumber
      name
      notes
      landId
      type
      rrcLeaseNumber
      permitNumber
      permitDate
      abstract
      status
      spudDate
      rigReleaseDate
      completionDate
      dateProducing
      dateInPay
      OGL
      location
      dpu
      decProdUnit
      producingFormation
      lengthOfLateral
      rrcField
      oilPayor
      gasPayor
      operator
      familyID
      mpiDOI
      mesDOI
      sroDOI
      inService
      inactive
      lowerPerf
      perfLength
      upperPerf
      location
      latitude
      longitude
      latitudeBottom
      longitudeBottom
      drillType
      operatorInterval
      rrcLeaseHistory
      operatorHistory
      state
      county
      trueVerticalDepth
      totalDepth
      plat
      g1
      p4
      p12
      p16
      p17
      fracFocus
      doto
      completionApp
      mesDivOrder
      mpiDivOrder
      location
      totalDOI
      lease {
        id
        _id
        leaseName
      }
      drilledOn {
        name
        state
        county
        id
        _id
      }
      divisionOrder {
        id
        mesDOI
        sroDOI
        mpiDOI
      }
      operatedBy {
        id
        name
        _id
        wellCount
        swdCount
        operatorNumber
      }
      unit {
        id
        name
        unitGMA
        propertyDesc
      }
    }
  }
`;

export const EDIT_WELL = gql`
  mutation UpdateWell(
    $id: ID!
    $apiNumber: String!
    $name: String
    $notes: String
    $type: String
    $rrcLeaseNumber: String
    $permitNumber: String
    $permitDate: String
    $abstract: String
    $status: String
    $spudDate: String
    $rigReleaseDate: String
    $completionDate: String
    $dateProducing: String
    $dateInPay: String
    $OGL: String
    $location: String
    $decProdUnit: String
    $dpu: String
    $producingFormation: String
    $lengthOfLateral: String
    $rrcField: String
    $oilPayor: String
    $gasPayor: String
    $operator: String
    $familyID: String
    $mpiDOI: String
    $mesDOI: String
    $sroDOI: String
    $inService: Boolean
    $inactive: Boolean
    $drillType: String
    $operatorInterval: String
    $upperPerf: String
    $lowerPerf: String
    $perfLength: String
    $operatorHistory: String
    $rrcLeaseHistory: String
    $longitude: String
    $latitude: String
    $longitudeBottom: String
    $latitudeBottom: String
    $state: String
    $county: String
    $trueVerticalDepth: String
    $totalDepth: String
    $landId: String
    $plat: String
    $g1: String
    $p4: String
    $p12: String
    $p16: String
    $p17: String
    $fracFocus: String
    $doto: String
    $completionApp: String
    $mesDivOrder: String
    $mpiDivOrder: String
    $divisionOrderNotes: String
    $titleOpinion: String
    $platURL: String
  ) {
    UpdateWell(
      id: $id
      apiNumber: $apiNumber
      name: $name
      notes: $notes
      type: $type
      rrcLeaseNumber: $rrcLeaseNumber
      permitNumber: $permitNumber
      permitDate: $permitDate
      abstract: $abstract
      status: $status
      spudDate: $spudDate
      rigReleaseDate: $rigReleaseDate
      completionDate: $completionDate
      dateProducing: $dateProducing
      dateInPay: $dateInPay
      OGL: $OGL
      location: $location
      decProdUnit: $decProdUnit
      dpu: $dpu
      producingFormation: $producingFormation
      lengthOfLateral: $lengthOfLateral
      rrcField: $rrcField
      oilPayor: $oilPayor
      gasPayor: $gasPayor
      operator: $operator
      familyID: $familyID
      mpiDOI: $mpiDOI
      mesDOI: $mesDOI
      sroDOI: $sroDOI
      inService: $inService
      inactive: $inactive
      drillType: $drillType
      operatorInterval: $operatorInterval
      upperPerf: $upperPerf
      lowerPerf: $lowerPerf
      perfLength: $perfLength
      operatorHistory: $operatorHistory
      rrcLeaseHistory: $rrcLeaseHistory
      latitude: $latitude
      longitude: $longitude
      latitudeBottom: $latitudeBottom
      longitudeBottom: $longitudeBottom
      state: $state
      county: $county
      trueVerticalDepth: $trueVerticalDepth
      totalDepth: $totalDepth
      landId: $landId
      plat: $plat
      g1: $g1
      p4: $p4
      p12: $p12
      p16: $p16
      p17: $p17
      fracFocus: $fracFocus
      doto: $doto
      completionApp: $completionApp
      mesDivOrder: $mesDivOrder
      mpiDivOrder: $mpiDivOrder
      divisionOrderNotes: $divisionOrderNotes
      titleOpinion: $titleOpinion
      platURL: $platURL
    ) {
      ${WellFragment}
    }
  }
`;

export const ADD_WELL = gql`
  mutation CreateWell(
    $id: ID
    $apiNumber: String!
    $name: String!
    $notes: String
    $type: String
    $rrcLeaseNumber: String
    $permitNumber: String
    $permitDate: String
    $abstract: String!
    $status: String
    $spudDate: String
    $rigReleaseDate: String
    $completionDate: String
    $dateProducing: String
    $dateInPay: String
    $OGL: String
    $location: String
    $decProdUnit: String
    $dpu: String
    $producingFormation: String
    $lengthOfLateral: String
    $rrcField: String
    $oilPayor: String
    $gasPayor: String
    $operator: String
    $familyID: String
    $mpiDOI: String
    $mesDOI: String
    $sroDOI: String
    $inService: Boolean
    $inactive: Boolean
    $drillType: String
    $operatorInterval: String
    $upperPerf: String
    $lowerPerf: String
    $perfLength: String
    $operatorHistory: String
    $rrcLeaseHistory: String
    $longitude: String
    $latitude: String
    $longitudeBottom: String
    $latitudeBottom: String
    $state: String
    $county: String
    $trueVerticalDepth: String
    $totalDepth: String
    $plat: String
    $g1: String
    $p4: String
    $p12: String
    $p16: String
    $p17: String
    $fracFocus: String
    $doto: String
    $completionApp: String
    $mesDivOrder: String
    $mpiDivOrder: String
  ) {
    CreateWell(
      id: $id
      apiNumber: $apiNumber
      name: $name
      notes: $notes
      type: $type
      rrcLeaseNumber: $rrcLeaseNumber
      permitNumber: $permitNumber
      permitDate: $permitDate
      abstract: $abstract
      status: $status
      spudDate: $spudDate
      rigReleaseDate: $rigReleaseDate
      completionDate: $completionDate
      dateProducing: $dateProducing
      dateInPay: $dateInPay
      OGL: $OGL
      location: $location
      decProdUnit: $decProdUnit
      dpu: $dpu
      producingFormation: $producingFormation
      lengthOfLateral: $lengthOfLateral
      rrcField: $rrcField
      oilPayor: $oilPayor
      gasPayor: $gasPayor
      operator: $operator
      familyID: $familyID
      mpiDOI: $mpiDOI
      mesDOI: $mesDOI
      sroDOI: $sroDOI
      inService: $inService
      inactive: $inactive
      drillType: $drillType
      operatorInterval: $operatorInterval
      upperPerf: $upperPerf
      lowerPerf: $lowerPerf
      perfLength: $perfLength
      operatorHistory: $operatorHistory
      rrcLeaseHistory: $rrcLeaseHistory
      latitude: $latitude
      longitude: $longitude
      latitudeBottom: $latitudeBottom
      longitudeBottom: $longitudeBottom
      state: $state
      county: $county
      trueVerticalDepth: $trueVerticalDepth
      totalDepth: $totalDepth
      plat: $plat
      g1: $g1
      p4: $p4
      p12: $p12
      p16: $p16
      p17: $p17
      fracFocus: $fracFocus
      doto: $doto
      completionApp: $completionApp
      mesDivOrder: $mesDivOrder
      mpiDivOrder: $mpiDivOrder
    ) {
      _id
      id
      apiNumber
      name
      notes
      landId
      type
      rrcLeaseNumber
      permitNumber
      permitDate
      abstract
      status
      spudDate
      rigReleaseDate
      completionDate
      dateProducing
      dateInPay
      OGL
      location
      dpu
      decProdUnit
      producingFormation
      lengthOfLateral
      rrcField
      oilPayor
      gasPayor
      operator
      familyID
      mpiDOI
      mesDOI
      sroDOI
      inService
      inactive
      lowerPerf
      perfLength
      upperPerf
      location
      latitude
      longitude
      latitudeBottom
      longitudeBottom
      drillType
      operatorInterval
      rrcLeaseHistory
      operatorHistory
      state
      county
      trueVerticalDepth
      totalDepth
      plat
      g1
      p4
      p12
      p16
      p17
      fracFocus
      doto
      completionApp
      mesDivOrder
      mpiDivOrder
      location
      drilledOn {
        name
        state
        county
        id
        _id
      }
      operatedBy {
        id
        name
        _id
        wellCount
        swdCount
      }
      unit {
        id
        name
      }
    }
  }
`;

export const wellCardQuery = gql`
  query {
    Well {
      name
      id
      apiNumber
      inService
      drilledOn {
        name
        state
        county
        id
      }
      operatedBy {
        name
        id
      }
    }
  }
`;

export const WELL_AUTOCOMPLETE = gql`
  query {
    Well(inactive: false) {
      name
      id
    }
  }
`;

export const ADD_WELL_DRILLED_ON = gql`
  mutation AddWellDrilledOn($from: _WellInput!, $to: _TractInput!) {
    AddWellDrilledOn(from: $from, to: $to) {
      from {
        id
        name
      }
      to {
        id
        name
      }
    }
  }
`;

export const REMOVE_WELL_DRILLED_ON = gql`
  mutation RemoveWellDrilledOn($from: _WellInput!, $to: _TractInput!) {
    RemoveWellDrilledOn(from: $from, to: $to) {
      from {
        id
        name
      }
      to {
        id
        name
      }
    }
  }
`;

export const DELETE_WELL = gql`
  mutation DeleteWell($id: ID!) {
    DeleteWell(id: $id) {
      id
      inactive
      inService
    }
  }
`;

export const GET_DI_WELL = gql`
  query GetDIWell($api: String!) {
    getWell(api: $api) {
      type
      drillType
      rrcLeaseNumber
      abstract
      inService
      spudDate
      completionDate
      producingFormation
      upperPerf
      lowerPerf
      perfLength
      lengthOfLateral
      rrcField
      operator
      longitude
      latitude
      longitudeBottom
      latitudeBottom
      totalDepth
      trueVerticalDepth
      permitDate
      permitNumber
      state
      county
    }
  }
`;

export const GET_INACTIVE_WELLS = gql`
  query {
    Well(inactive: true) {
      _id
      id
      apiNumber
      name
      notes
      landId
      type
      rrcLeaseNumber
      permitNumber
      permitDate
      abstract
      status
      landId
      spudDate
      rigReleaseDate
      completionDate
      dateProducing
      dateInPay
      OGL
      location
      dpu
      decProdUnit
      producingFormation
      lengthOfLateral
      rrcField
      oilPayor
      gasPayor
      operator
      familyID
      mpiDOI
      mesDOI
      sroDOI
      inService
      lowerPerf
      perfLength
      upperPerf
      location
      latitude
      latitudeBottom
      longitude
      longitudeBottom
      totalDepth
      trueVerticalDepth
      drillType
      operatorInterval
      operatorHistory
      rrcLeaseHistory
      trueVerticalDepth
      totalDepth
      drilledOn {
        name
        state
        county
        id
        _id
      }
      operatedBy {
        id
        name
        _id
        wellCount
        swdCount
      }
      unit {
        _id
        id
        name
      }
    }
  }
`;

export const GET_INPROGRESS_WELLS = gql`
  query {
    Well(status: "In Progress", orderBy: name_asc) {
      _id
      id
      apiNumber
      name
      notes
      landId
      type
      rrcLeaseNumber
      permitNumber
      permitDate
      abstract
      status
      landId
      spudDate
      rigReleaseDate
      completionDate
      dateProducing
      dateInPay
      OGL
      location
      dpu
      decProdUnit
      producingFormation
      lengthOfLateral
      rrcField
      oilPayor
      gasPayor
      operator
      familyID
      mpiDOI
      mesDOI
      sroDOI
      inService
      lowerPerf
      perfLength
      upperPerf
      location
      latitude
      latitudeBottom
      longitude
      longitudeBottom
      totalDepth
      trueVerticalDepth
      drillType
      operatorInterval
      operatorHistory
      rrcLeaseHistory
      state
      county
      drilledOn {
        name
        state
        county
        id
        _id
      }
      operatedBy {
        id
        name
        _id
        wellCount
        swdCount
      }
      unit {
        _id
        id
        name
      }
      divisionOrder {
        id
        mesDOI
        sroDOI
        mpiDOI
      }
    }
  }
`;

export const GET_ACTIVE_WELLS = gql`
  query {
    Well(orderBy: name_asc) {
      _id
      id
      apiNumber
      name
      notes
      landId
      type
      rrcLeaseNumber
      permitNumber
      permitDate
      abstract
      status
      landId
      spudDate
      rigReleaseDate
      completionDate
      dateProducing
      dateInPay
      OGL
      location
      dpu
      decProdUnit
      producingFormation
      lengthOfLateral
      rrcField
      oilPayor
      gasPayor
      operator
      familyID
      mpiDOI
      mesDOI
      sroDOI
      inService
      lowerPerf
      perfLength
      upperPerf
      location
      latitude
      latitudeBottom
      longitude
      longitudeBottom
      totalDepth
      trueVerticalDepth
      drillType
      operatorInterval
      operatorHistory
      rrcLeaseHistory
      state
      county
      lease {
        _id
        id
        leaseName
      }
      divisionOrder {
        mesDOI
        mpiDOI
        sroDOI
      }
      drilledOn {
        name
        state
        county
        id
        _id
      }
      operatedBy {
        id
        name
        _id
        wellCount
        swdCount
      }
      unit {
        _id
        id
        name
      }
    }
  }
`;

export const ADD_WELL_OPERATED_BY = gql`
  mutation AddWellOperatedBy($to: _WellInput!, $from: _OperatorInput!) {
    AddWellOperatedBy(to: $to, from: $from) {
      from {
        id
        name
        swdCount
        wellCount
      }
      to {
        id
        name
      }
    }
  }
`;

export const UPDATE_WELL_OPERATED_BY = gql`
  mutation UpdateWellOperatedBy($wellId: String, $operatorId: String) {
    setNewOperator(wellId: $wellId, operatorId: $operatorId) {
      _id
      id
      name
      operatedBy {
        id
        name
        wellCount
        swdCount
      }
    }
  }
`;

// TODO: Return updated well with updated drilledOn tracts rather than the drilledOn tracts themselves
export const UPDATE_WELL_DRILLED_ON = gql`
  mutation UpdateWellDrilledOn($wellId: String, $newDrills: [String]) {
    setNewDrilledOn(wellId: $wellId, newDrills: $newDrills) {
      id
      name
      county
      state
    }
  }
`;

export const WELL_PRODUCTION_QUERY = gql`
  query {
    Well(status: "Active") {
      _id
      name
      county
      id
      productionCount
      operatedBy {
        _id
        id
        name
      }
      drilledOn {
        id
      }
    }
  }
`;

export const ADD_DIVISION_ORDER = gql`
  mutation AddWellDivisionOrder($from: _WellInput!, $to: _TractInput!, $data: _DOIInput!) {
    AddWellDivisionOrder(from: $from, to: $to, data: $data) {
      from {
        id
      }
      to {
        id
      }
      id
      platTract
      platTractAcres
      wellUnitAcres
      mineralOwnership
      royaltyRate
      totalDOI
      familyAcres
    }
  }
`;

export const REMOVE_DIVISION_ORDER = gql`
  mutation RemoveWellDivisonOrder($from: _WellInput!, $to: _TractInput!) {
    RemoveWellDivisionOrder(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;
