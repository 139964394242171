import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  CLONE_LEASE_ASSIGNMENTS,
  CLONE_LEASE_CLAUSES,
  CLONE_LEASE_INTEREST,
  CLONE_LEASE_RECORDINGS,
  CREATE_LEASE,
  GET_ALL_LEASES,
  GET_LEASE,
} from '../../components/leases/LeaseQueries';
import LoadingView from '../../components/LoadingView';
import { Field, FieldArray, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Auth from '../../util/Auth';
import { makeStyles } from '@material-ui/styles';
import { v4 } from 'uuid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LEASE);

const tableStyles = makeStyles((theme) => ({
  Switch: {
    margin: 0,
  },
  BottomBar: {
    display: hasPermissions ? 'flex' : 'none',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  SubmitButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    margin: 10,
  },
  DeleteButton: {
    display: hasPermissions ? 'inline-block' : 'none',
    maxWidth: '200px',
    maxHeight: '50px',
    minWidth: '200px',
    float: 'right',
  },
  FormControlLabel: {
    paddingRight: '5em',
  },
  Select: {
    display: 'block',
  },
  FullWidthTextBox: {
    marginBottom: 20,
    marginTop: 20,
  },
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
  },
  landsTable: {
    display: 'none',
  },
}));

const CloneLease = ({ match, history }) => {
  const [cloneID, setCloneID] = React.useState('');
  const [landsSelectable, setLandsSelectable] = React.useState(false);
  const [recordingsSelectable, setRecordingsSelectable] = React.useState(false);
  const [assigneesSelectable, setAssigneesSelectable] = React.useState(false);
  const [leaseCloned, setLeaseCloned] = React.useState(false);
  const [clones, setClones] = React.useState({});
  const [assignmentClones, setAssignmentClones] = React.useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [interestClone] = useMutation(CLONE_LEASE_INTEREST);
  const [recordsClone] = useMutation(CLONE_LEASE_RECORDINGS);
  const [assignmentsClone] = useMutation(CLONE_LEASE_ASSIGNMENTS);
  const [cloneClauses] = useMutation(CLONE_LEASE_CLAUSES);

  const [AddLease] = useMutation(CREATE_LEASE);

  const cloneLeaseClauses = (values) => {
    return cloneClauses({
      variables: values,
    });
  };

  const cloneLeaseInterest = (values) => {
    return interestClone({
      variables: values,
    });
  };

  const cloneLeaseRecordings = (values) => {
    return recordsClone({
      variables: values,
    });
  };

  const cloneLeaseAssignments = (values) => {
    return assignmentsClone({
      variables: values,
    });
  };

  const classes = tableStyles();

  const { data, loading, error } = useQuery(GET_LEASE, {
    variables: {
      id: match.params.leaseID,
    },
  });

  if (loading) {
    return <LoadingView />;
  } else if (error) {
    console.log(error);
  }

  const Lease = data.Lease[0];

  const newID = v4();

  return (
    <div>
      <Formik
        initialValues={Lease}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          const cloneLeaseValues = {
            ...values,
            id: newID,
            template: false,
          };
          return AddLease({
            variables: cloneLeaseValues,
            update: (store, { data: { CreateLease } }) => {
              setCloneID(CreateLease.id);
              console.log(CreateLease);
              try {
                const AllLeasesQuery = store.readQuery({ query: GET_ALL_LEASES });

                const data = { Lease: [...AllLeasesQuery.Lease, CreateLease] };

                store.writeQuery({ query: GET_ALL_LEASES, data: data });
              } catch (e) {
                console.log('Could not add lease to all leases query due to it not being run yet');
              }
            },
          })
            .then(function() {
              return cloneLeaseClauses({ leaseID: newID, clauses: Lease.leaseClauses.map((c) => c.id) });
            })
            .then(
              (data) => {
                enqueueSnackbar('Lease Successfully Cloned', { variant: 'success' });
                actions.setSubmitting(false);
                actions.setFieldValue('id', cloneLeaseValues.id);
                setLeaseCloned(true);
                closeSnackbar();
              },
              (error) => {
                enqueueSnackbar(`${error}`, { variant: 'error' });
                actions.setSubmitting(false);
                closeSnackbar();
              }
            );
        }}
      >
        {(props) => {
          return (
            <Form>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                Clone Lease
              </Typography>
              <div style={{ display: 'Flex' }}>
                <Field
                  name='leaseName'
                  placeholder='id'
                  label='id'
                  component={TextField}
                  className={classes.TextField}
                  style={{ width: 300 }}
                  fullWidth
                />
                <Field
                  name='currentLessee'
                  placeholder='Current Lessee'
                  label='Current Lessee'
                  component={TextField}
                  className={classes.TextField}
                  style={{ width: 350 }}
                  fullWidth
                />
              </div>
              <div style={{ display: 'Flex' }}>
                <Field
                  name='grossAcres'
                  placeholder='Gross Acres'
                  label='Gross Acres'
                  component={TextField}
                  className={classes.TextField}
                />
                <Field
                  name='netMineralAcres'
                  placeholder='Net Mineral Acres'
                  label='Net Mineral Acres'
                  component={TextField}
                  className={classes.TextField}
                />
                <Field
                  name='netSurfaceAcres'
                  placeholder='Net Surface Acres'
                  label='Net Surface Acres'
                  component={TextField}
                  className={classes.TextField}
                />
                <Field
                  name='heldByProductionAcres'
                  placeholder='HBP Acres'
                  label='HBP Acres'
                  component={TextField}
                  className={classes.TextField}
                />
              </div>
              <div>
                <Field
                  name='status'
                  placeholder='Status'
                  label='Status'
                  component={TextField}
                  className={classes.TextField}
                />
              </div>
              <div>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.SubmitButton}
                  disabled={Lease.leaseName === props.values.leaseName || leaseCloned}
                  style={{ display: hasPermissions ? 'inline-block' : 'none' }}
                  onClick={() => {
                    props.handleSubmit();
                    setLandsSelectable(true);
                  }}
                  fullWidth
                >
                  Clone Lease
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  disabled={leaseCloned}
                  style={{ display: hasPermissions ? 'inline-block' : 'none' }}
                  className={classes.SubmitButton}
                  onClick={() => history.push('/Leases')}
                  fullWidth
                >
                  Cancel
                </Button>
              </div>
              <hr />
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                Leased Lands
              </Typography>
              <FieldArray name='leasedLands'>
                {({ form }) => {
                  const columns = [
                    {
                      title: 'Land ID',
                      field: 'Tract.id',
                      render: (rowData) => <Link to={`/lands/${rowData.Tract.id}`}>{rowData.Tract.id}</Link>,
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'NMA',
                      field: 'netMineralAcres',
                    },
                    {
                      title: 'NSA',
                      field: 'netSurfaceAcres',
                    },
                    {
                      title: 'Depths',
                      field: 'depths',
                    },
                    {
                      title: 'Description',
                      field: 'shortDescription',
                    },
                  ];
                  return (
                    <MaterialTable
                      columns={columns}
                      data={props.values.leasedLands}
                      title={''}
                      isLoading={form.isSubmitting}
                      options={{
                        headerStyle: {
                          backgroundColor: '#1976d2',
                          color: '#FFF',
                          textAlign: 'center',
                        },
                        cellStyle: {
                          maxWidth: 0,
                          overFlowX: 'auto',
                        },
                        search: false,
                        pageSize: props.values.leasedLands.length,
                        selection: landsSelectable,
                      }}
                      components={{
                        Pagination: (props) => {
                          return (
                            <td>
                              <Button
                                variant='contained'
                                color='primary'
                                className={classes.SubmitButton}
                                disabled={!landsSelectable}
                                onClick={() => {
                                  form.setSubmitting(true);
                                  setLandsSelectable(false);
                                  return cloneLeaseInterest(clones).then(
                                    (data) => {
                                      enqueueSnackbar('Lands Successfully Cloned', { variant: 'success' });
                                      form.setSubmitting(false);
                                      setRecordingsSelectable(true);
                                      setLandsSelectable(false);
                                    },
                                    (error) => {
                                      enqueueSnackbar(`${error}`, { variant: 'error' });
                                      form.setSubmitting(false);
                                      setLandsSelectable(true);
                                    }
                                  );
                                }}
                              >
                                Submit Leased Lands
                              </Button>
                            </td>
                          );
                        },
                      }}
                      onSelectionChange={(rows) => {
                        const landClones = rows.map((land) => ({
                          netSurfaceAcres: land.netSurfaceAcres,
                          netMineralAcres: land.netMineralAcres,
                          depths: land.depths,
                          shortDescription: land.shortDescription,
                          tractID: land.Tract.id,
                        }));
                        setClones({
                          leaseID: props.values.id,
                          relationships: landClones,
                        });
                      }}
                    />
                  );
                }}
              </FieldArray>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                Recordings
              </Typography>
              <FieldArray name='leaseRecordings'>
                {({ form }) => {
                  console.log(form.values.leaseRecordings);
                  const columns = [
                    {
                      title: 'Vol/Page',
                      field: 'volPage',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Book',
                      field: 'book',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'County',
                      field: 'county',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Effective Date',
                      field: 'effectiveDate',
                      render: (rowData) => moment(rowData.effectiveDate).format('MM/DD/YYYY'),
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Date Filed',
                      field: 'dateFiled',
                      render: (rowData) => moment(rowData.dateFiled).format('MM/DD/YYYY'),
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Document name',
                      field: 'documentName',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Notes',
                      field: 'notes',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                  ];

                  return (
                    <MaterialTable
                      columns={columns}
                      data={props.values.leaseRecordings}
                      title={''}
                      options={{
                        headerStyle: {
                          backgroundColor: '#1976d2',
                          color: '#FFF',
                          textAlign: 'center',
                        },
                        cellStyle: {
                          maxWidth: 0,
                          overFlowX: 'auto',
                        },
                        search: false,
                        pageSize: props.values.leaseRecordings.length,
                        selection: recordingsSelectable,
                      }}
                      components={{
                        Pagination: (props) => {
                          return (
                            <td>
                              <Button
                                variant='contained'
                                color='primary'
                                className={classes.SubmitButton}
                                disabled={!recordingsSelectable}
                                onClick={() => {
                                  form.setSubmitting(true);
                                  setRecordingsSelectable(false);
                                  return Promise.all(clones.map((clone) => cloneLeaseRecordings(clone))).then(
                                    (data) => {
                                      enqueueSnackbar('Recordings Successfully Cloned', { variant: 'success' });
                                      form.setSubmitting(false);
                                      setAssigneesSelectable(true);
                                    },
                                    (error) => {
                                      enqueueSnackbar(`${error}`, { variant: 'error' });
                                      form.setSubmitting(false);
                                      setRecordingsSelectable(true);
                                    }
                                  );
                                }}
                              >
                                Submit Lease Recordings
                              </Button>
                            </td>
                          );
                        },
                      }}
                      onSelectionChange={(rows) => {
                        setClones(
                          rows.map((row) => ({
                            leaseID: props.values.id,
                            recordingID: row.id,
                            grantorID: row.grantor.id,
                            granteeID: row.grantee.id,
                            recordingInterest: row.recordingInterest.map((a) => ({
                              TractID: a.Tract.id,
                              acres: a.acres,
                            })),
                          }))
                        );
                      }}
                    />
                  );
                }}
              </FieldArray>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                Assignments
              </Typography>
              <FieldArray name='assignments'>
                {({ form }) => {
                  const columns = [
                    {
                      title: 'Vol/Page',
                      field: 'volPage',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'Book',
                      field: 'book',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'Effective Date',
                      field: 'effectiveDate',
                      render: (rowData) => moment(rowData.effectiveDate).format('MM/DD/YYYY'),
                      customSort: (a, b) => (moment(a.effectiveDate).isAfter(moment(b.effectiveDate)) ? 1 : -1),
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'Date Filed',
                      field: 'dateFiled',
                      render: (rowData) => moment(rowData.dateFiled).format('MM/DD/YYYY'),
                      customSort: (a, b) => (moment(a.dateFiled).isAfter(moment(b.dateFiled)) ? 1 : -1),
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'Doc Type',
                      field: 'docType',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'Grantor',
                      field: 'grantor.name',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                    {
                      title: 'Grantee',
                      field: 'grantee.name',
                      editable: 'onAdd',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                      },
                    },
                  ];

                  return (
                    <MaterialTable
                      columns={columns}
                      data={props.values.assignments}
                      options={{
                        headerStyle: {
                          backgroundColor: '#1976d2',
                          color: '#FFF',
                          textAlign: 'left',
                          whiteSpace: 'nowrap',
                        },
                        cellStyle: {
                          maxWidth: 0,
                          overFlowX: 'auto',
                        },
                        toolbarButtonAlignment: 'left',
                        pageSize: props.values.assignments.length,
                        search: false,
                        selection: assigneesSelectable,
                      }}
                      title={''}
                      components={{
                        Pagination: (props) => {
                          return (
                            <td>
                              <Button
                                variant='contained'
                                color='primary'
                                className={classes.SubmitButton}
                                disabled={!assigneesSelectable}
                                onClick={() => {
                                  form.setSubmitting(true);
                                  setAssigneesSelectable(false);
                                  return Promise.all(assignmentClones.map((ac) => cloneLeaseAssignments(ac))).then(
                                    (data) => {
                                      enqueueSnackbar('AssignmentSuccessfully Cloned', { variant: 'success' });
                                      form.setSubmitting(false);
                                      setAssigneesSelectable(false);
                                      history.push(`/Leases/${cloneID}`);
                                    },
                                    (error) => {
                                      enqueueSnackbar(`${error}`, { variant: 'error' });
                                      form.setSubmitting(false);
                                      setAssigneesSelectable(true);
                                    }
                                  );
                                }}
                              >
                                Submit Assignments
                              </Button>
                            </td>
                          );
                        },
                      }}
                      onSelectionChange={(rows) => {
                        setAssignmentClones(
                          rows.map((row) => ({
                            leaseID: props.values.id,
                            assignmentID: row.id,
                            grantorID: row.grantor.id,
                            granteeID: row.grantee.id,
                            assignmentInterest: row.assignmentInterests.map((a) => ({
                              TractID: a.Tract.id,
                              acres: a.acres,
                            })),
                          }))
                        );
                      }}
                    />
                  );
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CloneLease;
