import React from 'react';
import Auth from '../../../util/Auth';
import { Route, Redirect } from 'react-router-dom';

const RoleProtectedRoute = ({ component: Component, requiredRoles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.hasRoles(requiredRoles) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default RoleProtectedRoute;
