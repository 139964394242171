import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Auth from '../../util/Auth';
import { Button } from '@material-ui/core';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LANDS);

const TableStyles = makeStyles((theme) => ({
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '10px',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
  },
}));

const LandMapandLegal = ({ Tract, EditTract, setNotification, mapType }) => {
  const classes = TableStyles();

  return (
    <>
      <Formik
        initialValues={Tract}
        onSubmit={(values, actions) => {
          const mapUpdate = {
            ...Tract,
          };
          mapUpdate[mapType] = values[mapType];
          actions.setSubmitting(true);
          return EditTract({
            variables: mapUpdate,
          })
            .then(() => {
              setNotification('Successfully edited Land!', { variant: 'success' });
              actions.setSubmitting(false);
            })
            .catch((err) => {
              console.error(err);
              setNotification({ err }, { variant: 'error' });
              actions.setSubmitting(false);
            });
        }}
      >
        {(props) => {
          return (
            <Form className={classes.form}>
              <Field
                name={mapType}
                placeholder='Image URL'
                label='Image URL'
                component={TextField}
                className={classes.TextField}
                fullWidth
              />
              <div>
                <Button variant='contained' color='primary' type='submit' style={{ margin: 20 }}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default LandMapandLegal;
