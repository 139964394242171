import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext} from "@apollo/client/link/context";
import { createUploadLink } from 'apollo-upload-client'


const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:4000/graphql',
});


const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('idToken');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const cache = new InMemoryCache({
  dataIdFromObject: (obj) => {
    switch (obj.__typename) {
      default: {
        return obj.id;
      }
    }
  },
});

const createClient = () =>
  new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  });

const Client = () => {
  let instance;

  if (instance) {
    console.error('Tried to create a second instance!');
    return;
  } else {
    instance = createClient();
  }

  return instance;
};

export default Client();
