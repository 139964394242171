import React, { useState } from 'react';
import {
  CREATE_LAND_TITLE,
  DELETE_TITLE_DOCUMENT,
  EDIT_MINERAL_OWNERSHIP,
  EDIT_SURFACE_OWNERSHIP,
  EDIT_TITLE_DOCUMENT,
  GET_TRACT,
  TRACTS_QUERY,
} from '../../components/tracts/TractQueries';
import EditTractForm from './editTractForm/EditTractForm';
import LoadingView from '../../components/LoadingView';
import { Typography, Tab, Tabs, AppBar } from '@material-ui/core';
import Auth from '../../util/Auth';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { DELETE_TRACT, EDIT_TRACT } from '../../components/tracts/TractQueries';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import LandWellsTable from './LandWellsTable';
import LandROWTable from './LandROWTable';
import LandOwnershipInterest from './LandOwnershipInterest';
import LandsLegal from './LandsLegal';
import ChainOfTitleTable from './ChainOfTitleTable';
import math from 'mathjs';
import OGLTable from './OGLTable';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import OtherLeasesTable from './editTractForm/OtherLeasesTable';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LANDS);

const makeEditTractStyles = makeStyles((theme) => ({
  isAuthorized: {
    display: hasPermissions ? 'none' : 'block',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
    align: 'center',
  },
}));

const TitleFragment = gql`
  fragment TitleFragment on Tract {
    id
    titleRun {
      id
      volPage
      book
      dateFiled
      effectiveDate
      docType
      comments
      grantor
      grantee
      nsaTotal
      nmaTotal
      fileName
    }
  }
`;

const propertyValues = (fraction) => (gross) => {
  const decimal = math.number(math.fraction(fraction));
  return {
    net: math.multiply(decimal, gross),
    decimal: math.multiply(decimal, 100),
  };
};

const submitValues = (values) => ({
  ...values,
  grossMineralAcres: parseFloat(values.grossMineralAcres),
  grossSurfaceAcres: parseFloat(values.grossSurfaceAcres),
  mineralOwnershipPercentage: propertyValues(values.mineralFraction)(values.grossMineralAcres).decimal.toFixed(8),
  surfaceOwnershipPercentage: propertyValues(values.surfaceFraction)(values.grossSurfaceAcres).decimal.toFixed(8),
  familyNMA: propertyValues(values.mineralFraction)(values.grossMineralAcres).net.toFixed(8),
  familyNSA: propertyValues(values.surfaceFraction)(values.grossSurfaceAcres).net.toFixed(8),
  mineralOwnership: values.grossMineralAcres !== 0 ? true : false,
  surfaceOwnership: values.grossSurfaceAcres !== 0 ? true : false,
});

function TabContainer(props) {
  return (
    <Typography component='div' style={{ paddingTop: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const GetTractFromQuery = (queryResult) => {
  if (!Array.isArray(queryResult) || queryResult.length === 0) {
    return false;
  }

  return queryResult[0];
};

function EditTract({ match, history }) {
  const classes = makeEditTractStyles();
  const { data, error, loading } = useQuery(GET_TRACT, {
    variables: {
      id: match.params.tractID,
    },
  });
  const [UpdateTract] = useMutation(EDIT_TRACT);
  const [DeleteTract] = useMutation(DELETE_TRACT, {
    update: (store, { data: { DeleteTract } }) => {
      try {
        const LandQuery = store.readQuery({ query: TRACTS_QUERY });

        const data = LandQuery.Tract.filter((tract) => tract.id !== DeleteTract.id);

        store.writeQuery({
          query: TRACTS_QUERY,
          data: data,
        });
      } catch (e) {
        console.log('TRACTS_QUERY has not been run yet!');
      }
    },
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [addTitle] = useMutation(CREATE_LAND_TITLE);
  const [removeTitle] = useMutation(DELETE_TITLE_DOCUMENT);
  const [updateTitle] = useMutation(EDIT_TITLE_DOCUMENT);
  const [editMinerals] = useMutation(EDIT_MINERAL_OWNERSHIP);
  const [editSurface] = useMutation(EDIT_SURFACE_OWNERSHIP);

  const updateSurfaceOwnership = (values) => {
    return editSurface({
      variables: values,
    });
  };

  const updateMineralOwnership = (values) => {
    return editMinerals({
      variables: values,
    });
  };

  const editTitle = (values) => {
    return updateTitle({
      variables: values,
    });
  };

  const createTitle = (values) => {
    return addTitle({
      variables: values,
    });
  };

  const deleteTitle = (values) => {
    return removeTitle({
      variables: values,
      update: (store, { data: { DeleteClause } }) => {
        try {
          const oldTitle = store.readFragment({
            id: match.params.tractID,
            fragment: TitleFragment,
          });

          const updatedTitle = oldTitle.titleRun.filter((title) => values.id !== title.id);

          store.writeFragment({
            id: match.params.tractID,
            fragment: TitleFragment,
            data: {
              ...oldTitle,
              titleRun: updatedTitle,
            },
          });
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  const [value, setValue] = useState(0);

  if (error) {
    console.error(error);
    return <h1>{error.toString()}</h1>;
  } else if (loading) {
    return <LoadingView />;
  }

  const Tract = GetTractFromQuery(data.Tract) || [];
  const editableTract = Object.assign({}, Tract)

  return (
    <>
      <Typography variant='h5' className={classes.title}>
        {Tract.id}
      </Typography>
      <br />
      <AppBar position='static'>
        <Tabs value={value} onChange={(e, newVal) => setValue(newVal)}>
          <Tab label='Main' />
          <Tab label='County Map' />
          <Tab label='Detail Map' />
          <Tab label='Chain of Title' />
          <Tab label='Ownership Interest' />
          <Tab label='Wells' />
          <Tab label='ROW' />
          <Tab label='OGL(S)' />
          <Tab label='Other Leases' />
        </Tabs>
      </AppBar>

      {value === 0 && (
        <TabContainer>
          <EditTractForm
            Tract={editableTract}
            history={history}
            EditTract={UpdateTract}
            DeleteTract={DeleteTract}
            updateMineralOwnership={updateMineralOwnership}
            updateSurfaceOwnership={updateSurfaceOwnership}
            snackbarControls={{ open: enqueueSnackbar, close: closeSnackbar }}
            submitValues={submitValues}
          />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <LandsLegal
            Tract={editableTract}
            EditTract={UpdateTract}
            mapUrl={Tract.countyMap}
            setNotification={enqueueSnackbar}
            mapType={'countyMap'}
          />
        </TabContainer>
      )}
      {value === 2 && (
        <TabContainer>
          <LandsLegal
            Tract={editableTract}
            EditTract={UpdateTract}
            mapUrl={Tract.detailMap}
            setNotification={enqueueSnackbar}
            mapType={'detailMap'}
          />
        </TabContainer>
      )}
      {value === 3 && (
        <TabContainer>
          <ChainOfTitleTable
            Tract={editableTract}
            queries={{
              createTitle,
              deleteTitle,
              editTitle,
            }}
            setValue={setValue}
            history={history}
            match={match}
          />
        </TabContainer>
      )}
      {value === 4 && (
        <TabContainer>
          <LandOwnershipInterest
            Tract={editableTract}
            EditTract={UpdateTract}
            updateMineralOwnership={updateMineralOwnership}
            updateSurfaceOwnership={updateSurfaceOwnership}
            setNotification={enqueueSnackbar}
            submitValues={submitValues}
            match={match}
          />
        </TabContainer>
      )}
      {value === 5 && (
        <TabContainer>
          <LandWellsTable Land={editableTract} />
        </TabContainer>
      )}
      {value === 6 && (
        <TabContainer>
          <LandROWTable Land={editableTract} />
        </TabContainer>
      )}
      {value === 7 && (
        <TabContainer>
          <OGLTable Tract={editableTract} />
        </TabContainer>
      )}
      {value === 8 && (
        <TabContainer>
          <OtherLeasesTable Tract={editableTract} />
        </TabContainer>
      )}
      <Typography variant='h5' className={classes.isAuthorized}>
        Not Authorized To Make Changes
      </Typography>
    </>
  );
}

export default EditTract;
