import React from 'react';
import {Button, Typography, MenuItem, TextField as MaterialUITextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import Auth from '../../../util/Auth';
import {Form, Field, Formik} from 'formik';
import {TractSchema} from '../../../components/tracts/TractSchema';
import {TextField, Switch} from 'formik-material-ui';
import TractMapCard from '../../maps/TractMapCard';
import Paper from '@material-ui/core/Paper';
import DeleteTractModal from '../DeleteTractModal';
import math from 'mathjs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PERMISSIONS_LIST from '../../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_LANDS);

const EditFormStyles = makeStyles((theme) => ({
    paper: {
        padding: 25,
        marginBottom: '1rem',
        marginTop: '1rem',
    },
    TextField: {
        display: 'block',
        marginTop: 10,
        marginBottom: 10,
        paddingRight: '1em',
    },
    form: {
        marginTop: '20px',
    },
    title: {
        color: '#1976d2',
        fontSize: '1.75rem',
        textAlign: 'center',
        textDecoration: 'underline',
    },
    gdone: {
        display: 'grid',
        gridTemplateColumns: '250px auto 200px',
        gridTemplateRows: '90% 10%',
        gap: '.5rem 2rem',
    },
    left: {
        display: 'grid',
        gridTemplateRows: '',
    },
    middle: {
        display: 'grid',
        gridTemplateRows: 'repeat (3, 1fr)',
    },
    right: {
        display: 'grid',
        gridTemplateRows: 'repeat (3, 1fr)',
    },
    notes: {
        width: '100%',
        height: '100%',
    },
    deleteButton: {
        gridRow: '',
        gridColumn: '3',
    },
    buttons: {
        display: hasPermissions ? '' : 'none',
    },
}));

const mineralClassifiedText = (
    <Typography
        style={{
            color: '#1976d2',
            fontSize: '1.5rem',
            textAlign: 'center',
            textDecoration: 'underline',
        }}
        color='textSecondary'
        gutterBottom
    >
        Mineral Classified - Family owns surface, State owns minerals. Family is agent for State, Family & State share
        50/50
        all lease/well/surface payments.
    </Typography>
);

const submitForm = (
    values,
    actions,
    {submitValues, Tract, updateMineralOwnership, updateSurfaceOwnership, EditTract, open}
) => {
    //check values on update
    const parsedValues = submitValues(values);

    actions.setSubmitting(true);

    if (
        math.number(Tract.grossMineralAcres) !== math.number(values.grossMineralAcres) ||
        Tract.mineralFraction !== values.mineralFraction
    ) {
        const overGross = math.number(values.grossMineralAcres);
        const overDecimal = math.number(math.fraction(values.mineralFraction));
        const overNet = math.multiply(overGross, overDecimal);
        Promise.all(
            values.mineralsOwned.map((m, i) => {
                const mineralFraction = math.fraction(m.mineralFraction);
                const mineralPercentage = math.number(mineralFraction);
                const netMineralAcres = math.multiply(mineralPercentage, overNet);

                const update = {
                    relationshipID: m.id,
                    mineralFraction: m.mineralFraction,
                    mineralPercentage: Number(mineralPercentage.toFixed(8)),
                    netMineralAcres,
                };

                actions.setFieldValue(`mineralsOwned[${i}]`, {
                    ...update,
                    id: m.id,
                    Owner: {
                        name: m.Owner.name,
                    },
                });

                return updateMineralOwnership(update)
                    .then(({data: {updateMineralOwnership}}) => {
                        console.log(updateMineralOwnership, ' Updated ');
                    })
                    .catch((error) => console.log(error));
            })
        ).catch((error) => console.log(error));
    }

    console.log(' Did we get past GMA?');

    if (
        math.number(Tract.grossSurfaceAcres) !== math.number(values.grossSurfaceAcres) ||
        Tract.surfaceFraction !== values.surfaceFraction
    ) {
        const overGross = math.number(values.grossSurfaceAcres);
        const overDecimal = math.number(math.fraction(values.surfaceFraction));
        const overNet = math.multiply(overGross, overDecimal);
        Promise.all(
            values.surfaceOwned
                .map((m, i) => {
                    const surfaceFraction = math.fraction(m.surfaceFraction);
                    const surfacePercentage = math.number(surfaceFraction);
                    const netSurfaceAcres = math.multiply(surfacePercentage, overNet);

                    const update = {
                        relationshipID: m.id,
                        surfaceFraction: m.surfaceFraction,
                        surfacePercentage,
                        netSurfaceAcres,
                    };

                    actions.setFieldValue(`surfaceOwned[${i}]`, {
                        ...update,
                        id: m.id,
                        Owner: {
                            name: m.Owner.name,
                        },
                    });

                    return updateSurfaceOwnership(update)
                        .then(({data: {updateSurfaceOwnership}}) => {
                        })
                        .catch((error) => console.log(error));
                })
                .catch((error) => console.log(error))
        );
    }

    return EditTract({
        variables: parsedValues,
    })
        .then((data) => {
            open('Successfully updated ownership!', {variant: 'success'});
            actions.setSubmitting(false);
        })
        .catch((error) => {
            console.error(error);
            open(error.toString(), {
                variant: 'error',
            });
            actions.setSubmitting(false);
        });
};

const EditTractForm = ({
                           Tract,
                           EditTract,
                           snackbarControls,
                           DeleteTract,
                           history,
                           submitValues,
                           updateMineralOwnership,
                           updateSurfaceOwnership,
                       }) => {
    const {open} = snackbarControls;
    const classes = EditFormStyles();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [minFracPercent, setMinFracPercent] = React.useState(math.number(math.fraction(Tract.mineralFraction)));
    const [surfaceFracPercent, setSurfaceFracPercent] = React.useState(math.number(math.fraction(Tract.surfaceFraction)));
    const [nma, setNMA] = React.useState(Tract.familyNMA);
    const [nsa, setNSA] = React.useState(Tract.familyNSA);

    return (
        <>
            <Formik
                initialValues={Tract}
                validationSchema={TractSchema}
                onSubmit={(values, actions) => {
                    submitForm(values, actions, {
                        submitValues,
                        Tract,
                        updateMineralOwnership,
                        updateSurfaceOwnership,
                        EditTract,
                        open,
                    });
                }}
            >
                {(props) => {
                    setMinFracPercent(math.number(math.fraction(Tract.mineralFraction)));
                    setSurfaceFracPercent(math.number(math.fraction(Tract.surfaceFraction)));
                    setNMA(math.multiply(minFracPercent, math.number(Tract.grossMineralAcres)).toFixed(8));
                    setNSA(math.multiply(surfaceFracPercent, math.number(Tract.grossSurfaceAcres)).toFixed(8));
                    const finalMinPercent = (minFracPercent * 100).toFixed(8);
                    const finalSurfacePercent = (surfaceFracPercent * 100).toFixed(8);

                    return (
                        <Form>
                            <div style={{display: 'flex', direction: 'row'}}>
                                <Field
                                    name='name'
                                    placeholder='Land ID'
                                    component={TextField}
                                    label='Land ID'
                                    className={classes.TextField}
                                />
                                <Field
                                    name='familyID'
                                    placeholder='Family ID'
                                    component={TextField}
                                    label='Family ID'
                                    className={classes.TextField}
                                />
                                <Field
                                    name='bfNumber'
                                    placeholder='BF #'
                                    component={TextField}
                                    label='BF #'
                                    className={classes.TextField}
                                />
                                <Field
                                    name='abstract'
                                    placeholder='Abstract'
                                    component={TextField}
                                    label='Abstract'
                                    className={classes.TextField}
                                />
                                <Field
                                    name='description'
                                    placeholder='Description'
                                    component={TextField}
                                    label='Description'
                                    className={classes.TextField}
                                    fullWidth
                                    style={{width: 300}}
                                />
                                <Field
                                    name='state'
                                    placeholder='State'
                                    component={TextField}
                                    label='State'
                                    className={classes.TextField}
                                />
                                <Field
                                    name='county'
                                    placeholder='County'
                                    component={TextField}
                                    label='County'
                                    className={classes.TextField}
                                />
                                <Field
                                    name='dateOfPatent'
                                    label='Date Of Patent'
                                    placeholder='MM-DD-YYYY'
                                    type='date'
                                    component={TextField}
                                    className={classes.TextField}
                                    margin={'normal'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div style={{display: 'flex'}}>
                                <Field
                                    name='patentInformation'
                                    placeholder='Patent Information'
                                    component={TextField}
                                    label='Patent Information'
                                    className={classes.TextField}
                                />
                                <Field
                                    name='purchaser'
                                    placeholder='Purchaser'
                                    component={TextField}
                                    label='Purchaser'
                                    className={classes.TextField}
                                />
                                <Field
                                    name='docType'
                                    placeholder='Document Type'
                                    component={TextField}
                                    label='Document Type'
                                    className={classes.TextField}
                                />
                                <Field
                                    name='recording'
                                    placeholder='Recording'
                                    component={TextField}
                                    label='Recording'
                                    className={classes.TextField}
                                />
                                <Field
                                    name='purchaseDate'
                                    label='Purchase Date'
                                    placeholder='MM-DD-YYYY'
                                    type='date'
                                    component={TextField}
                                    className={classes.TextField}
                                    margin={'normal'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <Field
                                    name='survey'
                                    placeholder='Document Type'
                                    component={TextField}
                                    label='Survey'
                                    className={classes.TextField}
                                />
                                <Field
                                    name='color'
                                    select
                                    label='Color Code'
                                    component={TextField}
                                    className={classes.TextField}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{width: 200}}
                                >
                                    <MenuItem value={'M'}>Minerals</MenuItem>
                                    <MenuItem value={'M&S'}>Minerals & Surface</MenuItem>
                                    <MenuItem value={'S'}>Surface</MenuItem>
                                </Field>
                                <FormControlLabel
                                    control={<Field name='mineralClassified' component={Switch} color='primary'/>}
                                    label='Mineral Classified'
                                    labelPlacement='top'
                                />
                                <FormControlLabel
                                    control={<Field name='npriInterest' component={Switch} color='primary'/>}
                                    label='NRPI'
                                    labelPlacement='top'
                                />
                            </div>
                            {props.values.mineralClassified ? mineralClassifiedText : ''}
                            <hr/>
                            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                <Paper className={classes.paper}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography variant='h5' align='center' className={classes.title}>
                                            {Tract.state === 'Texas' ? 'Texas' : 'Cats & Dogs'}
                                        </Typography>
                                        <Field
                                            name={Tract.state === 'Texas' ? 'blk' : 'range'}
                                            placeholder={Tract.state === 'Texas' ? 'TX Block' : 'Range'}
                                            component={TextField}
                                            label={Tract.state === 'Texas' ? 'TX Block' : 'Range'}
                                            className={classes.TextField}
                                        />
                                        <Field
                                            name={Tract.state === 'Texas' ? 'section' : 'township'}
                                            placeholder={Tract.state === 'Texas' ? 'TX Section' : 'Township'}
                                            component={TextField}
                                            label={Tract.state === 'Texas' ? 'TX Section' : 'Township'}
                                            className={classes.TextField}
                                        />
                                        <Field
                                            name={Tract.state === 'Texas' ? 'tract' : 'section'}
                                            placeholder={Tract.state === 'Texas' ? 'TX Tract' : 'Section'}
                                            component={TextField}
                                            label={Tract.state === 'Texas' ? ' TX Tract' : 'Section'}
                                            className={classes.TextField}
                                        />
                                        <Field
                                            name={Tract.state === 'Texas' ? 'lot' : 'tract'}
                                            placeholder={Tract.state === 'Texas' ? 'TX Lot' : 'Tract'}
                                            component={TextField}
                                            label={Tract.state === 'Texas' ? 'TX Lot' : 'Tract'}
                                            className={classes.TextField}
                                        />
                                        <Field
                                            name={Tract.state === 'Texas' ? 'survey' : 'lot'}
                                            placeholder={Tract.state === 'Texas' ? 'Survey' : 'Lot'}
                                            component={TextField}
                                            label={Tract.state === 'Texas' ? 'Survey' : 'Lot'}
                                            className={classes.TextField}
                                        />
                                    </div>
                                </Paper>
                                <Paper className={classes.paper}>
                                    <div>
                                        <Typography variant='h5' align='center' className={classes.title}>
                                            Ownership
                                        </Typography>
                                        <div style={{display: 'Flex', alignContent: 'space-around'}}>
                                            <div>
                                                <Typography variant='h5' align='center' className={classes.title}>
                                                    Minerals
                                                </Typography>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <Field
                                                        name='grossMineralAcres'
                                                        placeholder='GMA'
                                                        component={TextField}
                                                        label='GMA'
                                                        className={classes.TextField}
                                                    />
                                                    <Field
                                                        name='mineralFraction'
                                                        placeholder='Mineral Fraction'
                                                        component={TextField}
                                                        label='Mineral Fraction'
                                                        className={classes.TextField}
                                                        disabled
                                                    />
                                                    <MaterialUITextField value={finalMinPercent} label='MIN%' disabled/>
                                                    <MaterialUITextField value={nma} label='NMA' disabled/>
                                                </div>
                                            </div>
                                            <div>
                                                <Typography variant='h5' align='center' className={classes.title}>
                                                    Surface
                                                </Typography>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <Field
                                                        name='grossSurfaceAcres'
                                                        placeholder='GSA'
                                                        component={TextField}
                                                        label='GSA'
                                                        className={classes.TextField}
                                                    />
                                                    <Field
                                                        name='surfaceFraction'
                                                        placeholder='Surface Fraction'
                                                        component={TextField}
                                                        label='Surface Fraction'
                                                        className={classes.TextField}
                                                        disabled
                                                    />
                                                    <MaterialUITextField value={finalSurfacePercent} label='SRF%'
                                                                         disabled/>
                                                    <MaterialUITextField value={nsa} label='NSA' disabled/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                            <hr/>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignContent: 'space-between',
                                height: 'auto'
                            }}>
                                <Field
                                    name='notes'
                                    placeholder='Notes'
                                    component={TextField}
                                    label='Notes'
                                    className={classes.TextArea}
                                    fullWidth
                                    multiline
                                    variant='outlined'
                                    rows={4}
                                    style={{marginTop: 50, marginBottom: 50}}
                                />
                                <Field
                                    name='legalDescription'
                                    placeholder='Legal Description of Property'
                                    component={TextField}
                                    label='Legal Description of Property'
                                    className={classes.TextArea}
                                    fullWidth
                                    multiline
                                    variant='outlined'
                                    rows={4}
                                    style={{marginTop: 50, marginBottom: 50}}
                                />
                                <Field
                                    name='geometry'
                                    placeholder='Geometry'
                                    component={TextField}
                                    label='Geometry'
                                    className={classes.TextArea}
                                    fullWidth
                                    multiline
                                    variant='outlined'
                                    rows={4}
                                    style={{marginBottom: 50}}
                                />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    className={classes.deleteButton}
                                    onClick={() => {
                                        setModalOpen(true);
                                    }}
                                >
                                    Delete Land
                                </Button>
                                <DeleteTractModal
                                    open={modalOpen}
                                    handleClose={() => setModalOpen(false)}
                                    handleAgree={() => {
                                        return DeleteTract({
                                            variables: {
                                                id: props.values.id,
                                            },
                                        })
                                            .then(({data}) => {
                                                open('Successfully deleted Tract', {variant: 'success'});
                                                history.push('/Lands');
                                            })
                                            .catch((err) => {
                                                console.error(err);
                                                open(err.toString, {variant: 'error'});
                                            });
                                    }}
                                />
                                <Button variant='contained' color='primary' type='submit'>
                                    Update Land
                                </Button>
                            </div>
                            {!Tract.geometry ? '' : <TractMapCard geometry={Tract.geometry} name={Tract.name}/>}
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default EditTractForm;
