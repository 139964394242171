import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';

function DeleteUnitModal({ open, handleClose, handleAgree, id }) {
  return (
    <Dialog open={open}>
      <DialogTitle>Are you sure you want to delete this Unit?</DialogTitle>
      <DialogContent>
        <Typography variant='body1'>Warning: This cannot be reversed.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          Close
        </Button>
        <Button
          color='primary'
          onClick={() => {
            handleAgree();
            handleClose(false);
          }}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteUnitModal;
