import React from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import Auth from '../../util/Auth';
import moment from 'moment';
import { Button, FormLabel, InputLabel, MenuItem, TextField as MaterialUITextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import DeleteROWModal from './DeleteROWModal';
import Select from 'react-select';
import CreateableSelect from 'react-select/creatable';
import Link from 'react-router-dom/Link';
import PERMISSIONS_LIST from '../../util/RolesEnum';
import {rowStatuses, rowTerms} from "../../util/Statuses";

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_ROWS);

const ROWDetailPageStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  gdone: {
    display: 'grid',
    gridTemplateColumns: '250px auto 200px',
    gridTemplateRows: '90% 10%',
    gap: '.5rem 2rem',
  },
  left: {
    display: 'grid',
    gridTemplateRows: '',
  },
  middle: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  right: {
    display: 'grid',
    gridTemplateRows: 'repeat (3, 1fr)',
  },
  notes: {
    width: '100%',
    height: '100%',
  },
  deleteButton: {
    gridRow: '',
    gridColumn: '3',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
    marginTop: 20,
  },
}));

const ROWDetailPage = ({
  ROW,
  EditRow,
  DeleteRow,
  placeROW,
  removeROW,
  tracts,
  history,
  operators,
  addRowOperator,
  removeRowOperator,
  addROWPayment,
  deleteROWPayment,
  updateROWPayment,
}) => {
  const classes = ROWDetailPageStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = React.useState(false);
  const currencyFormat = (locale = 'en-US', style = 'currency', currency = 'USD') => {
    return new Intl.NumberFormat(locale, {
      style,
      currency,
    });
  };

  const editableRow = Object.assign({}, ROW)

  //Format Lease Date strings
  editableRow.dateOfLease = !editableRow.dateOfLease ? 'None' : moment(editableRow.dateOfLease).format('MM/DD/YYYY');
  editableRow.dateOfExpiration = !editableRow.dateOfExpiration ? 'None' : moment(editableRow.dateOfExpiration).format('YYYY-MM-DD');
  editableRow.datePaid = moment(editableRow.datePaid).format('YYYY-MM-DD');

  return (
    <Formik
      initialValues={ROW}
      onSubmit={(values, actions) => {
        if (!values.operator) {
          addRowOperator({
            variables: {
              from: { id: values.operatorID },
              to: { id: editableRow.id },
            },
          })
            .then(({ data: { AddOperatorRow } }) => {
              actions.setFieldValue('operator', AddOperatorRow.from);
            })
            .catch((error) => console.log(error));
        }

        if (values.lessee !== editableRow.lessee && values.operator) {
          removeRowOperator({
            variables: {
              from: { id: editableRow.operator.id },
              to: { id: editableRow.id },
            },
          })
            .then(
              addRowOperator({
                variables: {
                  from: { id: values.operatorID },
                  to: { id: editableRow.id },
                },
              }).then(({ data: { AddOperatorRow } }) => {
                actions.setFieldValue('operator', AddOperatorRow.from);
              })
            )
            .catch((error) => console.log(error));
        }

        return EditRow({
          variables: { ...values },
        })
          .then(({ data: { UpdateROW } }) => {
            enqueueSnackbar('Right of Way successfully edited', { variant: 'success' });
            actions.setSubmitting(false);
          })
          .catch((err) => {
            enqueueSnackbar(err.toString(), { variant: 'error' });
            actions.setSubmitting(false);
          });
      }}
    >
      {(props) => {
        return (
          <Form>
            <div style={{ display: 'grid', gridTemplateColumns: '25% 1fr' }}>
              <div id='1'>
                <Field
                  name='projectName'
                  placeholder='ROW Name'
                  label='ROW Name'
                  component={TextField}
                  className={classes.TextField}
                  fullWidth
                />
                <Field
                  name='status'
                  margin='normal'
                  select
                  label='Status'
                  component={TextField}
                  className={classes.TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginBottom: 20 }}
                >
                  {
                    Object.keys(rowStatuses).map((status) => {
                      return <MenuItem key={status} value={status}>{rowStatuses[status]}</MenuItem>
                    })
                  }
                </Field>
                <div style={{ width: 300 }}>
                  <InputLabel>Lessee</InputLabel>
                  <Field
                    name='lessee'
                    id='lessee'
                    render={({ field }) => {
                      return (
                        <CreateableSelect
                          defaultValue={{
                            name: editableRow.operator ? field.value : '...Select',
                            id: editableRow.operator ? props.values.operator.id : field.value,
                          }}
                          options={operators}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(option) => {
                            props.setFieldValue('lessee', option.name);
                            props.setFieldValue('operatorID', option.id);
                            props.setFieldTouched('lessee');
                          }}
                          styles={{
                            option: (provided, state) => ({
                              ...provided,
                              fontFamily: 'Roboto',
                            }),
                            singleValue: (provided, state) => ({
                              ...provided,
                              fontFamily: 'Roboto',
                            }),
                          }}
                        />
                      );
                    }}
                  />
                </div>
                <Field
                  name='dateOfLease'
                  placeholder='Lease Date'
                  component={TextField}
                  label='Lease Date'
                  className={classes.TextField}
                />
                <Field
                  name='termOfLease'
                  select
                  label='Term of ROW'
                  component={TextField}
                  className={classes.TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autowidth="true"
                >
                  {
                    Object.keys(rowTerms).map((term) => {
                      return <MenuItem key={term} value={term}>{rowTerms[term]}</MenuItem>;
                    })
                  }
                </Field>
                <Field
                  name='dateOfExpiration'
                  label='Date Of Expiration'
                  placeholder='MM-DD-YYYY'
                  type='date'
                  component={TextField}
                  className={classes.TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Field
                  name='type'
                  margin='normal'
                  select
                  label='Type of Lease'
                  component={TextField}
                  className={classes.TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginBottom: 20 }}
                >
                  <MenuItem value={'Electric Line'}>Electric Line</MenuItem>
                  <MenuItem value={'Fiber Optic Line'}>Fiber Optic Line</MenuItem>
                  <MenuItem value={'Frac Pond'}>Frac Pond</MenuItem>
                  <MenuItem value={'Gathering Line'}>Gathering Line</MenuItem>
                  <MenuItem value={'Pad Site & Wellbore'}>Pad Site & Wellbore</MenuItem>
                  <MenuItem value={'Pipeline - Gas'}>Pipeline - Gas</MenuItem>
                  <MenuItem value={'Pipeline - Oil'}>Pipeline - Oil</MenuItem>
                  <MenuItem value={'Pipeline - Temp'}>Pipeline - Temp</MenuItem>
                  <MenuItem value={'Road'}>Road</MenuItem>
                  <MenuItem value={'Road - Temp'}>Road - Temp</MenuItem>
                  <MenuItem value={'Seismic'}>Seismic</MenuItem>
                  <MenuItem value={'Storage Tank'}>Storage Tank</MenuItem>
                  <MenuItem value={'SWD'}>SWD</MenuItem>
                  <MenuItem value={'Waterline'}>Waterline</MenuItem>
                  <MenuItem value={'Waterline - Temp'}>Waterline - Temp</MenuItem>
                </Field>
                <Field
                  name='acreage'
                  label='Acreage'
                  placeholder='Acreage'
                  component={TextField}
                  className={classes.TextField}
                  fullWidth
                />
                <Field
                  name='sizeOfPipeline'
                  label='Size Of Pipeline'
                  placeholder='Size Of Pipeline'
                  component={TextField}
                  className={classes.TextField}
                  fullWidth
                />
                <Field
                  name='lengthOfPipeline'
                  label='Length Of Pipeline'
                  placeholder='Length Of Pipeline'
                  component={TextField}
                  className={classes.TextField}
                  fullWidth
                />
                <Field
                  name='costPerUnit'
                  label='Cost Per Unit'
                  placeholder='Cost Per Unit'
                  component={TextField}
                  className={classes.TextField}
                  fullWidth
                />
                <Field
                  name='datePaid'
                  label='Date Paid'
                  placeholder='MM-DD-YYYY'
                  type='date'
                  component={TextField}
                  className={classes.TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <InputLabel>Vol & Page / Recording Info</InputLabel>
                <Field
                    name='volAndPage'
                    placeholder='Vol & Page'
                    component={TextField}
                    className={classes.TextField}
                    fullWidth
                />
                <div>
                  <FormLabel style={{ display: 'block' }}>Original In File</FormLabel>
                  <Field color='primary' name='original' component={Switch} margin='normal' />
                </div>
              </div>
              <div id='2'>
                <div>
                  <FieldArray name='locatedOn'>
                    {({ push, remove }) => {
                      const columns = [
                        {
                          title: 'Land ID',
                          field: 'id',
                          editComponent: ({ onChange }) => (
                            <Select
                              options={tracts}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              onChange={onChange}
                              styles={{
                                menu: (provided, state) => ({ ...provided, width: 150 }),
                                menuList: (provided, state) => ({
                                  ...provided,
                                  width: 150,
                                }),
                              }}
                            />
                          ),
                        },
                        {
                          title: 'Family ID',
                          field: 'familyID',
                          editable: 'never',
                        },
                        {
                          title: 'State',
                          field: 'state',
                          editable: 'never',
                        },
                        {
                          title: 'County',
                          field: 'county',
                          editable: 'never',
                        },
                      ];

                      return (
                        <MaterialTable
                          columns={columns}
                          data={props.values.locatedOn.map(m => ({...m}))}
                          title='Lands'
                          options={{
                            headerStyle: {
                              backgroundColor: '#1976d2',
                              color: '#FFF',
                              textAlign: 'left',
                              width: 'auto',
                            },
                            cellStyle: {
                              maxWidth: 0,
                              overFlowX: 'auto',
                            },
                            search: false,
                            paging: false,
                            toolbarButtonAlignment: 'left',
                            addRowPosition: 'first',
                            exportButton: true,
                          }}
                          editable={{
                            onRowAdd: (newData) => {
                              const tractID = newData.id.id;
                              props.setSubmitting(true);
                              return placeROW({
                                variables: {
                                  from: { id: editableRow.id },
                                  to: { id: tractID },
                                },
                              }).then((data) => {
                                push(data.data.AddROWLocatedOn.to);
                                props.setSubmitting(false);
                              });
                            },
                            onRowDelete: (oldData) => {
                              props.setSubmitting(true);
                              return removeROW({
                                variables: {
                                  from: { id: editableRow.id },
                                  to: { id: oldData.id },
                                },
                              }).then((data) => {
                                props.setSubmitting(false);
                                remove(oldData.tableData.id);
                              });
                            },
                          }}
                        />
                      );
                    }}
                  </FieldArray>
                  <div style={{ marginTop: 20 }}>
                    <FieldArray name='payments'>
                      {({ push, remove, insert }) => {
                        const columns = [
                          {
                            title: 'Date of Check',
                            field: 'date',
                          },
                          {
                            title: 'Payment From',
                            field: 'paymentFrom',
                          },
                          {
                            title: 'Entity Paid',
                            field: 'entity',
                          },
                          {
                            title: 'Type of Payment',
                            field: 'paymentType',
                          },
                          {
                            title: 'Amount',
                            field: 'amount',
                          },
                        ];
                        return (
                          <MaterialTable
                            columns={columns}
                            data={!props.values.payments ? [] : props.values.payments.map(p => ({...p}))}
                            title={'Payments'}
                            options={{
                              headerStyle: {
                                backgroundColor: '#1976d2',
                                color: '#FFF',
                              },
                              rowStyle: (rowData) => ({
                                backgroundColor: rowData.current === 'Yes' ? '#EEE' : '#FFF',
                              }),
                              cellStyle: {
                                maxWidth: 0,
                                overFlowX: 'auto',
                              },
                              paging: false,
                              exportButton: true,
                              toolbarButtonAlignment: 'left',
                              searchFieldAlignment: 'left',
                            }}
                            editable={{
                              onRowAdd: (newData) => {
                                props.setSubmitting(true);
                                return addROWPayment({
                                  variables: {
                                    ROWID: editableRow.id,
                                    info: {
                                      ...newData,
                                      amount: currencyFormat().format(newData.amount),
                                    },
                                  },
                                })
                                  .then(({ data: { addROWPayment } }) => {
                                    push(addROWPayment.payments[0]);
                                    props.values.payments.push(addROWPayment.payments[0]);
                                    props.setSubmitting(false);
                                  })
                                  .catch((error) => console.log(error));
                              },
                              onRowUpdate: (newData, oldData) => {
                                // console.log(newData, oldData);
                                const { amount, paymentType, entity, paymentFrom, date, id } = newData;
                                return updateROWPayment({
                                  variables: {
                                    paymentID: id,
                                    paymentInfo: {
                                      entity,
                                      paymentType,
                                      paymentFrom,
                                      date,
                                      amount: currencyFormat().format(amount.replace(/[^0-9.-]+/g,"")),
                                    },
                                  },
                                }).then(({ data: { updateROWPayment } }) => {
                                  remove(oldData.tableData.id);
                                  insert(oldData.tableData.id, updateROWPayment);
                                });
                              },
                              onRowDelete: (oldData) => {
                                props.setSubmitting(true);
                                return deleteROWPayment({
                                  variables: {
                                    paymentID: oldData.id,
                                  },
                                }).then((data) => {
                                  props.setSubmitting(false);
                                  remove(oldData.tableData.id);
                                });
                              },
                            }}
                          />
                        );
                      }}
                    </FieldArray>
                  </div>
                  <br />
                  <br />
                  <Field
                    name='comments'
                    placeholder='Notes'
                    label='Notes'
                    component={TextField}
                    multiline={true}
                    rows={18}
                    variant='outlined'
                    className={classes.notes}
                    fullWidth
                  />
                </div>
              </div>
            </div>
            <div>
              <hr />
              <Button
                variant='contained'
                color='secondary'
                className={classes.deleteButton}
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Delete Row
              </Button>

              <DeleteROWModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                handleAgree={() => {
                  DeleteRow({
                    variables: {
                      id: props.values.id,
                    },
                    update: (store, { data: { DeleteROW } }) => {
                      store.data.delete(DeleteROW.id);
                    },
                  })
                    .then(({ data }) => {
                      enqueueSnackbar('Successfully deleted Lease', { variant: 'success' });
                      history.push('/RightOfWays');
                    })
                    .catch((err) => {
                      console.error(err);
                      enqueueSnackbar(err.toString, { variant: 'error' });
                    });
                }}
              />
              <Button variant='contained' color='primary' type='submit' style={{ float: 'right' }}>
                Update ROW
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ROWDetailPage;
