import * as yup from 'yup';

export const leaseTemplateSchema = yup.object({
  leaseName: yup.string().required(),
});

export const tractObject = yup.object({
  tractID: yup.string().nullable(),
  netMineralAcres: yup
    .string()
    .nullable()
    .trim(),
  netSurfaceAcres: yup
    .string()
    .nullable()
    .trim(),
  depths: yup
    .string()
    .nullable()
    .trim(),
});

export const clauseObject = yup.object().shape({
  name: yup.string().trim(),
  type: yup.string().trim(),
  text: yup.string().trim(),
});

export const LeaseSchema = yup.object().shape({
  id: yup.string().required(),
  leaseName: yup
    .string()
    .nullable()
    .required(),
  mineralClassified: yup.boolean().nullable(),
  shortDescription: yup.string().nullable(),
  cdcCalc: yup
    .string()
    .required('Required Field')
    .nullable(),
  currentLessee: yup
    .string()
    .nullable()
    .trim(),
  grossAcres: yup
    .string()
    .trim()
    .nullable(),
  netMineralAcres: yup
    .string()
    .trim()
    .nullable(),
  netSurfaceAcres: yup
    .string()
    .trim()
    .nullable(),
  heldByProductionAcres: yup
    .string()
    .trim()
    .nullable(),
  status: yup
    .string()
    .trim()
    .nullable(),
  termYears: yup
    .string()
    .trim()
    .nullable(),
  termMonth: yup
    .string()
    .trim()
    .nullable(),
  effectiveDate: yup
    .string()
    .trim()
    .nullable(),
  primaryExpirationDate: yup
    .string()
    .trim()
    .nullable(),
  bonusPerAcre: yup
    .string()
    .trim()
    .nullable(),
  bonusAmount: yup
    .string()
    .trim()
    .nullable(),
  paidUpOGL: yup.boolean().nullable(),
  rentalPerAcre: yup
    .string()
    .trim()
    .nullable(),
  rentalAmount: yup
    .string()
    .trim()
    .nullable(),
  extensionOptionsYears: yup
    .string()
    .trim()
    .nullable(),
  extensionOptionsMonths: yup
    .string()
    .trim()
    .nullable(),
  extensionDueDate: yup
    .string()
    .trim()
    .nullable(),
  extensionExpirationDate: yup
    .string()
    .trim()
    .nullable(),
  amountPerAcre: yup
    .string()
    .trim()
    .nullable(),
  totalAmount: yup
    .string()
    .trim()
    .nullable(),
  cdcDescription: yup
    .string()
    .trim()
    .nullable(),
  verticalPughDescription: yup
    .string()
    .trim()
    .nullable(),
  verticalPughDate: yup
    .string()
    .trim()
    .nullable(),
  horizontalPughDeepDescription: yup
    .string()
    .trim()
    .nullable(),
  horizontalPughDeepDate: yup
    .string()
    .trim()
    .nullable(),
  horizontalPughShallowDescription: yup
    .string()
    .trim()
    .nullable(),
  horizontalPughShallowDate: yup
    .string()
    .trim()
    .nullable(),
  pughRelease: yup.boolean().nullable(),
  shutinDescription: yup
    .string()
    .trim()
    .nullable(),
  dueOil: yup
    .string()
    .trim()
    .nullable(),
  dueGas: yup
    .string()
    .trim()
    .nullable(),
  minRoyalty: yup
    .string()
    .trim()
    .nullable(),
  RoyaltyRate: yup
    .string()
    .trim()
    .nullable(),
  compensatory: yup
    .string()
    .trim()
    .nullable(),
  notes: yup
    .string()
    .trim()
    .nullable(),
  legalDescription: yup
    .string()
    .trim()
    .nullable(),
  mapURL: yup
    .string()
    .trim()
    .nullable(),
  cdcPeriod: yup
    .string()
    .trim()
    .nullable(),
  cdcDeadline: yup
    .string()
    .trim()
    .nullable(),
  shutinTriggerDate: yup
    .string()
    .trim()
    .nullable(),
  shutinMaxConsecutive: yup
    .string()
    .trim()
    .nullable(),
  shutinMaxPost: yup
    .string()
    .trim()
    .nullable(),
  skimOil: yup.boolean().nullable(),
  condensateOil: yup.boolean().nullable(),
  leaseUseGas: yup.boolean().nullable(),
  flaredGas: yup.boolean().nullable(),
  depthsLeased: yup.string().nullable(),
});
