import React, { useState } from 'react';
import { TableCell, TableRow, Radio } from '@material-ui/core';

const DIWellDiffRadio = ({ property, CurrentWellValue, diWellValue, setProperty }) => {
  const [selectedOption, setSelectedOption] = useState(1);
  return (
    <TableRow>
      <TableCell>{property}</TableCell>
      <TableCell>
        {CurrentWellValue !== null && CurrentWellValue !== undefined ? CurrentWellValue.toString() : null}
      </TableCell>
      <TableCell>{diWellValue !== null ? diWellValue.toString() : null}</TableCell>
      <TableCell>
        <Radio
          checked={selectedOption === 0}
          onClick={() => {
            setProperty(property, CurrentWellValue);
            setSelectedOption(0);
          }}
        />
      </TableCell>
      <TableCell>
        <Radio
          checked={selectedOption === 1}
          onClick={() => {
            setProperty(property, diWellValue);
            setSelectedOption(1);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default DIWellDiffRadio;
