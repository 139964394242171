import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, withStyles } from '@material-ui/core';

const UserFormStyles = (theme) => ({
  root: {
    padding: 24,
  },
  Form: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  ColumnLeft: {
    flexGrow: 3,
    marginRight: 5,
  },
  ColumnMiddle: {
    flexGrow: 3,
    marginLeft: 5,
    marginRight: 5,
  },
  ColumnRight: {
    flexGrow: 1,
    marginLeft: 5,
  },
});

const CreateUserForm = ({ mutations: { CreateUser }, enqueueSnackbar, classes }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Paper className={classes.root} square>
      <div>
        <Typography variant='h6'>Create User Form</Typography>
      </div>
      <div>
        <form className={classes.Form}>
          <TextField
            label='Email'
            type='email'
            autoComplete='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant='outlined'
            className={classes.ColumnLeft}
          />
          <TextField
            label='Password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant='outlined'
            className={classes.ColumnMiddle}
          />
          <Button
            variant='contained'
            color='primary'
            onClick={() =>
              CreateUser({ variables: { email, password } })
                .then((res) => enqueueSnackbar('Successfully created account!', { variant: 'success' }))
                .catch((err) => {
                  console.error(err.message);
                  enqueueSnackbar(err.message, { variant: 'error' });
                })
            }
            className={classes.ColumnRight}
          >
            Submit
          </Button>
        </form>
      </div>
    </Paper>
  );
};

export default withStyles(UserFormStyles)(CreateUserForm);
