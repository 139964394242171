import gql from 'graphql-tag';
import { ROW_FRAGMENT } from '../row/ROWQueries';

export const defaultOL = {
  dateOfLease: '0001-01-01',
  lessee: 'Default',
  termOfLease: 'Default',
  dateOfExpiration: '0001-01-01',
  type: 'Default',
  original: false,
  volAndPage: 'Default',
  datePaid: '0001-01-01',
  familyId: 'Default',
  projectName: 'Default',
  comments: 'None',
  status: 'None',
  sizeOfPipeline: 'None',
  lengthOfPipeline: 'None',
  costPerUnit: 'None',
  acreage: 'None',
  contactName: 'None',
  contactAddress: 'None',
  contactPhone: 'None',
  contactEmail: 'None',
};

export const OTHER_LEASE_FRAGMENT = `
{
    id
    landId
    dateOfLease
    lessee
    termOfLease
    dateOfExpiration
    type
    original
    volAndPage
    datePaid
    familyId
    projectName
    comments
    status
    sizeOfPipeline
    lengthOfPipeline
    costPerUnit
    acreage
    contactName
    contactAddress
    contactPhone
    contactEmail
    locatedOn {
        name
        id
        familyID
        state
        county
    }
    operator{
      name
      id
      address1
      address2
      city
      state
      zip
      contacts{
        id
        contactName
        position
        directLine
        cell
        email
        date
        current
      }
    }
    
  }
`;

export const GET_ALL_OTHER_LEASES = gql`
    query {
        OtherLease
        ${OTHER_LEASE_FRAGMENT}
    }
`;

export const GET_OTHER_LEASE = gql`
    query OtherLease($id: ID!) {
        OtherLease(id: $id)
        ${OTHER_LEASE_FRAGMENT}
    }
`;

export const ADD_OTHER_LEASE = gql`
    mutation CreateOtherLease(
        $id: ID!
        $dateOfLease: String
        $lessee: String
        $termOfLease: String
        $dateOfExpiration: String
        $type: String
        $original: Boolean
        $volAndPage: String
        $datePaid: String
        $familyId: String
        $projectName: String
        $comments: String
        $status: String
        $sizeOfPipeline: String
        $lengthOfPipeline: String
        $costPerUnit: String
        $acreage: String
        $contactName: String
        $contactAddress: String
        $contactPhone: String
        $contactEmail: String
    ) {
        CreateOtherLease(
            id: $id
            dateOfLease: $dateOfLease
            lessee: $lessee
            termOfLease: $termOfLease
            dateOfExpiration: $dateOfExpiration
            type: $type
            original: $original
            volAndPage: $volAndPage
            datePaid: $datePaid
            familyId: $familyId
            projectName: $projectName
            comments: $comments
            status: $status
            sizeOfPipeline: $sizeOfPipeline
            lengthOfPipeline: $lengthOfPipeline
            costPerUnit: $costPerUnit
            acreage: $acreage
            contactName: $contactName
            contactAddress: $contactAddress
            contactPhone: $contactPhone
            contactEmail: $contactEmail
        )
        ${OTHER_LEASE_FRAGMENT}
    }
`;

export const EDIT_OTHER_LEASE = gql`
    mutation UpdateOtherLease(
        $id: ID!
        $dateOfLease: String
        $lessee: String
        $termOfLease: String
        $dateOfExpiration: String
        $type: String
        $original: Boolean
        $volAndPage: String
        $datePaid: String
        $familyId: String
        $projectName: String
        $comments: String
        $status: String
        $sizeOfPipeline: String
        $lengthOfPipeline: String
        $costPerUnit: String
        $acreage: String
        $contactName: String
        $contactAddress: String
        $contactPhone: String
        $contactEmail: String
    ) {
        UpdateOtherLease(
            id: $id
            dateOfLease: $dateOfLease
            lessee: $lessee
            termOfLease: $termOfLease
            dateOfExpiration: $dateOfExpiration
            type: $type
            original: $original
            volAndPage: $volAndPage
            datePaid: $datePaid
            familyId: $familyId
            projectName: $projectName
            comments: $comments
            status: $status
            sizeOfPipeline: $sizeOfPipeline
            lengthOfPipeline: $lengthOfPipeline
            costPerUnit: $costPerUnit
            acreage: $acreage
            contactName: $contactName
            contactAddress: $contactAddress
            contactPhone: $contactPhone
            contactEmail: $contactEmail
        )
        ${OTHER_LEASE_FRAGMENT}
    }
`;

export const DELETE_OTHER_LEASE = gql`
    mutation DeleteOtherLease($id: ID!){
        DeleteOtherLease(id: $id)
        ${OTHER_LEASE_FRAGMENT}
    }
`;

export const ADD_OTHER_LEASE_ON = gql`
  mutation AddOtherLeaseLocatedOn($from: _OtherLeaseInput!, $to: _TractInput!) {
    AddOtherLeaseLocatedOn(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
        state
        county
        familyID
      }
    }
  }
`;

export const REMOVE_OTHER_LEASE_ON = gql`
  mutation RemoveOtherLeaseLocatedOn($from: _OtherLeaseInput!, $to: _TractInput!) {
    RemoveOtherLeaseLocatedOn(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const ADD_OTHER_LEASE_OPERATOR = gql`
  mutation AddOperatorOtherLeases($from: _OperatorInput!, $to: _OtherLeaseInput!) {
    AddOperatorOtherLeases(from: $from, to: $to) {
      from {
        name
        id
        address1
        address2
        city
        state
        zip
        contacts {
          id
          contactName
          position
          directLine
          cell
          email
          date
          current
        }
      }
      to {
        id
      }
    }
  }
`;

export const REMOVE_OTHER_LEASE_OPERATOR = gql`
  mutation RemoveOperatorOtherLeases($from: _OperatorInput!, $to: _OtherLeaseInput!) {
    RemoveOperatorOtherLeases(from: $from, to: $to) {
      from {
        id
      }
      to {
        id
      }
    }
  }
`;

export const ADD_OTHER_LEASE_TITLE = gql`
  mutation addOtherLeaseTitle($LeaseID: ID!, $info: OtherLeaseTitleInfo) {
    addOtherLeaseTitle(LeaseID: $LeaseID, info: $info) {
      id
      titleRun {
        id
        volPage
        effectiveDate
        dateFiled
        comments
        grantor
        grantee
      }
    }
  }
`;

export const REMOVE_OTHER_LEASE_TITLE = gql`
  mutation removeOTHER_LEASETitle($OtherLeaseTitleID: ID!) {
    removeOtherLeaseTitle(OtherLeaseTitleID: $OtherLeaseTitleID) {
      id
      titleRun {
        id
        volPage
        effectiveDate
        dateFiled
        comments
        grantor
        grantee
      }
    }
  }
`;

export const UPDATE_OTHER_LEASE_TITLE = gql`
  mutation updateOtherLeaseTitle($OtherLeaseTitleID: ID!, $info: OtherLeaseTitleInfo) {
    updateOtherLeaseTitle(OtherLeaseTitleID: $OtherLeaseTitleID, info: $info) {
      id
      titleRun {
        id
        volPage
        effectiveDate
        dateFiled
        comments
        grantor
        grantee
      }
    }
  }
`;
