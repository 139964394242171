import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { useMutation } from '@apollo/client';
import { Mutation } from "@apollo/client/react/components";
import { CLONE_LEASE_CLAUSES, CREATE_LEASE, DELETE_LEASE, GET_ALL_LEASES } from '../../components/leases/LeaseQueries';
import { v4 } from 'uuid';
import LoadingView from '../../components/LoadingView';

const TemplateLeaseTable = ({ templateData, history }) => {
  const [tableData, setTableData] = useState(Array.isArray(...templateData) ? [...templateData] : []);

  const [deleteLease] = useMutation(DELETE_LEASE, {
    update: (store, { data: { DeleteLease } }) => {
      try {
        const AllLeasesQuery = store.readQuery({ query: GET_ALL_LEASES });

        const filteredLeases = AllLeasesQuery.Lease.filter((lease) => lease.id !== DeleteLease.id);

        store.writeQuery({ query: GET_ALL_LEASES, data: { Lease: filteredLeases } });
      } catch (e) {
        console.log(e);
      }
    },
  });

  const removeLease = (values) => {
    return deleteLease({
      variables: values,
    });
  };

  const [clauseClone] = useMutation(CLONE_LEASE_CLAUSES);

  const cloneClauses = (values) => {
    return clauseClone({
      variables: values,
    });
  };

  const columns = [
    {
      title: 'Lease',
      field: 'leaseName',
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'left',
      },
    },
  ];

  const tableUpdate = (leaseID) => setTableData(prevTableData => prevTableData.filter((lease) => lease.id !== leaseID));
  return (
    <Mutation mutation={CREATE_LEASE}>
      {(CreateLease, { loading, data, error }) => {
        if (loading) {
          return <LoadingView />;
        }
        if (error) {
          console.log(error);
        }
        return (
          <MaterialTable
            data={[...tableData]}
            columns={columns}
            title={''}
            options={{
              headerStyle: {
                backgroundColor: '#1976d2',
                color: '#FFF',
                textAlign: 'left',
              },
              selection: false,
              exportButton: true,
              pageSize: tableData.length,
              searchFieldAlignment: 'left',
              toolbarButtonAlignment: 'left',
              showTitle: false,
            }}
            editable={{
              onRowDelete: (oldData) => {
                return removeLease({ id: oldData.id }).then((data) => tableUpdate(oldData.id));
              },
            }}
            actions={[
              {
                icon: 'check',
                tooltip: 'Use Lease Template',
                onClick: (event, rowData) => {
                  console.log(rowData, 'Template To Be Cloned Data');
                  const newId = v4();
                  const useTemplateData = {
                    ...rowData,
                    id: newId,
                    leaseName: 'LeaseTemplate',
                    template: false,
                  };
                  CreateLease({
                    variables: useTemplateData,
                    update: (store, { data: { CreateLease } }) => {
                      try {
                        const AllLeasesQuery = store.readQuery({ query: GET_ALL_LEASES });

                        const data = { Lease: [...AllLeasesQuery.Lease, CreateLease] };

                        store.writeQuery({ query: GET_ALL_LEASES, data: data });
                      } catch (e) {
                        console.log('Could not add lease to all leases query due to it not being run yet');
                      }
                    },
                  })
                    .then((data) => {
                      return cloneClauses({
                        leaseID: newId,
                        clauses: rowData.leaseClauses.map((c) => c.id),
                      });
                    })
                    .then((data) => history.push(`/Leases/${newId}`));
                },
                //todo create new lease push to new lease
              },
              {
                icon: 'edit',
                tooltip: 'Edit Lease Template',
                onClick: (event, rowData) => history.push(`/EditLeaseTemplate/${rowData.id}`),
              },
            ]}
          />
        );
      }}
    </Mutation>
  );
};

export default TemplateLeaseTable;
