import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import React from 'react';
import WellMaster from './WellMaster';
import InactiveWells from './InactiveWells';
import InProgressWells from './InProgressWells';
import RightOfWay from './RightOfWay';
import CustomReports from "./CustomReports";

const Reports = () => {
  // Renders Tab
  function TabContainer(props) {
    return (
      <Typography component='div' style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }

  //State for tab value
  const [value, setValue] = React.useState(0);


  //Handle tab value change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position='static'>
        <Tabs value={value} onChange={handleChange}>
          <Tab label='Wells' />
          <Tab label='Inactive Wells' />
          <Tab label='InProgress Wells' />
          <Tab label='ROWs' />
          <Tab label='Production' />
          <Tab label='Leases' />
          <Tab label='Lands' />
          <Tab label='Operators' />
          <Tab label='Custom' />
        </Tabs>
      </AppBar>
      {value === 0 && (
        <TabContainer>
          <WellMaster />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <InactiveWells />
        </TabContainer>
      )}
      {value === 2 && (
        <TabContainer>
          <InProgressWells />
        </TabContainer>
      )}
      {value === 3 && (
        <TabContainer>
          <RightOfWay />
        </TabContainer>
      )}
      {value === 4 && <TabContainer>Production</TabContainer>}
      {value === 5 && <TabContainer>Leases</TabContainer>}
      {value === 6 && <TabContainer>Lands</TabContainer>}
      {value === 7 && <TabContainer>Operators</TabContainer>}
      {value === 8 && <TabContainer><CustomReports /></TabContainer>}
    </>
  );
};

export default Reports;
