import React from 'react';
import Link from 'react-router-dom/Link';
import MaterialTable from 'material-table';
import moment from 'moment';
import {intersperse} from "../units/UnitTable";

const ROWtable = ({ data }) => {

  const editableROWs = data.map((u) => ({ ...u }));
    editableROWs.forEach((row) => {
    const larr = new Set([]);
    row.locatedOn.map((tract) => larr.add(tract.id));
    row.locatedOn = [...larr].map((i) => i).join(',');
    });

  const columns = [
    {
      title: 'Row Name',
      field: 'projectName',
      defaultSort: 'asc',
      render: (rowData) => (
        <Link to={`/RightOfWays/${rowData.id}`} target='_blank'>
          {rowData.projectName === ' ' ? 'None' : rowData.projectName}
        </Link>
      ),
    },
    {
      title: 'Lessee',
      field: 'lessee',
    },
    {
      title: 'Type of ROW',
      field: 'type',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Expiration Date',
      field: 'dateOfExpiration',
      render: (rowData) =>
        moment(rowData.dateOfExpiration).isValid()
          ? moment(rowData.dateOfExpiration).format('MM/DD/YYYY')
          : rowData.dateOfExpiration,
    },
    {
      title: 'Lease Date',
      field: 'dateOfLease',
      render: (rowData) =>
        moment(rowData.dateOfLease).isValid() ? moment(rowData.dateOfLease).format('MM/DD/YYYY') : rowData.dateOfLease,
      customSort: (a, b) => moment.utc(a.dateOfLease).diff(moment.utc(b.dateOfLease))
    },
    {
      title: 'Land ID',
      field: 'locatedOn',
      render: (rowData) => {
        if (rowData.locatedOn.length === 0){
          return <span style={{ color: 'red' }}>Error No Connection To Tract</span>
        }
        else {
          const splits = rowData.locatedOn.split(',');
          const Links = splits.map((e) => (
              <Link to={`/Lands/${e}`} key={e} target='_blank'>
                {e.trim()}
              </Link>
          ));
          return intersperse(Links, ', ')
        }
      },
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={editableROWs}
      options={{
        searchFieldAlignment: 'left',
        exportButton: true,
        pageSize: data.length,
        showTitle: false,
        toolbarButtonAlignment: 'left',
        headerStyle: {
          backgroundColor: '#1976d2',
          color: '#FFF',
          textAlign: 'left',
          justifyContent: 'center',
          whiteSpace: 'pre',
        },
      }}
    />
  );
};

export default ROWtable;
