import gql from 'graphql-tag';

export const UPLOAD_FILE = gql`
    mutation singleUpload($file: Upload!, $fileSize: String, $name: String) {
        singleUpload(file: $file, fileSize: $fileSize, name: $name) {
            filename
            mimetype
        }
    }
`;

export const FILE_QUERY = gql`
    query($fileName: String!) {
        getFile(fileName: $fileName) {
            requestUrl
        }
    }
`;

