import React from 'react';
import MaterialTable from 'material-table';
import {Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';

const OtherLeasesTable = ({Tract}) => {
    console.log(Tract);
    const columns = [
        {
            title: 'Lease',
            field: 'id',
            render: (rowData) => {
                return (
                    <Link to={`/OtherLeases/${rowData.id}`} target={'_blank'}>
                        {rowData.projectName}
                    </Link>
                );
            },
        },
    ];

    const data = Tract.otherLeases;

    return (
        <div>
            <Typography
                variant='h5'
                style={{
                    color: '#1976d2',
                    fontSize: '1.75rem',
                    textAlign: 'center',
                    textDecoration: 'underline',
                }}
            >
                Other Leases
            </Typography>
            <MaterialTable
                columns={columns}
                data={data.map(d => ({...d}))}
                options={{
                    search: false,
                    showTitle: false,
                    paging: false,
                    exportButton: true,
                    toolbarButtonAlignment: 'left',
                    headerStyle: {
                        backgroundColor: '#1976d2',
                        color: '#FFF',
                        textAlign: 'left',
                        justifyContent: 'center',
                        whiteSpace: 'pre',
                    },
                }}
            />
        </div>
    );
};

export default OtherLeasesTable;
