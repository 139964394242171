import * as yup from 'yup';

//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const operatorSchema = yup.object().shape({
  id: yup
    .string()
    .required()
    .nullable(),
  name: yup
    .string()
    .required()
    .nullable(),
  alias: yup.string().nullable(),
  type: yup.string().nullable(),
  address1: yup
    .string()
    .required()
    .nullable(),
  address2: yup.string().nullable(),
  city: yup
    .string()
    .required()
    .nullable(),
  state: yup
    .string()
    .required()
    .nullable(),
  zip: yup
    .string()
    .required()
    .nullable(),
  phone: yup
    .string()
    .required()
    .nullable(),
  operatorNumber: yup
    .string()
    .required()
    .nullable(),
  notes: yup.string().nullable(),
  companyWebsite: yup.string().nullable(),
  mesAccountNumber: yup.string().nullable(),
  mpiAccountNumber: yup.string().nullable(),
  sroAccountNumber: yup.string().nullable(),
});

export default operatorSchema;
