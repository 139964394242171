import React from 'react';
import MaterialTable from 'material-table';
import {TextField, Typography} from '@material-ui/core';
import moment from 'moment';
import {FieldArray, Form, Formik} from 'formik';
import gql from 'graphql-tag';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import LoadingView from '../../components/LoadingView';
import {ADD_ROW_TITLE, REMOVE_ROW_TITLE, UPDATE_ROW_TITLE} from '../../components/row/ROWQueries';
import {FILE_QUERY, UPLOAD_FILE} from '../../components/files/fileQueries';
import FileUploadModal from "../../components/files/FileUploadModal";
import Uploader from "../../util/Uploader";

const titleQuery = gql`
    query ROW($id: ID!) {
        ROW(id: $id) {
            id
            projectName
            titleRun {
                id
                volPage
                effectiveDate
                dateFiled
                comments
                grantor
                grantee
                fileName
            }
        }
    }
`;

const ROWChainOfTitle = ({history, match, enqueueSnackbar, setValue}) => {
    const [loading, setLoading] = React.useState(false);
    const [addTitle] = useMutation(ADD_ROW_TITLE);
    const [deleteTitle] = useMutation(REMOVE_ROW_TITLE);
    const [editTitle] = useMutation(UPDATE_ROW_TITLE);
    const [fileKey, setFileKey] = React.useState(null);
    const [rowInfo, setRowInfo] = React.useState(null);
    const [fileUploadOpen, setFileUploadOpen] = React.useState(false);
    const [loadFile, {data: fileData, loading: fileLoading, error: fileError}] = useLazyQuery(FILE_QUERY,
        {variables: {fileName: fileKey}});

    const {data, loading: titleLoading, error} = useQuery(titleQuery, {
        variables: {id: match.params.rowID},
    });

    if (loading || titleLoading) {
        return <LoadingView/>;
    }
    if (error) {
        return error.toString();
    }

    const ROW = data.ROW[0] || [];

    // const editableTitleRun = ROW.titleRun.map((title) => ({...title}));

    const formikData = {
        finalData: ROW.titleRun.map((title) => ({...title}))
    }

    return (
        <div>
            <Uploader
                itemID={ROW.id}
                updateFunction={addTitle}
                typeOfUploader={"ROW"}
                history={history}
                setValue={setValue}
                setLoading={setLoading}
            />
            <Formik onSubmit={() => {
            }} initialValues={formikData}
            enableReinitialize={true}
            >
                {(props) => (
                    <Form>
                        <Typography
                            variant='h5'
                            style={{
                                color: '#1976d2',
                                fontSize: '1.75rem',
                                textAlign: 'center',
                                textDecoration: 'underline',
                            }}
                        >
                            ROW Chain Of Title
                        </Typography>
                        <br/>
                        <hr/>
                        <br/>
                        <Typography
                            variant='h5'
                            style={{
                                color: '#1976d2',
                                fontSize: '1.75rem',
                                textAlign: 'center',
                                textDecoration: 'underline',
                            }}
                        >
                            {ROW.projectName}
                        </Typography>
                        <FieldArray name='titleRun'>
                            {({push, remove, insert}) => {
                                const columns = [
                                    {
                                        title: 'Vol/Page',
                                        field: 'volPage',
                                        cellStyle: {
                                            whiteSpace: 'nowrap',
                                        },
                                    },
                                    {
                                        title: 'Date Filed',
                                        field: 'dateFiled',
                                        render: (rowData) => moment(rowData.dateFiled).format('MM/DD/YYYY'),
                                        // Keep in case we need
                                        // customSort: (a, b) => (moment(a.dateFiled).isAfter(moment(b.dateFiled)) ? 1 : -1),
                                        defaultSort: 'asc',
                                        editComponent: (props) => (
                                            <TextField type='date' onChange={(e) => props.onChange(e.target.value)}/>
                                        ),
                                    },
                                    {
                                        title: 'Effective Date',
                                        field: 'effectiveDate',
                                        render: (rowData) => moment(rowData.effectiveDate).format('MM/DD/YYYY'),
                                        editComponent: (props) => (
                                            <TextField type='date' onChange={(e) => props.onChange(e.target.value)}/>
                                        ),
                                    },
                                    {
                                        title: 'Grantor',
                                        field: 'grantor',
                                    },
                                    {
                                        title: 'Grantee',
                                        field: 'grantee',
                                        cellStyle: {
                                            whiteSpace: 'nowrap',
                                        },
                                    },
                                    {
                                        title: 'Comments',
                                        field: 'comments',
                                    },
                                ];

                                return (
                                    <MaterialTable
                                        columns={columns}
                                        data={props.values.finalData}
                                        options={{
                                            searchFieldAlignment: 'left',
                                            showTitle: false,
                                            toolbarButtonAlignment: 'left',
                                            addRowPosition: 'first',
                                            exportButton: true,
                                            paging: false,
                                            headerStyle: {
                                                backgroundColor: '#1976d2',
                                                color: '#FFF',
                                                textAlign: 'left',
                                                justifyContent: 'center',
                                                whiteSpace: 'pre',
                                            },
                                        }}
                                        editable={{
                                            onRowAdd: (newData) => {
                                                return addTitle({
                                                    variables: {
                                                        ROWID: ROW?.id,
                                                        rowinfo: {...newData},
                                                    },
                                                })
                                                    .then(({data: {addROWTitle}}) => {
                                                        push(addROWTitle.titleRun[0]);
                                                    })
                                                    .catch((error) => console.log(error));
                                            },
                                            onRowUpdate: (newData, oldData) => {
                                                const {__typename, id, ...updateData} = newData;
                                                return editTitle({
                                                    variables: {
                                                        ROWTitleID: oldData.id,
                                                        rowinfo: {...updateData},
                                                    },
                                                }).then(({data: {updateROWTitle}}) => {
                                                    remove(oldData.tableData.id);
                                                    insert(oldData.tableData.id, updateROWTitle.titleRun[0]);
                                                });
                                            },
                                            onRowDelete: (oldData) => {
                                                return deleteTitle({
                                                    variables: {
                                                        ROWID: props.values.id,
                                                        ROWTitleID: oldData.id,
                                                    },
                                                })
                                                    .then((data) => {
                                                        remove(oldData.tableData.id);
                                                    })
                                                    .catch((error) => console.log(error));
                                            },
                                        }}
                                        actions={
                                            [
                                                {
                                                    icon: 'addFile',
                                                    tooltip: 'Add File',
                                                    onClick: (event, rowData, tableData) => {
                                                        const {id} = rowData;
                                                        const rowID = match.params.rowID;
                                                        const fileName = `${rowID}/${id}`;
                                                        setFileKey(fileName);
                                                        setRowInfo(rowData);
                                                        setFileUploadOpen(!fileUploadOpen);
                                                    }
                                                },
                                                {
                                                    icon: 'map',
                                                    tooltip: 'View File',
                                                    onClick: async (event, rowData) => {
                                                        const {id} = rowData;
                                                        const leaseID = match.params.leaseID;
                                                        const fileName = `${leaseID}/${id}`;
                                                        setFileKey(rowData.fileName ? rowData.fileName : fileName);
                                                        try {
                                                            const response = await loadFile();
                                                            const {data: {getFile}} = response;
                                                            const url = getFile[0].requestUrl;
                                                            const link = document.createElement('a');
                                                            link.href = url;
                                                            link.click();
                                                        } catch (e) {
                                                            // console.log(e);
                                                        }
                                                    }
                                                }
                                            ]
                                        }
                                    />
                                );
                            }}
                        </FieldArray>
                        <FileUploadModal
                            open={fileUploadOpen}
                            setFileUploadOpen={setFileUploadOpen}
                            recordUpdate={editTitle}
                            rowInfo={rowInfo}
                            fileKey={fileKey}
                            values={props.values}
                            setFieldValue={props.setFieldValue}
                            uploadType={'ROW'}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ROWChainOfTitle;
