import React from 'react';
import { ApolloProvider } from '@apollo/client';
import Client from './components/apolloClient/apolloClient';
import { install } from '@material-ui/styles';
import Routes from './Routes';
import ThemeProvider from './Theme';
import { SnackbarProvider } from 'notistack';

export default function App() {
  install();

  return (
    <ApolloProvider client={Client}>
        <ThemeProvider>
          <SnackbarProvider maxSnack={5}>
            <Routes />
          </SnackbarProvider>
        </ThemeProvider>
    </ApolloProvider>
  );
}
