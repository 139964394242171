import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Typography, Paper, TextField } from '@material-ui/core';

function PasswordReset(props) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  // Access location from props
  const { history, location } = props;
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('t'); // Get the token from URL query parameters



  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    try {
      const data = { password, password2: confirmPassword, token };

      const response = await fetch(process.env.REACT_APP_RESET_PASS_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((json) => {

          switch(json.status){
            case 200:
              setMessage("Password reset successfully! You may now login using it.");
              localStorage.setItem('loginMessage', 'Password reset successfully! You may now login using it.');
              history.push('/');
              break;
            case 406:
              setMessage("Password must be at least 12 characters containing One special character, One number and One upperCase letter");
              break;
            case 412:
              setMessage("The link has expired, navigate to the login portal and try password reset");
              break;
            default:
              setMessage("Please try again later!");
              break;
          }


        })


    } catch (error) {
      console.error('Error:', error);
      setMessage('Failed to reset password. Network Error.');
    }
  };

  const glassEffectStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    padding: '30px',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
  };

  const rootStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%)',
  };

  return (
    <div style={rootStyle}>
      <Paper style={glassEffectStyle}>
        <Typography variant="h4" style={{ marginBottom: '20px' }}>
          Password Reset
        </Typography>
        {message && (
          <Typography color="error" style={{ marginBottom: '20px' }}>
            {message}
          </Typography>
        )}
        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TextField
            label="New Password"
            variant="outlined"
            type="password"
            style={{ width: '300px', marginBottom: '20px' }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            type="password"
            style={{ width: '300px', marginBottom: '20px' }}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            style={{ borderRadius: '20px', padding: '10px 30px' }}
          >
            Reset Password
          </Button>
        </form>
      </Paper>
    </div>
  );
}

export default withRouter(PasswordReset);
