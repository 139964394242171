import React from 'react';
import {Button} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import Typography from "@material-ui/core/Typography";
import {TextField} from "formik-material-ui";
import Iframe from "react-iframe";
import {useMutation, useQuery} from "@apollo/client";
import {EDIT_UNIT} from "../../components/units/UnitQueries";
import gql from "graphql-tag";
import LoadingView from "../../components/LoadingView";
import {useSnackbar} from "notistack/build";

const PLAT_QUERY = gql`
    query GetUnit($id: ID!) {
        Unit(id: $id) {
            id
            name
            platURL
        }
    }
`;

const UnitPlat = ({match}) => {
    const {params: {unitID}} = match;
    const {data, loading, error} = useQuery(PLAT_QUERY, {
        variables: {id: unitID},
    });
    const {enqueueSnackbar} = useSnackbar();

    const [updatePlat] = useMutation(EDIT_UNIT);

    if (loading) {
        return <LoadingView/>;
    }

    if (error) {
        return error.toString();
    }

    const Unit = data.Unit[0] || [];

    return (
        <Formik
            onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                return updatePlat({variables: values})
                    .then(() => {
                        enqueueSnackbar('Successfully updated Plat URL', {variant: 'success'});
                        actions.setSubmitting(false);
                    })
                    .catch((error) => {
                        enqueueSnackbar(`Could not update Plat URL: ${error.toString()}`, {variant: 'error'});
                        actions.setSubmitting(false);
                    });
            }}
            initialValues={Unit}
        >
            {(props) => {
                return (
                    <Form>
                        <div>
                            <Typography color='textSecondary' gutterBottom
                                        style={{textAlign: 'center'}}>
                                {Unit.name}
                            </Typography>
                        </div>
                        <br/>
                        <hr/>
                        <br/>
                        <Field
                            name='platURL'
                            placeholder='Plat URL'
                            component={TextField}
                            label='Plat URL'
                            fullWidth
                        />
                        <div>
                            <Button variant='contained' color='primary' type='submit' fullWidth>
                                Update Plat URL
                            </Button>
                        </div>
                        <div style={{paddingTop: 20, width: 'auto'}}>
                            <Iframe
                                url={props.values.platURL}
                                width='100%'
                                height='100%'
                                style={{display: !props.values.platURL ? 'none' : 'block'}}
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default UnitPlat;