import React from 'react';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/styles';
import Auth from '../../util/Auth';
import { Formik, Form, FieldArray } from 'formik';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';
import { withApollo } from '@apollo/client/react/hoc';
import moment from 'moment';
import Select from 'react-select';
import { LeaseSchema } from '../../util/schema/LeaseSchema';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.ADMIN);

const CDCWellsStyles = makeStyles((theme) => ({
  paper: {
    padding: 25,
    marginBottom: '1rem',
    marginTop: '1rem',
    display: 'grid',
    gridTemplateColumns: '80% 20%',
  },
  TextField: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    paddingRight: '1em',
  },
  form: {
    marginTop: '20px',
  },
  title: {
    color: '#1976d2',
    fontSize: '1.75rem',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  buttons: {
    display: hasPermissions ? '' : 'none',
  },

  notes: {
    width: '100%',
    height: '100%',
  },
  fab: {
    margin: '3rem',
  },
}));

const GET_CDC_WELL_INFO = gql`
  query GetWell($name: String!) {
    Well(name: $name) {
      id
      name
      completionDate
      rigReleaseDate
      spudDate
      status
      landId
    }
  }
`;

const CDCWells = ({ queries, client, Lease }) => {
  const classes = CDCWellsStyles();

  const { addCDCWell, wellAutoComplete, removeCDCWell, editCDCWell } = queries;

  const { data } = wellAutoComplete;

  const cdcStartDate = (cdcFrom, completionDate, rigReleaseDate) => {
    if (cdcFrom === 'Completion') {
      return completionDate;
    } else if (cdcFrom === 'RigRelease') {
      return rigReleaseDate;
    } else {
      return null;
    }
  };

  async function addWell(newData) {
    const FindWellByName = await client.query({
      query: GET_CDC_WELL_INFO,
      variables: { name: newData.name },
    });

    const {
      data: { Well },
    } = FindWellByName;

    const { completionDate, rigReleaseDate } = Well[0];

    const cdcDate = moment(cdcStartDate(Lease.cdcCalc, completionDate, rigReleaseDate))
      .add(newData.cdcTerm, 'days')
      .format('YYYY-MM-DD');

    return { ...Well[0], ...newData, cdcDate, leaseID: Lease.id };
  }

  return (
    <>
      <Formik initialValues={Lease} validationSchema={LeaseSchema} onSubmit={() => {}}>
        {(props) => {
          return (
            <Form className={classes.form}>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                CDC Wells
              </Typography>
              <FieldArray name='wells'>
                {({ push, remove, insert }) => {
                  const columns = [
                    {
                      title: 'Well Name',
                      field: 'name',
                      editable: 'onAdd',
                      editComponent: ({ onChange }) => (
                        <Select
                          options={data.Well}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          onChange={({ name }) => onChange(name)}
                        />
                      ),
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        minWidth: 100,
                      },
                    },
                    {
                      title: 'CDC Term',
                      field: 'cdcTerm',
                      type: 'numeric',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Land ID(s)',
                      field: 'landId',
                      editable: 'never',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Spud Date',
                      field: 'spudDate',
                      editable: 'never',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Rig Release Date',
                      field: 'rigReleaseDate',
                      editable: 'never',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Completion Date',
                      field: 'completionDate',
                      editable: 'never',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'CDC Date',
                      field: 'cdcDate',
                      editable: 'never',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                    {
                      title: 'Status',
                      field: 'status',
                      editable: 'never',
                      cellStyle: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      },
                    },
                  ];
                  return (
                    <MaterialTable
                      columns={columns}
                      data={props.values.wells.map(w => ({...w}))}
                      editable={{
                        onRowAdd: (newData) => {
                          return addWell(newData).then((data) => {
                            const { cdcDate, cdcTerm, name, leaseID } = data;
                            const mutationData = {
                              cdcDate,
                              cdcTerm,
                              wellName: name,
                              leaseID,
                            };
                            return addCDCWell(mutationData)
                              .then(() => {
                                push(data);
                              })
                              .catch((err) => console.log(err));
                          });
                        },
                        onRowUpdate: (newData, oldData) => {
                          const { cdcTerm, name } = newData;
                          const { completionDate, rigReleaseDate } = oldData;
                          const cdcDate = moment(cdcStartDate(Lease.cdcCalc, completionDate, rigReleaseDate))
                            .add(cdcTerm, 'days')
                            .format('YYYY-MM-DD');
                          newData.cdcTerm = cdcTerm;
                          newData.cdcDate = cdcDate;
                          return editCDCWell({ wellName: name, cdcTerm, cdcDate }).then(() => {
                            remove(oldData.tableData.id);
                            insert(oldData.tableData.id, newData);
                          });
                        },
                        onRowDelete: (oldData) => {
                          return removeCDCWell({ wellName: oldData.name, leaseID: Lease.id }).then(() => {
                            remove(oldData.tableData.id);
                          });
                        },
                      }}
                      options={{
                        headerStyle: {
                          backgroundColor: '#1976d2',
                          color: '#FFF',
                          textAlign: 'center',
                        },
                        cellStyle: {
                          maxWidth: 0,
                          overFlowX: 'auto',
                        },
                        exportButton: true,
                        searchFieldAlignment: 'left',
                        toolbarButtonAlignment: 'left',
                        pageSize: 10,
                      }}
                      title={'CDC Wells'}
                    />
                  );
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default withApollo(CDCWells);
